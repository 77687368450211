import { AxiosError } from 'axios';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  intakeFormUpdateFieldOptions,
  intakeFormUpdateFieldValue,
} from '~/actions';
import { downloadTicketAttachment, uploadAtttachments } from '~/api';
import { documentTypeIcon } from '~/components/DocumentsPage/Document.utils';
import DepartmentSelect from '~/components/Shared/DepartmentSelect';
import { showToast } from '~/components/Shared/EcToast';
import UserSelect from '~/components/Shared/UserSelect';
import {
  customSettingsKeys,
  customSettingsOptionValues,
  valueKeys,
} from '~/constants/workflow';
import { useAppConfigContext } from '~/contexts';
import {
  Box,
  CountrySelect,
  CurrencySelect,
  IconButton,
  Layout,
  MarkedText,
  NumberInput,
  Select,
  StateProvinceSelect,
  Text,
  TextArea,
  Tooltip,
  types,
} from '~/eds';
import {
  FileExtensionType,
  TicketSpecialOptionValueType,
  TicketStageType,
  WorkflowFieldType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { selectors } from '~/redux';
import { IntakeForm } from '~/redux/api/methods';
import { QuestionFieldState } from '~/redux/selectors/intakeForm';
import { IntakeFormFile, Nullable } from '~/types';
import * as toastTypes from '~/types/toast.types';
import {
  CheckboxGroup,
  DateInput,
  EmailInput,
  FileInput,
  InputLabel,
  RadioGroup,
  TextInput,
  NumberInput as UINumberInput,
} from '~/ui';
import { createOnFilterOption } from '~/utils';
import { downloadFile } from '~/utils/files';

interface Props {
  id: types.Uuid;
  isEditing: boolean;
  search: string;
}

interface FieldProps {
  isPanelEnlarged: boolean;
  disabled: boolean;
  field: QuestionFieldState;
  search: string;
  showError: boolean;
  validateAttachments: boolean;
  updateField: (id: types.Uuid, value: any) => void;
  intakeFormUpdateFieldOptions: (value: any) => void;
  setAttachments: React.Dispatch<
    React.SetStateAction<Nullable<IntakeFormFile[]>>
  >;
}

export const renderQuestionField = ({
  isPanelEnlarged,
  disabled,
  field,
  search,
  showError = false,
  updateField = noop,
  intakeFormUpdateFieldOptions: updateOptions = noop,
  setAttachments,
  validateAttachments = false,
}: FieldProps) => {
  const {
    customSettings,
    customSettings: { format, options },
    errorMessage,
    id,
    type,
    value: fieldValue,
  } = field;
  const valueKey: any = valueKeys[type as keyof typeof valueKeys];

  function handleFileAttachError(
    error: AxiosError<{ data: string }>,
    fileName: string,
  ) {
    let message = 'Attach file failed. Please try again.';
    if (validateAttachments && error?.response?.status === 400) {
      message = `${fileName} failed to upload. ${error.response.data.data}`;
    }
    showToast(toastTypes.ERROR, message);
  }

  function downloadFileAttachment(fileObject: any) {
    downloadTicketAttachment(fileObject.id)
      .then((file) => {
        showToast(
          toastTypes.DOWNLOAD,
          `${fileObject.fileName}.${fileObject.fileType} successfully downloaded.`,
        );
        downloadFile(file, `${fileObject.fileName}.${fileObject.fileType}`);
      })
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Download attachment failed. Please try again.',
        ),
      );
  }

  function getFieldValueByKey(key: string) {
    return fieldValue[key];
  }

  function setFieldValueByKey(key: string, newValue: any) {
    updateField(id, { ...fieldValue, [key]: newValue });
  }

  function setFieldOptions(options: any) {
    updateOptions({ id, options });
  }

  // TODO: Deprecate InputLabel after requirements for milestone are met.
  switch (type) {
    case WorkflowFieldType.Address: {
      // STREET_2 and STATE fields are non-required
      return (
        <Layout direction="column" spacing={2}>
          <InputLabel
            isRequired
            id="country"
            label="Select a Country"
            search={search}
          >
            <CountrySelect
              name="country-select"
              disabled={disabled}
              error={showError && errorMessage ? errorMessage : ''}
              value={getFieldValueByKey(valueKey.COUNTRY) || null}
              width={isPanelEnlarged ? 'input.l.width' : '100%'}
              onChange={(val: Nullable<string>) =>
                setFieldValueByKey(valueKey.COUNTRY, val)
              }
              isMulti={false}
              enablePortal={true}
            />
          </InputLabel>
          <InputLabel isRequired id="address" label="Address" search={search}>
            <TextInput
              //   @ts-ignore props mapping missing
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.STREET_1) && showError}
              placeholder="Street address 1"
              value={getFieldValueByKey(valueKey.STREET_1)}
              width={isPanelEnlarged ? 'l' : 'fullWidth'}
              onChange={(val: string) =>
                setFieldValueByKey(valueKey.STREET_1, val)
              }
            />
            <TextInput
              //   @ts-ignore props mapping missing
              disabled={disabled}
              placeholder="Street address 2"
              value={getFieldValueByKey(valueKey.STREET_2)}
              width={isPanelEnlarged ? 'l' : 'fullWidth'}
              onChange={(val: string) =>
                setFieldValueByKey(valueKey.STREET_2, val)
              }
            />
          </InputLabel>
          <InputLabel isRequired id="city" label="Town / City" search={search}>
            <TextInput
              //   @ts-ignore props mapping missing
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.CITY) && showError}
              placeholder="City"
              value={getFieldValueByKey(valueKey.CITY)}
              width={isPanelEnlarged ? 'm' : 'fullWidth'}
              onChange={(val: string) => setFieldValueByKey(valueKey.CITY, val)}
            />
          </InputLabel>
          <InputLabel
            isRequired
            id="city"
            label="State / Province"
            search={search}
          >
            <StateProvinceSelect
              name="state-province-select"
              country={getFieldValueByKey(valueKey.COUNTRY)}
              //   @ts-ignore props mapping missing
              disabled={disabled}
              error={showError && errorMessage ? errorMessage : ''}
              value={getFieldValueByKey(valueKey.STATE) || null}
              width={isPanelEnlarged ? 'input.m.width' : '100%'}
              onChange={(val: Nullable<string>) =>
                setFieldValueByKey(valueKey.STATE, val)
              }
              isMulti={false}
              enablePortal={true}
            />
          </InputLabel>
          <InputLabel
            isRequired
            id="zipCode"
            label="Zip Code / Post Code"
            search={search}
          >
            <TextInput
              //   @ts-ignore props mapping missing
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.ZIP_CODE) && showError}
              placeholder={
                getFieldValueByKey(valueKey.COUNTRY) === 'United States'
                  ? 'Zip Code'
                  : 'Post Code'
              }
              value={getFieldValueByKey(valueKey.ZIP_CODE)}
              width={isPanelEnlarged ? 's' : 'fullWidth'}
              onChange={(val: string) =>
                setFieldValueByKey(valueKey.ZIP_CODE, val)
              }
            />
          </InputLabel>
        </Layout>
      );
    }
    case WorkflowFieldType.Boolean: {
      const BOOLEAN_OPTIONS = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ];
      return (
        <Select
          name="boolean-select"
          disabled={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          options={BOOLEAN_OPTIONS}
          value={getFieldValueByKey(valueKey.VALUE) || null}
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          isMulti={false}
          enablePortal={true}
        />
      );
    }
    case WorkflowFieldType.Checkbox: {
      return (
        <Layout flexDirection="column" spacing={4}>
          <CheckboxGroup
            disabled={disabled}
            options={options || []}
            values={getFieldValueByKey(valueKey.VALUE) || []}
            onChange={(val: string[]) =>
              setFieldValueByKey(valueKey.VALUE, val)
            }
          />
          {getFieldValueByKey(valueKey.VALUE)?.includes(
            TicketSpecialOptionValueType.Other,
          ) && (
            <TextInput
              //   @ts-ignore props mapping missing
              disabled={disabled}
              placeholder="Specify other option..."
              value={getFieldValueByKey(valueKey.OTHER_VALUE)}
              width="m"
              onChange={(val: string) =>
                setFieldValueByKey(valueKey.OTHER_VALUE, val)
              }
            />
          )}
        </Layout>
      );
    }
    case WorkflowFieldType.Date: {
      return (
        <DateInput
          //   @ts-ignore props mapping missing
          disabled={disabled}
          error={showError}
          format={format}
          value={getFieldValueByKey(valueKey.VALUE)}
          width={isPanelEnlarged ? 'm' : 'fullWidth'}
          onChange={(val: string) => setFieldValueByKey(valueKey.VALUE, val)}
        />
      );
    }
    case WorkflowFieldType.Department: {
      const { departments } = customSettings;
      const blacklistOptions =
        customSettings[customSettingsKeys[type].RULE] ===
        customSettingsOptionValues[type].RULE.ALL_EXCEPT
          ? departments
          : [];
      const whitelistOptions =
        customSettings[customSettingsKeys[type].RULE] ===
        customSettingsOptionValues[type].RULE.SPECIFIC
          ? departments
          : [];

      return customSettings[customSettingsKeys[type].ALLOW_MULTIPLE] ? (
        <DepartmentSelect
          notAvailableDepartments={blacklistOptions}
          disabled={disabled}
          isMulti
          error={showError && errorMessage ? errorMessage : ''}
          value={getFieldValueByKey(valueKey.VALUE) || []}
          specificDepartments={whitelistOptions}
          width={isPanelEnlarged ? 'l' : '100%'}
          onChange={(val: number[]) => setFieldValueByKey(valueKey.VALUE, val)}
          enablePortal={true}
        />
      ) : (
        <DepartmentSelect
          notAvailableDepartments={blacklistOptions}
          disabled={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          value={getFieldValueByKey(valueKey.VALUE) || null}
          specificDepartments={whitelistOptions}
          width={isPanelEnlarged ? 'l' : '100%'}
          onChange={(val: number) => setFieldValueByKey(valueKey.VALUE, val)}
          enablePortal={true}
        />
      );
    }
    case WorkflowFieldType.Email: {
      return (
        <EmailInput
          //   @ts-ignore props mapping missing
          disabled={disabled}
          error={showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width={isPanelEnlarged ? 'm' : 'fullWidth'}
          onChange={(val: string) => setFieldValueByKey(valueKey.VALUE, val)}
        />
      );
    }
    case WorkflowFieldType.File: {
      const allFileTypes = Object.values(FileExtensionType);
      const allowedFileTypes =
        customSettings[customSettingsKeys[type].ALLOWED_FILE_TYPES];
      const acceptedFileTypes = allFileTypes
        .filter((type) => !allowedFileTypes?.includes(type))
        .concat(
          allowedFileTypes?.filter((t: any) => !allowedFileTypes?.includes(t)),
        )
        .toString();
      const allowMultipleFiles =
        customSettings[customSettingsKeys[type].FILE_LIMIT] !==
        customSettingsOptionValues[type].FILE_LIMIT.SINGLE;
      const customSettingsAllowedTypes =
        customSettings[customSettingsKeys[type].ALLOWED_FILE_TYPE_RULE];
      const fileTypes =
        customSettingsAllowedTypes ===
        customSettingsOptionValues[type].FILE_TYPE_RULE.ANY_EXCEPT
          ? acceptedFileTypes
          : customSettings[customSettingsKeys[type].ALLOWED_FILE_TYPES]
          ? customSettings[
              customSettingsKeys[type].ALLOWED_FILE_TYPES
            ].toString()
          : '*';

      const handleFileDataChange = (newFile: IntakeFormFile[]) => {
        setAttachments((prevState) => {
          const newAttachments = [...(prevState ? prevState : []), ...newFile];
          return newAttachments.length ? newAttachments : null;
        });
      };

      return (
        <Layout direction="column" spacing={8}>
          {fieldValue?.value?.map((fileObject: any) => {
            return (
              <Layout
                align="center"
                bg="gray-200"
                key={fileObject.id}
                justifyContent="space-between"
                mb={3}
                px={3}
                spacing={6}
                sx={{ borderRadius: 'm', height: 'input-height' }}
              >
                <Layout
                  align="center"
                  onClick={() => downloadFileAttachment(fileObject)}
                >
                  {documentTypeIcon(fileObject?.fileType)}
                  <Text variant="body-bold" color="inverse.text.link">
                    {fileObject.fileName}
                  </Text>
                </Layout>
                <IconButton
                  disabled={disabled}
                  icon="trash"
                  size="s"
                  tooltip="remove file"
                  onClick={() => {
                    const newFiles = fieldValue.value.filter(
                      (f: any) => f.id !== fileObject.id,
                    );
                    setAttachments(newFiles);
                  }}
                />
              </Layout>
            );
          })}
          {fieldValue?.value?.length && !allowMultipleFiles ? null : (
            <FileInput
              // @ts-ignore props mapping missing
              error={showError && errorMessage ? errorMessage : ''}
              acceptedFiles={fileTypes}
              enableUpload={!disabled}
              enableDropzone
              enableMultiple={allowMultipleFiles}
              onChange={(files) =>
                uploadAtttachments(
                  files,
                  handleFileDataChange,
                  handleFileAttachError,
                  validateAttachments ? field.id : undefined,
                )
              }
              label={undefined}
              file={undefined}
              id={undefined}
            />
          )}
        </Layout>
      );
    }
    case WorkflowFieldType.LongResponse: {
      return (
        <TextArea
          readOnly={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          name="Long Response"
          value={getFieldValueByKey(valueKey.VALUE)}
          width="100%"
          onChange={(value: Nullable<string>) =>
            setFieldValueByKey(valueKey.VALUE, value)
          }
        />
      );
    }
    case WorkflowFieldType.MonetaryValue: {
      const currencyRule =
        customSettings[customSettingsKeys[type].CURRENCY_RULE];
      const currencyList =
        customSettings[customSettingsKeys[type].CURRENCY_LIST];
      return (
        <Layout direction="column" spacing={2}>
          <CurrencySelect
            name="currency-select"
            width={isPanelEnlarged ? 'input.l.width' : '100%'}
            onFilterOption={createOnFilterOption({
              includeValues:
                currencyRule ===
                  customSettingsOptionValues[type].CURRENCY_RULE.SPECIFIC &&
                currencyList,
              excludeValues:
                currencyRule ===
                  customSettingsOptionValues[type].CURRENCY_RULE.ANY_EXCEPT &&
                currencyList,
            })}
            disabled={disabled}
            error={showError && errorMessage ? errorMessage : ''}
            value={getFieldValueByKey(valueKey.CURRENCY) || null}
            onChange={(val) => setFieldValueByKey(valueKey.CURRENCY, val)}
            isMulti={false}
            enablePortal={true}
          />
          <NumberInput
            disabled={disabled}
            error={showError && errorMessage ? errorMessage : ''}
            name="Monetary Value"
            value={getFieldValueByKey(valueKey.AMOUNT)}
            width={isPanelEnlarged ? 'input.l.width' : '100%'}
            onChange={(val: Nullable<number>) =>
              setFieldValueByKey(valueKey.AMOUNT, val)
            }
          />
        </Layout>
      );
    }
    case WorkflowFieldType.MultiSelect: {
      const handleAddOption = (newOption: any) => {
        setFieldOptions([...options, newOption]);
      };
      return (
        <Select
          name="multi-select"
          disabled={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          isMulti
          options={options || []}
          placeholder="Pick an option..."
          value={getFieldValueByKey(valueKey.VALUE) || null}
          width={isPanelEnlarged ? 'input.l.width' : '100%'}
          onAddOption={
            customSettings[customSettingsKeys[type].ENABLE_CREATE_OPTIONS]
              ? handleAddOption
              : undefined
          }
          onChange={(val: Nullable<string[]>) =>
            setFieldValueByKey(valueKey.VALUE, val)
          }
          enablePortal={true}
        />
      );
    }
    case WorkflowFieldType.Number: {
      const { allow_decimals } = customSettings;
      // TODO: Update NumberInput to handle decimals.
      return (
        <UINumberInput
          //   @ts-ignore props mapping missing
          disabled={disabled}
          error={showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width={isPanelEnlarged ? 's' : 'fullWidth'}
          onChange={(val: Nullable<number>) =>
            setFieldValueByKey(valueKey.VALUE, val)
          }
          allowDecimals={!!allow_decimals}
        />
      );
    }
    case WorkflowFieldType.Percentage: {
      const { min, max } = customSettings;
      return (
        <NumberInput
          disabled={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          max={max}
          min={min}
          name="Monetary Value"
          value={getFieldValueByKey(valueKey.VALUE)}
          width={isPanelEnlarged ? 'input.s.width' : '100%'}
          onChange={(val: Nullable<number>) =>
            setFieldValueByKey(valueKey.VALUE, val)
          }
        />
      );
    }
    case WorkflowFieldType.Radio: {
      return (
        <Layout flexDirection="column" space={4}>
          <RadioGroup
            disabled={disabled}
            options={options || []}
            value={getFieldValueByKey(valueKey.VALUE)}
            onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          />
          {getFieldValueByKey(valueKey.VALUE) ===
            TicketSpecialOptionValueType.Other && (
            <Box mt={4}>
              <TextInput
                //   @ts-ignore props mapping missing
                disabled={disabled}
                placeholder="Specify other option..."
                value={getFieldValueByKey(valueKey.OTHER_VALUE)}
                width="m"
                onChange={(val: string) =>
                  setFieldValueByKey(valueKey.OTHER_VALUE, val)
                }
              />
            </Box>
          )}
        </Layout>
      );
    }
    case WorkflowFieldType.ShortResponse: {
      if (disabled) {
        return (
          <Text preset="description">
            {getFieldValueByKey(valueKey.VALUE) || 'Enter a value...'}
          </Text>
        );
      }
      return (
        <TextInput
          //   @ts-ignore props mapping missing
          disabled={disabled}
          error={showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width={isPanelEnlarged ? 'l' : 'fullWidth'}
          onChange={(val: string) => setFieldValueByKey(valueKey.VALUE, val)}
        />
      );
    }
    case WorkflowFieldType.SingleSelect: {
      const handleAddOption = (newOption: any) => {
        setFieldOptions([...options, newOption]);
      };
      if (disabled) {
        return (
          <Text preset="description">
            {getFieldValueByKey(valueKey.VALUE) || 'Select an option...'}
          </Text>
        );
      }
      return (
        <Select
          name="single-select"
          disabled={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          options={options || []}
          value={getFieldValueByKey(valueKey.VALUE) || null}
          width={isPanelEnlarged ? 'input.l.width' : '100%'}
          onAddOption={
            customSettings[customSettingsKeys[type].ENABLE_CREATE_OPTIONS]
              ? handleAddOption
              : undefined
          }
          onChange={(val: string) => setFieldValueByKey(valueKey.VALUE, val)}
          isMulti={false}
          enablePortal={true}
        />
      );
    }
    case WorkflowFieldType.TimePeriod: {
      return (
        <Layout direction="column" spacing={2}>
          <NumberInput
            disabled={disabled}
            error={showError && errorMessage ? errorMessage : ''}
            name="Time Period"
            value={getFieldValueByKey(valueKey.AMOUNT)}
            width={isPanelEnlarged ? 'input.s.width' : '100%'}
            onChange={(val: Nullable<number>) =>
              setFieldValueByKey(valueKey.AMOUNT, val)
            }
          />
          <Select
            name="time-period"
            disabled={disabled}
            error={showError && errorMessage ? errorMessage : ''}
            options={options || []}
            value={getFieldValueByKey(valueKey.PERIOD) || null}
            width={isPanelEnlarged ? 'input.m.width' : '100%'}
            onChange={(val) => setFieldValueByKey(valueKey.PERIOD, val)}
            isMulti={false}
            enablePortal={true}
          />
        </Layout>
      );
    }
    case WorkflowFieldType.User: {
      const { users } = customSettings;
      if (customSettings[customSettingsKeys[type].ALLOW_MULTIPLE]) {
        return (
          <UserSelect
            name="user-select"
            notAvailableUsers={
              customSettings[customSettingsKeys[type].RULE] ===
              customSettingsOptionValues[type].RULE.ALL_EXCEPT
                ? users
                : undefined
            }
            disabled={disabled}
            error={showError && errorMessage ? errorMessage : ''}
            isMulti
            placeholder="Specify one or more users…"
            value={getFieldValueByKey(valueKey.VALUE) || null}
            specificUsers={
              customSettings[customSettingsKeys[type].RULE] ===
              customSettingsOptionValues[type].RULE.SPECIFIC
                ? users
                : undefined
            }
            width={isPanelEnlarged ? 'input.l.width' : '100%'}
            onChange={(val: Nullable<types.PilotId[] | null>) =>
              setFieldValueByKey(valueKey.VALUE, val)
            }
            enablePortal={true}
          />
        );
      }
      return (
        <UserSelect
          name="user-select"
          notAvailableUsers={
            customSettings[customSettingsKeys[type].RULE] ===
            customSettingsOptionValues[type].RULE.ALL_EXCEPT
              ? users
              : undefined
          }
          disabled={disabled}
          error={showError && errorMessage ? errorMessage : ''}
          isMulti={false}
          placeholder="Specify a user…"
          value={getFieldValueByKey(valueKey.VALUE) || null}
          specificUsers={
            customSettings[customSettingsKeys[type].RULE] ===
            customSettingsOptionValues[type].RULE.SPECIFIC
              ? users
              : undefined
          }
          width={isPanelEnlarged ? 'input.l.width' : '100%'}
          onChange={(val: Nullable<types.PilotId>) =>
            setFieldValueByKey(valueKey.VALUE, val)
          }
          enablePortal={true}
        />
      );
    }
    default:
      return null;
  }
};

export const WorkflowQuestion = ({ id, isEditing, search }: Props) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const { isPanelEnlarged } = useAppConfigContext();
  const {
    description,
    isRequired,
    title,
    isReadOnly: isQuestionReadOnly,
  } = useSelector((intakeForm: IntakeForm) =>
    selectors.selectFormQuestion(intakeForm, id),
  );
  const field = useSelector((intakeForm: IntakeForm) =>
    selectors.selectQuestionField(intakeForm, id),
  );
  const [attachments, setAttachments] = useState<Nullable<IntakeFormFile[]>>(
    field.type === WorkflowFieldType.File ? field.value.value : null,
  );
  const isFormValidated = useSelector(selectors.selectFormValidation);
  const ticketStage = useSelector(selectors.selectTicketStage);
  const {
    customSettings,
    errorMessage,
    id: fieldId,
    isEnabled,
    isPlaceHolder,
    type,
    warningMessage,
  } = field;
  const showError = !!(isFormValidated && errorMessage);
  const updateField = (id: types.Uuid, value: any) =>
    dispatch(intakeFormUpdateFieldValue({ id, value: value }));

  const isCheckFormFieldFileExtensionEnabled = useFlag(
    FlagType.CheckFormFieldFileExtension,
  );

  const isIntakeFormFieldWithReadOnlyEnabled = useFlag(
    FlagType.IntakeFormFieldWithReadOnly,
  );

  const isReadOnly = isIntakeFormFieldWithReadOnlyEnabled
    ? isQuestionReadOnly
    : false;

  // populate email when email_type is submitter
  useEffect(() => {
    const isSubmitterType =
      customSettings?.[
        customSettingsKeys[WorkflowFieldType.Email].EMAIL_TYPE
      ] ===
      customSettingsOptionValues?.[WorkflowFieldType.Email].EMAIL_TYPE
        .SUBMITTER;
    if (type === WorkflowFieldType.Email && isSubmitterType) {
      updateField(fieldId, { value: currentUser.email });
    }
  }, []);

  useEffect(() => {
    if (attachments && field && field.id) {
      updateField(field.id, { ...field.value, value: attachments });
    }
  }, [attachments]);

  useEffect(() => {
    if (field && field.type === WorkflowFieldType.File && field.value) {
      setAttachments(field.value.value);
    }
  }, [field.value]);

  if (
    !isEnabled ||
    [WorkflowFieldType.DateSigned, WorkflowFieldType.Signature].includes(type)
  ) {
    return null;
  }

  const isDisabled =
    (isPlaceHolder &&
      [TicketStageType.Sign, TicketStageType.Finalize].includes(ticketStage)) ||
    !isEditing;

  const questionComponent = renderQuestionField({
    isPanelEnlarged: isPanelEnlarged,
    disabled: isDisabled || isReadOnly,
    field,
    search,
    showError,
    updateField,
    intakeFormUpdateFieldOptions,
    setAttachments,
    validateAttachments: isCheckFormFieldFileExtensionEnabled,
  });

  return (
    <Layout direction="column" spacing={2}>
      <MarkedText search={search} text={isRequired ? `${title} *` : title} />
      {description && (
        <Text preset="description">
          <MarkedText search={search} text={description} />
        </Text>
      )}
      <Layout direction="column" spacing={2}>
        {questionComponent && (
          <Tooltip
            tooltip={
              isReadOnly &&
              'This is a synced field and cannot be edited in Evisort'
            }
          >
            {questionComponent}
          </Tooltip>
        )}
        {showError && <Text color="status.danger">{errorMessage}</Text>}
        {
          // do not show the warningMessage when creating a ticket but need to show it afterwards (refer to EKP-2186)
          !!ticketStage && warningMessage && <Text>{warningMessage}</Text>
        }
      </Layout>
    </Layout>
  );
};
