import React from 'react';
import { useDispatch } from 'react-redux';

import { Info, Panel } from '~/eds';
import { actions } from '~/redux';
import { Nullable } from '~/types';

import { Endpoint } from './types';

interface Props {
  activeEndpoint: Nullable<Endpoint>;
}

export const EndpointConfiguration = ({ activeEndpoint }: Props) => {
  const dispatch = useDispatch();

  if (!activeEndpoint) return null;

  return (
    <Panel
      actions={[
        {
          icon: 'x',
          text: 'Close',
          onClick: () => dispatch(actions.setActiveEndpoint(null)),
        },
      ]}
      children={
        <Info
          sections={[
            {
              title: activeEndpoint.endpointUrl,
              infos: [
                {
                  title: 'Status',
                  type: 'text' as const,
                  props: { text: activeEndpoint.status },
                },
                {
                  title: 'Description',
                  type: 'text' as const,
                  props: { text: activeEndpoint.description },
                },
                {
                  title: 'Selected Events',
                  type: 'text' as const,
                  props: {
                    text: activeEndpoint.selectedEvents.join(', ').toString(),
                  },
                },
                {
                  title: 'Created',
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Created',
                    date: activeEndpoint.dateCreated,
                    user: {
                      id: 1,
                      firstName: 'User',
                      lastName: 'Name',
                      email: '',
                    },
                  },
                },
                {
                  title: 'Modified',
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Modified',
                    date: activeEndpoint.dateModified,
                    user: {
                      id: 2,
                      firstName: 'User',
                      lastName: 'Name',
                      email: '',
                    },
                  },
                },
                {
                  title: 'Secure Signing',
                  type: 'content' as const,
                  props: {
                    children: <div>KEY</div>,
                  },
                },
                {
                  title: '',
                  type: 'content' as const,
                  props: {
                    children: <div>See all attempts</div>,
                  },
                },
              ],
            },
          ]}
        />
      }
      footer={{
        actions: [
          {
            text: 'Delete',
            level: 'tertiary',
            onClick: () => {
              dispatch(actions.setEndpointAction('delete'));
            },
          },
          {
            text: 'Suspend',
            onClick: () => {
              dispatch(actions.setEndpointAction('suspend'));
            },
          },
          {
            text: 'Edit',
            onClick: () => {
              dispatch(actions.setEndpointAction('edit'));
            },
          },
        ],
      }}
      hidden={{
        isHidden: false,
        onHide: () => dispatch(actions.setActiveEndpoint(null)),
      }}
      position="right"
      title="Endpoint Configuration"
      width="m"
    />
  );
};
