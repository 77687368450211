import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { DashboardIframe } from '~/components/DashboardsPage';
import DashboardV2 from '~/components/DashboardV2';
import { DashboardV3 } from '~/components/DashboardV3';
import { ContentContainer, EmptyPage, ErrorPage, useToast } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, useDashboardsSubnavs } from '~/hooks';
import { api } from '~/redux';
import { RoutePathType } from '~/routing';
import { FlexLayout } from '~/ui';
import { testIsAdmin } from '~/utils/user';

const Page = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { dashboardId } = useParams();
  const { dashboards, isFetching } = useDashboardsSubnavs();
  const hasDashboardV2 = useFlag(FlagType.DashboardV2);
  const hasDashboardV3 = useFlag(FlagType.DashboardV3); // search service
  const hasRBAC = useFlag(FlagType.NewUsersPage); // RBAC
  const { toast } = useToast();

  const isLegacyDashboard = useMemo(() => {
    // Only legacy dashboards have the type attribute.
    // Can be removed once we deprecate looker dashboars.
    return dashboards.find((dashboard) => dashboard.type === dashboardId);
  }, [dashboardId, dashboards]);

  const {
    data: dashboardResponse,
    error: errorFetchingDashboard,
    isFetching: isFetchingDashboard,
    isError: isErrorFetchingDashboard,
  } = api.endpoints.getDashboardV2.useQuery(dashboardId, {
    skip:
      !dashboards.length ||
      !dashboardId ||
      !hasDashboardV2 ||
      isLegacyDashboard,
  });
  const errorCode = errorFetchingDashboard?.response?.status;
  const isAdmin = testIsAdmin(currentUser);
  const isTicketMockedDashboard =
    dashboardId === 'open_tickets' || dashboardId === 'completed_tickets';

  useEffect(() => {
    if (!dashboardId && dashboards?.length && !isFetching) {
      const [firstDashboard] = dashboards;
      if (hasDashboardV2 && !!firstDashboard.creator_id) {
        history.replace(`/dashboards/${firstDashboard.id}`);
      } else {
        history.replace(`/dashboards/${firstDashboard.type}`);
      }
    }
  }, [dashboards, dashboardId, isFetching]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateBack = () => history.push(RoutePathType.Home);

  const getErrorToast = () => {
    const resp = errorFetchingDashboard?.response || {};
    const msg = resp.data?.errors?.[0];
    if (resp.status === HttpStatusCodeType.NotFound) {
      return 'Dashboard not found';
    } else if (msg) {
      return msg.title;
    } else {
      return 'An error occurred while loading the dashboard.';
    }
  };
  useEffect(() => {
    if (isErrorFetchingDashboard) {
      if (errorCode !== HttpStatusCodeType.Forbidden) {
        toast({
          message: getErrorToast(),
          status: 'danger',
        });
      }
    }
  }, [isErrorFetchingDashboard]);

  const shouldRenderLegacyDashboards =
    !hasDashboardV2 ||
    (hasDashboardV2 &&
      (dashboardId === 'tickets' || dashboardId === 'workflows'));

  const isPreSigDashboard =
    dashboardResponse?.entity === 'ticket' ||
    dashboardResponse?.entity === 'workflow';

  const renderDashboard = () => {
    if (shouldRenderLegacyDashboards) {
      return <DashboardIframe type={dashboardId} />;
    }

    // TODO: Remove once we deprecate mock data for pre-signature dashboards
    if (isTicketMockedDashboard && !hasRBAC && !isAdmin) {
      return (
        <ErrorPage
          preset="page-access"
          resolveAction={{ onClick: navigateBack }}
        />
      );
    }

    if (!isErrorFetchingDashboard && dashboardResponse) {
      if (isPreSigDashboard && hasDashboardV3) {
        return <DashboardV3 dashboard={dashboardResponse} />;
      } else {
        return <DashboardV2 dashboard={dashboardResponse} />;
      }
    }
    if (errorCode === HttpStatusCodeType.Forbidden) {
      return (
        <ErrorPage
          preset="page-access"
          resolveAction={{ onClick: navigateBack }}
        />
      );
    }
    return <EmptyPage preset="no-dashboard" />;
  };

  return (
    <FlexLayout flexDirection="column" space="x4">
      <ContentContainer
        loadingContent={{
          isLoading: isFetching || isFetchingDashboard,
          message: 'Loading Dashboards...',
        }}
      >
        {renderDashboard()}
      </ContentContainer>
    </FlexLayout>
  );
};

export default Page;
