import React, { useState } from 'react';

import { Box, Modal } from '~/eds';
import { Nullable, PilotId } from '~/types';

import { DocumentViewer, Props as DocumentViewerProps } from './DocumentViewer';

interface Props {
  documentViewer: DocumentViewerProps;
  onHide: () => void;
}

export const DocumentViewerModal = ({ documentViewer, onHide }: Props) => {
  const [resolvedDoc, setResolvedDoc] = useState<
    Nullable<{
      documentId: PilotId;
      file: File;
    }>
  >(null);

  return (
    <Modal
      isVisible={Boolean(documentViewer.documentVersionId)}
      title={resolvedDoc?.file.name ?? 'Document Viewer'}
      titleLink={
        resolvedDoc
          ? { pathname: `/document/${resolvedDoc.documentId}/` }
          : undefined
      }
      onHide={onHide}
      children={
        <Box styles={{ height: '60vh' }}>
          <DocumentViewer
            {...documentViewer}
            onResolveDocument={setResolvedDoc}
          />
        </Box>
      }
    />
  );
};
