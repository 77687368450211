import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~/eds';
import { actions, selectors } from '~/redux';

export const EndpointModal = () => {
  const dispatch = useDispatch();
  const handleHide = () => dispatch(actions.setEndpointAction(null));

  const action = useSelector(selectors.selectEndpointAction);

  const modalProps = {
    children: <div>TBD</div>,
    isFullPage: true,
    isVisible: Boolean(action),
    primaryAction: {
      onClick: () => {},
      text: 'Add Endpoint',
    },
    title: 'Add Endpoint',
    onHide: handleHide,
    onCancel: handleHide,
  };

  return <Modal {...modalProps} />;
};
