import { pilot } from '../services';

/**
 * @param {object} params
 * @param {string} params.client client/workspace
 * @param {string} params.userId user email
 * @returns {Promise<{msg: string}>}
 */
export const sendForgotPasswordEmail = (params) => {
  return pilot.post('login/forgot-password/', params);
};

export const sendInvitationEmail = (data) => {
  return pilot.post('login/admin-reset-password/', data);
};

export const changePassword = ({ token, formData }) => {
  return pilot.post(`login/change-password/${token}/`, formData);
};

export const activateUserAccount = ({ token, formData }) => {
  return pilot.post(`login/activation/${token}/`, formData);
};

export const updatePassword = ({ formData }) => {
  return pilot.put('/user/change-password/', formData);
};
