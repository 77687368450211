import { useState } from 'react';

import {
  Form,
  formatDate,
  Icon,
  Layout,
  requiredValidator,
  Text,
  TextArea,
  TextInput,
  types,
  useModal,
  User,
  useToast,
} from '~/eds';
import { useResolveUsers } from '~/hooks';
import { api } from '~/redux';
import { Question } from '~/types';

import { QuestionGroupSelect } from './QuestionGroupSelect';

interface EditQuestionFormProps {
  question: Question;
}

const fields = {
  name: {
    key: 'name',
    type: 'field' as const,
    name: 'name',
    label: 'Saved Question Name',
    optional: true,
    input: TextInput,
    placeholder: 'e.g. Contract Law Compliance',
  },
  text: {
    key: 'text',
    type: 'field' as const,
    name: 'text',
    label: 'Saved Question',
    input: TextArea,
  },
  questionGroups: {
    key: 'questionGroups',
    type: 'field' as const,
    name: 'questionGroups',
    label: 'Add to Question Group(s)',
    description: 'Search and select the question group(s).',
    input: QuestionGroupSelect,
  },
};

type QuestionForm = {
  name: string;
  text: string;
  questionGroups: string[];
};

export const useEditQuestionModal = ({ question }: EditQuestionFormProps) => {
  const [questionData, setQuestionData] = useState<Question>(question);
  const [hasErrors, setHasErrors] = useState(false);
  const { users } = useResolveUsers({
    userIds: [question.createdBy],
  });

  const { toast } = useToast();

  const [
    updateQuestion,
    { isLoading: isUpdating },
  ] = api.endpoints.updateQuestion.useMutation();

  const questionInitialState = {
    name: { value: question.name },
    text: { value: question.text },
    questionGroups: { value: question.questionGroupIds },
  };

  const handleUpdateQuestion = async () => {
    try {
      await updateQuestion({
        id: question.id,
        name: questionData.name,
        text: questionData.text,
        questionGroupIds: questionData.questionGroupIds,
      }).unwrap();
      toast({
        status: 'success',
        message: `The question has been successfully updated`,
      });
    } catch (apiError: any) {
      toast({
        status: 'danger',
        message: `The question updated failed due to ${apiError.errors[0].title}.`,
      });
    }
  };

  const handleOnChange = ({
    state,
    errors,
  }: types.Form.StateUpdater<QuestionForm>) => {
    setHasErrors(Object.values(errors || {}).some((error) => error.length > 0));
    setQuestionData({
      ...question,
      name: state.name.value,
      text: state.text.value,
      questionGroupIds: state.questionGroups.value,
    });
  };

  const renderForm = () => {
    return (
      <>
        <Form<QuestionForm>
          fields={fields}
          onChange={handleOnChange}
          state={questionInitialState}
          validators={[requiredValidator(['text'])]}
        />
        <Layout flex={1} my={4} spacing={4}>
          <Layout direction="column" flex={1}>
            <Text variant="tiny">Created</Text>
            <Layout spacing={1} direction="column">
              <Layout spacing={2}>
                <Icon icon="field-date" size="s" />
                <Text>{formatDate(question.createdAt, 'long')}</Text>
              </Layout>
              <Layout spacing={2}>
                <Icon icon="user" size="s" />
                <User user={users?.[question.createdBy]} mode="name" />
              </Layout>
            </Layout>
          </Layout>
          <Layout direction="column" flex={1}>
            <Text variant="tiny">Modified</Text>
            <Layout spacing={1} direction="column">
              <Layout spacing={2}>
                <Icon icon="field-date" size="s" />
                <Text>{formatDate(question.updatedAt, 'long')}</Text>
              </Layout>
              <Layout spacing={2}>
                <Icon icon="user" size="s" />
                <User user={users?.[question.createdBy]} mode="name" />
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  };

  const modal = useModal({
    title: 'Edit',
    children: renderForm(),
    primaryAction: {
      text: 'Save',
      onClick: handleUpdateQuestion,
      isLoading: isUpdating,
      disabled: hasErrors,
      tooltip: hasErrors ? 'Please fill in all required fields' : undefined,
    },
  });

  return modal;
};
