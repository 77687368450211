import { DEFAULT_POLL_INTERVAL_MS } from '~/eds';
import { api } from '~/redux';
import { Nullable, Uuid } from '~/types';

import { OptimizeState } from '../types';
import { testIsOptimizingState } from '../utils';

interface Props {
  initialOptimizeState: Nullable<OptimizeState>;
  modelId: Nullable<Uuid>;
  version: Nullable<number>;
}

// TODO: deprecate once pages/fields/DEPRECATED_index.tsx is removed
export const usePollModelVersionOptimizeState = ({
  initialOptimizeState = null,
  modelId,
  version,
}: Props) => {
  const {
    data: optimizeResults = null,
  } = api.endpoints.getPromptModelOptimizeResults.useQuery(
    {
      modelId: modelId!,
      version: version!,
    },
    {
      pollingInterval: testIsOptimizingState(initialOptimizeState)
        ? DEFAULT_POLL_INTERVAL_MS
        : undefined,
      skip: !modelId || !version,
    },
  );

  return {
    optimizeState: optimizeResults?.state,
  };
};
