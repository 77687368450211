import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { showToast } from '~/components/Shared/EcToast';
import { DashboardType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { api, coerceRtkqError } from '~/redux';
import { preSigMocks } from '~/redux/api/methods';
import { Dashboard as DashboardObjectType } from '~/types';
import { ERROR } from '~/types/toast.types';

import { useCurrentUser } from './useCurrentUser';
import { usePermission } from './usePermission';

export const useDashboardsSubnavs = () => {
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const hasDashboardV2 = useFlag(FlagType.DashboardV2);
  const hasDashboardV3 = useFlag(FlagType.DashboardV3);
  const hasRBACEnabled = useFlag(FlagType.NewUsersPage);
  const {
    isLoading: isWorkflowDashboardPermissionLoading,
    hasPermission: hasWorkflowDashboardPermission,
  } = usePermission({
    permission: { resourceId: 'workflows_dashboard', resourceType: 'view' },
  });
  const {
    isLoading: isDocDashboardPermissionLoading,
    hasPermission: hasDocDashboardPermission,
  } = usePermission({
    permission: { resourceId: 'documents_dashboard', resourceType: 'view' },
  });
  const [
    loadLegacyDashboards,
    legacyDashboardsResults,
  ] = api.endpoints.getDashboards.useLazyQuery(undefined);
  const { data: legacyDashboards = [] } = legacyDashboardsResults;
  const [
    getDashboardsV2,
    {
      data: dashboardsResponse,
      isError: isFetchingDashboardsErrorV2,
      isSuccess: isFetchingDashboardsSuccessV2,
    },
  ] = api.endpoints.getDashboardsV2.useLazyQuery();

  const [
    createDashboards,
    createDefaultDashboardsResult,
  ] = api.endpoints.createDefaultDashboards.useLazyQuery(undefined);
  const {
    data: defaultDashboardsResponse,
    error,
    isSuccess: isCreatingDefaultDashboardsSuccess,
    isError: isCreatingDefaultDashboardsError,
  } = createDefaultDashboardsResult;

  const hasDefaultDashboards = dashboardsResponse?.results.some(
    (d: any) => d.is_default,
  );

  const isLoadingPermissions =
    isWorkflowDashboardPermissionLoading || isDocDashboardPermissionLoading;

  const hasDocumentDashboardPermission =
    !isDocDashboardPermissionLoading && hasDocDashboardPermission;

  const isDefaultDashboardsSet =
    !hasDashboardV2 || !hasDocumentDashboardPermission
      ? true
      : hasDefaultDashboards ||
        isFetchingDashboardsErrorV2 ||
        (defaultDashboardsResponse?.length &&
          isCreatingDefaultDashboardsSuccess);

  const isFetching = isLoadingPermissions || !isDefaultDashboardsSet;

  const checkIfLegacyDashboard = (i: DashboardObjectType) =>
    i['type'] === ('tickets' as DashboardType) ||
    i['type'] === ('workflows' as DashboardType);

  const preSigDashboards =
    hasDashboardV3 && hasWorkflowDashboardPermission
      ? preSigMocks(currentUser, hasRBACEnabled)
      : legacyDashboards.filter(checkIfLegacyDashboard);

  useEffect(() => {
    if (
      pathname.startsWith('/dashboards') ||
      pathname.startsWith('/saved-dashboards')
    ) {
      // still need to load legacy dashboards for tickets and workflows tabs uuid
      if (!hasDashboardV3) {
        loadLegacyDashboards(undefined);
      }
      if (hasDashboardV2) {
        getDashboardsV2({ page: 1, pageSize: 2, return_defaults: true });
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (
      hasDashboardV2 &&
      hasDocumentDashboardPermission &&
      !!isFetchingDashboardsSuccessV2 &&
      dashboardsResponse?.results.length === 0
    ) {
      createDashboards(undefined);
    }
  }, [
    hasDocumentDashboardPermission,
    isFetchingDashboardsSuccessV2,
    dashboardsResponse,
  ]);

  useEffect(() => {
    if (isCreatingDefaultDashboardsSuccess && !!defaultDashboardsResponse) {
      getDashboardsV2({ page: 1, pageSize: 2, return_defaults: true });
    }
  }, [isCreatingDefaultDashboardsSuccess, defaultDashboardsResponse]);

  useEffect(() => {
    if (isCreatingDefaultDashboardsError) {
      showToast(
        ERROR,
        `${coerceRtkqError(error)?.response?.data?.errors?.[0]?.title}` ||
          'An error occurs when creating a dashboard.',
      );
    }
  }, [isCreatingDefaultDashboardsError, error]);

  const dashboards = hasDashboardV2
    ? [...(dashboardsResponse?.results || []), ...preSigDashboards]
    : legacyDashboards;

  return {
    dashboards,
    isFetching,
  };
};
