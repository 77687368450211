import React from 'react';
import { components, MenuProps } from 'react-select';

import { Option } from '../../../types';
import { Box } from '../../Box';
import { Footer } from '../../Footer';
import { Icon } from '../../Icon';
import { Layout } from '../../Layout';
import { Text } from '../../Text';

export const Menu = (props: MenuProps<Option, boolean>) => {
  const { children, selectProps } = props;
  const {
    onCreateOption,
    footerAction,
    menuHeader,
    isEmbedded,
    isLoading,
    onMenuClose,
  } = selectProps;

  const enableCreateOption = Boolean(onCreateOption);

  return (
    <components.Menu {...props}>
      <Box
        role="menu"
        styles={[
          componentStyles.base,
          isEmbedded ? null : componentStyles.default,
        ]}
      >
        {menuHeader && (
          <Box borderBottom="border" py={2}>
            {menuHeader}
          </Box>
        )}
        {isLoading ? (
          <Box px={4} py={2}>
            <Text preset="description">Loading...</Text>
          </Box>
        ) : (
          children
        )}
        {(footerAction || enableCreateOption) && (
          <Footer
            isEmbedded={!enableCreateOption}
            actions={[
              {
                ...footerAction,
                onClick: () => {
                  footerAction.onClick();
                  onMenuClose?.(); // always close the menu
                },
              },
            ]}
            justify="flex-start"
            size="s"
          >
            {enableCreateOption ? (
              <Layout align="center" spacing={1}>
                <Icon color="status.info" icon="help" />
                <Text preset="help">Type to search or create new option</Text>
              </Layout>
            ) : null}
          </Footer>
        )}
      </Box>
    </components.Menu>
  );
};

export const componentStyles = {
  base: {
    marginTop: 1,
  },
  default: {
    backgroundColor: 'background',
    zIndex: 'forward',
  },
};
