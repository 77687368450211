import { api } from '../createApi';
import { TagType } from '../TagType';

export const TAG_BY_LIST = { type: TagType.Sylvanus, id: 'LIST' };
export const TAG_BY_MODEL_PROGRESS = {
  type: TagType.Sylvanus,
  id: 'MODEL_PROGRESS',
};

api.enhanceEndpoints({
  addTagTypes: [TagType.Sylvanus as TagType],
  endpoints: {
    createPromptModelTestCase: {
      invalidatesTags: [TAG_BY_LIST],
    },
    createPromptModelVersion: {
      invalidatesTags: [TAG_BY_LIST],
    },
    executePromptModelTestCase: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deletePromptModel: {
      invalidatesTags: [TAG_BY_LIST],
    },
    getPromptModelProgress: {
      providesTags: [TAG_BY_MODEL_PROGRESS],
    },
    DEPRECATED_getPromptModels: {
      providesTags: [TAG_BY_LIST],
    },
    getPromptModelResults: {
      providesTags: [TAG_BY_LIST],
    },
    optimizePromptModel: {
      invalidatesTags: [TAG_BY_LIST],
    },
    publishPromptModel: {
      invalidatesTags: [TAG_BY_MODEL_PROGRESS, TAG_BY_LIST],
    },
    suspendPromptModel: {
      invalidatesTags: [TAG_BY_MODEL_PROGRESS, TAG_BY_LIST],
    },
  },
});
