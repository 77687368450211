import pluralize from 'pluralize';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { getReviewers, styles } from '~/components/ReviewersPage';
import TimelapseIcon from '~/components/Shared/Icons/TimelapseIcon';
import { Button, Card, Divider, Layout, PageLayout, Text } from '~/eds';
import { featureFlagIncluded } from '~/utils/user';

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewDocuments: null,
      flaggedReviewDocuments: null,
      loading: true,
      errorLoading: false,
    };
  }

  componentDidMount() {
    getReviewers()
      .then((res) => {
        this.setState({
          reviewDocuments: res.documents_to_review,
          flaggedReviewDocuments: res.flagged_documents_to_review,
          loading: false,
          errorLoading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false, errorLoading: true });
      });
  }

  render() {
    const { classes, currentUser, history } = this.props;
    const {
      loading,
      errorLoading,
      reviewDocuments,
      flaggedReviewDocuments,
    } = this.state;

    const children = (
      <Card
        header={{
          title: 'Name',
        }}
        mode="bordered"
      >
        <Layout preset="cards" direction="column">
          <Card id="normal-data-review-wrapper" mode="embedded">
            <div>
              <Text variant="section">Data Field Review</Text>
              <div className={classes.metaData}>
                <TimelapseIcon size="20" blue />
                <div>
                  <span>
                    {reviewDocuments} {pluralize('document', reviewDocuments)}
                  </span>{' '}
                  left to review.
                </div>
              </div>
            </div>
            <div className={classes.listItemAction}>
              {reviewDocuments ? (
                <Link
                  to={{
                    pathname: '/reviewer/data-field-review',
                    state: { flagged: false },
                  }}
                >
                  <Button text="Continue" />
                </Link>
              ) : null}
            </div>
          </Card>
          <Divider />
          <Card id="normal-data-review-wrapper" mode="embedded">
            <div>
              <Text variant="section"> Flagged - Data Field Review</Text>
              <div className={classes.metaData}>
                <TimelapseIcon size="20" blue />
                <div>
                  <span>
                    {flaggedReviewDocuments}{' '}
                    {pluralize('document', flaggedReviewDocuments)}
                  </span>{' '}
                  left to review.
                </div>
              </div>
            </div>
            <div className={classes.listItemAction}>
              {flaggedReviewDocuments ? (
                <Link
                  to={{
                    pathname: '/reviewer/data-field-review',
                    state: { flagged: true },
                  }}
                >
                  <Button text="Continue" />
                </Link>
              ) : null}
            </div>
          </Card>
        </Layout>
      </Card>
    );
    const actions = featureFlagIncluded(currentUser, 'SUPER')
      ? [
          {
            text: 'Admin',
            level: 'primary',
            mode: 'link',
            onClick: () => {
              history.push('/reviewer/admin');
            },
          },
        ]
      : undefined;
    const placeholderContent =
      !loading && errorLoading
        ? { message: 'An error occurred while loading the page.' }
        : undefined;
    return (
      <PageLayout
        actions={actions}
        children={children}
        loadingContent={{ isLoading: loading && !errorLoading }}
        placeholderContent={placeholderContent}
        title="Contract Reviewer"
      />
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

Page = injectSheet(styles)(Page);

export default connect(mapStateToProps)(Page);
