import PT from 'prop-types';
import React, { useState } from 'react';

import Button from '../Button';
import FlexLayout from '../FlexLayout';
import Folder, { folderShape } from '../Folder';
import FolderModal from '../FolderModal';
import { folderTreePropType } from '../FolderTree';
import Icon from '../Icon';

function FolderInput({
  disabled,
  folder: initialFolder,
  folderTreeProps,
  id,
  isLoading,
  modalTitle,
  placeholder = 'Select a folder',
  onChange,
}) {
  const [showModal, setShowModal] = useState(false);
  const [draftFolder, setDraftFolder] = useState(initialFolder);
  const [folder, setFolder] = useState(draftFolder);

  const handleChange = (_updatedValue, updatedFolder) =>
    setDraftFolder(updatedFolder);

  const handleSelectFolder = () => {
    setFolder(draftFolder);
    onChange(draftFolder);
  };

  const handleHideModal = () => setShowModal(false);

  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <FlexLayout
        alignItems="center"
        justifyContent="space-between"
        disabled={disabled || isLoading}
        px={4}
        py={3}
        space={5}
        sx={{
          border: 'border',
          borderRadius: 'm',
          height: 'input-height',
        }}
      >
        <Folder
          shouldTruncate
          emptyFolderMessage={placeholder}
          folder={folder}
        />
        {isLoading ? (
          <Icon icon="spinner" />
        ) : (
          <Button
            id={id}
            text={folder ? 'Change' : 'Select'}
            variant="link"
            onClick={handleShowModal}
          />
        )}
      </FlexLayout>
      <FolderModal
        disabled={!draftFolder}
        folderTreeProps={{
          ...folderTreeProps,
          value: folder?.id,
          onChange: handleChange,
        }}
        title={modalTitle}
        visible={showModal}
        modalWidth="m"
        onHide={handleHideModal}
        onChange={handleChange}
        onSelectFolder={handleSelectFolder}
      />
    </>
  );
}

FolderInput.propTypes = {
  /** Disables the input */
  disabled: PT.bool,
  /** Adds an id attribute */
  id: PT.string,
  /** FolderTree props */
  folderTreeProps: PT.shape(folderTreePropType).isRequired,
  /** A Folder object */
  folder: PT.shape(folderShape),
  /** Title of the folder modal */
  modalTitle: PT.string,
  /** Placeholder message if folder is empty */
  placeholder: PT.string,
  /** Callback capturing selected folder object */
  onChange: PT.func,
};

export default FolderInput;
