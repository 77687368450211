import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '~/eds';
import { Card, CardList } from '~/ui';
import fieldEmailLogoSrc from '~/ui/assets/icons/field-email.svg';

import IntegrationDetailCard from '../IntegrationDetailCard';

function EmailIntegrations() {
  const history = useHistory();
  const params = useParams();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const footerActionButtons = (
    <Button
      text="Close"
      variant="tertiary"
      onClick={() => setIsCollapsed(true)}
    />
  );

  const headerRightContent = (
    <Button
      text="Go to Email Intake"
      variant="action"
      onClick={() => {
        history.push(`/admin/console/${params.clientId}/email-intake`);
      }}
    />
  );

  return (
    <CardList title="Email">
      <IntegrationDetailCard
        footer={<Card.Footer actionButtons={footerActionButtons} />}
        headerRightContent={headerRightContent}
        isCollapsed={isCollapsed}
        logoSrc={fieldEmailLogoSrc}
        title="Email Intake"
        onToggleCollapse={setIsCollapsed}
      >
        Forward emails to the platform containing contracts you want uploaded
        into Evisort
      </IntegrationDetailCard>
    </CardList>
  );
}

export default EmailIntegrations;
