import { getEnvVariable } from '~/dev';

export default {
  atlas: getEnvVariable('REACT_APP_ATLAS_ENDPOINT'),
  authEndpoint: getEnvVariable('REACT_APP_AUTH_ENDPOINT'),
  baseUrl: getEnvVariable('REACT_APP_BASE_URL'),
  clouseauEndpoint: getEnvVariable('REACT_APP_CLOUSEAU_ENDPOINT'),
  codeCash: getEnvVariable('REACT_APP_CODECASH_ENDPOINT'),
  docProxyEndpoint: getEnvVariable('REACT_APP_DOC_PROXY_ENDPOINT'),
  earlyAccessEndpoint: getEnvVariable('REACT_APP_EARLY_ACCESS_ENDPOINT'),
  everestEndpoint: getEnvVariable('REACT_APP_EVEREST_ENDPOINT'),
  evisearchEndpoint: getEnvVariable('REACT_APP_EVISEARCH_ENDPOINT'),
  genAiEndpoint: getEnvVariable('REACT_APP_GEN_AI_ENDPOINT'),
  museEndpoint: getEnvVariable('REACT_APP_MUSE_ENDPOINT'),
  pilotEndpoint: getEnvVariable('REACT_APP_BACKEND_ENDPOINT'),
  publicApiEndpoint: getEnvVariable('REACT_APP_PUBLIC_API_ENDPOINT'),
  sylvanusEndpoint: getEnvVariable('REACT_APP_SYLVANUS_ENDPOINT'),
  weaverEndpoint: getEnvVariable('REACT_APP_WEAVER_ENDPOINT'),
  wopiEndpoint: getEnvVariable('REACT_APP_WOPI_ENDPOINT'),
  rolodexEndpoint: getEnvVariable('REACT_APP_ROLODEX_ENDPOINT'),
  zendeskAuthEntryUrl: getEnvVariable('ZENDESK_AUTH_ENTRY_URL'),
};
