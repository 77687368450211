import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Markdown, Modal, useToast } from '~/eds';
import { actions, api, selectors } from '~/redux';

import { ModelActionType, ModelInfo } from '../types';

interface Props {
  onConfirmEdit: (model: ModelInfo) => void;
}

export const ModelModal = ({ onConfirmEdit }: Props) => {
  const dispatch = useDispatch();

  const { toast } = useToast();

  const [
    deleteFieldModel,
    { isLoading: isDeletingFieldModel },
  ] = api.endpoints.deletePromptModel.useMutation();

  const [
    suspendFieldModel,
    { isLoading: isSuspendingFieldModel },
  ] = api.endpoints.suspendPromptModel.useMutation();

  const modelAction = useSelector(selectors.selectFieldAiModelAction);

  const isLoading = isDeletingFieldModel || isSuspendingFieldModel;

  if (modelAction === null) {
    return null;
  }

  const { model, action } = modelAction;
  const isResumeAction = action === 'resume';

  if (isResumeAction) {
    return null;
  }

  const { description = '', status, toastMessage = '' } =
    resolveModalProps(action, model) ?? {};

  const handleHide = () => dispatch(actions.setFieldAiModelAction(null));

  const handleModelActionConfirm = () => {
    const queries = {
      delete: deleteFieldModel,
      suspend: suspendFieldModel,
      edit: suspendFieldModel,
    };

    const query = queries[action];

    query({
      modelId: model.id,
      version: model.version,
    })
      ?.unwrap()
      .then(() => {
        if (action === 'edit') {
          onConfirmEdit(model);
        }
        toast({
          message: toastMessage,
          status: 'success',
        });
      })
      .catch(() =>
        toast({
          message: `${toastMessage} failed.  Please try again.`,
          status: 'danger',
        }),
      )
      .finally(handleHide);
  };

  const modalProps = {
    children: <Markdown text={description} />,
    disableHideOnEscape: true,
    isVisible: true,
    primaryAction: {
      isLoading,
      text: 'Confirm',
      variant: status,
      onClick: handleModelActionConfirm,
    },
    title: `${capitalize(action)} Field Model`,
    onHide: handleHide,
  };

  return <Modal {...modalProps} />;
};

const resolveModalProps = (
  modalAction: ModelActionType,
  model: { name: string },
) => {
  switch (modalAction) {
    case 'delete':
      return {
        description: `Please confirm that you would like to delete Field Model “**${model.name}**”.\n\nThis will not delete all values previously found by the model, but will remove this model and stop it from running on any new docs that fit within the model scope. This action cannot be undone.`,
        status: 'danger' as const,
        toastMessage: `Deleted **${model.name}**`,
      };

    case 'suspend': {
      return {
        description: `Confirm that you would like to suspend Field Model **“${model.name}”**.\nThis will suspend the field identifications run by the Field Model.\nYou can resume the Field Model back to the published state after suspending.`,
        status: 'primary' as const,
        toastMessage: `Suspended **${model.name}**`,
      };
    }
    case 'edit': {
      return {
        description: `When you enter edit mode, your model will be suspended and will stop running on unprocessed documents. Are you sure you want to continue?\n\n You can come back to this page to resume your model, or republish your model with your changes.`,
        status: 'primary' as const,
        toastMessage: `Suspended **${model.name}**`,
      };
    }
    case 'resume':
    default:
      return null;
  }
};
