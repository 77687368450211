import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ticketUpdate } from '~/actions';
import { deleteTicket, partialUpdateTicket } from '~/api';
import EcInput from '~/components/Shared/EcInput';
import FormLabel from '~/components/Shared/FormLabel';
import { User } from '~/components/Shared/User';
import {
  ActionsMenu,
  ContentContainer,
  DateText,
  FormField,
  IconButton,
  Layout,
  TextArea,
  useToast,
} from '~/eds';
import { TicketPermissionType, TicketStatusType } from '~/enums';
import { api } from '~/redux';
import { TAG_BY_ACTIVITY_LOG } from '~/redux/api/endpoints/tickets';
import turnTrackingSlice from '~/redux/slices/turnTracking';
import { FlexLayout, Steps, Text, useModal } from '~/ui';
import { getSimplifiedStageNames, getStagePermissions } from '~/utils/ticket';

function TicketHeader({
  ticket,
  loading,
  // connected
  canDelete,
  canCancel,
  isCompleted,
  ticketUpdate,
}) {
  const [inputTicketName, setInputTicketName] = useState(ticket.name);
  const [cancelNote, setCancelNote] = useState();
  const [cancelTicket] = api.endpoints.cancelTicket.useMutation();
  const history = useHistory();
  const { toast } = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    setInputTicketName(ticket.name);
  }, [ticket.name]);

  const [deleteModal, showDeleteModal] = useModal({
    title: 'Delete ticket?',
    actionButton: {
      text: 'Delete',
      variant: 'red',
      errorHandler: () =>
        toast({
          message:
            'Unable to delete the ticket. Please refresh the page and try again.',
          status: 'danger',
        }),
      promise: async () => {
        await deleteTicket({ ticketId: ticket.id }, true);
        toast({
          message: `Ticket "${ticket.name}" successfully deleted.`,
          status: 'success',
        });
        history.push('/workflow/tickets');
      },
    },
    content: (
      <FlexLayout flexDirection="column" space={8}>
        <FlexLayout flexDirection="column" space={2}>
          <Text variant="xs-dense">
            Are you sure you want to delete <strong>{ticket.name}</strong>{' '}
            ticket?
          </Text>
          <Text variant="xs-dense-bold">
            You won’t be able to undo this action.
          </Text>
        </FlexLayout>
      </FlexLayout>
    ),
  });

  const [renameModal, showRenameModal] = useModal({
    title: 'Rename ticket',
    onCancel: () => setInputTicketName(ticket.name),
    actionButton: {
      disabled: inputTicketName === ticket.name || !inputTicketName.trim(),
      text: 'Rename',
      variant: 'green',
      errorHandler: () =>
        toast({
          message:
            'Unable to rename the ticket. Please refresh the page and try again.',
          status: 'danger',
        }),
      promise: async () => {
        const res = await partialUpdateTicket(ticket.id, {
          name: inputTicketName,
        });
        ticketUpdate({ name: res.name });
        toast({
          message: 'Ticket successfully renamed.',
          status: 'success',
        });
      },
    },
    content: (
      <FlexLayout flexDirection="column" space={8}>
        <FormLabel
          label="Name"
          input={
            <EcInput
              input={{
                value: inputTicketName,
                onChange: (e) => setInputTicketName(e.target.value),
                autoFocus: true,
              }}
              width="100%"
            />
          }
        />
      </FlexLayout>
    ),
  });

  const onCloseCancelModal = () => setCancelNote(null);

  const [cancelModal, showCancelModal] = useModal({
    title: 'Cancel this ticket?',
    onCancel: onCloseCancelModal,
    onHide: onCloseCancelModal,
    actionButton: {
      text: 'Cancel Ticket',
      errorHandler: () => {
        toast({
          message:
            'Unable to cancel ticket. Please refresh the page and try again.',
          status: 'danger',
        });
      },
      promise: async () => {
        const res = await cancelTicket({
          ticketId: ticket.id,
          note: cancelNote,
        }).unwrap();
        dispatch(api.util.invalidateTags([TAG_BY_ACTIVITY_LOG]));
        ticketUpdate({ isCancelled: true, cancelledDate: res.cancelledDate });
        const esigErrorMessage = res?.esigErrorMessage;
        esigErrorMessage
          ? toast({
              message: esigErrorMessage,
              status: 'info',
            })
          : toast({
              message: 'Ticket successfully cancelled.',
              status: 'success',
            });
      },
    },
    content: (
      <Layout direction="column" spacing={8}>
        <Text variant="xs-dense">
          Are you sure you want to cancel this workflow ticket?{' '}
          <Text isBold>
            You won’t be able to restore this ticket after canceling it.
          </Text>
        </Text>

        <FormField
          label="Note"
          description="Leave a comment outlining the reason."
          name="notes"
          value={cancelNote}
          onChange={setCancelNote}
          placeholder="Note"
          autoFocus
          input={TextArea}
        />
      </Layout>
    ),
  });

  const navigateBack = () => {
    /** We needed to indicate that ticket page was accessed via a redirection from
     * another page (e.g. Dashboards). In this case, the back button should return
     * to the origin of the redirection.
     */
    dispatch(turnTrackingSlice.actions.resetTurnTracking());
    history.location.state && history.location.state.isRedirect
      ? history.goBack()
      : history.push('/workflow/tickets');
  };

  const getMenuItems = () => {
    const items = [];

    items.push({
      id: 'rename-ticket',
      label: 'Rename ticket',
      onClick: () => showRenameModal(),
    });

    if (!isCompleted && canDelete) {
      items.push({
        id: 'delete-ticket',
        label: 'Delete ticket',
        onClick: () => showDeleteModal(),
      });
    }
    if (!isCompleted && canCancel) {
      items.push({
        id: 'cancel-ticket',
        label: 'Cancel ticket',
        onClick: () => showCancelModal(),
        disabled: ticket.isCancelled,
      });
    }

    return items;
  };

  const steps = getSimplifiedStageNames(ticket);

  return (
    <div>
      <Layout
        align="center"
        styles={{
          paddingBottom: '24px',
        }}
      >
        <IconButton
          icon="arrow-back"
          onClick={navigateBack}
          tooltip="Back to all tickets"
        />
        <FlexLayout flexGrow="1">
          <FlexLayout flexDirection="column">
            <ContentContainer loadingContent={{ isLoading: loading }}>
              <Text color="gray-900" variant="2xl-spaced-bold">
                {ticket.name}
              </Text>
              <span style={{ height: '8px' }} />
              <Text color="gray-600" variant="xs-dense">
                Submitted by{' '}
                <Text color="gray-700" variant="xs-dense-bold">
                  <User id={ticket.creatorId} />
                </Text>{' '}
                on{' '}
                <DateText
                  date={
                    ticket.createdDate
                      ? new Date(ticket.createdDate)
                      : undefined
                  }
                  format="full"
                  enableTick
                />
              </Text>
            </ContentContainer>
          </FlexLayout>
        </FlexLayout>
        {!loading && (
          <FlexLayout alignItems="center" flex="none" spacing="x4">
            <Steps
              currentStepValue={ticket.stage}
              steps={steps}
              isCompleted={isCompleted}
              isCancelled={ticket.isCancelled}
            />
            <ActionsMenu
              id={`workflow_ticket_header_id=${ticket.id}`}
              actions={getMenuItems()}
              name="Ticket Actions"
            />
          </FlexLayout>
        )}
      </Layout>
      {deleteModal}
      {renameModal}
      {cancelModal}
    </div>
  );
}

const mapStateToProps = ({ ticket }) => ({
  isCompleted: ticket.status === TicketStatusType.Completed,
  canDelete: getStagePermissions(ticket.permissionMap, ticket.stage).includes(
    TicketPermissionType.Delete,
  ),
  canCancel: getStagePermissions(ticket.permissionMap, ticket.stage).includes(
    TicketPermissionType.Cancel,
  ),
});

export default connect(mapStateToProps, {
  ticketUpdate,
})(TicketHeader);
