import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  builderReset,
  builderSetShouldEditSetup,
  workflowReset,
} from '~/actions';
import {
  deleteWorkflow,
  getWorkflows,
  publishWorkflow,
  unpublishWorkflow,
} from '~/api';
import { trackSegment } from '~/components/SegmentAnalytics';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import { showToast } from '~/components/Shared/EcToast';
import ReactTable from '~/components/Shared/ReactTable';
import { User } from '~/components/Shared/User';
import VersionsMenu from '~/components/Workflow/shared/VersionsMenu';
import PublishAction from '~/components/Workflow/WorkflowBuilderDashboardPage/PublishAction';
import Setup from '~/components/Workflow/WorkflowBuilderPage/Setup';
import { PAGE_SIZE, PAGE_START } from '~/constants/page';
import { DateText, Icon, PageLayout } from '~/eds';
import {
  QueryParamType,
  WorkflowPublishActionType,
  WorkflowPublishCategoryType,
} from '~/enums';
import { withCurrentUser, withUsers } from '~/hocs';
import { useAsync } from '~/hooks';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Box, FlexLayout, Link, Text, useModal } from '~/ui';
import { sortByDateValue } from '~/utils/array';
import { copyToClipboard } from '~/utils/helper.utils';
import {
  getPageSearchQuery,
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { getClientInfo, getUserClientInfo, getUserName } from '~/utils/user';

function Page({
  history,
  // connected
  users,
  shouldEditSetup,
  builderReset,
  builderSetShouldEditSetup,
  workflowReset,
  currentUser,
}) {
  const location = useLocation();
  const urlParams = {
    page: getPageSearchQueryByKey([QueryParamType.Page], PAGE_START),
    pageSize: getPageSearchQueryByKey([QueryParamType.PageSize], PAGE_SIZE),
    sortedColumn: getPageSearchQueryByKey(
      [QueryParamType.SortedColumn],
      'modifiedDate',
    ),
    sortedOrder: getPageSearchQueryByKey([QueryParamType.SortedOrder], 'true'),
  };

  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState({});

  const [
    publishUnpublishWithPrefilledCheck,
    setPublishUnpublishWithPrefilledCheck,
  ] = useState('');

  useEffect(() => {
    const prefilledFormURLExists = !!selectedWorkflow.prefilledFormData;
    const unpublishAction = checkIfUnpublishAction();

    if (prefilledFormURLExists && !unpublishAction) {
      setPublishUnpublishWithPrefilledCheck(
        WorkflowPublishCategoryType.OveridePublish,
      );
    } else if (prefilledFormURLExists && unpublishAction) {
      setPublishUnpublishWithPrefilledCheck(
        WorkflowPublishCategoryType.OverideUnpublish,
      );
    } else if (!prefilledFormURLExists && unpublishAction) {
      setPublishUnpublishWithPrefilledCheck(
        WorkflowPublishCategoryType.RegularUnpublish,
      );
    } else {
      setPublishUnpublishWithPrefilledCheck(
        WorkflowPublishCategoryType.RegularPublish,
      );
    }
  }, [selectedWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  function checkIfUnpublishAction() {
    return selectedWorkflow.actionType === WorkflowPublishActionType.Unpublish;
  }

  function renderPublishUnpublishContent() {
    switch (publishUnpublishWithPrefilledCheck) {
      case WorkflowPublishCategoryType.OverideUnpublish:
        return (
          <>
            Are you sure you want to unpublish this workflow? Please note that:
            <br />
            <ul>
              <li>
                Users will not be able to create new tickets based on this
                workflow.
              </li>
              <li>
                Your Prefillable Form Link will be cleared. Upon republishing,
                you will need to re-map and re-generate your Prefillable Form
                Link in the Settings tab of Workflow Builder.
              </li>
            </ul>
            Pending tickets from this workflow will not be effected.
          </>
        );
      case WorkflowPublishCategoryType.RegularUnpublish:
        return (
          <>
            Are you sure you want to unpublish this workflow? Users will not be
            able to create new tickets based on this workflow.{' '}
            <Text variant="s-spaced-bold">
              Any pending tickets will not be affected.
            </Text>
          </>
        );
      case WorkflowPublishCategoryType.OveridePublish:
        return (
          <>
            Republishing the workflow will necessitate re-mapping and
            re-generating your Prefillable Form Link in Settings.
          </>
        );
      default:
        return 'Are you sure you want to publish this workflow? It will be available to users and they will be able to create new tickets based on this workflow.';
    }
  }

  function getActionButtonCopy() {
    switch (publishUnpublishWithPrefilledCheck) {
      case WorkflowPublishCategoryType.OveridePublish:
        return 'Reset Mapping & Publish';
      case WorkflowPublishCategoryType.OverideUnpublish:
        return 'Reset Mapping & Unpublish';
      case WorkflowPublishCategoryType.RegularUnpublish:
        return 'Unpublish Workflow';
      default:
        return 'Publish Workflow';
    }
  }

  const [publishModal, showPublishModal] = useModal({
    actionButton: {
      promise: async () => {
        const isUnpublish = checkIfUnpublishAction();
        const publishApi = isUnpublish ? unpublishWorkflow : publishWorkflow;
        const res = await publishApi({ id: selectedWorkflow.id });

        handleWorkflowPublishChange({
          id: selectedWorkflow.id,
          isDraft: res.isDraft,
          isPublished: res.published,
          status: res.status,
        });
        showToast(
          SUCCESS,
          `Workflow ${isUnpublish ? 'unpublished' : 'published'}.`,
        );
        const newWorkflows = await getWorkflows();
        setWorkflows(newWorkflows);
      },
      text: getActionButtonCopy(),
    },
    title: `${checkIfUnpublishAction() ? 'Unpublish' : 'Publish'} Workflow?`,
    content: (
      <Text color="gray-900" variant="s-spaced">
        {renderPublishUnpublishContent()}
      </Text>
    ),
  });

  const [deleteModal, showDeleteModal] = useModal({
    actionButton: {
      variant: 'red',
      text: 'Delete',
      errorHandler: () => {
        showToast(ERROR, 'Delete Workflow failed. Please try again.');
      },
      promise: async () => {
        await deleteWorkflow({ id: selectedWorkflow.id });
        handleDeleteWorkflow(selectedWorkflow.id);
        showToast(
          SUCCESS,
          `Workflow "${selectedWorkflow.name}" successfully deleted.`,
        );
      },
    },
    onHide: () => setSelectedWorkflow({}),
    title: 'Delete Workflow?',
    content: (
      <Text color="gray-900" variant="s-spaced">
        Are you sure you want to delete{' '}
        <Text variant="s-spaced-bold">{selectedWorkflow.name}</Text>{' '}
        Workflow?&nbsp;
        <Text variant="s-spaced-bold">This action cannot be reverted.</Text>
      </Text>
    ),
  });

  const { executor: fetchWFs, isLoading } = useAsync(getWorkflows, null, {
    condition: true,
    successHandler: setWorkflows,
    errorToastMessage:
      'An error occurred while loading workflows. Please refresh the page and try again.',
  });

  useEffect(() => {
    if (!getPageSearchQuery().toString()) {
      updatePageSearchQuery(urlParams);
    }

    if (!workflows) {
      fetchWFs();
    }
  });

  if (shouldEditSetup) {
    return <Setup history={history} />;
  }

  function handleWorkflowPublishChange({ id, isDraft, isPublished, status }) {
    const updatedWorkflows = workflows.map((workflow) =>
      workflow.id === id
        ? { ...workflow, isDraft, isPublished, status }
        : workflow,
    );
    setWorkflows(updatedWorkflows);
  }

  function handleCreateWorkflow() {
    builderReset();
    builderSetShouldEditSetup(true);
    workflowReset();
    trackSegment('User Begins New Workflow', {
      groupId: getClientInfo(currentUser),
      userId: getUserClientInfo(currentUser),
    });
  }

  function handleDeleteWorkflow(workflowId) {
    const newWorkflows = workflows.filter((w) => w.id !== workflowId);
    setWorkflows(newWorkflows);
  }

  function renderPublishActionCell(item) {
    const { isDraft, isPublished, canPublish } = item;

    return (
      <PublishAction
        isDraft={isDraft}
        isPublished={isPublished}
        canPublish={canPublish}
        onClick={(actionType) => {
          setSelectedWorkflow({ ...item, actionType });
          showPublishModal();
        }}
      />
    );
  }

  function renderStatusCell(item) {
    let bg;
    let color;
    switch (item.status) {
      case 'published': {
        bg = 'green-100';
        color = 'green-400';
        break;
      }
      case 'unpublished': {
        bg = 'yellow-100';
        color = 'peach-800';
        break;
      }
      default:
        bg = 'gray-200';
        color = 'gray-600';
        break;
    }

    return (
      <Box bg={bg} p="6px 12px" sx={{ borderRadius: 'm' }}>
        <Text
          color={color}
          sx={{ textTransform: 'capitalize' }}
          variant="2xs-dense-bold"
        >
          {item.status}
        </Text>
      </Box>
    );
  }

  function renderLastUpdatedCell(item) {
    return (
      <FlexLayout
        flexDirection="column"
        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        <DateText
          date={item.modifiedDate ? new Date(item.modifiedDate) : undefined}
          format="duration"
          enableTick
        />
        <Text color="gray-600" variant="2xs-dense">
          <User id={item.lastModifiedBy} mode="name" />
        </Text>
      </FlexLayout>
    );
  }

  function renderCreatedByCell(item) {
    return (
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <User id={item.createdBy} mode="avatar-name" />
      </Box>
    );
  }

  function renderVersionsCell(item) {
    const { versions } = item;
    const hasMultipleVersions = versions.length > 1;
    const color = hasMultipleVersions ? 'blue-500' : 'black-alpha-25';

    return (
      <VersionsMenu
        versions={versions}
        trigger={
          <FlexLayout alignItems="center" space={2}>
            <Text color={color} variant="xs-dense-bold">
              {versions.length > 1
                ? `${versions.length} ${pluralize('version', versions.length)}`
                : 'Current'}
            </Text>
            <Icon color={color} icon="chevron-down" size="s" />
          </FlexLayout>
        }
      />
    );
  }

  function renderNameCell(item) {
    return (
      <Text shouldTruncate>
        <Link variant="s-dense-bold" to={`/workflow/builder/${item.id}`}>
          {item.name}
        </Link>
      </Text>
    );
  }

  function getMenuItems(item) {
    const items = [];

    items.push({
      content: 'Edit',
      onClick: () => history.push(`${location.pathname}/${item.id}`),
    });

    if (item.permanentLinkId) {
      items.push({
        content: 'Copy Intake Form Link',
        onClick: () => handleOnCopyAction(item.permanentLinkId),
      });
    }

    items.push({
      content: 'Delete',
      onClick: () => {
        setSelectedWorkflow(item);
        showDeleteModal();
      },
    });

    return items;
  }

  function renderActionMenuCell(item) {
    return (
      <ActionsMenu
        id={`workflow_builder_list_actions?id=${item.id}`}
        items={getMenuItems(item)}
      />
    );
  }

  function handleOnCopyAction(permanentLinkId) {
    const linkConstructed = `${window.location.origin}/${currentUser.client_config.sub_domain}/workflow/${permanentLinkId}/intake-form/link`;
    copyToClipboard(linkConstructed, 'Intake form link copied to clipboard.');
  }

  const columns = [
    {
      label: '',
      value: 'actionMenu',
      width: 56,
      sortable: false,
      renderCustomCell: renderActionMenuCell,
    },
    {
      label: 'Name',
      value: 'name',
      width: 300,
      resizable: true,
      renderCustomCell: renderNameCell,
    },
    {
      label: 'Versions',
      value: 'version',
      width: 150,
      sortable: false,
      renderCustomCell: renderVersionsCell,
    },
    {
      label: 'Created By',
      value: 'createdByUser',
      width: 270,
      renderCustomCell: renderCreatedByCell,
    },
    {
      label: 'Last Updated',
      value: 'modifiedDate',
      disableGlobalFilter: true,
      width: 250,
      renderCustomCell: renderLastUpdatedCell,
    },
    {
      label: 'Status',
      value: 'status',
      width: 130,
      renderCustomCell: renderStatusCell,
    },
    {
      label: '',
      value: 'publishAction',
      width: 400,
      sortable: false,
      renderCustomCell: renderPublishActionCell,
    },
  ];

  const data = workflows
    .map((workflow) => {
      return {
        ...workflow,
        status: workflow.isPublished ? 'published' : workflow.status,
        createdByUser: getUserName(users[workflow.createdBy]),
      };
    })
    .sort(sortByDateValue('modifiedDate'));

  return (
    <PageLayout
      title="Workflow Builder"
      actions={
        currentUser?.['is_workflow_admin']
          ? [
              {
                level: 'primary',
                text: 'New Workflow',
                onClick: handleCreateWorkflow,
              },
            ]
          : []
      }
      loadingContent={{
        isLoading,
        message: 'Loading workflows…',
      }}
    >
      <ReactTable
        columns={columns}
        data={data}
        title="workflow templates"
        handleTablePropsChange={updatePageSearchQuery}
        settings={urlParams}
      />
      {publishModal}
      {deleteModal}
    </PageLayout>
  );
}

function mapStateToProps({ builder }) {
  return { shouldEditSetup: builder.shouldEditSetup };
}

export default connect(mapStateToProps, {
  builderReset,
  builderSetShouldEditSetup,
  workflowReset,
})(withUsers(withCurrentUser(Page)));
