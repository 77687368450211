export enum QueryParamType {
  Action = 'action',
  ActiveTab = 'activeTab',
  ClientId = 'client_id',
  Clause = 'clause',
  clauseId = 'clauseId',
  Code = 'code',
  CommentId = 'commentId',
  CompareTo = 'compareTo',
  DocumentId = 'documentId',
  Expanded = 'expanded',
  Error = 'error',
  EviPersistKey = 'eviPersistKey',
  ExportId = 'exportId',
  FirstLoginSuccessToken = 'first_login_success_token',
  FormData = 'formData',
  IsAllSelected = 'isAllSelected',
  IsEditMode = 'isEditMode',
  Layout = 'layout',
  Page = 'page',
  PageSize = 'pageSize',
  Provider = 'provider',
  Query = 'query',
  RedirectUri = 'redirect_uri',
  ResetSuccessToken = 'reset_success_token',
  ResponseType = 'response_type',
  ReturnTo = 'return_to',
  SamlError = 'saml_error',
  SamlSuccess = 'saml_success',
  SearchText = 'searchText',
  SelectedItems = 'selectedItems',
  State = 'state',
  States = 'states',
  SortedColumn = 'sortedColumn',
  SortedOrder = 'sortedOrder',
  Sso = 'sso',
  Success = 'success',
  TicketCommentId = 'ticketCommentId',
  Token = 'token',
  VersionId = 'versionId',
  Workspace = 'workspace',
}
