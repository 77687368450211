import uuid from 'uuid';

import { types } from '~/eds';
import Configuration from '~/services/configuration';
import { Uuid } from '~/types';

import { SseRequestProps } from './hooks';
import type { MessageType } from './types';

export const replyAiMessage = (): MessageType => ({
  id: uuid.v4(),
  text: '',
  timestamp: new Date(),
  userType: 'other',
});

export const replyUserMessage = (text: string): MessageType => ({
  id: uuid.v4(),
  text,
  timestamp: new Date(),
  userType: 'current',
});

export const getUserTheme = (userType: types.Chat.UserType) => ({
  bg: userType === 'other' ? 'brand.ai.light' : 'background',
});

export const getStreamEndpoint = (id: Uuid) =>
  `${Configuration.sylvanusEndpoint}/api/v1/conversation/${id}/ai_message/stream`;

export const SSE_REQUEST_PROPS: SseRequestProps = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  openWhenHidden: true,
};

export const parseStreamEndMessage = (message: string) => {
  const [error, details] = message.split(':');
  return (details ?? error).trim();
};

export const STATIC_CONTEXT_ACTIONS = [
  {
    label: 'Search Range',
    options: [
      {
        label: 'Documents',
        value: 'documents',
      },
      {
        label: 'Workflow',
        value: 'workflow',
        disabled: true,
        chips: [
          {
            text: 'Coming soon',
          },
        ],
      },
      {
        label: 'Clause Library',
        value: 'clause-library',
        disabled: true,
        chips: [
          {
            text: 'Coming soon',
          },
        ],
      },
      {
        label: 'Knowledge Center',
        value: 'kownledge-center',
        disabled: true,
        chips: [
          {
            text: 'Coming soon',
          },
        ],
      },
    ],
  },
];
