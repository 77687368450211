import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Actions,
  Feedback,
  formatDate,
  getUserName,
  Icon,
  Layout,
  LoadingShimmer,
  Markdown,
  Tooltip,
  types,
} from '~/eds';
import { api } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { getApplicationRoute } from '~/routing';

import { BlinkingCursor } from './BlinkingCursor';
import { SourcesList } from './components/Sources/SourcesList';
import { CHOICE_DISPLAY_LENGTH, MESSAGE_ACTIONS_CLASSNAME } from './constants';
import { Citation, FeedbackDetails, Message as MessageType } from './types';
import { createMarkdownComponents, testIsTicketCitation } from './utils';

interface Props<Source> {
  message: MessageType<Source>;
  user: types.User;
  onChoose: (message: MessageType<Source>, choice: string) => void;
  onSubmitFeedback: (
    message: MessageType<Source>,
    feedbackDetails: FeedbackDetails,
  ) => void;
  onSelectSource?: (message: MessageType<Source>, sourceIndex: number) => void;
  disableChoices?: boolean;
  isLoading?: boolean;
  isReplying?: boolean;
  messageActions: types.UserAction[];
  shouldShowActions: boolean;
}

export const AiMessage = <Source extends unknown>({
  disableChoices,
  isLoading = false,
  isReplying = false,
  message,
  messageActions,
  shouldShowActions,
  user,
  onChoose,
  onSelectSource,
  onSubmitFeedback,
}: Props<Source>) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [resolveCitation] = api.endpoints.resolveCitation.useLazyQuery();
  const handleSubmitFeedback = (value: types.Nullable<boolean>) => {
    if (message) {
      onSubmitFeedback(message, { value });
    }
  };
  const { choices = [], text, timestamp, citations = [] } = message ?? {};
  const tooltip = `${getUserName(user)}\n${formatDate(timestamp, 'full')}`;

  const messageChoices = useMemo(
    () =>
      (message?.choices ?? []).map((choice) => {
        /** TODO - `Button` should handle this truncation internally after design proposes the display length limit */
        const isLongText = choice.length > CHOICE_DISPLAY_LENGTH;
        return {
          disabled: disableChoices,
          level: 'secondary' as const,
          text: isLongText
            ? choice.substring(0, CHOICE_DISPLAY_LENGTH) + '…'
            : choice,
          tooltip: isLongText ? choice : undefined,
          onClick: () => {
            if (message) {
              onChoose(message, choice);
            }
          },
        };
      }),
    [disableChoices, message],
  );

  const handleTicketEntityCitationClick = async (citation: Citation) => {
    const resolvedCitation = await resolveCitation(citation.entity).unwrap();
    if (resolvedCitation) {
      handleCitationLinkAndHighlights({
        ...citation,
        link: resolvedCitation.link,
      });
    }
  };

  const handleCitationLinkAndHighlights = (citation: Citation) => {
    if (citation?.link) {
      if (citation.highlights.length) {
        dispatch(chatbotSlice.actions.setSources([...citation.highlights]));
        dispatch(chatbotSlice.actions.setActiveSourceIndex(0));
      }
      const destinationRoute = testIsTicketCitation(citation)
        ? citation.link
        : getApplicationRoute(citation.link);

      if (history.location.pathname !== destinationRoute) {
        history.push(destinationRoute);
      }
    }
  };

  const handleCitationClick = (citation: Citation) => {
    if (testIsTicketCitation(citation)) {
      handleTicketEntityCitationClick(citation);
    } else {
      handleCitationLinkAndHighlights(citation);
    }
  };

  const hasCitations = citations.length > 0;

  const markdownComponents = useMemo(
    () =>
      createMarkdownComponents(
        {
          citations: citations,
          onCitationClick: handleCitationClick,
          isReplying,
        },
        {
          shouldSkipLinks: hasCitations,
        },
      ),
    [hasCitations],
  );
  return (
    <Layout direction="column" spacing={4} styles={componentStyles.message}>
      <Layout align="center" spacing={3}>
        <Layout alignSelf="flex-start">
          <Tooltip tooltip={tooltip} placement="left">
            <Icon
              name="evisort-ai-animated"
              size="ml"
              icon="evisort-ai-animated"
            />
          </Tooltip>
        </Layout>
        {isLoading ? (
          <Layout direction="column" w="100%" py={2} spacing={1}>
            <LoadingShimmer height="20px" width="100%" />
            <LoadingShimmer height="20px" width="90%" />
            <LoadingShimmer height="20px" width="80%" />
          </Layout>
        ) : (
          <Markdown components={markdownComponents} text={text} />
        )}
      </Layout>
      {isReplying && !isLoading && (
        <Layout>
          <BlinkingCursor />
        </Layout>
      )}

      <Layout
        direction="column"
        flex="auto"
        justify="center"
        minW={0}
        position="relative"
        spacing={2}
      >
        {choices && <Actions wrap actions={messageChoices} size="s" />}
      </Layout>
      <SourcesList<Source>
        message={message}
        onSelectSource={onSelectSource}
        onSelectCitation={handleCitationClick}
      />
      {shouldShowActions && (
        <Layout
          bg="background"
          borderRadius={4}
          className={MESSAGE_ACTIONS_CLASSNAME}
          styles={componentStyles.actions}
        >
          <Actions actions={messageActions} />
          {Boolean(messageActions.length) && (
            <Feedback
              value={message?.feedbackDetails?.value ?? null}
              onChange={handleSubmitFeedback}
            />
          )}
        </Layout>
      )}
    </Layout>
  );
};

const componentStyles = {
  message: {
    position: 'relative',
    [`&:hover .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
    [`&.current:hover + .other .${MESSAGE_ACTIONS_CLASSNAME}`]: {
      visibility: 'visible',
    },
    paddingTop: 1,
  },
  actions: {
    boxShadow: 'raised',
    bottom: 0,
    position: 'absolute',
    right: 4,
    transform: 'translateY(-10%)',
    visibility: 'hidden',
  },
};
