import { Nullable } from '~/types';

import { FieldModel, OptimizeState, PromptModelPublishStatus } from '../types';

export const testIsActivePublishStatus = (
  publishStatus: Nullable<PromptModelPublishStatus>,
) => testIsPublishingStatus(publishStatus) || publishStatus === 'completed';

export const testIsOptimizingState = (state: Nullable<OptimizeState>) =>
  state === 'initializing' || state === 'running' || state === null;

export const testIsPublishingStatus = (
  publishStatus?: Nullable<PromptModelPublishStatus>,
) => publishStatus === 'in_progress' || publishStatus === 'resumed';

// TODO: deprecate in favor of existing checks
export const testIsNonOptimizedModel = (
  model: FieldModel,
  publishStatusChecks: PromptModelPublishStatus[],
) => {
  const { optimizeState, publishStatus } = model;
  return (
    !optimizeState &&
    publishStatus &&
    publishStatusChecks.includes(publishStatus)
  );
};
