import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Accordion, ContentContainer, Section } from '~/eds';
import { actions, api } from '~/redux';

import { DEPRECATED_ModelInfo } from '../../DEPRECATED_ModelInfo';
import { ModelTitle } from '../../fields/shared/ModelTitle';
import { FieldModel } from '../../fields/types';
import { usePollModelProgress } from '../../hooks';
import { Evaluate } from '../../ModelDetails/Evaluate';
import { compareConfig, testIsNonOptimizedModel } from '../../utils';
import { PublishOptions } from './PublishOptions';

interface Props {
  modelVersion: FieldModel;
}

export const ReviewPublish = ({ modelVersion }: Props) => {
  const [enablePolling, setEnablePolling] = useState<boolean>(true);
  const dispatch = useDispatch();

  const editModelConfirmed = testIsNonOptimizedModel(modelVersion, [
    'suspended',
  ]);

  const modelId = modelVersion.id;

  const {
    data: model,
    isLoading: isLoadingModel,
  } = api.endpoints.getPromptModel.useQuery(modelId);

  const { id } = modelVersion;
  const lastPublishVersion = Number(
    model?.currentPublished?.version_number ?? 0,
  );

  const {
    data: previousModelVersion = null,
    isLoading: isPreviousModelVersionLoading,
  } = api.endpoints.DEPRECATED_getPromptModelVersion.useQuery(
    {
      modelId: id,
      version: lastPublishVersion,
    },
    {
      skip: lastPublishVersion <= 0 || !lastPublishVersion,
    },
  );

  const { progress, isLoading: isLoadingProgress } = usePollModelProgress({
    model: modelVersion,
    enablePolling,
  });

  const { publishStatus } = progress;
  const currentConfig = modelVersion.config;
  const previousConfig = previousModelVersion?.config;

  const hasOnlyFiltersChanged = useMemo(() => {
    if (previousConfig && currentConfig) {
      //TODO: rework hasFiltersChanged as it always returns true due to uuid
      const { hasFiltersChanged, hasInstructionsChanged } = compareConfig(
        previousConfig,
        currentConfig,
      );
      return hasFiltersChanged && !hasInstructionsChanged;
    }
    return false;
  }, [previousConfig, currentConfig]);

  useEffect(() => {
    dispatch(
      actions.setFieldAiPublishMode(
        hasOnlyFiltersChanged ? 'new_docs_in_scope' : 'all_docs_in_scope',
      ),
    );
  }, [hasOnlyFiltersChanged]);

  const items = [
    {
      title: 'General Information',
      // eslint-disable-next-line react/jsx-pascal-case
      content: <DEPRECATED_ModelInfo modelVersion={modelVersion} />,
    },
    {
      title: 'Model Performance',
      content: <Evaluate readOnly mode="summary-only" model={modelVersion} />,
    },
  ];

  return (
    <ContentContainer
      loadingContent={{
        isLoading:
          isPreviousModelVersionLoading || isLoadingProgress || isLoadingModel,
      }}
    >
      <>
        <ModelTitle modelVersion={modelVersion} publishStatus={publishStatus} />
        {editModelConfirmed && (
          <Section title="Review your model">
            <PublishOptions
              progress={progress}
              modelVersion={modelVersion}
              hasOnlyFiltersChanged={hasOnlyFiltersChanged}
              onStopPolling={setEnablePolling}
            />
          </Section>
        )}
        <Section title="Overview of your model">
          <Accordion border="vertical" items={items} />
        </Section>
      </>
    </ContentContainer>
  );
};
