import { useMemo } from 'react';

import { Actions, CollapsibleList } from '~/eds';

import { Citation as CitationType, Message } from '../../types';
import { Citation } from './Citation';

interface Props {
  message: Message;
  onSelectCitation: (citation: CitationType) => void;
}
export const Citations = ({ message, onSelectCitation }: Props) => {
  const { citations = [] } = message;

  const items = useMemo(() => {
    return citations.map((citation, index) => {
      return {
        text: `${index + 1}`,
        level: 'secondary' as const,
        citation,
        onClick: () => onSelectCitation(citation),
      };
    });
  }, [citations]);

  if (citations.some((citation) => citation.entity.type === 'ticket')) {
    return <Actions actions={items} size="xs" />;
  }
  return (
    <CollapsibleList
      name="sources_list"
      limit={3}
      items={items}
      renderItem={(item) => (
        <Citation
          text={item.text}
          citation={item.citation}
          onClick={onSelectCitation}
        />
      )}
      serializeItem={(item) => `Source ${item.text}`}
    />
  );
};
