import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

import { withFlags } from '~/flags';
import NotFoundPage from '~/pages/404';
import { matchPath } from '~/routing';
import { setDocumentTitle } from '~/utils/dom';

import PrivateRoute from './PrivateRoute';
import { resolveComponent } from './resolveComponent';
import { routes } from './routes';

export function Router({ currentUser, environment, flags }) {
  let location = useLocation();
  useEffect(() => {
    const routePath = Object.keys(routes).find((path) =>
      matchPath(path, location.pathname),
    );
    const route = routes[routePath];
    if (route) {
      setDocumentTitle(route.title);
    }
    return () => setDocumentTitle();
  }, [location]);

  const context = {
    environment,
    routeParams: location.state || {},
    user: currentUser,
  };

  const mapRoute = (route) => {
    const { isExact, isPublic, path, resolvers, flag } = route;
    const routeProps = {
      component: (props) => {
        if (flag && !flags[flag]) {
          return <NotFoundPage />;
        }
        const Resolved = resolveComponent(resolvers, context);
        return <Resolved {...props} />;
      },
      path,
      exact: isExact,
    };
    const Route = isPublic ? CompatRoute : PrivateRoute;
    return <Route key={path} {...routeProps} permissions={route.permissions} />;
  };

  return <Switch>{Object.values(routes).map(mapRoute)}</Switch>;
}

export const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default connect(mapStateToProps)(withFlags(Router));
