import { PromptModel } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModel as PromptModelResponse } from '../../types/sylvanus/model';

export const getPromptModel = async (modelId: Uuid): Promise<PromptModel> => {
  const response: PromptModelResponse = await sylvanus.get(`/model/${modelId}`);

  return {
    currentOptimizeInfo: response.current_optimize
      ? {
          id: response.current_optimize.optimize_run_id,
          state: response.current_optimize.state,
          testCaseCount: response.current_optimize.total_test_cases ?? 0,
          version: response.current_optimize.version_number,
        }
      : null,
    id: response.id,
    versions: response.version_numbers ?? [],
    fieldId: parseInt(response.target_entity.id),
    publishStatus: response.current_publish?.status,
    currentPublished: response.current_publish ?? null,
  };
};
