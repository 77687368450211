export const presets = {
  back: {
    text: 'Back',
    variant: 'tertiary',
  },
  cancel: {
    text: 'Cancel',
    variant: 'tertiary',
  },
  ai: {
    icon: 'ai',
    iconPosition: 'left',
    status: 'ai',
  },
  onboarding: {
    icon: 'help',
    iconPosition: 'left',
    text: 'Onboarding',
    variant: 'onboarding',
  },
  cta: {
    variant: 'cta',
    size: 'l',
  },
  'cta-ai': {
    icon: 'evisort-ai-static',
    variant: 'cta-ai',
    size: 'l',
    mode: 'chip',
    textVariant: 'section-title',
  },
};
