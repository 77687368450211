import { useEffect, useState } from 'react';

import { RETRY_ERROR } from '~/constants/errors';
import { useToast } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { Uuid } from '~/types';

import { testIsTestCaseNotExecuted } from '../fields/utils';
import { PromptModelTestCase } from '../types';

export const useBulkExecuteModelTestCases = ({
  testCases,
  modelId,
  version,
}: {
  testCases: PromptModelTestCase[];
  modelId: Uuid;
  version: number;
}) => {
  const MAX_RETRIES = 10;

  const enableDocumentXRayBulkExecute = useFlag(
    FlagType.DocumentXRayDevBulkExecute,
  );

  const [executeCount, setExecuteCount] = useState(0);

  const { toast } = useToast();

  const [
    executePromptModelTestCases,
  ] = api.endpoints.executePromptModelTestCases.useMutation();

  const executeQueryKey = testCases // for determining effect deps to rerun bulk execute
    .filter(testIsTestCaseNotExecuted)
    .map((testCase) => testCase.number)
    .join('-');

  useEffect(() => {
    if (!executeQueryKey) {
      return;
    }

    if (executeCount > MAX_RETRIES) {
      toast({
        message: 'Failed to evaluate results.  Please refresh the page.',
        status: 'danger',
      });
    } else {
      setExecuteCount((prevExecuteCount) => prevExecuteCount + 1);
      executePromptModelTestCases({
        modelId,
        version,
        queryKey: enableDocumentXRayBulkExecute ? undefined : executeQueryKey,
      })
        .unwrap()
        .catch((error) => {
          if (error.response) {
            const message = error.response.data.detail ?? RETRY_ERROR;
            switch (error.response.status) {
              case 409:
              case 429:
                break; // intentionally swallow rate-limit or conflict errors
              default:
                toast({
                  key: modelId + version,
                  message,
                  status: 'danger',
                });
                break;
            }
          }
        });
    }
  }, [executeQueryKey, modelId, version]);
};
