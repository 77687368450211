import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { showToast } from '~/components/Shared/EcToast';
import { Modal, Text } from '~/eds';
import { api, selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';
import { PilotId } from '~/types';
import { MODAL_DELETE_DOCUMENTS_GROUP } from '~/types/modal.types';
import { SUCCESS, WARNING } from '~/types/toast.types';

type Props = {
  groupId: PilotId;
  name?: string;
};

export const DeleteGroup = ({ groupId, name = '' }: Props) => {
  const dispatch = useDispatch();
  const modal = useSelector(selectors.selectDocGroupModal);
  const history = useHistory();
  const [
    deleteDocumentGroup,
    result,
  ] = api.endpoints.deleteDocumentGroup.useMutation();
  const { isSuccess, isError, isLoading } = result;
  const { modalType } = modal || {};

  const onClose = useCallback(() => {
    dispatch(documentGroup.actions.setModal());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, 'Group has been deleted successfully.');
      onClose();
      history.push('/document-groups');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(
        WARNING,
        'An error occurred while deleting the document group.',
      );
    }
  });

  return (
    <Modal
      isVisible={modalType === MODAL_DELETE_DOCUMENTS_GROUP}
      title="Delete Group?"
      onCancel={onClose}
      onHide={onClose}
      primaryAction={{
        text: 'Delete Group',
        variant: 'danger',
        icon: 'trash',
        iconPosition: 'left',
        onClick: () => {
          deleteDocumentGroup(groupId);
        },
        isLoading,
      }}
    >
      <Text>
        Are you sure you want to delete the group {name}? You won't be able to
        undo this action.
      </Text>
    </Modal>
  );
};
