import { capitalize } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import { PageLayout, Table, useToast } from '~/eds';
import { AlgorithmMetric } from '~/features/process-documents';
import { api } from '~/redux';
import { generateNav, RoutePathType } from '~/routing';

const TABLE_OPTIONS = {
  enableExportXlsx: false,
  enableManageColumns: false,
  enableSelectRows: true,
};

const PAGE_INDEX = 1;

const Page = () => {
  const param = useParams<{ clientId: string }>().clientId;
  const clientId = Number(param);
  const { toast } = useToast();

  const {
    data: algorithmMetrics = [],
    isFetching: isLoading,
    error,
  } = api.endpoints.getAlgorithmMetrics.useQuery(
    { clientId },
    { skip: !clientId },
  );

  const [reprocessAlgorithm] = api.endpoints.reprocessAlgorithms.useMutation();
  const [
    stopReprocessAlgorithm,
  ] = api.endpoints.stopReprocessAlgorithms.useMutation();

  const nav = generateNav({
    current: {
      text: 'Document Processing ',
      pathname: RoutePathType.AdminConsoleClientProcessDocuments,
    },
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId,
    },
  });

  const placeholdeContent =
    algorithmMetrics.length === 0
      ? { message: 'No algorithms found.' }
      : error
      ? { message: 'Error loading upload logs.' }
      : undefined;

  const columns = [
    {
      key: 'name',
      cellType: 'text',
      disableSortBy: true,
      title: 'Algorithm',
      mapCellProps: (d: AlgorithmMetric) => ({
        text: d.title,
      }),
    },
    {
      key: 'failed',
      cellType: 'number',
      disableSortBy: true,
      title: 'Failed',
      mapCellProps: (d: AlgorithmMetric) => ({
        number: d.counts.failed,
      }),
    },
    {
      key: 'unprocessed',
      cellType: 'number',
      disableSortBy: true,
      title: 'Unprocessed',
      mapCellProps: (d: AlgorithmMetric) => ({
        number: d.counts.notRun,
      }),
    },
    {
      key: 'successful',
      cellType: 'number',
      disableSortBy: true,
      title: 'Successful',
      mapCellProps: (d: AlgorithmMetric) => ({
        number: d.counts.successful,
      }),
    },
    {
      key: 'lastRun',
      cellType: 'datetime',
      disableSortBy: true,
      title: 'Last Ran',
      mapCellProps: (d: AlgorithmMetric) => ({
        datetime: d.lastRunDate,
        format: 'full',
      }),
      width: 'auto',
    },
    {
      key: 'active',
      cellType: 'chips',
      disableSortBy: true,
      title: 'Active',
      mapCellProps: (d: AlgorithmMetric) => {
        if (d.title === 'Total') return { chips: [] };
        return {
          chips: [
            {
              text: capitalize(d.status),
              status: d.status,
            },
          ],
        };
      },
    },
  ];

  const getBulkActions = (state: any) => {
    const algorithms = state.selectedRowIds.filter(
      (id: string) => id !== 'Total',
    );

    const bulkActionsConfigs = [
      {
        label: 'Run Failed',
        condition: 'failed',
        tooltip: 'Run failed algorithms',
      },
      {
        label: 'Run Unprocessed',
        condition: 'unprocessed',
        tooltip: 'Run unprocessed algorithms',
      },
      {
        label: 'Run All',
        condition: 'all',
        tooltip: 'Run all algorithms',
      },
      {
        label: 'Stop Task',
        condition: 'stop',
        tooltip: 'Stop reprocessing algorithms',
      },
    ];

    return bulkActionsConfigs.map((bulkAction) => {
      const condition = bulkAction.condition;
      return {
        label: bulkAction.label,
        onClick: () => {
          switch (condition) {
            case 'stop':
              stopReprocessAlgorithm({
                clientId,
                algorithms,
              })
                .unwrap()
                .then(() => {
                  toast({
                    message: `Stopped processing ${algorithms.join('\n')}`,
                    status: 'success',
                  });
                })
                .catch(() => {
                  toast({
                    message: `An error occurred while trying to stop reprocessing ${condition} documents`,
                    status: 'danger',
                  });
                });
              break;
            case 'all':
            case 'failed':
            case 'unprocessed':
              reprocessAlgorithm({
                clientId,
                algorithms,
                condition,
              })
                .unwrap()
                .then(() => {
                  toast({
                    message: `Rerunning ${condition} documents`,
                    status: 'success',
                  });
                })
                .catch(() => {
                  toast({
                    message: `An error occurred while trying to reprocess ${condition} documents`,
                    status: 'danger',
                  });
                });
              break;
          }
        },
        tooltip: bulkAction.tooltip,
      };
    });
  };

  const children = (
    <Table
      name="Algorithms Logs"
      data={algorithmMetrics}
      getBulkActions={getBulkActions}
      columns={columns}
      options={TABLE_OPTIONS}
      state={{
        pageIndex: PAGE_INDEX,
        pageSize: algorithmMetrics.length,
      }}
    />
  );

  return (
    <PageLayout
      title="Algorithm Metrics"
      nav={nav}
      children={children}
      loadingContent={{ isLoading, message: 'Loading algorithms…' }}
      placeholderContent={placeholdeContent}
    />
  );
};

export default Page;
