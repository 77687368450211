import React from 'react';

import { ContentContainer, Link, StatusText } from '~/eds';
import { RoutePathType } from '~/routing';

import { useResolveTargetEntityDetails } from '../hooks';
import { ModelResult } from '../types';

interface Props {
  modelResult: ModelResult;
}

export const ModelResultTargetEntity = ({ modelResult }: Props) => {
  const { targetEntity, versions, id } = modelResult;

  const [
    targetEntityDetails,
    isResolvingTargetEntity,
  ] = useResolveTargetEntityDetails(targetEntity);

  const { label } = targetEntityDetails;
  const hasNoVersions = versions.length === 0;

  return (
    <ContentContainer
      loadingContent={{
        isLoading: isResolvingTargetEntity,
        shimmer: {
          height: '100%',
        },
      }}
    >
      {hasNoVersions ? (
        <StatusText
          icon="status-danger"
          iconTooltip="This model has no versions, please delete it."
          text={label}
        />
      ) : (
        <Link pathname={`${RoutePathType.AutomationHubFields}/${id}`}>
          {targetEntityDetails.label}
        </Link>
      )}
    </ContentContainer>
  );
};
