import { createRef } from 'react';
import Cookies from 'universal-cookie';

import { isDev, isProd } from '~/dev';
import { getWildDomain } from '~/utils/browser';
import { testIsValidWindowOpener } from '~/utils/dom';

// TODO: DEPRECATE Next 3 lines - EKP-15824
const access_token = 'access_token';
const refresh_token = 'refresh_token';

// Cookies
const logged_in_cookie = 'logged_in_cookie';
const expires_at_cookie = 'expires_at_cookie';
// Local Storage
const inactive_expires_at = 'inactive_expires_at';
const access_token_created_at = 'access_token_created_at';
const session_subdomain = 'session_subdomain';
// Session Storage
const zendesk_redirect_url = 'zendesk_redirect_url';
const zendesk_redirect_expire = 'zendesk_redirect_expire';
const oauth_redirect_uri = 'oauth_redirect_uri';
const oauth_client_id = 'oauth_client_id';
const oauth_state = 'oauth_state';
const oauth_redirect_expire = 'oauth_redirect_expire';

const CSRF_TOKEN_REFERENCE = createRef();

const AuthenticationStore = () => {
  const defaultOptions = {
    domain: getWildDomain(window.location.hostname),
    path: '/',
    sameSite: 'None',
    secure: true,
  };

  const constants = {
    MS_UNTIL_ZENDESK_EXPIRE: 5 * 60 * 1000,
    MS_UNTIL_OAUTH_EXPIRE: 5 * 60 * 1000,
    S_UNTIL_INACTIVE_TIMEOUT: 3600,
  };

  const cookie = new Cookies();

  // we consider the session embedded (e.g. in an iframe) if the window is not the current/top window, or if it has been opened by another window.
  function isEmbed() {
    if (isProd) {
      return window.self !== window.top || testIsValidWindowOpener();
    }
    return false;
  }

  function getStoredLoggedInCookie() {
    return cookie.get(logged_in_cookie, defaultOptions);
  }

  function getStoredExpiresAtCookie() {
    return cookie.get(expires_at_cookie, defaultOptions);
  }

  function saveAccessToken(accessToken) {
    cookie.set(access_token, accessToken, defaultOptions);
  }

  function removeInactiveTimer() {
    localStorage.removeItem(inactive_expires_at);
  }

  function resetInactiveTimer(timeoutInSecond) {
    localStorage.setItem(
      inactive_expires_at,
      String(Date.now() + timeoutInSecond * 1000),
    );
  }

  function saveAuthentication(accessToken, refreshToken) {
    clearAuthentication();

    cookie.set(access_token, accessToken, defaultOptions);

    const maxAge = parseInt(getStoredExpiresAtCookie(), 10);
    cookie.set(refresh_token, refreshToken, {
      ...defaultOptions,
      maxAge:
        !Number.isNaN(maxAge) && !isDev
          ? maxAge - Math.ceil(Date.now() / 1000)
          : undefined,
    });

    removeInactiveTimer();
    saveAccessTokenCreationTime();
  }

  function clearAuthentication() {
    localStorage.removeItem(inactive_expires_at);
    // for non-httponly auth path
    cookie.remove(access_token, defaultOptions);
    cookie.remove(refresh_token, defaultOptions);
    // for httponly auth path
    cookie.remove(logged_in_cookie, defaultOptions);

    // set csrf to null so it can refetch
    setCsrf(null);
  }

  function getAccessToken() {
    return cookie.get(access_token, defaultOptions);
  }

  function getRefreshToken() {
    return cookie.get(refresh_token, defaultOptions);
  }

  function isAuthSessionExpired() {
    if (isDev) return false;
    const expiresAt = getStoredExpiresAtCookie();
    if (!expiresAt) return true;

    return Math.ceil(Date.now() / 1000) > parseInt(expiresAt, 10);
  }

  function isInactiveTimeoutReached() {
    const inactiveExpireTime = localStorage.getItem(inactive_expires_at);
    if (!inactiveExpireTime) return false;

    return Date.now() > parseInt(inactiveExpireTime, 10);
  }

  function shouldConsiderSessionValid() {
    if (getStoredExpiresAtCookie() && !isDev) {
      return !isAuthSessionExpired();
    }
    return true;
  }

  function shouldConsiderUserLoggedIn() {
    // for non-httponly auth path - TODO: DEPRECATE Next line - EKP-15824
    const hasValidTokens = getAccessToken() && getRefreshToken();
    // for httponly auth path
    const hasLoggedInFlag = getStoredLoggedInCookie();

    return hasValidTokens || hasLoggedInFlag;
  }

  function isRolodexAuthed() {
    return getStoredLoggedInCookie() === 'ROLODEX';
  }

  function isAegisAuthed() {
    return getStoredLoggedInCookie() === 'AEGIS';
  }

  function isLoggedIn() {
    return shouldConsiderUserLoggedIn() && shouldConsiderSessionValid();
  }

  function getSubDomainInSession() {
    return localStorage.getItem(session_subdomain);
  }

  function saveSubDomainInSession(subdomain) {
    localStorage.setItem(session_subdomain, subdomain);
  }

  function getZendeskUrl() {
    return sessionStorage.getItem(zendesk_redirect_url);
  }

  function getZendeskExpire() {
    return sessionStorage.getItem(zendesk_redirect_expire);
  }

  const setZendeskUrl = (redirectUrl) => {
    const zendeskRedirectExp = (
      new Date().valueOf() + constants.MS_UNTIL_ZENDESK_EXPIRE
    ).toString();

    sessionStorage.setItem(zendesk_redirect_url, redirectUrl);
    sessionStorage.setItem(zendesk_redirect_expire, zendeskRedirectExp);
  };

  const removeZendeskFlag = () => {
    sessionStorage.removeItem(zendesk_redirect_url);
    sessionStorage.removeItem(zendesk_redirect_expire);
  };

  function getOAuthExpire() {
    return sessionStorage.getItem(oauth_redirect_expire);
  }

  function getOAuthVars() {
    return [
      sessionStorage.getItem(oauth_redirect_uri),
      sessionStorage.getItem(oauth_client_id),
      sessionStorage.getItem(oauth_state),
    ];
  }

  const setOAuthVars = (redirectUri, clientId, state) => {
    const oauthRedirectExp = (
      new Date().valueOf() + constants.MS_UNTIL_OAUTH_EXPIRE
    ).toString();

    sessionStorage.setItem(oauth_redirect_uri, redirectUri);
    sessionStorage.setItem(oauth_client_id, clientId);
    sessionStorage.setItem(oauth_state, state);
    sessionStorage.setItem(oauth_redirect_expire, oauthRedirectExp);
  };

  const removeOAuthFlag = () => {
    sessionStorage.removeItem(oauth_redirect_uri);
    sessionStorage.removeItem(oauth_client_id);
    sessionStorage.removeItem(oauth_state);
    sessionStorage.removeItem(oauth_redirect_expire);
  };

  const saveAccessTokenCreationTime = () => {
    localStorage.setItem(access_token_created_at, new Date().getTime());
  };

  const getAccessTokenCreationTime = () => {
    const tokenCreationTime = localStorage.getItem(access_token_created_at);
    return tokenCreationTime ? parseInt(tokenCreationTime, 10) : null;
  };

  const setCsrf = (token) => {
    CSRF_TOKEN_REFERENCE.current = token;
  };

  const getCsrf = () => {
    return CSRF_TOKEN_REFERENCE.current;
  };

  return {
    constants,
    clearAuthentication,
    getAccessToken,
    getRefreshToken,
    getSubDomainInSession,
    isAegisAuthed,
    isRolodexAuthed,
    isEmbed,
    isLoggedIn,
    isAuthSessionExpired,
    isInactiveTimeoutReached,
    removeInactiveTimer,
    resetInactiveTimer,
    saveSubDomainInSession,
    saveAccessToken,
    saveAuthentication,
    getZendeskUrl,
    getZendeskExpire,
    setZendeskUrl,
    removeZendeskFlag,
    getOAuthVars,
    getOAuthExpire,
    setOAuthVars,
    removeOAuthFlag,
    saveAccessTokenCreationTime,
    getAccessTokenCreationTime,
    setCsrf,
    getCsrf,
  };
};

export default AuthenticationStore;
