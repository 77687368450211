import { useEffect, useState } from 'react';

import {
  Accordion,
  Layout,
  StatusMessage,
  Text,
  useDeleteModal,
  useDeletePermanentModal,
  useToast,
} from '~/eds';
import { useConversationPipeline } from '~/features/ask-anything/hooks';
import { api } from '~/redux';
import { Nullable, Question, QuestionGroup, Uuid } from '~/types';

import { QuestionList } from '../Questions/QuestionList';
import { QuestionGroupItem } from './QuestionGroupItem';
import { useQuestionGroupFormModal } from './useQuestionGroupFormModal';

interface QuestionGroupListProps {
  questionGroups: QuestionGroup[];
  runningQuestionGroupId?: string;
  onQuestionClick: (question: Question, questionGroupId: Uuid) => void;
  onQuestionGroupClick: (
    questionGroup: QuestionGroup,
    questions: Question[],
  ) => void;
}

export const QuestionGroupList = ({
  questionGroups,
  runningQuestionGroupId,
  onQuestionClick,
  onQuestionGroupClick,
}: QuestionGroupListProps) => {
  const [
    getQuestionGroupQuestions,
    { isFetching: isFetchingQuestions },
  ] = api.endpoints.getQuestionGroupQuestions.useLazyQuery();
  const [
    questionGroupQuestionsMapping,
    setQuestionGroupQuestionsMapping,
  ] = useState<
    Record<
      string,
      {
        data: Question[];
        isLoading: boolean;
      }
    >
  >({});

  const [actionData, setActionData] = useState<{
    action: 'edit' | 'delete' | null;
    questionGroup: Nullable<QuestionGroup>;
  }>({
    action: null,
    questionGroup: null,
  });

  const [editModal, showEditModal] = useQuestionGroupFormModal({
    questionGroup: actionData.questionGroup,
    onClose: () => setActionData({ action: null, questionGroup: null }),
  });

  const { queue: messageQueue } = useConversationPipeline();

  const getQuestionStatus = (questionId: string, questionGroupId: string) => {
    if (runningQuestionGroupId === questionGroupId) {
      return messageQueue.find((message) => message.id === questionId)?.status;
    }
    return 'idle';
  };

  const getQuestionGroupStatus = (questionGroupId: string) => {
    if (runningQuestionGroupId === questionGroupId) {
      return messageQueue.every((question) => question.status === 'complete')
        ? 'complete'
        : 'running';
    }
    return 'idle';
  };

  const [
    deleteQuestionGroup,
    { isLoading: isDeleting },
  ] = api.endpoints.deleteQuestionGroup.useMutation();

  const { toast } = useToast();

  const handleOnToggle = async (
    questionGroupId: string,
    isExpanded: boolean,
  ) => {
    // cleanup if the group is collapsed
    if (!isExpanded) {
      setQuestionGroupQuestionsMapping((prev) => ({
        ...prev,
        [questionGroupId]: {
          data: [],
          isLoading: false,
        },
      }));
      return;
    }
    try {
      setQuestionGroupQuestionsMapping((prev) => ({
        ...prev,
        [questionGroupId]: {
          data: [],
          isLoading: true,
        },
      }));
      const questions = await getQuestionGroupQuestions({
        id: questionGroupId,
      }).unwrap();
      if (questions) {
        setQuestionGroupQuestionsMapping((prev) => ({
          ...prev,
          [questionGroupId]: {
            data: questions,
            isLoading: false,
          },
        }));
      }
    } catch (error) {
      toast({
        status: 'warning',
        title: 'Error: Unable to Load Questions',
        message: 'Failed to load questions for the selected group.',
      });
    }
  };

  const handleDelete = async () => {
    if (!actionData.questionGroup) {
      return;
    }
    try {
      await deleteQuestionGroup({ id: actionData.questionGroup.id }).unwrap();
      toast({
        status: 'success',
        message: 'The question group has been successfully deleted.',
      });
      hideDeleteModal();
    } catch (apiError: any) {
      toast({
        status: 'danger',
        message: `The delete action failed due to ${apiError.errors[0].title}.`,
      });
    }
  };

  const [
    confirmationDeleteModal,
    showConfirmationDeleteModal,
  ] = useDeletePermanentModal({
    confirmText: 'DELETE',
    children: (
      <Text>
        This item is shared with other members in your organization. Are you
        sure you want to delete the 1 item you have selected? You won't be able
        to undo this action.
      </Text>
    ),
    title: 'Delete Question Group',
    onDelete: handleDelete,
    isDeleting,
  });

  const [deleteModal, showDeleteModal, hideDeleteModal] = useDeleteModal({
    children: (
      <Text>
        Are you sure you want to delete the 1 item you have selected? You won't
        be able to undo this action.
      </Text>
    ),
    isDeleting,
    primaryActionText: 'Delete',
    onDelete: handleDelete,
    title: 'Delete Question Group',
  });

  useEffect(() => {
    switch (actionData.action) {
      case 'edit':
        showEditModal();
        break;
      case 'delete':
        actionData.questionGroup?.visibility === 'PRIVATE'
          ? showDeleteModal()
          : showConfirmationDeleteModal();
        break;
      default:
        break;
    }
  }, [actionData]);

  const getQuestionGroupItems = () => {
    return questionGroups.map((questionGroup) => {
      const questionData = questionGroupQuestionsMapping[questionGroup.id] ?? {
        data: null,
        isLoading: false,
      };

      if (questionData.data) {
        questionData.data = questionData.data.map((question) => {
          return {
            ...question,
            status: getQuestionStatus(question.id, questionGroup.id),
          };
        });
      }

      const questionGroupStatus = getQuestionGroupStatus(questionGroup.id);

      return {
        title: questionGroup.name,
        headerBorder: 'bottom' as const,
        headerContentAlignment: 'start' as const,
        headerBorderType: 'rounded' as const,
        summary: (
          <QuestionGroupItem
            questionGroup={questionGroup}
            onAction={setActionData}
            disableRun={!!runningQuestionGroupId || isFetchingQuestions}
            status={questionGroupStatus}
            onQuestionGroupClick={async () => {
              if (questionData.data && questionData.data.length) {
                onQuestionGroupClick(questionGroup, questionData.data);
              } else {
                setQuestionGroupQuestionsMapping((prev) => ({
                  ...prev,
                  [questionGroup.id]: {
                    data: [],
                    isLoading: true,
                  },
                }));
                const questions = await getQuestionGroupQuestions({
                  id: questionGroup.id,
                }).unwrap();
                if (questions) {
                  setQuestionGroupQuestionsMapping((prev) => ({
                    ...prev,
                    [questionGroup.id]: {
                      data: questions,
                      isLoading: false,
                    },
                  }));
                  onQuestionGroupClick(questionGroup, questions);
                }
              }
            }}
          />
        ),
        isLoading: questionData.isLoading,
        details: (
          <Layout py={2} ml={54}>
            <Text variant="tiny" color="text.secondary">
              {questionGroup.description}
            </Text>
          </Layout>
        ),
        content: questionGroup.questionIds.length ? (
          <QuestionList
            questions={questionData.data || []}
            onQuestionClick={(question) =>
              onQuestionClick(question, questionGroup.id)
            }
            disableRun={!!runningQuestionGroupId}
            disableActions
          />
        ) : (
          <StatusMessage
            status="info"
            title="No questions in the group"
            mode="column"
            message="Click on More Options > Edit to add questions to the question group."
            action={{
              level: 'action',
              text: 'Add Questions',
              onClick: () => setActionData({ action: 'edit', questionGroup }),
            }}
          />
        ),
        data: questionGroup,
      };
    });
  };

  return (
    <Layout direction="column" overflowY="auto" height="100%" flex={1}>
      <Accordion<QuestionGroup>
        border="none"
        padding="none"
        items={getQuestionGroupItems()}
        onToggle={(isExpanded, item) =>
          handleOnToggle(item?.data?.id || '', isExpanded)
        }
      />
      {editModal}
      {deleteModal}
      {confirmationDeleteModal}
    </Layout>
  );
};
