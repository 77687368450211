import React from 'react';

import { Box, Tabs, types } from '~/eds';
import { useRouting } from '~/routing';

import { DEFAULT_TAB } from '../../../constants';
import { TabValue } from '../../../types';
import { formatUrl } from '../../../utils';
import { ClauseSummaries } from './ClauseSummaries';

export const LeftPane = () => {
  const tabs: types.Tab<TabValue>[] = [
    { label: 'Published', value: 'published', panel: <ClauseSummaries /> },
    { label: 'Review', value: 'review', panel: <ClauseSummaries /> },
    {
      label: 'AI Suggested',
      value: 'ai-suggested',
      panel: <ClauseSummaries />,
    },
  ];

  const { history, getSearchParam } = useRouting();
  const tab = getSearchParam<TabValue>('tab', DEFAULT_TAB);

  const handleUpdateTab = (tabValue: TabValue) => {
    history.push(formatUrl(tabValue));
  };

  return (
    <Box
      flex="none"
      w="25%"
      minW="sidebar.width.s"
      maxW="sidebar.width.m"
      overflowY="auto"
    >
      <Tabs
        enableContentPadding={false}
        onSelectTab={handleUpdateTab}
        selectedTab={tab}
        tabs={tabs}
      />
    </Box>
  );
};
