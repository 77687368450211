import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { showToast } from '~/components/Shared/EcToast';
import { Button, Layout, NumberInput, Select, Text } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Card } from '~/ui';

const SESSION_TIMEOUT_OPTIONS = [
  { label: '24 hours', value: 86400 },
  { label: '3 days', value: 259200 },
  { label: '7 days', value: 604800 },
  { label: 'Custom', value: 'custom' },
];

const INACTIVE_TIMEOUT_OPTIONS = [
  { label: '1 hour', value: 3600 },
  { label: '2 hours', value: 7200 },
  { label: '8 hours', value: 28800 },
  { label: '12 hours', value: 43200 },
  { label: '24 hours', value: 86400 },
  { label: '3 days', value: 259200 },
  { label: '7 days', value: 604800 },
  { label: 'Custom', value: 'custom' },
];

const SessionTimeout = () => {
  const currentUser = useCurrentUser();
  const { clientId } = useParams() as { clientId: string };

  const [customInactiveTimeoutBase, setCustomInactiveTimeoutBase] = useState(0);
  const [customInactiveTimeoutValue, setCustomInactiveTimeoutValue] = useState<
    number | null
  >(null);
  const [inactiveTimeoutInSeconds, setInactiveTimeoutInSeconds] = useState<
    number | string
  >(3600);
  const [inactiveTimeoutError, setInactiveTimeoutError] = useState('');

  const [customSessionTimeoutBase, setCustomSessionTimeoutBase] = useState(0);
  const [customSessionTimeoutValue, setCustomSessionTimeoutValue] = useState<
    number | null
  >(null);
  const [sessionTimeoutInSeconds, setSessionTimeoutInSeconds] = useState<
    number | string
  >(3600);
  const [sessionTimeoutError, setTiSessionTimeoutError] = useState('');

  const [
    updateClientAdmin,
    updateClientResult,
  ] = api.endpoints.updateClientAdmin.useMutation();
  const {
    error: updateClientErrorMsg,
    isError: isUpdateClientError,
    isSuccess: isUpdateClientSuccess,
    isLoading: isUpdateClientLoading,
  } = updateClientResult;

  useEffect(() => {
    if (isUpdateClientSuccess) {
      const message =
        'Successfully updated client session timeout configuration.';
      showToast(SUCCESS, message);
    }
    if (isUpdateClientError) {
      const message = `Failed to update client session timeout configuration due to ${updateClientErrorMsg}.`;

      showToast(ERROR, message);
    }
  }, [isUpdateClientSuccess, isUpdateClientError, updateClientErrorMsg]);

  useEffect(() => {
    if (!currentUser) return;

    const userInactiveTimeoutInSeconds =
      currentUser?.client_config?.inactive_timeout_in_seconds;
    const userSessionTimeoutInSeconds =
      currentUser?.client_config?.session_timeout_in_seconds;

    if (!!userInactiveTimeoutInSeconds) {
      if (
        !INACTIVE_TIMEOUT_OPTIONS.map((o) => o.value).includes(
          userInactiveTimeoutInSeconds,
        )
      ) {
        // custom value occurred
        const base = !(userInactiveTimeoutInSeconds % 86400)
          ? 86400
          : !(userInactiveTimeoutInSeconds % 3600)
          ? 3600
          : 60;
        setCustomInactiveTimeoutBase(base);
        setCustomInactiveTimeoutValue(
          Math.ceil(userInactiveTimeoutInSeconds / base),
        );
        setInactiveTimeoutInSeconds('custom');
      } else {
        setInactiveTimeoutInSeconds(userInactiveTimeoutInSeconds);
      }
    }

    if (!!userSessionTimeoutInSeconds) {
      if (
        !SESSION_TIMEOUT_OPTIONS.map((o) => o.value).includes(
          userSessionTimeoutInSeconds,
        )
      ) {
        // custom value occurred
        const base = !(userSessionTimeoutInSeconds % 86400) ? 86400 : 3600;
        setCustomSessionTimeoutBase(base);
        setCustomSessionTimeoutValue(
          Math.ceil(userInactiveTimeoutInSeconds / base),
        );
        setSessionTimeoutInSeconds('custom');
      } else {
        setSessionTimeoutInSeconds(userSessionTimeoutInSeconds);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (sessionTimeoutInSeconds === 'custom') {
      setTiSessionTimeoutError('');
    } else {
      validateSessionTimeout();
    }
  }, [sessionTimeoutInSeconds]);

  useEffect(() => {
    if (sessionTimeoutInSeconds !== 'custom' || !customSessionTimeoutValue)
      return;
    validateSessionTimeout();
    validateInactiveTimeout();
  }, [
    sessionTimeoutInSeconds,
    customSessionTimeoutBase,
    customSessionTimeoutValue,
  ]);

  useEffect(() => {
    if (inactiveTimeoutInSeconds === 'custom') {
      setInactiveTimeoutError('');
    } else {
      validateInactiveTimeout();
    }
  }, [inactiveTimeoutInSeconds]);

  useEffect(() => {
    if (inactiveTimeoutInSeconds !== 'custom' || !customInactiveTimeoutValue)
      return;
    validateInactiveTimeout();
    validateSessionTimeout();
  }, [
    inactiveTimeoutInSeconds,
    customInactiveTimeoutBase,
    customInactiveTimeoutValue,
  ]);

  const validateInactiveTimeout = () => {
    const currentInactiveTimeoutValue =
      inactiveTimeoutInSeconds === 'custom'
        ? (customInactiveTimeoutValue ?? 0) * customInactiveTimeoutBase
        : inactiveTimeoutInSeconds;

    if (
      currentInactiveTimeoutValue < 60 ||
      currentInactiveTimeoutValue > 604800
    ) {
      setInactiveTimeoutError(
        'Value is outside the acceptable range.  The acceptable range is 1 minute to 7 days.',
      );
      return false;
    }

    const currentSessionTimeoutValue =
      sessionTimeoutInSeconds === 'custom'
        ? (customSessionTimeoutValue ?? 0) * customSessionTimeoutBase
        : sessionTimeoutInSeconds;

    if (
      customSessionTimeoutValue &&
      currentInactiveTimeoutValue > currentSessionTimeoutValue
    ) {
      setInactiveTimeoutError(
        'Idle Session Timeout must be equal to or lower than Reauthentication Frequency.',
      );
      return false;
    }

    setInactiveTimeoutError('');
    return true;
  };

  const validateSessionTimeout = () => {
    const currentSessionTimeoutValue =
      sessionTimeoutInSeconds === 'custom'
        ? (customSessionTimeoutValue ?? 0) * customSessionTimeoutBase
        : sessionTimeoutInSeconds;

    if (
      currentSessionTimeoutValue < 86400 ||
      currentSessionTimeoutValue > 604800
    ) {
      setTiSessionTimeoutError(
        'Value is outside the acceptable range. The acceptable range is 24 hours to 7 days.',
      );
      return false;
    }

    const currentInactiveTimeoutValue =
      inactiveTimeoutInSeconds === 'custom'
        ? (customInactiveTimeoutValue ?? 0) * customInactiveTimeoutBase
        : inactiveTimeoutInSeconds;

    if (
      customInactiveTimeoutValue &&
      currentInactiveTimeoutValue > currentSessionTimeoutValue
    ) {
      setTiSessionTimeoutError(
        'Reauthentication Frequency must be equal to or larger than Idle Session Timeout.',
      );
      return false;
    }

    setTiSessionTimeoutError('');
    return true;
  };

  const onSubmit = () => {
    if (!validateInactiveTimeout() || !validateSessionTimeout()) return;
    const currentInactiveTimeoutValue =
      inactiveTimeoutInSeconds === 'custom'
        ? (customInactiveTimeoutValue ?? 0) * customInactiveTimeoutBase
        : (inactiveTimeoutInSeconds as number);

    const currentSessionTimeoutValue =
      sessionTimeoutInSeconds === 'custom'
        ? (customSessionTimeoutValue ?? 0) * customSessionTimeoutBase
        : (sessionTimeoutInSeconds as number);

    updateClientAdmin({
      clientId,
      inactiveTimeoutInSeconds: currentInactiveTimeoutValue,
      sessionTimeoutInSeconds: currentSessionTimeoutValue,
    });
  };

  return (
    <Card
      collapseIconPosition="left"
      onToggleCollapse={() => {}}
      header={<Card.Header title="Session Timeout" />}
    >
      <Layout direction="column" spacing={6}>
        <Layout direction="column" spacing={2}>
          <Text variant="section">Idle Session Timeout</Text>
          <Layout direction="row" spacing={1} justify="initial" align="center">
            <Text>Users will be automatically logged out after</Text>
            <Layout inline={true} spacing={2}>
              <Select
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                name="custom-inactive-timeout-select"
                width="input.s.width"
                error={inactiveTimeoutError}
                value={inactiveTimeoutInSeconds}
                options={INACTIVE_TIMEOUT_OPTIONS}
                onChange={(value) => {
                  if (value === inactiveTimeoutInSeconds) return;
                  setInactiveTimeoutInSeconds(value as string | number);
                  setCustomInactiveTimeoutBase(value === 'custom' ? 3600 : 0);
                  setCustomInactiveTimeoutValue(null);
                }}
              />
              {customInactiveTimeoutBase > 0 && (
                <>
                  <NumberInput
                    min={0}
                    autoFocus={!customInactiveTimeoutValue}
                    name="custom-inactive-timeout-value"
                    width="input.m.width"
                    error={inactiveTimeoutError}
                    value={customInactiveTimeoutValue}
                    onBlur={validateInactiveTimeout}
                    placeholder="Min 1 minute, Max 7 days"
                    onChange={(value) => {
                      setCustomInactiveTimeoutValue(value);
                      validateInactiveTimeout();
                    }}
                  />
                  <Select
                    isMulti={false}
                    isSearchable={false}
                    isClearable={false}
                    name="custom-inactive-timeout-base-select"
                    width="input.s.width"
                    value={customInactiveTimeoutBase}
                    options={[
                      { label: 'minute', value: 60 },
                      { label: 'hours', value: 3600 },
                      { label: 'days', value: 86400 },
                    ]}
                    onChange={(value) => {
                      setCustomInactiveTimeoutBase(value as number);
                      validateInactiveTimeout();
                    }}
                  />
                </>
              )}
            </Layout>
            <Text>of inactivity.</Text>
          </Layout>
          {inactiveTimeoutError && (
            <Text variant="tiny" color="status.danger">
              {inactiveTimeoutError}
            </Text>
          )}
        </Layout>
        <Layout direction="column" spacing={2}>
          <Text variant="section">Reauthentication Frequency</Text>
          <Layout direction="row" spacing={1} justify="initial" align="center">
            <Text>Users will be required to login every</Text>
            <Layout inline={true} spacing={2}>
              <Select
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                name="custom-session-timeout"
                value={sessionTimeoutInSeconds}
                width="input.s.width"
                error={sessionTimeoutError}
                options={SESSION_TIMEOUT_OPTIONS}
                onChange={(value) => {
                  if (value === sessionTimeoutInSeconds) return;
                  setSessionTimeoutInSeconds(value as string | number);
                  setCustomSessionTimeoutBase(value === 'custom' ? 3600 : 0);
                  setCustomSessionTimeoutValue(null);
                }}
              />
              {customSessionTimeoutBase > 0 && (
                <>
                  <NumberInput
                    min={0}
                    autoFocus={!customSessionTimeoutValue}
                    name="custom-session-timeout-value"
                    width="input.m.width"
                    error={sessionTimeoutError}
                    value={customSessionTimeoutValue}
                    onBlur={validateSessionTimeout}
                    placeholder="Min 24 hours, Max 7 days"
                    onChange={(value) => {
                      setCustomSessionTimeoutValue(value);
                      validateSessionTimeout();
                    }}
                  />
                  <Select
                    isMulti={false}
                    isSearchable={false}
                    isClearable={false}
                    name="custom-session-timeout-select-base"
                    width="input.s.width"
                    value={customSessionTimeoutBase}
                    options={[
                      { label: 'hours', value: 3600 },
                      { label: 'days', value: 86400 },
                    ]}
                    onChange={(value) => {
                      setCustomSessionTimeoutBase(value as number);
                      validateSessionTimeout();
                    }}
                  />
                </>
              )}
            </Layout>
            <Text>.</Text>
          </Layout>
          {sessionTimeoutError && (
            <Text variant="tiny" color="status.danger">
              {sessionTimeoutError}
            </Text>
          )}
        </Layout>
        <Layout direction="row" justify="initial" align="center">
          <Button
            isLoading={isUpdateClientLoading}
            id="save-session-timeout-config"
            disabled={!!sessionTimeoutError || !!inactiveTimeoutError}
            onClick={onSubmit}
            text="Save Changes"
            tooltip="Save Changes"
            variant="primary"
          />
        </Layout>
      </Layout>
    </Card>
  );
};

export default SessionTimeout;
