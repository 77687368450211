import React, { useCallback, useMemo } from 'react';

import { SearchableFields } from '~/api';
import { Link } from '~/eds';
import { api } from '~/redux';
import { DocumentSearchResultItem } from '~/redux/api/methods';
import { SearchDocumentItem } from '~/redux/api/methods/searchV3';
import { MORE_FILTERS_SECTION_ID } from '~/redux/api/transformers';
import { DocumentGroup, SearchFilter } from '~/types';
import { FolderValue, renderDashboardsTableCellValue } from '~/utils/table';

type Props = {
  columnWidths: {
    [key: string]: number;
  };
  // TODO: change to EDS type once is available.
  hardcodedColumns: Array<any>;
  searchFilterColumns: SearchFilter[];
};

export const useSearchColumns = ({
  columnWidths,
  hardcodedColumns,
  searchFilterColumns,
}: Props) => {
  const {
    data: documentGroupsData,
  } = api.endpoints.getAllDocumentGroups.useQuery(undefined);

  const renderGroupCell = useCallback(
    (document: SearchDocumentItem) => {
      if (document.selected_field_values?.document_group_id) {
        const { value } = document.selected_field_values?.document_group_id;
        return (
          <Link pathname={`/document-group/${value[0]}`}>
            {getGroupName(Number(value[0]), documentGroupsData?.results)}
          </Link>
        );
      } else {
        return null;
      }
    },
    [documentGroupsData?.results],
  );

  const columns = useMemo(
    () => [
      ...hardcodedColumns.map((column) => ({
        ...column,
        rawWidth: columnWidths[column.key],
      })),
      ...searchFilterColumns.map((searchFilter) => {
        return {
          ...fieldToColumn(searchFilter, renderGroupCell),
          rawWidth: columnWidths[searchFilter.id],
        };
      }),
    ],
    [searchFilterColumns, columnWidths, documentGroupsData?.results],
  );
  return columns;
};

const fieldToColumn = (
  searchFilter: SearchFilter,
  renderGroupCell: (document: SearchDocumentItem) => JSX.Element | null,
) => {
  if (searchFilter.id === 'document_group_id') {
    return {
      key: SearchableFields.DocumentGroup,
      title: 'Document Group',
      minWidth: 'm',
      disableSortBy: true,
      renderCell: renderGroupCell,
      section: MORE_FILTERS_SECTION_ID,
    };
  } else if (searchFilter.id === 'folder_id') {
    return {
      key: SearchableFields.Folder,
      title: 'Folder',
      minWidth: 'm',
      disableSortBy: true,
      renderCell: (document: DocumentSearchResultItem) => (
        <FolderValue handlers={document.document_handlers} />
      ),
      section: MORE_FILTERS_SECTION_ID,
    };
  }
  const unsortableTypes = ['text'];
  return {
    key: String(searchFilter.id),
    field: searchFilter,
    title: searchFilter.label,
    disableSortBy: unsortableTypes.includes(searchFilter.type),
    mapCellProps: (document: DocumentSearchResultItem) => ({
      text: renderDashboardsTableCellValue(
        searchFilter,
        document.selected_field_values,
      ),
    }),
    section: searchFilter.section,
  };
};

export const getGroupName = (
  groupId: number,
  documentGroups: DocumentGroup[] = [],
) => {
  return documentGroups.find((group) => group.groupId === groupId)?.name;
};
