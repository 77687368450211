import React from 'react';

import { Chip } from '~/eds';
import { api } from '~/redux';

import { ModelResult } from '../types';
import { getModelStatusChip } from '../utils';

interface Props {
  modelResult: ModelResult;
}

export const ModelResultStatus = ({ modelResult }: Props) => {
  const { currentOptimize, currentPublishStatus, latestVersion } = modelResult;

  const { data: progress } = api.endpoints.getPromptModelProgress.useQuery({
    modelId: modelResult.id,
  });

  const chip = getModelStatusChip({
    optimize: currentOptimize,
    publishStatus: progress?.publishStatus ?? currentPublishStatus,
    version: latestVersion,
  });

  return <Chip {...chip} />;
};
