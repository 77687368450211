import { DEFAULT_POLL_INTERVAL_MS } from '~/eds';
import { api } from '~/redux';
import { Nullable } from '~/types';

import { FieldModel, ModelProgress } from '../types';
import { testIsPublishingStatus } from '../utils';

const initialProgress = {
  docsProcessed: 0,
  docsInScope: 0,
  publishStatus: null,
  valuesFound: 0,
  docsInScopeProcessing: false,
};

interface Props {
  model: Nullable<FieldModel>;
  enablePolling?: boolean;
}

/**
 * TODO: Deprecate this in favor of shared solution of usePollModelVersionProgress
 * This shareable hook conditionally polls the publish progress given a modelId.
 */
export const usePollModelProgress = ({
  model,
  enablePolling = false,
}: Props): {
  isLoading: boolean;
  progress: ModelProgress;
} => {
  const {
    data: progress = initialProgress,
    isLoading, // use isLoading over isFetching as we are only indicating the initial loading state for this polled request
  } = api.endpoints.getPromptModelProgress.useQuery(
    {
      // @ts-expect-error: query is skipped if either model or id is not defined
      modelId: model?.id,
    },
    {
      pollingInterval:
        testIsPublishingStatus(model?.publishStatus) || enablePolling
          ? DEFAULT_POLL_INTERVAL_MS
          : undefined,
      skip: !model?.id,
    },
  );

  return {
    progress,
    isLoading,
  };
};
