import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DeleteRoleModal, RoleFormPanel } from '~/components/Roles';
import { trackSegment } from '~/components/SegmentAnalytics';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { Layout, PageLayout, SearchInput, Select, Text } from '~/eds';
import { RoleType, TableContextType } from '~/enums';
import { RoleDetails } from '~/features/roles/';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { RoleAttributes } from '~/redux/api/methods';
import { generateNav, RoutePathType } from '~/routing';
import { Nullable } from '~/types';

const COLUMNS = [
  'name',
  'role_type',
  'description',
  'users',
  'created_by',
  'created_on',
];

const current = {
  text: 'Roles',
  pathname: RoutePathType.AdminRoles,
};

const Page = () => {
  const [search, setSearch] = useState<Nullable<string>>('');
  const [roleType, setRoleType] = useState<Nullable<string>>('');
  const [showRolePanel, setShowRolePanel] = useState(false);
  const [roleDetails, setRoleDetails] = useState<RoleAttributes | undefined>(
    undefined,
  );
  const [showDuplicateRolePanel, setShowDuplicateRolePanel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Nullable<RoleAttributes>>(
    null,
  );
  const user = useCurrentUser();
  const hasPremiumPermissions = useFlag(FlagType.PremiumPermissions);
  const { clientId } = useParams<{ clientId: string }>();

  const nav = generateNav({
    current,
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: clientId ?? user.client,
    },
  });

  const {
    tableSettings = {
      pageSize: 10,
      columnOrder: COLUMNS,
    },
  } = useTableSettings(TableContextType.CustomRoles);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(tableSettings.pageSize);
  const [
    getRoles,
    { isFetching, data },
  ] = api.endpoints.getRoles.useLazyQuery();

  useEffect(() => {
    getRoles({
      client_id: clientId ?? user.client,
      page,
      pageSize,
      name: search || undefined,
      role_type: roleType || undefined,
    });
  }, [page, pageSize, roleType]);

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        disableSortBy: true,
        mapCellProps: (role: RoleAttributes) => ({
          text: role.name,
        }),
      },
      {
        key: 'role_type',
        title: 'Role type',
        disableSortBy: true,
        mapCellProps: (role: RoleAttributes) => ({
          text: role.role_type,
        }),
      },
      {
        key: 'description',
        title: 'Role description',
        disableSortBy: true,
        mapCellProps: (role: RoleAttributes) => ({
          text: role.description,
        }),
      },
      {
        key: 'users',
        title: 'Users',
        cellType: 'link',
        disableSortBy: true,
        mapCellProps: (role: RoleAttributes) => ({
          text: role.users,
          pathname: `/admin/${clientId}/users/`,
          search: `role=${role.id}`,
        }),
      },
      {
        key: 'created_by',
        title: 'Created by',
        disableSortBy: true,
        renderCell: (role: RoleAttributes) => {
          return (
            <User
              mode="avatar-name"
              id={role.created_by}
              preset={!role.created_by ? 'evisort' : undefined}
            />
          );
        },
      },
      {
        key: 'created_at',
        title: 'Created on',
        disableSortBy: true,
        cellType: 'datetime',
        mapCellProps: ({ created_at }: RoleAttributes): Object => ({
          datetime: created_at ? new Date(created_at) : undefined,
        }),
      },
    ],
    [],
  );

  const getRowActions = (row: RoleAttributes) => [
    {
      label: 'Edit role',
      onClick: async (role: RoleAttributes) => {
        setShowRolePanel(true);
        setSelectedRole(role);
        trackSegment('openRoleFormPanel', {
          user_id: user.id,
          client_id: clientId ?? user.client,
          role_id: role.id,
          role_name: role.name,
        });
      },
      disabled: row.role_type === RoleType.PreBuiltRole,
    },
    {
      condition: () => hasPremiumPermissions,
      label: 'Duplicate role',
      onClick: async (role: RoleAttributes) => {
        setShowDuplicateRolePanel(true);
        setShowRolePanel(true);
        setSelectedRole(role);
        trackSegment('openDuplicateRolePanel', {
          user_id: user.id,
          client_id: clientId ?? user.client,
          role_id: role.id,
          role_name: role.name,
        });
      },
      disabled:
        row.role_type === RoleType.PreBuiltRole &&
        (row.name === 'Owner' || !hasPremiumPermissions),
    },
    {
      label: 'Delete role',
      onClick: async (role: RoleAttributes) => {
        trackSegment('openDeleteRoleModal', {
          user_id: user.id,
          client_id: clientId ?? user.client,
          role_id: role.id,
          role_name: role.name,
        });
        setSelectedRole(role);
        setShowDeleteModal(true);
      },
      disabled: row.role_type === RoleType.PreBuiltRole,
    },
  ];

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        setPage(pageIndex);
      }
    },
    [page],
  );
  const onPageSizeChange = useCallback((pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  }, []);

  const panel = roleDetails
    ? {
        title: roleDetails.name,
        width: 'm' as const,
        chips: [
          {
            text: roleDetails.role_type,
            status: 'info' as const,
          },
        ],
        hidden: {
          isHidden: false,
          onHide: () => setRoleDetails(undefined),
        },
        children: <RoleDetails role={roleDetails} />,
      }
    : undefined;

  return (
    <PageLayout
      title="Roles"
      nav={nav}
      description="Manage and create custom roles with specific permissions to control what your users can do."
      panel={panel}
      actions={[
        {
          level: 'primary',
          text: 'New Role',
          onClick: () => {
            setShowRolePanel(true);
            trackSegment('openRoleFormPanel', {
              user_id: user.id,
              client_id: clientId ?? user.client,
            });
          },
        },
      ]}
      loadingContent={{
        isLoading: isFetching,
        message: 'Loading roles…',
      }}
    >
      <Layout justify="space-between">
        <Layout flex="1" mr={4}>
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault();
              trackSegment('searchTextRoles', {
                search,
                user_id: user.id,
                client_id: clientId ?? user.client,
              });
              setPage(1);
              getRoles({
                page: 1,
                pageSize,
                name: search || undefined,
                client_id: clientId ?? user.client,
                role_type: roleType || undefined,
              });
            }}
          >
            <SearchInput
              width="100%"
              name="search"
              value={search}
              onChange={setSearch}
              placeholder="Search by role name"
            />
          </form>
        </Layout>
        <Layout align="center" spacing={1}>
          <Text>Filters</Text>
          <Select
            width="input.m.width"
            options={Object.values(RoleType).map((type) => ({
              label: type,
              value: type,
            }))}
            name="roleType"
            placeholder="Role Type"
            value={roleType}
            onChange={(value) => {
              trackSegment('selectRoleType', {
                name: value,
                user_id: user.id,
                client_id: clientId ?? user.client,
              });
              setRoleType(value);
            }}
            isMulti={false}
          />
        </Layout>
      </Layout>
      <Layout direction="column" spacing={8} mt={6}>
        {data?.results?.length === 0 && search ? (
          <Layout align="center" justify="center">
            <Text>
              No results for: <Text variant="body-bold">{search}</Text>
            </Text>
          </Layout>
        ) : (
          <PersistedTable
            name={TableContextType.CustomRoles}
            data={data?.results || []}
            options={{
              enableSelectRows: false,
              enablePageSizeSelect: true,
            }}
            state={{
              pageIndex: page,
              pageSize,
            }}
            rowDetails={{
              onClick: setRoleDetails,
            }}
            getRowActions={getRowActions}
            columns={columns}
            context={TableContextType.CustomRoles}
            totalCount={data?.total}
            onPaginate={onPaginate}
            onPageSizeChange={onPageSizeChange}
          ></PersistedTable>
        )}
      </Layout>
      <RoleFormPanel
        visible={showRolePanel}
        isDuplicate={showDuplicateRolePanel}
        clientId={clientId ?? user.client}
        onHide={() => {
          setShowRolePanel(false);
          setShowDuplicateRolePanel(false);
          setSelectedRole(null);
        }}
        role={selectedRole}
      />
      {selectedRole && (
        <DeleteRoleModal
          isDefaultRole={selectedRole?.id === data?.samlDefaultRoleUuid}
          isVisible={showDeleteModal}
          role={selectedRole}
          clientId={clientId ?? user.client}
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedRole(null);
          }}
        />
      )}
    </PageLayout>
  );
};

export default Page;
