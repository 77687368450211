import { useEffect } from 'react';

import { FileMimeType } from '~/enums';

import { PdfDocumentViewer } from './PdfDocumentViewer';
import {
  ResolvedDocument,
  ResolveDocumentCallback,
  SupportedFileMimeType,
} from './types';
import { UnsupportedDocumentViewer } from './UnknownDocumentViewer';
import { WordDocumentViewer } from './WordDocumentViewer';

const viewers = {
  [FileMimeType.Pdf]: PdfDocumentViewer,
  [FileMimeType.Html]: WordDocumentViewer, // Once using DocumentEditor (EJ2) as the viewer, update the map to reference Word mimetypes
};

/**
 * This hook resolves the document file and Viewer based on its mime-type
 */
export const useResolveDocument = ({
  resolvedDocument,
  onResolveDocument,
}: {
  resolvedDocument?: ResolvedDocument;
  onResolveDocument?: ResolveDocumentCallback;
}) => {
  useEffect(() => {
    if (onResolveDocument && resolvedDocument) {
      onResolveDocument(resolvedDocument);
    }
  }, [resolvedDocument]);

  return {
    Viewer:
      viewers[resolvedDocument?.file.type as SupportedFileMimeType] ??
      UnsupportedDocumentViewer,
    file: resolvedDocument?.file,
  };
};
