import { isEqual, orderBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  builderSetFieldSections,
  builderSetInitialWorkflow,
  builderSetShouldEnableAllComponents,
  builderSetShouldValidate,
  builderSetTab,
  workflowClearFile,
  workflowLoad,
} from '~/actions';
import {
  discardDraft,
  getWorkflow,
  patchWorkflow,
  patchWorkflowSettings,
  saveAndPublishWorkflow,
} from '~/api/workflows';
import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import LockIcon from '~/components/Shared/Icons/LockIcon';
import LoadingPage from '~/components/Shared/LoadingPage';
import ErrorIcon from '~/components/Workflow/shared/ErrorIcon';
import { parseDataFieldSections } from '~/components/Workflow/Workflow.utils';
import {
  CreatePartySigner,
  Edit,
  Finalize,
  Form,
  Review,
  Settings,
  Setup,
  Sidebar,
  Sign,
  Source,
} from '~/components/Workflow/WorkflowBuilderPage';
import { Button } from '~/eds';
import { BuilderTabType } from '~/enums';
import { withUsers } from '~/hocs';
import { useAsync, useCurrentUser } from '~/hooks';
import { getErrors } from '~/reducers/workflow';
import { api } from '~/redux';
import { ERROR } from '~/types/toast.types';
import {
  FlexLayout,
  PageLayout,
  Text,
  useConfirmChanges,
  useModal,
  useModalSimple,
} from '~/ui';
import { joinWith } from '~/utils/array';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

function getTabsData({ shouldEnableAllComponents, errors, shouldValidate }) {
  const tooltipMsg =
    'Changes you can make to this workflow are limited since this workflow is no longer active.';
  return {
    source: {
      content: <Source />,
      iconLeft: 'documentOutline',
      iconRight: shouldEnableAllComponents ? (
        <ErrorIcon errors={errors.source} shouldRender={shouldValidate} />
      ) : (
        <LockIcon />
      ),
      id: 'workflow-navs--source',
      label: 'Source',
      value: BuilderTabType.Source,
      tooltip: shouldEnableAllComponents ? null : tooltipMsg,
    },
    form: {
      content: <Form />,
      iconLeft: 'field',
      iconRight: shouldEnableAllComponents ? (
        <ErrorIcon errors={errors.form} shouldRender={shouldValidate} />
      ) : (
        <LockIcon />
      ),
      id: 'workflow-navs--form',
      label: 'Form',
      value: BuilderTabType.Form,
      tooltip: shouldEnableAllComponents ? null : tooltipMsg,
    },
    edit: {
      content: <Edit />,
      iconLeft: 'edit',
      iconRight: shouldEnableAllComponents ? (
        <ErrorIcon errors={errors.edit} shouldRender={shouldValidate} />
      ) : (
        <LockIcon />
      ),
      label: 'Edit',
      value: BuilderTabType.Edit,
      tooltip: shouldEnableAllComponents ? null : tooltipMsg,
    },
    review: {
      content: <Review />,
      iconLeft: 'checklist',
      iconRight: shouldEnableAllComponents ? (
        <ErrorIcon errors={errors.review} shouldRender={shouldValidate} />
      ) : (
        <LockIcon />
      ),
      id: 'workflow-navs--review',
      label: 'Review',
      value: BuilderTabType.Review,
      tooltip: shouldEnableAllComponents ? null : tooltipMsg,
    },
    sign: {
      content: <Sign />,
      iconLeft: 'signature',
      iconRight: shouldEnableAllComponents ? (
        <ErrorIcon errors={errors.sign} shouldRender={shouldValidate} />
      ) : (
        <LockIcon />
      ),
      id: 'workflow-navs--sign',
      label: 'Sign',
      value: BuilderTabType.Sign,
      tooltip: shouldEnableAllComponents ? null : tooltipMsg,
    },
    finalize: {
      content: <Finalize />,
      iconLeft: 'inbox',
      iconRight: shouldEnableAllComponents ? (
        <ErrorIcon errors={errors.finalize} shouldRender={shouldValidate} />
      ) : (
        <LockIcon />
      ),
      id: 'workflow-navs--finalize',
      label: 'Finalize',
      value: BuilderTabType.Finalize,
      tooltip: shouldEnableAllComponents ? null : tooltipMsg,
    },
    settings: {
      content: <Settings />,
      iconLeft: 'wrench',
      iconRight: (
        <ErrorIcon errors={errors.settings} shouldRender={shouldValidate} />
      ),
      id: 'workflow-navs--settings',
      label: 'Settings',
      value: BuilderTabType.Settings,
    },
  };
}

function Page({
  // connected,
  errors,
  folderTree,
  selectedTab,
  shouldEditParties,
  shouldEditSetup,
  shouldEnableAllComponents,
  shouldValidate,
  workflow,
  workflowClearFile,
  builderSetShouldValidate,
  workflowLoad,
  builderSetShouldEnableAllComponents,
  builderSetTab,
  builderSetFieldSections,
  builderSetInitialWorkflow,
  hasUnsavedChanges: initalHasUnsavedChanges,
}) {
  const history = useHistory();
  const params = useParams();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(
    initalHasUnsavedChanges,
  );
  const currentUser = useCurrentUser();
  const clientId = currentUser.client;
  const {
    data: dataFieldSections,
  } = api.endpoints.getDataFieldSections.useQuery(clientId);
  useEffect(() => setHasUnsavedChanges(initalHasUnsavedChanges), [
    initalHasUnsavedChanges,
  ]);

  useEffect(() => {
    if (dataFieldSections) {
      const parsedDataFieldSections = parseDataFieldSections(dataFieldSections);
      builderSetFieldSections(parsedDataFieldSections);
    }
  }, [dataFieldSections]);

  const workflowHasErrors = Object.values(errors).some(
    (error) => error.count > 0,
  );
  const { workflowId } = params;
  const workflowWithPublishFlag = {
    ...workflow,
    publishable: !workflowHasErrors,
  };
  const hasPrefilledFormData = !!workflow.prefilledFormData;

  function goBack() {
    history.goBack();
  }

  const trackSegmentWorkflow = (name, newWorkflowId) => {
    trackSegment(name, {
      groupId: getClientInfo(currentUser),
      userId: getUserClientInfo(currentUser),
      workflowId: newWorkflowId ?? workflow.id,
      workflowName: workflow.name,
    });
  };

  const { executor: fetchWorkflow, isLoading: isLoadingWorkflow } = useAsync(
    getWorkflow,
    { id: workflowId, folderTreeId: folderTree.id },
    {
      condition: workflowId,
      deps: [folderTree, workflowId],
      errorHandler: goBack,
      successHandler: (response) => {
        builderSetShouldValidate(true);
        workflowLoad(response);
        builderSetShouldEnableAllComponents(response);
        builderSetInitialWorkflow(response);
      },
      errorToastMessage: 'Failed to load workflow, please try again.',
    },
  );

  const { executor: executeDiscardDraft } = useAsync(discardDraft, workflow, {
    errorToastMessage: `Unable to discard draft "${workflow.name}", please try again.`,
    successHandler: () => {
      const segmentName = isNew
        ? 'User Deletes Workflow Draft'
        : 'User Reverts Workflow Draft';
      trackSegmentWorkflow(segmentName);
      goBack();
    },
    successToastMessage: `Workflow "${workflow.name}" draft has been successfully discarded.`,
  });

  const { executor: executePatchWorkflow, isLoading: isSaving } = useAsync(
    patchWorkflow,
    workflowWithPublishFlag,
    {
      errorToastMessage: `Unable to save workflow "${workflow.name}", please try again.`,
      successHandler: (response) => {
        workflowClearFile();
        setHasUnsavedChanges(false);
        trackSegmentWorkflow('User Saves Workflow Draft', response.id);
        history.replace(`/workflow/builder/${response.id}`);
      },
      successToastMessage: `Workflow "${workflow.name}" has been successfully saved.`,
    },
  );

  const {
    executor: executePatchWorkflowSettings,
    isLoading: isSavingSettings,
  } = useAsync(patchWorkflowSettings, workflow, {
    errorToastMessage: `Unable to save workflow "${workflow.name}", please try again.`,
    successHandler: () => {
      setHasUnsavedChanges(false);
      fetchWorkflow();
    },
    successToastMessage: `Workflow "${workflow.name}" has been successfully saved.`,
  });

  const [publishModal, showPublishModal] = useModal({
    actionButton: {
      errorHandler: () => {
        showToast(
          ERROR,
          `Unable to publish workflow "${workflow.name}", please try again.`,
        );
      },
      promise: async () => {
        const response = await saveAndPublishWorkflow(workflow);
        trackSegmentWorkflow('User Publishes Workflow Draft', response.id);
        history.replace(`/workflow/builder/${response.id}`);
      },
      text: 'Reset Mapping & Publish',
    },
    title: 'Publish Workflow',
    content: (
      <Text color="gray-900" variant="s-spaced">
        <>
          Republishing the workflow will necessitate re-mapping and
          re-generating your Prefillable Form Link in Settings.
        </>
      </Text>
    ),
  });

  const {
    executor: executeSaveAndPublishWorkflow,
    isLoading: isPublishing,
  } = useAsync(saveAndPublishWorkflow, workflow, {
    errorToastMessage: `Unable to publish workflow "${workflow.name}", please try again.`,
    successHandler: (response) => {
      workflowClearFile();
      setHasUnsavedChanges(false);
      trackSegmentWorkflow('User Publishes Workflow Draft', response.id);
      history.replace(`/workflow/builder/${response.id}`);
    },
    successToastMessage: `Workflow "${workflow.name}" has been successfully published.`,
  });

  const isNew = workflow.versions.length === 1;
  const discardDraftTitle = isNew ? 'Delete Draft' : 'Revert Draft';
  const discardDraftDescription = isNew
    ? 'Do you want to delete this draft?'
    : 'Do you want to discard all saved changes that have been made since the last time the form was published?';
  const [discardDraftModal, showDiscardDraftModal] = useModalSimple({
    confirm: executeDiscardDraft,
    text: isNew ? 'Delete' : 'Revert',
    title: discardDraftTitle,
    content: (
      <Text color="gray-700" variant="s-spaced">
        {`${discardDraftDescription} This action cannot be undone.`}
      </Text>
    ),
    width: 'm',
  });

  const isLoading =
    isLoadingWorkflow || isSaving || isSavingSettings || isPublishing;

  const Prompt = useConfirmChanges(hasUnsavedChanges);

  const tooltipErrorMessage = useMemo(() => {
    const errorTypes = orderBy(
      Object.entries(errors)
        .filter(([_type, { count }]) => count)
        .map(([type]) => type),
    );
    const errorTypesString = joinWith(errorTypes, {
      delimiter: ', ',
      lastDelimiter: ' and ',
    });
    const pluralizedStep = pluralize('step', errorTypes.length);
    return `There is an error in your ${errorTypesString} ${pluralizedStep} please edit and try again`;
  }, [errors]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const header = (
    <PageLayout.Header
      leftIcon={{
        icon: 'close',
        onClick: goBack,
      }}
      rightContent={
        <FlexLayout space={4}>
          {shouldEnableAllComponents ? (
            <React.Fragment>
              <Button
                disabled={!workflow.isDraft}
                id="workflow--discard-draft"
                text={discardDraftTitle}
                onClick={showDiscardDraftModal}
              />
              <Button
                disabled={!hasUnsavedChanges}
                id="workflow--save-draft"
                text="Save Draft"
                onClick={executePatchWorkflow}
              />
              <Button
                disabled={workflowHasErrors}
                tooltip={workflowHasErrors && tooltipErrorMessage}
                iconLeft="checkmark"
                id="workflow--publish"
                text="Publish"
                variant="primary"
                onClick={() =>
                  hasPrefilledFormData
                    ? showPublishModal()
                    : executeSaveAndPublishWorkflow()
                }
              />
            </React.Fragment>
          ) : (
            <Button
              disabled={!hasUnsavedChanges}
              text="Save Changes"
              variant="primary"
              onClick={executePatchWorkflowSettings}
            />
          )}
        </FlexLayout>
      }
      title={{
        text: workflow.name || '',
      }}
    />
  );

  const tabsData = getTabsData({
    shouldEnableAllComponents,
    errors,
    shouldValidate,
  });
  const tabs = Object.values(tabsData);

  const sidebar = (
    <PageLayout.Sidebar>
      <Sidebar />
    </PageLayout.Sidebar>
  );

  if (shouldEditSetup) {
    return <Setup />;
  }

  return (
    <>
      {shouldEditParties ? (
        <PageLayout>
          <CreatePartySigner />
        </PageLayout>
      ) : (
        <PageLayout
          bg="background.quiet"
          header={header}
          sidebar={sidebar}
          selectedTab={selectedTab}
          tabs={tabs}
          onSelectTab={builderSetTab}
        >
          {tabsData[selectedTab].content}
        </PageLayout>
      )}
      {Prompt}
      {discardDraftModal}
      {publishModal}
    </>
  );
}

function mapStateToProps({ workflow, builder, client }, { users }) {
  const {
    selectedTab,
    shouldEditParties,
    shouldEditSetup,
    shouldEnableAllComponents,
    shouldValidate,
  } = builder;
  const { prefilledFormData: _omittedPrefilled, ...restWorflow } = workflow;
  const {
    prefilledFormData: _omittedOriginalPrefilled,
    ...restOriginalWorflow
  } = builder.initialWorkflow;
  return {
    errors: getErrors(workflow, builder, users),
    folderTree: client.folderTree,
    workflow,
    selectedTab,
    shouldEditParties,
    shouldEditSetup,
    shouldEnableAllComponents,
    shouldValidate,
    hasUnsavedChanges: !isEqual(restWorflow, restOriginalWorflow),
  };
}

export default withUsers(
  connect(mapStateToProps, {
    workflowClearFile,
    builderSetFieldSections,
    builderSetShouldEnableAllComponents,
    builderSetShouldValidate,
    builderSetTab,
    builderSetInitialWorkflow,
    workflowLoad,
  })(Page),
);
