import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import { getSyncPairsAriba } from '~/api';
import { procurementIntegrations } from '~/constants/integrations';
import { Icon, Text } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { FlexLayout } from '~/ui';
import { createSyncPairData } from '~/utils';

import { getSyncPairCardStatus } from '../util';
import IntegrationCardList from './IntegrationCardList';

function ProcurementIntegrationCardList({
  isCentered = false,
  integrationsSetSyncPairs,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const usingOneTimeImportFlag = useFlag(FlagType.FieldMappingOneTimeImport);
  const { clientId } = useParams();

  const { response: aribaSyncPairs } = useAsync(
    getSyncPairsAriba,
    { clientId },
    {
      condition: true,
      errorToastMessage: 'Unable to fetch sync pairs, please try again.',
      successHandler: integrationsSetSyncPairs,
    },
  );

  const renderSyncPairCardStatus = ({ color, icon, count }) => {
    if (!count || count <= 0) {
      return <Icon color={color} icon={icon} />;
    }

    return (
      <FlexLayout alignItems="center" space={2}>
        <Icon color={color} icon={icon} />
        <Text color={color} variant="tiny-bold">
          {count}
        </Text>
      </FlexLayout>
    );
  };

  let integrationCards = Object.values(procurementIntegrations).map(
    (integration) => ({
      ...integration,
      status: renderSyncPairCardStatus(
        getSyncPairCardStatus(integration.provider, aribaSyncPairs),
      ),
      description: `Synchronize documents stored on ${integration.name} with Evisort.`,
      action: {
        text: 'Add Sync Pair',
        onClick: () => {
          const syncPairData = createSyncPairData(integration);
          integrationsSetActiveSyncPair(syncPairData);

          let stage = ManageSyncPairStageType.Realm;

          if (usingOneTimeImportFlag) {
            stage = ManageSyncPairStageType.MultiAPIs;
          }

          integrationsSetManageSyncPairStage(stage);
        },
      },
    }),
  );

  return (
    <IntegrationCardList
      isCentered={isCentered}
      integrationCards={integrationCards}
    />
  );
}

const mapStateToProps = ({ integrations }) => ({
  syncPairs: Object.values(integrations.syncPairs).map((syncPair) => ({
    ...procurementIntegrations[syncPair.provider],
    ...syncPair,
  })),
});

export default connect(mapStateToProps, {
  integrationsSetSyncPairs,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(ProcurementIntegrationCardList);
