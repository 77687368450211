import React from 'react';

import EcFab from '~/components/Shared/EcFab';
import EditIcon from '~/components/Shared/Icons/EditIcon';
import ProvisionIcon from '~/components/Shared/Icons/ProvisionIcon';
import RemoveProvisionIcon from '~/components/Shared/Icons/RemoveProvisionIcon';
import { FlexLayout } from '~/ui';
import Menu from '~/ui/components/Menu';

function IconBeforeText({ children }) {
  return <FlexLayout mr={2}>{children}</FlexLayout>;
}

function EditProvisionMenu({
  id,
  openTagClauseModal,
  openRemoveProvisionModal,
  selectedProvision,
}) {
  const items = [];

  if (!!selectedProvision) {
    items.push({
      content: (
        <>
          <IconBeforeText>
            <ProvisionIcon />
          </IconBeforeText>{' '}
          Edit Provision
        </>
      ),
      id: 'editProvision',
      onClick: openTagClauseModal,
    });

    items.push({
      content: (
        <>
          <IconBeforeText>
            <RemoveProvisionIcon />
          </IconBeforeText>{' '}
          Remove Provision
        </>
      ),
      id: 'removeProvision',
      onClick: openRemoveProvisionModal,
    });
  } else {
    items.push({
      content: (
        <>
          <IconBeforeText>
            <ProvisionIcon />
          </IconBeforeText>{' '}
          Quick AI
        </>
      ),
      id: 'removeProvision',
      onClick: openTagClauseModal,
    });
  }

  const renderMenuButton = () => ({ open }) => (
    <EcFab
      aria-haspopup
      clicked={open}
      icon={<EditIcon color="rgba(0, 0, 0, 0.75)" />}
      id={id}
      tabIndex={0}
      title="click to edit provision"
    />
  );

  return (
    <Menu
      aria-label="edit provision"
      items={items}
      menuButtonProps={{
        render: renderMenuButton,
      }}
    />
  );
}

export default EditProvisionMenu;
