import { isArray } from 'lodash';

import { Field } from './types';

export const requiredValidator = (requiredFields: string[]) => (
  field: Field,
  value: unknown,
) => {
  const isValueValid = isArray(value) ? value.length > 0 : !!value;
  if (requiredFields.includes(field.name)) {
    switch (typeof value) {
      case 'string':
        return value.trim().length > 0 ? null : 'This field is required';
      case 'number':
        return value === 0 || value ? null : 'This field is required';
      default:
        return isValueValid ? null : 'This field is required';
    }
  }
  return null;
};

export const uuidValidator = (fieldNames: string[]) => (
  field: Field,
  value: unknown,
) => {
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  if (
    fieldNames.includes(field.name) &&
    value &&
    !uuidPattern.test(value as string)
  ) {
    return 'The value is not a valid UUID, use pattern 00000000-0000-0000-0000-000000000000';
  }
  return null;
};
