import React, { useCallback, useMemo, useState } from 'react';

import EcModal from '~/components/Shared/EcModal';
import { Button, parseDate, SearchInput, Table } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { MODAL_NAME } from '~/types/modal.types';
import { Box, FlexLayout, Link, LoadingContainer, Text } from '~/ui';

const SelectGroup = ({ onSelectGroup, onNewGroupCreated }) => {
  const [filter, setFilter] = useState('');
  const [showNewGroup, setShowNewGroup] = useState(false);
  const refactorMoment = useFlag(FlagType.DeprecateMoment);

  const {
    data: documentGroupsData,
    isFetching: isFetchingDocumentGroups,
  } = api.endpoints.getAllDocumentGroups.useQuery();

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        renderCell: (group) => {
          return (
            <Link onClick={() => onSelectGroup?.(group.groupId)}>
              {group.name?.trim()}
            </Link>
          );
        },
      },
      {
        key: 'createdBy',
        title: 'Created by',
        renderCell: (group) => {
          return <Text variant="body1">{group.createdBy}</Text>;
        },
      },
      {
        key: 'dateAdded',
        title: 'Created on date',
        cellType: 'datetime',
        mapCellProps: (group) => ({
          datetime: parseDate(group.dateAdded, refactorMoment),
          format: 'iso',
        }),
      },
      {
        key: 'lastModified',
        title: 'Last updated',
        cellType: 'datetime',
        mapCellProps: (group) => ({
          datetime: parseDate(group.lastModified, refactorMoment),
          format: 'iso',
        }),
      },
      {
        key: 'numberOfDocs',
        title: 'Number of documents',
        mapCellProps: (group) => ({
          text: group.numberOfDocs,
        }),
      },
    ],
    [],
  );
  const handleSearch = useCallback((value) => setFilter(value), []);

  return (
    <Box mr={4} ml={4}>
      <FlexLayout mb={8}>
        <Text variant="subtitle">
          Select which group you want add this document to
        </Text>
      </FlexLayout>
      <FlexLayout mb={8}>
        <Box sx={{ flex: 1 }}>
          <SearchInput
            name="groups"
            placeholder="Search groups"
            value={filter}
            onChange={handleSearch}
          />
        </Box>

        <FlexLayout ml={8}>
          <Button text="+ New Group" onClick={() => setShowNewGroup(true)} />
        </FlexLayout>
      </FlexLayout>

      <LoadingContainer isLoading={isFetchingDocumentGroups}>
        {documentGroupsData && (
          <Table
            name="groups result"
            totalCount={documentGroupsData.count}
            columns={columns}
            data={documentGroupsData.results
              .map((group) => {
                return { ...group, id: group.groupId };
              })
              .filter(
                (group) =>
                  group.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0,
              )}
            options={{
              enableExportXlsx: false,
              enableSelectRows: false,
              enableManageColumns: false,
            }}
            reactTableOptions={{
              autoResetSortBy: false,
              disableSortRemove: true,
            }}
          />
        )}
      </LoadingContainer>
      {showNewGroup && (
        <EcModal
          modalType={MODAL_NAME}
          width="560px"
          title="New Group"
          labelText="Group Name"
          includeTextArea={true}
          labelTextArea="Notes"
          confirmButtonText="Create Group"
          handleNameChange={(name, note) => onNewGroupCreated?.({ name, note })}
          hideModal={() => setShowNewGroup(false)}
        />
      )}
    </Box>
  );
};

export default SelectGroup;
