import PT from 'prop-types';
import React, { useState } from 'react';

import {
  Box,
  FlexLayout,
  Icon,
  styles,
  Text,
  TextInput,
  useEscape,
  useHover,
} from '~/ui';

function EditableText({
  disabled = false,
  id,
  isOutsideHover = false,
  placeholder = 'Untitled',
  width,
  value,
  onChange,
  error,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [draftValue, setDraftValue] = useState(value);
  const [ref, isHovered] = useHover();

  function cancel() {
    setDraftValue(value);
    setIsEditing(false);
  }

  function submit(e) {
    e.preventDefault();

    if (draftValue) {
      onChange(draftValue);
    }
    setIsEditing(false);
  }

  useEscape(cancel);

  function getWidth(theme) {
    if (!width || !theme.sizes[`input-width-${width}`]) {
      return '100%';
    }
    return theme.sizes[`input-width-${width}`];
  }

  if (isEditing) {
    return (
      <Box as="form" sx={{ width: getWidth }} onBlur={submit} onSubmit={submit}>
        <TextInput
          autoFocus
          placeholder={placeholder}
          value={draftValue}
          width="fullWidth"
          onChange={setDraftValue}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 'auto' }}>
      <FlexLayout
        alignItems="center"
        bg={isHovered && !isOutsideHover ? 'black-alpha-04' : null}
        disabled={disabled}
        justifyContent="space-between"
        px={4}
        ref={ref}
        space={3}
        sx={{
          borderRadius: 'm',
          height: 'input-height',
          width: getWidth,
          borderColor: error ? 'red-400' : null,
          borderWidth: error ? 1 : null,
          borderStyle: error ? 'solid' : null,
        }}
        onClick={() => setIsEditing(true)}
      >
        <Text
          color={error ? 'red-400' : value ? 'gray-900' : 'gray-600'}
          variant="m-dense-bold"
          sx={{ ...styles.typography.truncate }}
        >
          {value || placeholder}
        </Text>
        {(isHovered || isOutsideHover) && !disabled ? (
          <Icon color="gray-600" icon="edit" id={id} size="s" />
        ) : null}
      </FlexLayout>
      {error && (
        <Text color="red-400" variant="tiny">
          {error}
        </Text>
      )}
    </Box>
  );
}

EditableText.propTypes = {
  disabled: PT.bool,
  /** ID */
  id: PT.string,
  /** Boolean indicating if container was hovered */
  isOutsideHover: PT.bool,
  /** Placeholder */
  placeholder: PT.string,
  /** Current text value */
  value: PT.string,
  /** Callback that returns new text value */
  onChange: PT.func.isRequired,
};

export default EditableText;
