import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Icon, Layout, PageLayout, Text } from '~/eds';
import { ClientIdType, FeatureFlagType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { withPermissions } from '~/hocs';
import { useCurrentUser, useHasFeatureFlag } from '~/hooks';
import {
  canUseAuditLogs,
  checkIsSuperAdmin,
  testHasClientId,
} from '~/permissions';
import { testIsSuperAdmin } from '~/utils/user';

type Props = {
  //router
  location: any;
  //injected
  checkPermission: (resourceId: string, resourceType: string) => boolean;
  isPermissionsLoading: boolean;
};

const PERMISSIONS = [
  {
    resourceId: 'users',
    resourceType: 'view',
  },
  {
    resourceId: 'roles',
    resourceType: 'view',
  },
  {
    resourceId: 'departments',
    resourceType: 'view',
  },
  {
    resourceId: 'email_intake',
    resourceType: 'view',
  },
  {
    resourceId: 'data_fields',
    resourceType: 'view',
  },
  {
    resourceId: 'integrations',
    resourceType: 'view',
  },
  {
    resourceId: 'sso',
    resourceType: 'view',
  },
  {
    resourceId: 'restoration_hub',
    resourceType: 'view',
  },
  //More settings permissions
  {
    resourceId: 'root_folder_access_settings',
    resourceType: 'view',
  },
  {
    resourceId: 'documents_metadata_bulk_upload',
    resourceType: 'view',
  },
  {
    resourceId: 'clause_quick_ai',
    resourceType: 'view',
  },
  {
    resourceId: 'deleted_documents',
    resourceType: 'view',
  },
  {
    resourceId: 'audit_logs',
    resourceType: 'view',
  },
];

const Page = ({ isPermissionsLoading, checkPermission }: Props) => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();
  const isInboundEmailFeatureEnabled = useHasFeatureFlag(
    FeatureFlagType.InboundEmail,
  );
  const hasNewUsersPage = useFlag(FlagType.NewUsersPage);
  const isSuperAdmin = checkIsSuperAdmin(currentUser);
  const hasAuditLogs = canUseAuditLogs(currentUser);
  const isGeneralClient =
    testHasClientId(ClientIdType.General)(currentUser) &&
    parseInt(clientId) === 1;

  const usersUrl = !hasNewUsersPage
    ? `/admin/console/${clientId || currentUser.client}/users`
    : `/admin/${clientId}/users`;

  const superAdminUsersUrl = !hasNewUsersPage
    ? `/admin/console/${clientId ?? currentUser.client}/users`
    : '/super-admin/users';

  //is this deprecated?
  const hasWorkflow = useHasFeatureFlag('WORKFLOW' as FeatureFlagType);

  const hasSingleSignOn = useHasFeatureFlag(FeatureFlagType.SingleSignOn);
  const hasSuperAdminSamlSSO = useFlag(FlagType.SuperAdminSamlSSO);
  const hasAdminSamlSSO = hasSingleSignOn && checkPermission('sso', 'view');
  const isSAMLSSOVisible = testIsSuperAdmin(currentUser)
    ? hasSuperAdminSamlSSO
    : hasAdminSamlSSO;
  const hasCopyWorkflow = useFlag(FlagType.CopyWorkflow);

  //Super Admin Guard
  useEffect(() => {
    if (!isSuperAdmin && !clientId) {
      history.push(`/admin/console/${currentUser.client}`);
    }
  }, [isSuperAdmin, clientId]);

  const canViewMoreSettings = useCallback(() => {
    return (
      checkPermission('root_folder_access_settings', 'view') ||
      checkPermission('documents_metadata_bulk_upload', 'view') ||
      checkPermission('clause_quick_ai', 'view') ||
      checkPermission('deleted_documents', 'view')
    );
  }, [checkPermission]);

  const workflowLinks = [
    {
      id: OnboardingIdType.AdminUserTab,
      title: 'Users',
      description:
        'Add, update, and remove users from your organization and manage access control settings.',
      url: usersUrl,
      visible: checkPermission('users', 'view'),
    },
    {
      title: 'Roles',
      description:
        ' Manage and create custom roles to control user permissions.',
      url: `/admin/${clientId}/roles`,
      visible: hasNewUsersPage && checkPermission('roles', 'view'),
    },
  ];

  const superAdminLinks = [
    {
      id: OnboardingIdType.AdminUserTab,
      title: 'Users',
      description:
        'Add, update, and remove users from your organization and manage access control settings.',
      url: superAdminUsersUrl,
      visible: true,
    },
    {
      title: 'Manage Clients',
      description:
        'Access clients and mirror client users to carry out administrative tasks.',
      url: '/admin/',
      visible: true,
    },
    {
      id: OnboardingIdType.AdminDepartmentTab,
      title: 'Departments',
      description:
        'Set up departments and assign users to them to easily manage things like access control settings.',
      url: `/admin/console/${clientId}/departments`,
      visible: true,
    },
    {
      title: 'Email Intake',
      description: 'Manage the different email intake rules.',
      url: `/admin/console/${clientId}/email-intake`,
      visible: true,
    },
    {
      title: 'Data Fields',
      description:
        'Configure data fields for documents to suit your organization needs.',
      url: `/admin/console/${clientId}/data-fields`,
      visible: true,
    },
    {
      title: 'Integrations',
      description: 'Connect Evisort with your external platforms and services.',
      url: `/admin/console/${clientId}/integrations`,
      visible: true,
    },
    {
      title: 'Copy Workflow',
      description: 'Copy workflows from one client to another.',
      url: `/admin/console/${clientId}/copy-workflow`,
      visible: hasCopyWorkflow && checkIsSuperAdmin(currentUser),
    },
    {
      title: 'Restoration Hub',
      description: 'You can restore any deleted items here.',
      url: `/admin/console/${clientId}/restoration-hub`,
      visible: true,
    },
    {
      title: 'More Settings',
      description: 'Configure more settings for your organization.',
      url: `/admin/console/${clientId}/more-settings`,
      visible: true,
    },
    {
      title: 'Document Processing',
      description:
        'View the number of documents processed and rerun failed algorithms.',
      url: `/admin/console/${clientId}/process-documents`,
      visible: checkIsSuperAdmin(currentUser),
    },
    {
      title: 'Manage Party Lists',
      description: 'Edit lists of internal parties and counterparties.',
      url: `/admin/console/${clientId}/manage-party-lists`,
      visible: checkIsSuperAdmin(currentUser),
    },
  ];

  const adminLinks = [
    {
      id: OnboardingIdType.AdminUserTab,
      title: 'Users',
      description:
        'Add, update, and remove users from your organization and manage access control settings.',
      url: usersUrl,
      visible: checkPermission('users', 'view'),
    },
    {
      id: OnboardingIdType.AdminDepartmentTab,
      title: 'Departments',
      description:
        'Set up departments and assign users to them to easily manage things like access control settings.',
      url: `/admin/console/${clientId}/departments`,
      visible: checkPermission('departments', 'view'),
    },
    {
      title: 'Roles',
      description:
        ' Manage and create custom roles to control user permissions.',
      url: `/admin/${clientId}/roles/`,
      visible: hasNewUsersPage && checkPermission('roles', 'view'),
    },
    {
      title: 'Document Processing',
      description:
        'View the number of documents processed and rerun failed algorithms.',
      url: `/admin/console/${clientId}/process-documents`,
      visible: checkIsSuperAdmin(currentUser),
    },
    {
      title: 'Manage Party Lists',
      description: 'Edit lists of internal parties and counterparties.',
      url: `/admin/console/${clientId}/manage-party-lists`,
      visible: checkIsSuperAdmin(currentUser),
    },
    {
      title: 'Copy Workflow',
      description: 'Copy workflows from one client to another.',
      url: `/admin/console/${clientId}/copy-workflow`,
      visible: hasCopyWorkflow && checkIsSuperAdmin(currentUser),
    },
    {
      title: 'Email Intake',
      description: 'Manage the different email intake rules.',
      url: `/admin/console/${clientId}/email-intake`,
      visible:
        isInboundEmailFeatureEnabled && checkPermission('email_intake', 'view'),
    },
    {
      title: 'Data Fields',
      description:
        'Configure data fields for documents to suit your organization needs.',
      url: `/admin/console/${clientId}/data-fields`,
      visible: checkPermission('data_fields', 'view'),
    },
    {
      title: 'Integrations',
      description: 'Connect Evisort with your external platforms and services.',
      url: `/admin/console/${clientId}/integrations`,
      visible: checkPermission('integrations', 'view'),
    },
    {
      id: OnboardingIdType.AdminSamlTab,
      title: 'Single Sign-On (SSO)',
      description:
        'Configure and manage SAML-based Single Sign-On for your organization.',
      url: `/admin/console/${clientId}/saml-configuration`,
      visible: isSAMLSSOVisible,
    },
    {
      title: 'Restoration Hub',
      description: 'You can restore any deleted items here.',
      url: `/admin/console/${clientId}/restoration-hub`,
      visible: checkPermission('restoration_hub', 'view'),
    },
    {
      title: 'More Settings',
      description: 'Configure more settings for your organization.',
      url: `/admin/console/${clientId}/more-settings`,
      visible: canViewMoreSettings(),
    },
    {
      title: 'Audit Logs',
      description: 'View and export audit logs for your organization',
      url: `/admin/console/${clientId}/audit-logs`,
      visible: hasAuditLogs && checkPermission('audit_logs', 'view'),
    },
  ];

  const getLinks = () => {
    let links;
    if (isSuperAdmin && isGeneralClient) {
      links = superAdminLinks;
    } else if (hasWorkflow) {
      links = workflowLinks;
    } else {
      links = adminLinks;
    }

    return links.filter((link) => link.visible);
  };

  const renderConsoleItems = () => {
    const links = getLinks();

    return links.map((link, index) => (
      <Layout
        role="menu"
        key={`admin_link_${index}`}
        pt={4}
        onClick={() => history.push(link.url)}
      >
        <Layout
          borderBottom={index === links.length - 1 ? undefined : 'border'}
          mr={4}
          ml={4}
          pb={4}
          w="100%"
          justify="space-between"
          align="center"
        >
          <Layout direction="column" id={link.id}>
            <Text variant="section" role="menuitem">
              {link.title}
            </Text>
            <Text variant="body" color="text.secondary" role="menuitem">
              {link.description}
            </Text>
          </Layout>
          <Icon icon="chevron-right"></Icon>
        </Layout>
      </Layout>
    ));
  };

  return (
    <PageLayout
      loadingContent={{ isLoading: isPermissionsLoading }}
      title="Admin Console"
    >
      <Layout direction="column" border="border">
        {renderConsoleItems()}
      </Layout>
    </PageLayout>
  );
};

export default withPermissions(Page, PERMISSIONS);
