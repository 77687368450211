import { ModelProgress } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelProgress } from '../../types/sylvanus/model';

interface Request {
  modelId: Uuid;
}

type Response = ModelProgress;

export const getPromptModelProgress = async ({
  modelId,
}: Request): Promise<Response> => {
  const response: PromptModelProgress = await sylvanus.get(
    `/model/${modelId}/progress`,
  );

  const docsInScope = response.docs_in_scope;
  const docsInScopeProcessing = response.dirty ?? false;
  const docsProcessed = response.docs_processed ?? 0;
  const publishStatus = response.current_publish_status ?? null;
  const valuesFound = response.values_found ?? 0;

  return {
    docsInScope,
    docsProcessed,
    publishStatus,
    valuesFound,
    docsInScopeProcessing,
  };
};
