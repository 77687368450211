import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, ErrorPage } from '~/eds';
import { RoutePathType } from '~/routing';

const Page = () => {
  const history = useHistory();
  const navigateBack = () => history.push(RoutePathType.Home);

  return (
    <Box mt={100}>
      <ErrorPage
        preset="page-access"
        resolveAction={{ onClick: navigateBack }}
      />
    </Box>
  );
};

export default Page;
