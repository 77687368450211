import React, { memo } from 'react';

import { Input } from '../../types';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { TextInput } from '../TextInput';

export const SearchInput = memo((props: Input<string>) => {
  const { disabled, error, hint, placeholder = 'Search', value, width } = props;

  return (
    <>
      <Box disabled={disabled} styles={componentStyles.container} w={width}>
        <TextInput {...props} placeholder={placeholder} type="search" />
        <Box styles={componentStyles.icon}>
          <Icon icon="search" />
        </Box>
      </Box>
      {hint && value && (
        <Box role="alert" aria-live="polite" mt={2}>
          <Text color="text.secondary" variant="tiny">
            {hint}
          </Text>
        </Box>
      )}
      {error && (
        <Box role="alert" aria-live="polite" mt={2}>
          <Text color="status.danger" variant="tiny">
            {error}
          </Text>
        </Box>
      )}
    </>
  );
});

export const componentStyles = {
  container: {
    position: 'relative',
    '> input': {
      paddingLeft: 8, // hardcoded use of "!important" but still preferable to overloading TextInput
    },
  },
  icon: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    left: 2,
    top: 0,
  },
};
