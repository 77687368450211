import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getSavedSearchById } from '~/api';
import DeleteSavedSearchModal from '~/components/SearchV2/DeleteSavedSearchModal';
import DuplicateSavedSearchModal from '~/components/SearchV2/DuplicateSavedSearchModal';
import EditSavedSearchModal from '~/components/SearchV2/EditSavedSearchModal';
import SavedSearchesTypesButtonGroup from '~/components/SearchV2/SavedSearchesTypesButtonGroup';
import { checkSearchQueryHasUnsupportedEntity } from '~/components/SearchV2/utils';
import { showToast } from '~/components/Shared/EcToast';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import {
  QueryEntityType,
  SaveSearchPermissionType,
  TableContextType,
} from '~/enums';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { testIsSupportedFilterType, toFilters } from '~/redux/api/methods';
import search from '~/redux/slices/search';
import {
  MODAL_DELETE_SAVED_SEARCH,
  MODAL_DUPLICATE_SAVED_SEARCH,
  MODAL_EDIT_SAVED_SEARCH,
} from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';
import { Box, Button, FlexLayout, LoadingContainer, Text } from '~/ui';
import { testIsAdmin } from '~/utils/user';

const PAGE_SIZE = 10;

const Page = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();

  const dispatch = useDispatch();
  const { data: getFiltersResponse } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );
  const fields = getFiltersResponse?.fields;

  const {
    tableSettings = {
      pageSize: PAGE_SIZE,
    },
  } = useTableSettings(TableContextType.SavedSearches);

  const [pageSize, setPageSize] = useState(tableSettings.pageSize);
  const [page, setPage] = useState(1);
  const [savedSearchesTypes, setSavedSearchesTypes] = useState([]);

  const {
    data: savedSearchesData,
    isFetching,
    error,
  } = api.endpoints.getSavedSearches.useQuery(
    { page, types: savedSearchesTypes.join(), pageSize },
    { skip: !page },
  );

  const handleSavedSearchesTypesChange = (type) => {
    setPage(1);
    setSavedSearchesTypes(type);
  };

  const handlePaginate = ({ pageIndex }) => {
    if (pageIndex !== page) {
      setPage(pageIndex);
    }
  };

  const runSearch = (searchQuery) => {
    const isComplexSearch = checkSearchQueryHasUnsupportedEntity(searchQuery);
    dispatch(search.actions.clearBooleanQuery());
    dispatch(search.actions.clearQuery());
    dispatch(search.actions.clearUnsupportedQuery());
    dispatch(search.actions.setFilters([]));

    if (isComplexSearch) {
      dispatch(search.actions.setUnsupportedQuery(searchQuery));
    } else {
      const booleanQuery =
        searchQuery.find((q) => q.entity === QueryEntityType.BoolTextSearch)
          ?.query || '';
      const supportedFieldEntities = searchQuery.filter((filter) =>
        testIsSupportedFilterType(filter),
      );
      const filters = toFilters(supportedFieldEntities);

      dispatch(search.actions.setBooleanQuery(booleanQuery));
      dispatch(search.actions.setFilters(filters));
      dispatch(search.actions.setQuery({ booleanQuery, filters, fields }));
    }
    dispatch(search.actions.setPage(1));
    history.push('/search');
  };

  const columns = [
    {
      key: 'name',
      cellType: 'text',
      title: 'Name',
      minWidth: 'xl',
      disableSortBy: true,
      renderCell: (savedSearchEntry) => {
        return (
          <FlexLayout>
            <Button
              sx={{ paddingLeft: 0, textAlign: 'left' }}
              text={savedSearchEntry.name}
              variant="link"
              onClick={() => runSearch(savedSearchEntry.query)}
            />
          </FlexLayout>
        );
      },
    },
    {
      key: 'created_by',
      cellType: 'text',
      title: 'Created by',
      width: 'l',
      disableSortBy: true,
      renderCell: (searchQuery) => {
        return (
          <FlexLayout alignItems="center">
            <User mode="avatar-name" id={searchQuery.user} />
          </FlexLayout>
        );
      },
    },
    {
      key: 'created_date',
      cellType: 'datetime',
      title: 'Created on date',
      width: 'l',
      disableSortBy: true,
      mapCellProps: ({ date_added }) => ({
        datetime: date_added ? new Date(date_added) : undefined,
      }),
    },
  ];

  function prepSavedSearchAction(row, action) {
    if (row.search_visibility !== SaveSearchPermissionType.Shared) {
      if (action === 'edit') {
        handleEdit(row);
      } else {
        handleDuplicate(row);
      }
    } else {
      loadSavedSearchRecord(row, action);
    }
  }

  function loadSavedSearchRecord(row, action) {
    getSavedSearchById(row.id)
      .then((savedSearchData) => {
        if (action === 'edit') {
          handleEdit({ ...row, ...savedSearchData });
        } else {
          handleDuplicate({ ...row, ...savedSearchData });
        }
      })
      .catch(() => {
        showToast(
          ERROR,
          'An error occurred while loading the saved search data',
        );
      });
  }

  const rowActions = [
    {
      condition: (row) =>
        row.user === currentUser.id || testIsAdmin(currentUser),
      label: 'Edit',
      onClick: (row) => {
        prepSavedSearchAction(row, 'edit');
      },
    },
    {
      label: 'Duplicate',
      onClick: (row) => {
        prepSavedSearchAction(row, 'duplicate');
      },
    },
    {
      condition: (row) =>
        row.user === currentUser.id || testIsAdmin(currentUser),
      label: 'Delete',
      onClick: (row) => {
        handleDelete(row);
      },
    },
  ];

  const handleEdit = useCallback((tableState) => {
    dispatch(
      search.actions.setModal({
        modalType: MODAL_EDIT_SAVED_SEARCH,
        context: { ...tableState },
      }),
    );
  }, []);

  const handleDuplicate = useCallback((tableState) => {
    dispatch(
      search.actions.setModal({
        modalType: MODAL_DUPLICATE_SAVED_SEARCH,
        context: { ...tableState },
      }),
    );
  }, []);

  const handleDelete = useCallback((tableState) => {
    dispatch(
      search.actions.setModal({
        modalType: MODAL_DELETE_SAVED_SEARCH,
        context: { ...tableState },
      }),
    );
  }, []);

  const onPageSizeChange = useCallback((pageSize) => {
    setPage(1);
    setPageSize(pageSize);
  }, []);

  const hasResults = !error && savedSearchesData?.results.length > 0;
  const noSavedSearches = !error && savedSearchesData?.results.length === 0;

  return (
    <>
      <Box mt={3}>
        <SavedSearchesTypesButtonGroup
          types={savedSearchesTypes}
          setTypesChange={handleSavedSearchesTypesChange}
        />
      </Box>
      <LoadingContainer isLoading={isFetching}>
        {hasResults && (
          <PersistedTable
            context={TableContextType.SavedSearches}
            name="saved searches"
            totalCount={savedSearchesData.count}
            columns={columns}
            data={savedSearchesData.results}
            rowActions={rowActions}
            state={{ pageIndex: page, pageSize }}
            onPaginate={handlePaginate}
            onPageSizeChange={onPageSizeChange}
            options={{
              enableExportXlsx: false,
              enableSelectRows: false,
              enablePageSizeSelect: true,
            }}
          />
        )}
        {noSavedSearches && (
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100px' }}
          >
            <Text variant="subtitle">No Saved Searches</Text>
          </FlexLayout>
        )}
        {error && (
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100px' }}
          >
            <Text variant="subtitle">
              An error occurred while loading Saved Searches
            </Text>
          </FlexLayout>
        )}
      </LoadingContainer>
      <DeleteSavedSearchModal />
      <DuplicateSavedSearchModal />
      <EditSavedSearchModal />
    </>
  );
};

export default Page;
