import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { Layout, Select, TextArea, TextInput } from '~/eds';
import { AutomationFormErrorType } from '~/enums';
import { SearchFiltersPreview } from '~/features/filters';
import { getRestrictFilterIds } from '~/features/quick-ai';
import { FlagType, useFlag } from '~/flags';
import { actions, api } from '~/redux';
import { isActiveFilter } from '~/redux/slices/search';
import { RoutePathType } from '~/routing';
import { ERROR } from '~/types/toast.types';
import { Alert, InputLabel, Link, Text } from '~/ui';

import { SAMPLE_MIN_WORD_COUNT_BULK_TAGGING } from '../../constants';
import { getSampleName, isSampleNameChanged } from '../../util';

const QuickAiForm = ({
  clause,
  quickAiForm,
  view,
  setAutomationClause,
  setAutomationQuickAiDataForm,
}) => {
  const [clauseOptions, setClauseOptions] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [filters, setFilters] = useState([]);

  const isOnClausePage = isEmpty(clause.name);
  const isUpdating = !!quickAiForm.id;

  const doesSampleNameOverLimit = (quickAiForm.sampleName.length ?? 0) > 100;
  const doesClauseNameOverLimit = (quickAiForm.name?.length ?? 0) > 500;
  const isSampleContentDuplicated = !!quickAiForm.errors?.includes(
    AutomationFormErrorType.DupContent,
  );
  const isSampleNameDuplicated = !!quickAiForm.errors?.includes(
    AutomationFormErrorType.DupSampleName,
  );
  const enableQuickAiFilters = useFlag(FlagType.QuickAiFilters);

  const restrictedClauseFilterIds = getRestrictFilterIds();

  useEffect(() => {
    if (quickAiForm.doesClauseExist) {
      setShowErrorAlert(true);
    }
  }, [quickAiForm.name]);

  useEffect(() => {
    const doesSampleNameExist = quickAiForm.sampleName.length ?? 0;
    if (view || !doesSampleNameExist) {
      const sampleName = !!clause.name ? getSampleName(clause.name) : '';
      setAutomationQuickAiDataForm({
        ...quickAiForm,
        name: clause.name,
        sampleName,
      });
    }
  }, [clause]);

  const { data, error } = api.endpoints.getProvisions.useQuery();
  const {
    data: existingClause = [],
  } = api.endpoints.getIdentifications.useQuery(
    {
      name: quickAiForm.name,
    },
    {
      skip: !quickAiForm.name,
    },
  );

  useEffect(() => {
    if (error) {
      showToast(ERROR, 'Failed to load clause. Please try again.');
    }

    const options = [];

    if (clause.name) {
      options.push({ label: clause.name, value: clause.name });
    }

    if (data) {
      data.suggestions.forEach((v) => {
        if (clause.name !== v) options.push({ label: v, value: v });
      });
    }

    setClauseOptions(options);
  }, [data, error]);

  useEffect(() => {
    if (!quickAiForm.doesClauseExist && enableQuickAiFilters) {
      const activeFilters = filters.filter(isActiveFilter);
      setAutomationQuickAiDataForm({
        ...quickAiForm,
        searchQuery: activeFilters,
      });
    }
  }, [filters, quickAiForm.doesClauseExist]);

  const setCreateClause = (event) => {
    const name = event?.trim();

    const shouldGenSampleName =
      !!name && !isSampleNameChanged(quickAiForm.sampleName);
    const sampleName = shouldGenSampleName
      ? getSampleName(name)
      : quickAiForm.sampleName;

    const doesClauseExist = !!data?.suggestions?.includes(name);

    setAutomationQuickAiDataForm({
      ...quickAiForm,
      name,
      sampleName,
      doesClauseExist,
    });
  };

  const onAddProvision = (event) => {
    const suggestion = { label: event.label.trim(), value: event.value.trim() };

    setClauseOptions([...clauseOptions, suggestion]);
    setCreateClause(suggestion.value);
  };

  const setCreateContent = (content) => {
    setAutomationQuickAiDataForm({ ...quickAiForm, content });
  };

  const setClauseName = () => {
    setAutomationClause({ ...clause, name: quickAiForm.name });
  };

  const setSampleName = (name) => {
    setAutomationQuickAiDataForm({
      ...quickAiForm,
      sampleName: name?.trimStart(),
    });
  };

  const existingClauseFilters = existingClause.filters?.length ?? 0;
  const isCreatingSample = Boolean(clause.name);

  const navigateToAutomationHub = {
    additionalText: `There are ${existingClauseFilters} existing filters. Filters can be managed in `,
    linkText: 'Automation Hub',
    pathname: `${RoutePathType.AutomationHub}`,
    tooltip: 'Go to Automation Hub page',
  };

  return (
    <Layout w="750px" direction="column" spacing={8} pb="20%">
      {isOnClausePage && showErrorAlert && quickAiForm.doesClauseExist && (
        <Alert
          key="warning"
          enableIcon
          variant="danger"
          onDismiss={() => setShowErrorAlert(!showErrorAlert)}
        >
          <Layout direction="column" spacing={2}>
            <Text variant="xs-dense">
              The {quickAiForm.name} Clause already exists. Looks like you want
              to create a New Sample instead of creating a New Clause.
            </Text>
            <Link onClick={setClauseName}>Create a New Sample</Link>
          </Layout>
        </Alert>
      )}
      {isUpdating ? (
        <Alert key="warning" enableIcon variant="warning">
          Your changes will only apply to future documents. To identify a new
          language in existing documents, consider creating a new sample.
        </Alert>
      ) : (
        <Alert key="info" enableIcon variant="info">
          Evisort will apply the Sample to all documents.
        </Alert>
      )}
      <InputLabel
        isRequired
        id="clause"
        label="Clause"
        description="The Clause that this language will be associated with when identified."
      >
        <Select
          disabled={!isOnClausePage}
          id="select-a-clause"
          options={clauseOptions}
          placeholder="Create a New Clause"
          value={quickAiForm.name}
          error={
            isOnClausePage && quickAiForm.doesClauseExist
              ? 'Duplicate name. Please select a different name.'
              : null
          }
          onAddOption={onAddProvision}
          onChange={setCreateClause}
        />
        <Layout mt={-2}>
          {doesClauseNameOverLimit && (
            <Text
              color="red-600"
              variant="2xs-dense"
              sx={{ position: 'absolute' }}
            >
              Clause name cannot be longer than 500 characters
            </Text>
          )}
        </Layout>
      </InputLabel>
      {enableQuickAiFilters && !isCreatingSample && (
        <SearchFiltersPreview
          filters={filters}
          defaultFilterIds={
            !quickAiForm.doesClauseExist
              ? ['folder', 'Contract Type', 'Effective Date']
              : undefined
          }
          description={
            !quickAiForm.doesClauseExist
              ? 'Filters will be used to select documents that contain this exact clause'
              : undefined
          }
          descriptionLink={
            quickAiForm.doesClauseExist ? navigateToAutomationHub : undefined
          }
          docsInScope
          onUpdateFilters={(updatedFilters) => {
            setFilters(updatedFilters);
          }}
          readOnly={quickAiForm.doesClauseExist}
          restrictFilterIds={restrictedClauseFilterIds}
          title="Document Filters"
        />
      )}
      <InputLabel
        isRequired
        id="sample-text"
        label="Sample text"
        description="We'll look for language similar to this in existing and future contracts."
      >
        <TextArea
          id="input-sample-text"
          name="input-sample-text"
          width="100%"
          placeholder=""
          value={quickAiForm.content}
          onChange={setCreateContent}
          rows={8}
        />
        {isSampleContentDuplicated && (
          <Text color="red-600" variant="2xs-dense" sx={{ marginTop: '-8px' }}>
            Another Sample text with an identical text already exists for this
            clause
          </Text>
        )}
        {!isSampleContentDuplicated && (
          <Text color="gray-600" variant="2xs-dense" sx={{ marginTop: '-8px' }}>
            Sample text must be at least {SAMPLE_MIN_WORD_COUNT_BULK_TAGGING}{' '}
            words long.
          </Text>
        )}
      </InputLabel>
      <InputLabel
        isRequired
        id="sample-name"
        label="Sample name"
        description="You may give this Sample a name to make it easier to search for it."
      >
        <TextInput
          id="input-a-sample-name"
          width="fullWidth"
          value={quickAiForm.sampleName}
          onChange={setSampleName}
        />

        {isSampleNameDuplicated ? (
          <Text color="red-600" variant="2xs-dense" sx={{ marginTop: '-8px' }}>
            Another Sample with an identical name already exists for this
            clause.
          </Text>
        ) : (
          <Text
            color={doesSampleNameOverLimit ? 'red-600' : 'gray-600'}
            variant="2xs-dense"
            sx={{ marginTop: '-8px' }}
          >
            {quickAiForm.sampleName.length ?? 0} / 100{' '}
            {doesSampleNameOverLimit &&
              '(Sample name cannot be longer than 100 characters)'}
          </Text>
        )}
      </InputLabel>
    </Layout>
  );
};

const mapStateToProps = ({ automation }) => ({
  clause: automation.clause,
  quickAiForm: automation.quickAiForm,
  view: automation.view,
});

export default connect(mapStateToProps, {
  setAutomationClause: actions.setAutomationClause,
  setAutomationQuickAiDataForm: actions.setAutomationQuickAiDataForm,
})(QuickAiForm);
