import { useEffect, useState } from 'react';

import {
  fetchCsrfToken,
  loginWithClientUsernamePassword,
  loginWithToken,
  sendForgotPasswordEmail,
} from '~/api';
import evisortConversationalAI from '~/assets/images/login/evisort-conversational-ai.gif';
import AuthenticationStore from '~/auth';
import { getDecodedUserInfoFromToken } from '~/components/LoginPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import { getEnvVariable } from '~/dev';
import { LoginViewType, QueryParamType } from '~/enums';
import { RoutePathType, setSearchParams, useRouting } from '~/routing';
import { SUCCESS } from '~/types/toast.types';
import { copyToClipboard } from '~/utils/helper.utils';
import {
  getPageSearchQuery,
  getPageSearchQueryByKey,
} from '~/utils/searchQuery';

const AS = AuthenticationStore();

const loginErrorMessage = {
  ACCOUNT_LOCKED: 'You have failed too many times. Try again in 30 minutes.',
  INVALID_CREDENTIALS: 'Invalid email or password.',
  INVALID_TOKEN: 'Invalid token.',
  SAML_ERROR:
    "Failed to log in using the supplied credentials. Contact your organization's administrator to verify that your account is set up correctly.  Click the button to copy the error code.",
  SERVER_ERROR: 'An error occurred. Please try again later.',
  SSO_ENFORCED:
    'Single sign-on is enforced for your organization and you must log in using your organization’s identity provider. Please click on the Sign In button above to log in.',
};

const welcomeBanner = {
  banner: '',
  bannerImageBackgroundSize: '100%',
  bannerImagePosition: 'center',
  bannerImageSrc: evisortConversationalAI,
  padding: 0,
};

export const useAegisLoginProps = (props) => {
  const { workspace, ssoConfigs, stage, error, setStage, setError } = props;
  const { history } = useRouting();

  const [oAuthRedirectTo, setOAuthRedirectTo] = useState(null);
  const [resetPasswordQueryResult, setResetPasswordQueryResult] = useState({});
  const [isLoadingLoginAccount, setIsLoadingLoginAccount] = useState(false);

  const isEmbed = AS.isEmbed();
  const isSsoOnly = isEmbed && ssoConfigs; // enable auto-login if in embedded login mode and ssoConfigs exists
  const searchParamKey = [...getPageSearchQuery().keys()][0];
  const samlErrorMsg = getPageSearchQueryByKey(QueryParamType.SamlError);

  // login initialization
  useEffect(() => {
    AS.setCsrf(null); // clean csrf token in auth store on login page load
  }, []);

  // auto-login with SSO-only if enabled appropriately
  useEffect(() => {
    if (isSsoOnly) handleLoginSso();
  }, [workspace, isSsoOnly]);

  useEffect(
    () => {
      const searchParamValue = getPageSearchQueryByKey(searchParamKey, '');
      const handleSAMLCallback = () => {
        if (!searchParamKey) return;

        const { client_id, user_id } = JSON.parse(atob(searchParamValue)) ?? {};

        if (client_id && user_id) {
          trackSegment('User SSO Login', {
            groupId: client_id,
            userId: `${user_id}_${client_id}`,
          });
        }

        // EKP-15827 when httponly enabled
        if (!AS.getAccessToken() || !AS.getRefreshToken()) {
          AS.removeInactiveTimer();
          AS.saveAccessTokenCreationTime();
        }

        setLogIn();
      };

      const handleResetPasswordCallback = (loggedInWithWelcome) => {
        loginWithToken({ token: searchParamValue })
          .then((data) => {
            // EKP-15827 when httponly not enabled
            if (data.access_token && data.refresh_token) {
              AS.saveAuthentication(data.access_token, data.refresh_token);
            }
            // EKP-15827 when httponly enabled
            if (data.user_id && data.client_id) {
              AS.removeInactiveTimer();
              AS.saveAccessTokenCreationTime();
            }
            setLogIn(loggedInWithWelcome);
          })
          .catch(() => setError('An error occurred with log in.'));
      };

      switch (searchParamKey) {
        case QueryParamType.SamlSuccess:
          return handleSAMLCallback();
        case QueryParamType.SamlError:
          return setStage(LoginViewType.SamlError);
        case QueryParamType.FirstLoginSuccessToken:
          return handleResetPasswordCallback(true);
        case QueryParamType.ResetSuccessToken:
          return handleResetPasswordCallback();
        default:
          return undefined;
      }
    },
    [props.location.search, props.history], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const from = getPageSearchQueryByKey('from', '');
    const redirectTo = getPageSearchQueryByKey('redirect_to', '');

    if (from === 'teamsApp' && redirectTo) {
      const envAllowedDomains = getEnvVariable(
        'REACT_APP_ALLOWED_REDIRECT_TO_DOMAIN',
      );
      const allowedDomains = envAllowedDomains
        ? envAllowedDomains.split(',')
        : [];
      const isAllowedDomain = allowedDomains.some((domain) => {
        // The domain can be a wildcard domain, so we need to convert it to a regex pattern
        // to match the origin of the redirect_to URL.
        // e.g. https://*.devtunnels.ms -> https:\/\/.*\.devtunnels\.ms$/
        const regexPattern = domain.replace(/\./g, '\\.').replace(/\*/g, '.*');
        const redirectToOrigin = new URL(redirectTo).origin;

        // This domain 'https://.*\\.devtunnels\\.ms' should match with
        // 'https://m349ndw7-3978.us.devtunnels.ms'
        return new RegExp(`^${regexPattern}$`).test(redirectToOrigin);
      });

      if (isAllowedDomain) {
        setOAuthRedirectTo(redirectTo);
      } else {
        setError('The auth success redirection will not be allowed.');
      }
    }
  }, [props.location.search, props.history]);

  const setLogIn = (loggedInWithWelcome = false) => {
    setTimeout(() => {
      if (AS.isLoggedIn()) {
        loggedInWithWelcome
          ? setStage(LoginViewType.LoggedInWithWelcome)
          : setStage(LoginViewType.LoggedIn);
      }
    }, 100);
  };

  const handleLogin = (formData) => {
    setIsLoadingLoginAccount(true);
    return loginWithClientUsernamePassword(formData)
      .then(async (data) => {
        // EKP-15827 when httponly not enabled
        if (data.access_token && data.refresh_token) {
          AS.saveAuthentication(data.access_token, data.refresh_token);

          const { client_id, user_id } = getDecodedUserInfoFromToken(
            data.access_token,
          );
          trackSegment('User Login', {
            groupId: client_id,
            userId: `${user_id}_${client_id}`,
          });
        }

        // EKP-15827 when httponly enabled
        if (data.user_id && data.client_id) {
          AS.removeInactiveTimer();
          AS.saveAccessTokenCreationTime();

          trackSegment('User Login', {
            groupId: data.client_id,
            userId: `${data.user_id}_${data.client_id}`,
          });
        }

        if (isEmbed) {
          try {
            if (!AS.getCsrf()) {
              await fetchCsrfToken();
            }
            history.push(RoutePathType.LoginEmbedSuccess);
            return;
          } catch {
            history.push(RoutePathType.Login);
          }
        }

        setTimeout(() => {
          if (AS.isLoggedIn()) {
            setStage(LoginViewType.LoggedIn);
          }

          if (oAuthRedirectTo) {
            window.location.href = oAuthRedirectTo;
          }
        }, 100);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const {
            response: {
              data: { invalid_attempts, msg },
            },
          } = error;

          if (invalid_attempts >= 3) {
            setError(
              `You have attempted to login ${invalid_attempts} times unsuccessfully.  After 5 failed attempts, your account will be locked for 30 minutes.`,
            );
          } else {
            setError(loginErrorMessage[msg]);
          }
        }
      })
      .finally(() => setIsLoadingLoginAccount(false));
  };

  const handleLoginSso = () => {
    AS.clearAuthentication();
    if (!ssoConfigs) return;

    window.location.href = isEmbed
      ? setSearchParams(ssoConfigs.signon_url, {
          RelayState: `${window.location.origin}/${workspace}${RoutePathType.LoginEmbedSuccess}`,
        })
      : ssoConfigs.signon_url;
  };

  const handleCopySsoError = () => {
    copyToClipboard(samlErrorMsg, 'Error code copied to clipboard.', SUCCESS);
  };

  const handleLoginAccount = (updatedAccount) => {
    handleLogin({
      client: workspace,
      username: updatedAccount.email,
      password: updatedAccount.password,
    });
  };

  const handleResetPassword = ({ email, workspace }) => {
    setResetPasswordQueryResult({ isFetching: true });
    sendForgotPasswordEmail({ client: workspace, userId: email })
      .then(() => {
        setResetPasswordQueryResult({
          isFetching: false,
          isSuccess: true,
        });
      })
      .catch(() => {
        setResetPasswordQueryResult({
          isError: true,
          isFetching: false,
        });
      });
  };

  // Not all legacy queries handle results (isLoading/isSuccess/isError);
  const queryLoginAccount = [
    handleLoginAccount,
    {
      error: stage === LoginViewType.SamlError ? undefined : error,
      isFetching: isLoadingLoginAccount,
    },
  ];
  const queryLoginSso = [
    handleLoginSso,
    {
      error:
        stage === LoginViewType.SamlError
          ? loginErrorMessage.SAML_ERROR
          : undefined,
    },
  ];
  const queryResetPassword = [handleResetPassword, resetPasswordQueryResult];

  const onBackToSignIn = () => setResetPasswordQueryResult({});

  const sso = ssoConfigs
    ? {
        id: ssoConfigs.provider_name,
        name: ssoConfigs.provider_name,
        url: ssoConfigs.signon_url,
      }
    : null;

  return {
    queryLoginAccount,
    queryLoginSso,
    queryResetPassword,
    onBackToSignIn,
    onResolveLoginSsoError: handleCopySsoError,
    sso,
    stage: stage === LoginViewType.Domain && !error ? 'workspace' : 'login', // this logic maps to the eds.LoginPage.stage.
    welcomeBanner,
  };
};
