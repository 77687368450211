import React, { memo } from 'react';

import { ContentContainer, types } from '~/eds';
import { api } from '~/redux';
import { Nullable, PilotId } from '~/types';

import { loadingMessage } from './constants';
import { PdfHighlighterProvider } from './PdfDocumentViewer';
import { BaseDocumentViewerProps, ResolveDocumentCallback } from './types';
import { useResolveDocument } from './useResolveDocument';

export type Props = Omit<BaseDocumentViewerProps, 'file'> & {
  /** Renders the document given its ID */
  documentId?: Nullable<PilotId>;
  /** Renders the document given its version ID (will resolve to a `documentId` internally) */
  documentVersionId?: Nullable<PilotId>;
  /** When specifying documentId or documentVersionId as `null`, be sure to attach an appropriate `placeholderContent` */
  placeholderContent?: types.BasePlaceholderContentProps;
  /** Callback to capture the resolved document */
  onResolveDocument?: ResolveDocumentCallback;
};

export const DocumentViewer = memo((props: Props) => {
  const {
    documentVersionId,
    placeholderContent: providedPlaceholderContent,
    documentId,
    onResolveDocument,
  } = props;

  const {
    data: resolvedDocument,
    isFetching: isLoading,
    error,
  } = api.endpoints.resolveDocument.useQuery({
    documentId,
    documentVersionId,
  });

  const { file, Viewer } = useResolveDocument({
    resolvedDocument,
    onResolveDocument,
  });

  const loadingContent = {
    isLoading,
    message: loadingMessage,
  };

  const placeholderContent = error ? providedPlaceholderContent : undefined;

  return (
    <ContentContainer
      loadingContent={loadingContent}
      placeholderContent={placeholderContent}
    >
      <PdfHighlighterProvider>
        {file ? <Viewer {...props} file={file} /> : null}
      </PdfHighlighterProvider>
    </ContentContainer>
  );
});
