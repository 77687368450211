import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Text } from '../Text';

const remarkPlugins = [remarkGfm];

interface Props extends React.ComponentProps<typeof Text> {
  /** Simple markdown string (e.g. 'hello', '# hello') */
  text: string;
  /** This prop can be used to render custom components for markdown elements. */
  components?: React.ComponentProps<typeof ReactMarkdown>['components'];
}

export const Markdown = memo(({ text, components, ...rest }: Props) => {
  return (
    <Text {...rest}>
      <ReactMarkdown
        className="eds-markdown"
        components={components}
        linkTarget="_blank"
        remarkPlugins={remarkPlugins}
        skipHtml={true}
      >
        {text}
      </ReactMarkdown>
    </Text>
  );
});
