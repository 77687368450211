import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Actions } from '~/eds';

import { testIsInADocViewerPage } from '../../../ask-anything-button/utils';
import { Message } from '../../types';

interface Props<Source> {
  message: Message<Source>;
  onSelectSource?: (message: Message<Source>, sourceIndex: number) => void;
}
export const Sources = <Source extends unknown>({
  message,
  onSelectSource,
}: Props<Source>) => {
  const history = useHistory();

  const isDocViewerPage = useMemo(
    () => testIsInADocViewerPage(history.location.pathname),
    [history.location.pathname],
  );

  const sources = useMemo(() => {
    return (message.sources ?? []).map((_source, index) => ({
      level: 'secondary' as const,
      text: `${index + 1}`,
      tooltip: !isDocViewerPage
        ? 'Source highlights are accessible when viewing the document version.'
        : `View source ${index + 1}`,
      onClick: () => {
        onSelectSource?.(message, index);
      },
      disabled: !isDocViewerPage,
    }));
  }, [message, isDocViewerPage]);

  if (!message.sources?.length) return null;

  return <Actions wrap actions={sources} size="xs" />;
};
