import React from 'react';

import { Chip, LoadingShimmer, NumberText } from '~/eds';
import { api } from '~/redux';
import { Nullable, Uuid } from '~/types';

import { PromptModelPublishStatus } from '../types';
import { getModelPublishStatusChip } from '../utils';

type ProgressMetric =
  | 'docsInScope'
  | 'docsProcessed'
  | 'publishStatus'
  | 'valuesFound';

type ProgressType = Nullable<PromptModelPublishStatus> &
  Record<ProgressMetric, number>;

interface Props {
  metric: ProgressMetric;
  modelId: Uuid;
  initialPublishStatus?: Nullable<PromptModelPublishStatus>;
}

export const ModelResultProgress = ({
  initialPublishStatus,
  metric,
  modelId,
}: Props) => {
  const {
    data: progress = initialPublishStatus as ProgressType,
    isLoading,
  } = api.endpoints.getPromptModelProgress.useQuery({
    modelId: modelId!,
  });

  if (isLoading) {
    return <LoadingShimmer />;
  } else if (progress) {
    switch (metric) {
      case 'docsInScope':
      case 'docsProcessed':
      case 'valuesFound':
        const number = progress[metric];
        return <NumberText number={number} />;
      // TODO: remove this when ModelList is deprecated
      case 'publishStatus':
        return (
          <Chip
            {...getModelPublishStatusChip(
              progress.publishStatus as Nullable<PromptModelPublishStatus>,
            )}
          />
        );
      default:
        return null;
    }
  } else {
    return null;
  }
};
