import * as Sentry from '@sentry/react';
import { StytchB2BProvider } from '@stytch/react/b2b';
import promiseFinally from 'promise.prototype.finally';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { clientSet } from '~/actions';
import { getClient, getCurrentUser, logout } from '~/api';
import AuthenticationStore from '~/auth';
import { useStytchClient } from '~/auth/useStytchClient';
import { AppConfigProvider, PusherProvider } from '~/contexts';
import { isDev } from '~/dev';
import { initialize as initializeEds } from '~/eds';
import { QueryParamType } from '~/enums';
import { FlagType, useFlag, useFlagsReady } from '~/flags';
import { actions } from '~/redux';
import {
  redirectToOAuth,
  redirectToProviderAuthPage,
  redirectToZendesk,
} from '~/services/redirects';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

import ErrorBoundary from '../ErrorBoundary';
import SplashPage from '../SplashPage';
import UnsupportedBrowserPage from '../UnsupportedBrowserPage';
import { Welcome } from '../Welcome/Welcome';
import { AppContents } from './AppContents';

// Needed for environments that do not support .finally() on promises
promiseFinally.shim();

initializeEds();

const isIE11AndPrior = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident');
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) <= 10;
  } else if (trident > 0) {
    return true;
  } else {
    return false;
  }
};

function App({
  // connected
  currentUser,
  clientSet,
  setCurrentUser,
}) {
  const { origin, pathname } = window.location;
  const { b2bClient } = useStytchClient();
  const isUserLoggedIn = AuthenticationStore().isLoggedIn();
  // TODO: research and untangle and remove the need for this check
  const enableReady = !(
    !pathname.includes('folders-permission-check') &&
    !isDev &&
    isUserLoggedIn
  );
  const [subdomain, setSubdomain] = useState('');
  const isReady = useFlagsReady({ enableReady, user: currentUser });

  const shouldInitialize =
    Object.keys(currentUser).length === 0 && pathname !== '/';

  /* For zendesk authenticate */
  const zendeskQuerySsoFlag = getPageSearchQueryByKey(QueryParamType.Sso);
  const zendeskQuerySsoAction = getPageSearchQueryByKey(QueryParamType.Action);
  const zendeskQueryRedirectUrl = getPageSearchQueryByKey(
    QueryParamType.ReturnTo,
  );
  /* For OAuth authenticate */
  const oAuthQueryClientId = getPageSearchQueryByKey(QueryParamType.ClientId);
  const oAuthQueryResponseType = getPageSearchQueryByKey(
    QueryParamType.ResponseType,
  );
  const oAuthQueryRedirectUri = getPageSearchQueryByKey(
    QueryParamType.RedirectUri,
  );
  const oAuthQueryState = getPageSearchQueryByKey(QueryParamType.State);
  /* For 3rd-party provider auth */
  const providerAuthState = getPageSearchQueryByKey(QueryParamType.State);
  const providerAuthCode = getPageSearchQueryByKey(QueryParamType.Code);

  const isComponentMonitoringEnabled = useFlag(
    FlagType.ComponentMonitoringInSentry,
  );

  const EnhancedAppContents = Sentry.withProfiler(AppContents, {
    disabled: !isComponentMonitoringEnabled,
  });

  const initialization = () => {
    const AS = AuthenticationStore();

    getCurrentUser()
      .then((currentUser) => {
        if (currentUser) {
          const { client_config } = currentUser;
          const { id: clientId, sub_domain } = client_config;

          checkSubDomain(sub_domain);

          setCurrentUser(currentUser);

          Sentry.setUser({ email: currentUser.email, id: currentUser.id });

          if (sub_domain && !subdomain) {
            setSubdomain(`/${sub_domain.toLowerCase()}`);
          }

          getClient(clientId).then(clientSet);
          AS.saveSubDomainInSession(sub_domain);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 423) {
          window.location.href = '/folders-permission-check';
        }
      });
  };

  const checkSubDomain = (userSubdomain) => {
    const clientSubdomain = pathname.split('/')[1];
    const isPublicError = ['word-online-invalid-subscription'].includes(
      clientSubdomain,
    );

    if (
      !isPublicError &&
      userSubdomain &&
      clientSubdomain &&
      clientSubdomain !== userSubdomain
    ) {
      logout().finally(() => {
        const redirectSubdomainContainPublicPath = [
          'activation',
          'change-password',
          'expired-token',
          'forgot-password',
        ].includes(clientSubdomain);
        AuthenticationStore().clearAuthentication();
        if (redirectSubdomainContainPublicPath) {
          window.location.href = `${origin}/${clientSubdomain}${window.location.search}`;
        } else {
          window.location.href = `${origin}/${clientSubdomain}/login`;
        }
      });
    }
  };

  const checkRedirectToZendesk = (loginStatus) => {
    const zendeskRedirectUrl = AuthenticationStore().getZendeskUrl();
    const zendeskRedirectExp = AuthenticationStore().getZendeskExpire();

    if (!zendeskRedirectUrl && zendeskQuerySsoFlag === 'zendesk') {
      AuthenticationStore().setZendeskUrl(zendeskQueryRedirectUrl);
    }

    if (
      zendeskQuerySsoFlag === 'zendesk' &&
      zendeskQuerySsoAction === 'logout'
    ) {
      AuthenticationStore().removeZendeskFlag();
      AuthenticationStore().clearAuthentication();
      window.location.href = `${origin}`;
    }

    if (zendeskRedirectUrl && loginStatus) {
      if (parseInt(zendeskRedirectExp, 10) < new Date().valueOf()) {
        AuthenticationStore().removeZendeskFlag();
      } else {
        redirectToZendesk(zendeskRedirectUrl);
      }
    }
  };

  const checkRedirectToOAuth = (loginStatus) => {
    const [
      oAuthRedirectUri,
      oAuthClientId,
      oAuthState,
    ] = AuthenticationStore().getOAuthVars();
    const oAuthRedirectExp = AuthenticationStore().getOAuthExpire();

    if (!oAuthRedirectUri && oAuthQueryResponseType === 'code') {
      AuthenticationStore().setOAuthVars(
        oAuthQueryRedirectUri,
        oAuthQueryClientId,
        oAuthQueryState,
      );
    }

    if (oAuthRedirectUri && loginStatus) {
      if (parseInt(oAuthRedirectExp, 10) < new Date().valueOf()) {
        AuthenticationStore().removeOAuthFlag();
      } else {
        redirectToOAuth(oAuthRedirectUri, oAuthClientId, oAuthState);
      }
    }
  };

  const fetchThirdPartyProviderAuthStateData = (state, code) => {
    redirectToProviderAuthPage(state, code);
  };

  useEffect(
    () => {
      if (pathname.includes('folders-permission-check')) return;

      if (isUserLoggedIn && pathname.includes('evisync') && providerAuthState) {
        return fetchThirdPartyProviderAuthStateData(
          providerAuthState,
          providerAuthCode,
        );
      }

      checkRedirectToZendesk(isUserLoggedIn);
      checkRedirectToOAuth(isUserLoggedIn);

      if (isUserLoggedIn && Object.keys(currentUser).length === 0) {
        initialization();
      } else {
        if (pathname !== '/') {
          const clientSubdomain = pathname.split('/')[1];
          setSubdomain(`/${clientSubdomain}`);
        }
      }
    },
    [isUserLoggedIn, pathname, subdomain], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (
    !isReady ||
    (!subdomain &&
      !pathname.includes('folders-permission-check') &&
      (isUserLoggedIn || shouldInitialize))
  ) {
    return <SplashPage />;
  }

  if (isIE11AndPrior()) {
    return <UnsupportedBrowserPage />;
  }

  const Router = window.require ? MemoryRouter : BrowserRouter;

  return (
    <Router basename={subdomain}>
      <CompatRouter>
        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
          <AppConfigProvider>
            <StytchB2BProvider stytch={b2bClient}>
              <PusherProvider>
                <EnhancedAppContents />
                <Welcome />
              </PusherProvider>
            </StytchB2BProvider>
          </AppConfigProvider>
        </Sentry.ErrorBoundary>
      </CompatRouter>
    </Router>
  );
}

export default connect(({ currentUser }) => ({ currentUser }), {
  clientSet,
  setCurrentUser: actions.setCurrentUser,
})(App);
