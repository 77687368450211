import type { Element } from 'hast';
import type { Link, Parent } from 'mdast';
import { ReactNode } from 'react';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { unified } from 'unified';
import { visit } from 'unist-util-visit';

import { Text } from '~/eds';

import { Citation } from '..';
import { InlineCitation } from './components/Sources/InlineCitation';

export const createMarkdownComponents = (
  {
    citations,
    onCitationClick,
    isReplying,
  }: {
    citations: Citation[];
    onCitationClick: (citation: Citation) => void;
    isReplying: boolean;
  },
  options?: Partial<{ shouldSkipLinks: boolean }>,
) => {
  if (!options) {
    return;
  }

  if (options.shouldSkipLinks) {
    const renderCustomLink = (props: {
      children: ReactNode[];
      node: Element;
    }) => {
      const linkIndex = citations.findIndex(
        (citation) => citation.key === props.node.children[0].value,
      );
      const text = `${linkIndex + 1}`;
      if (isReplying) return <Text>{text}</Text>;
      return (
        <InlineCitation
          text={text}
          onClick={() => onCitationClick(citations[linkIndex])}
        />
      );
    };
    return {
      a: renderCustomLink,
    };
  }
};

export const linkVisitor = (): any => {
  return (tree: Parent, file: any) => {
    const linkReferences: string[] = [];
    //@ts-ignore
    visit(
      tree,
      'linkReference',
      (node: Link, _index: number, _parent: Parent) => {
        linkReferences.push(node.label as string);
      },
    );
    file.data.links = linkReferences;
  };
};

export const removeHTMLNodes = () => {
  return (tree: Parent) => {
    //@ts-ignore
    visit(tree, 'html', (_node: any, index: number, parent: Parent) => {
      parent.children.splice(index, 1);
    });
  };
};

export const postProcessMarkdown = (
  text: string,
  citationsMap: Record<string, Citation>,
) => {
  const file = unified()
    .use(remarkParse)
    .use(removeHTMLNodes)
    .use(linkVisitor)
    .use(remarkStringify)
    .processSync(text);

  const links = file.data.links as string[];
  const citations: Citation[] = links.map((link) => ({
    ...citationsMap[link],
    key: link,
  }));

  return { citations, text: String(file) };
};

export const testIsTicketCitation = (citation: Citation) =>
  citation.entity.type === 'ticket';
