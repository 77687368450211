import React, { memo } from 'react';

import { SharedButtonProps } from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import { Image } from '../Image';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { presets } from './presets';

interface Props {
  /** Error page preset */
  preset: keyof typeof presets;
  /** Required CTA action for resolving the error state */
  resolveAction?: SharedButtonProps;
}

export const ErrorPage = memo(({ preset, resolveAction }: Props) => {
  const { description, image, imageAlt, resolveActionText, title } = presets[
    preset
  ];

  return (
    <Layout
      align="center"
      direction="column"
      h="100%"
      justify="center"
      minW="min-content"
      p={12}
      spacing={4}
      w="100%"
    >
      <Image alt={imageAlt} image={image} />
      <Text textAlign="center" variant="title">
        {title}
      </Text>
      <Text color="text.secondary" textAlign="center">
        {description}
      </Text>
      {resolveAction && (
        <Box pt={8}>
          <Button
            {...resolveAction}
            text={resolveActionText || resolveAction.text}
            variant="primary"
          />
        </Box>
      )}
    </Layout>
  );
});
