import { EntityType, SaveSearchPermissionType } from '~/enums';

import type { EntityVisibilityType } from './types';

export const getSaveSearchPermissionOptions = (clientName: string) => {
  return [
    { label: 'Only me (private)', value: SaveSearchPermissionType.Private },
    {
      label: 'Specific departments (groups) / users',
      value: SaveSearchPermissionType.Shared,
    },
    { label: `Anyone at ${clientName}`, value: SaveSearchPermissionType.Open },
  ];
};
export const getUserDepartmentArray = (
  entityVisibility: EntityVisibilityType,
) => {
  if (entityVisibility) {
    const { departmentIds = [], userIds = [] } = entityVisibility;
    const depObjs = departmentIds.map((id) => ({
      id,
      role: null,
      type: EntityType.Department,
    }));
    const userObjs = userIds.map((id) => ({
      id,
      role: null,
      type: EntityType.User,
    }));
    return [...depObjs, ...userObjs];
  }
  return [];
};
