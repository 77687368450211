import { uniq } from 'lodash';
import { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  ticketActivitiesSet,
  ticketDocumentVersionsSet,
  ticketParticipantsSet,
} from '~/actions';
import {
  addTicketParticipants,
  getActivities,
  getTicketParticipants,
  uploadNewDocumentVersion,
} from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import DescriptionTextAreaForm from '~/components/Workflow/shared/DescriptionTextAreaForm';
import { Button, IconButton, useModal } from '~/eds';
import {
  CharacterLimits,
  EntityType,
  FileExtensionType,
  FileMimeType,
} from '~/enums';
import { PartySelectField } from '~/features/turn-tracking';
import { FlagType, useFlag } from '~/flags';
import { withUsers } from '~/hocs';
import { useAsync, useCurrentUser } from '~/hooks';
import { api, TAGS } from '~/redux';
import * as toastTypes from '~/types/toast.types';
import {
  Alert,
  Box,
  enums,
  FileInput,
  FileLabel,
  FlexLayout,
  Icon,
  Switch,
  Text,
  Tooltip,
} from '~/ui';
import { getMentions } from '~/utils/comments';
import { coerceFileType } from '~/utils/files';
import { getUserName, testIsEverestAdmin } from '~/utils/user';
import { getAcceptedFiles } from '~/utils/workflow';

import AddParticipantToTicketPopover from './AddParticipantToTicketPopover';

function UploadVersionAction({
  disabled,
  iconOnly = false,
  // connected
  ticket,
  ticketActivitiesSet,
  ticketParticipantsSet,
  ticketDocumentVersionsSet,
  ticketId,
  documentId,
  //injected
  users,
  onUpload,
}) {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const mentionInputRef = useRef();
  const { versionNumber } = useParams();
  const currentVersion = versionNumber || ticket.documentVersions.length;
  const versionNumberExists = !!versionNumber;
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState('');
  const [isCounterparty, setIsCounterparty] = useState(true);
  const [isKeepingUploadedFilename, setIsKeepingUploadedFilename] = useState(
    false,
  );
  const commentExceedLimit = comment.length > enums.CHARACTER_INPUT_LIMIT_LONG;
  const [addParticipantPopoverData, setAddParticipantPopoverData] = useState({
    isVisible: false,
    participant: {},
  });
  const [usersToAddAsParticipants, setUsersToAddAsParticipants] = useState([]);
  const [isPrimaryActionLoading, setIsPrimaryActionLoading] = useState(false);
  const [isSecondaryActionLoading, setIsSecondaryActionLoading] = useState(
    false,
  );
  const [turnTrackingPartyId, setTurnTrackingPartyId] = useState();
  const enableTurnTracking = useFlag(FlagType.TurnTracking);

  async function uploadTicketDocVersion(mentions = null, isRedlining = false) {
    const res = await uploadNewDocumentVersion({
      ticketId,
      documentId,
      file,
      text: comment,
      isCounterparty,
      isKeepingUploadedFilename,
      userIds: mentions,
      ...(enableTurnTracking && {
        turnTracking: { partyId: turnTrackingPartyId },
      }),
    });
    // push the new version to redux
    ticketDocumentVersionsSet([
      { ...res, fileType: coerceFileType(res.fileType) },
      ...ticket.documentVersions,
    ]);

    dispatch(
      api.util.invalidateTags([
        TAGS.TAG_CURRENT_VERSION,
        TAGS.TURN_TRACKING_TAG,
      ]),
    );
    const activities = await getActivities({
      entityId: ticketId,
      entityType: EntityType.Ticket,
    });
    ticketActivitiesSet(activities);
    showToast(
      toastTypes.SUCCESS,
      'New document version successfully uploaded.',
    );

    const newVersionNumber = res.versionNumber;
    if (isRedlining) {
      history.push(
        `/workflow/tickets/${ticketId}/reviewer/${newVersionNumber}?compareTo=${currentVersion}`,
      );
    } else if (versionNumberExists) {
      history.push(
        `/workflow/tickets/${ticketId}/reviewer/${newVersionNumber}`,
      );
    }
  }
  const fileNameExceedsLimit = file?.name?.length > CharacterLimits.FileName;

  const resetForm = () => {
    // set to default values
    setIsPrimaryActionLoading(false);
    setIsSecondaryActionLoading(false);
    setFile(null);
    setComment('');
    setIsCounterparty(true);
    setIsKeepingUploadedFilename(false);
    setAddParticipantPopoverData({ isVisible: false, participant: {} });
    setUsersToAddAsParticipants([]);
  };

  async function addParticipantsToTicket(allMentions) {
    const usersToAdd = allMentions.filter((id) => !ticket.participants[id]);
    if (usersToAdd && usersToAdd.length > 0) {
      await addTicketParticipants({ ticketId, userIds: usersToAdd });
      showToast(
        toastTypes.SUCCESS,
        `${getUserNameText(usersToAdd)}
    ${
      usersToAdd.length > 1 ? 'have' : 'has'
    } been added to the list of participants.`,
      );
      handleGetParticipants();
    }
  }

  async function onUploadClick(isRedlining = false) {
    try {
      if (onUpload) {
        onUpload?.();
        hideModal();
      }
      const allMentions = uniq(getMentions(comment));
      await addParticipantsToTicket(allMentions);
      await uploadTicketDocVersion(allMentions, isRedlining);
    } catch (error) {
      showToast(
        toastTypes.ERROR,
        'New version upload failed. Please try again.',
      );
    } finally {
      // set to default values
      setIsPrimaryActionLoading(false);
      setIsSecondaryActionLoading(false);
      hideModal();
    }
  }

  const { executor: handleGetParticipants } = useAsync(
    getTicketParticipants,
    { ticketId },
    {
      condition: true,
      successHandler: ({ data }) => ticketParticipantsSet(data),
    },
  );

  function getUserNameText(userIds) {
    const userNames = userIds.map((id) => getUserName(users[id]));
    if (userNames.length === 1) return userNames[0];
    else if (userNames.length === 2) return userNames.join(' and ');
    else return `${userNames[0]} and ${userNames.length - 1} other people`;
  }

  function closePopover() {
    setAddParticipantPopoverData({
      isVisible: false,
    });
  }

  function onMentionUser(id) {
    if (
      !ticket.participants[id] &&
      !usersToAddAsParticipants.some((userId) => userId === id)
    ) {
      let user = users[id];
      setAddParticipantPopoverData({
        isVisible: true,
        participant: {
          name: getUserName(user),
          id,
        },
      });
    }
  }

  function removeUserMention(userId) {
    setComment(comment.replace(`{{user:${userId}}}`, ''));
  }

  function onCloseAddParticipantPopOver() {
    removeUserMention(addParticipantPopoverData.participant.id);
    closePopover();
  }

  function onAcceptAddParticipantPopOver({ id }) {
    setUsersToAddAsParticipants([...usersToAddAsParticipants, id]);
    closePopover();
  }

  function onUpdateComment(comment) {
    if (!addParticipantPopoverData.isVisible) {
      setComment(comment);
    }
  }

  function getUploadButtonTooltip() {
    if (!file) {
      return 'Attach a file';
    } else if (fileNameExceedsLimit) {
      return 'File name exceeds 255 character limit';
    } else if (commentExceedLimit) {
      return `Notes exceeds ${enums.CHARACTER_INPUT_LIMIT_LONG} character limit`;
    } else {
      return null;
    }
  }

  function getUploadRedlinesButtonTooltip(
    isFileAttachedNotDocx,
    isBaseVersionNotDocx,
  ) {
    let tooltipMessage = getUploadButtonTooltip();
    if (!tooltipMessage) {
      if (isFileAttachedNotDocx || isBaseVersionNotDocx) {
        tooltipMessage = 'Only .doc(x) files can be compared';
      }
    }

    return tooltipMessage;
  }

  const isFileAttachedNotDocx = file && file.type !== FileMimeType.Docx;
  const isBaseVersionNotDocx =
    ticket.documentVersions.find(
      (v) => v.versionNumber === Number(currentVersion),
    )?.fileType !== coerceFileType(FileExtensionType.Docx);

  const [modal, showModal, hideModal] = useModal({
    title: 'Upload New Version',
    onHide: resetForm,
    primaryAction: {
      disabled:
        !file ||
        commentExceedLimit ||
        addParticipantPopoverData.isVisible ||
        isSecondaryActionLoading ||
        fileNameExceedsLimit,
      text: 'Upload',
      tooltip: getUploadButtonTooltip(),
      onClick: () => {
        setIsPrimaryActionLoading(true);
        onUploadClick();
      },
      isLoading: isPrimaryActionLoading,
    },
    secondaryActions: [
      {
        disabled:
          isFileAttachedNotDocx ||
          isBaseVersionNotDocx ||
          !file ||
          commentExceedLimit ||
          isPrimaryActionLoading ||
          fileNameExceedsLimit,
        text: 'Upload & Generate Redlines',
        tooltip: getUploadRedlinesButtonTooltip(
          isFileAttachedNotDocx,
          isBaseVersionNotDocx,
        ),
        variant: 'secondary',
        onClick: () => {
          setIsSecondaryActionLoading(true);
          onUploadClick(true);
        },
        isLoading: isSecondaryActionLoading,
      },
    ],
    children: (
      <>
        <Box mb={4}>
          <Alert enableIcon variant="warning">
            <Text color="gray-700" variant="body2">
              After a new version of the document is uploaded, editing the
              intake form will no longer generate new document versions.
            </Text>
          </Alert>
        </Box>
        {file ? (
          <FlexLayout
            alignItems="center"
            bg="gray-200"
            justifyContent="space-between"
            p={6}
            space={3}
            sx={{ border: 'border', borderRadius: 'm' }}
          >
            <FileLabel file={file} shouldTruncate />
            <Icon
              color="gray-700"
              icon="close"
              size="m"
              title="Remove file"
              onClick={() => setFile(null)}
            />
          </FlexLayout>
        ) : (
          <FileInput
            acceptedFiles={getAcceptedFiles(ticket.workflow, [
              FileExtensionType.Docx,
            ])}
            enableDropzone
            enableUpload
            onChange={(files) => setFile(files[0])}
          />
        )}
        <Box mt={3}>
          <DescriptionTextAreaForm
            description={comment}
            id="workflow--ticket-upload-version-add-notes"
            limit={enums.CHARACTER_INPUT_LIMIT_LONG}
            title="notes"
            onUpdate={onUpdateComment}
            userMentionsData={users}
            onMentionUser={onMentionUser}
            mentionInputRef={mentionInputRef}
          />
          <AddParticipantToTicketPopover
            {...addParticipantPopoverData}
            onClose={onCloseAddParticipantPopOver}
            onSubmit={onAcceptAddParticipantPopOver}
          />
        </Box>
        {enableTurnTracking && (
          <PartySelectField
            workflowId={ticket.workflowId}
            onChange={(value) => setTurnTrackingPartyId(value)}
            errorMessage={{
              message: 'Try again in a few minutes.',
              title:
                'We’re experiencing difficulty loading Responsible Parties.',
            }}
          />
        )}
        <FlexLayout mt={5} space={3}>
          <Switch
            id="workflow--ticket-upload-version-counterparty-paper"
            size="s"
            value={isCounterparty}
            onChange={(updatedValue) => setIsCounterparty(updatedValue)}
          />
          <Text color="gray-900" variant="body2">
            Mark as Counterparty document
          </Text>
        </FlexLayout>
        {testIsEverestAdmin(currentUser) && (
          <FlexLayout mt={5} space={3}>
            <Switch
              id="workflow--ticket-upload-version-keep-filename"
              size="s"
              value={isKeepingUploadedFilename}
              onChange={(updatedValue) =>
                setIsKeepingUploadedFilename(updatedValue)
              }
            />
            <Text color="gray-900" variant="body2">
              Keep file name after upload
            </Text>
          </FlexLayout>
        )}
        <Box mt={3}>
          <Text color="gray-600" variant="tiny">
            All tracked changes included in this document version will be
            attributed to the counterparty.
          </Text>
        </Box>
      </>
    ),
    disableHideOnEscape: true,
    onCancel: () => {
      setComment('');
      setFile(null);
      setAddParticipantPopoverData({ isVisible: false });
      setUsersToAddAsParticipants([]);
    },
  });

  return (
    <>
      {iconOnly ? (
        <IconButton
          disabled={disabled}
          icon="upload"
          size="m"
          tooltip="Upload new version"
          onClick={showModal}
        />
      ) : (
        <Tooltip content="Upload new version">
          <Button
            disabled={disabled}
            iconPosition="left"
            text="New Version"
            onClick={showModal}
          />
        </Tooltip>
      )}
      {modal}
    </>
  );
}

const mapStateToProps = ({ ticket }) => {
  const { id: ticketId, document } = ticket;
  return { ticket, ticketId, documentId: document.id };
};

export default withUsers(
  connect(mapStateToProps, {
    ticketActivitiesSet,
    ticketDocumentVersionsSet,
    ticketParticipantsSet,
  })(UploadVersionAction),
);
