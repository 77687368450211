import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { WebhooksState as State } from '../types';

export const initialState: State = {
  activeEndpoint: null,
  endpointAction: null,
};

export const webhooks = createSlice({
  name: SliceType.Webhooks,
  initialState,
  reducers: {
    setActiveEndpoint: (
      state: State,
      action: PayloadAction<State['activeEndpoint']>,
    ) => {
      state.activeEndpoint = action.payload;
    },
    setEndpointAction: (
      state: State,
      action: PayloadAction<State['endpointAction']>,
    ) => {
      state.endpointAction = action.payload;
    },
  },
});
