import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { executePromptModelTestCase } from './executePromptModelTestCase';

interface Request {
  modelId: Uuid;
  version: number;
  // TODO: deprecate when FlagType.DocumentXrayDevBulkExecute is removed
  queryKey?: string; // e.g. 1-2-3-4 of test case numbers
}

export const executePromptModelTestCases = async ({
  modelId,
  version,
  queryKey,
}: Request): Promise<void> => {
  if (queryKey) {
    const testCaseNumbers = queryKey.split('-').map(Number);
    const promises = testCaseNumbers.map((testCaseNumber) =>
      executePromptModelTestCase({ modelId, version, testCaseNumber }),
    );

    await Promise.all(promises);
  } else {
    await sylvanus.post(`/model/${modelId}/version/${version}/execute`);
  }
  return;
};
