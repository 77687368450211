import { get, isEqual } from 'lodash';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ActionType } from 'react-table';

import { trackSegment } from '~/components/SegmentAnalytics';
import DocumentDetailsPanel from '~/components/Shared/DocumentDetailsPanel/DocumentDetailsPanel';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { ContentContainer, types, useTableSelection } from '~/eds';
import { TableContextType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, usePermission, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { ViewSetField } from '~/redux/api/methods';
import { ColumnSortOrder, FolderData, PilotId, User } from '~/types';
import {
  MODAL_DELETE,
  MODAL_DOCUMENT_GROUP,
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
  MODAL_FOLDER_TREE,
  MODAL_MULTI_EDIT,
} from '~/types/modal.types';
import { getValidEntity } from '~/utils/object';
import { testIsAdmin } from '~/utils/user';

type ConfigurableColumnState = {
  pageSize: number;
  page: number;
  sortBy: ColumnSortOrder;
};
interface Props {
  data: FolderData[];
  isLoading: boolean;
  onNavigate: (params: { type: FolderData['type'] }) => void;
  tableState: ConfigurableColumnState & {
    totalCount: number;
  };
  onUpdateTableState: (state: ConfigurableColumnState) => void;
  /** to reuse all permission and checkings made on the parent component, this component
   * will receive the menuItem for the legacu table and map it to eds.MenuAction
   */
  getMenuItems: (
    item: FolderData,
    currentUser: User,
  ) => {
    content: string;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
  }[];
  handleShowModal: (
    modalType: string,
    selectedItems?: FolderData[],
    modalBulkAction?: string,
  ) => void;
  refetchData: () => void;
  onSelectAllClick: (isSelectAll: boolean) => void;
}

function _DocumentsTableV2(
  {
    data,
    isLoading,
    tableState,
    getMenuItems,
    handleShowModal,
    onNavigate,
    onSelectAllClick,
    onUpdateTableState,
    refetchData,
  }: Props,
  ref: any,
) {
  const currentUser = useCurrentUser();
  const [dataMap, setDataMap] = useState<Record<types.PilotId, FolderData>>({});
  const isDetailsPanelEnabled = useFlag(FlagType.UnifiedDocumentDetailsPanel);
  const disableSorting = useFlag(FlagType.DisableDocumentTableSorting);
  const { hasPermission: hasDocumentGroupPermission } = usePermission({
    permission: {
      resourceId: 'document_groups',
      resourceType: 'view',
    },
  });
  const [activeDocument, setActiveDocument] = useState<
    types.Nullable<FolderData>
  >(null);
  const {
    data: fieldsResponse,
  } = api.endpoints.getDocumentsViewsetFields.useQuery({
    hasDocumentGroupPermission: !!hasDocumentGroupPermission,
  });

  const { page, pageSize, sortBy, totalCount } = tableState;

  const {
    data: tableColumns,
    isFetching: isColumnsFetching,
  } = api.endpoints.getCurrentTableView.useQuery({
    context: undefined,
  });

  const {
    tableSettings = {
      columnOrder: [],
      pageSize,
      sortBy,
      columnWidths: {},
    },
    updateTableSettings,
  } = useTableSettings(TableContextType.Documents);

  const {
    columnOrder,
    columnWidths,
    sortBy: sortByTableSetting,
  } = tableSettings;

  useEffect(() => {
    if (tableColumns) {
      const dynamicColumns = tableColumns.map((el) => `${el.field_id}`);
      updateTableSettings({
        ...tableSettings,
        columnOrder: ['name', ...dynamicColumns],
      });
    }
  }, [tableColumns]);

  const derivedcolumnOrder = useMemo(() => {
    if (tableColumns) {
      return ['name', ...tableColumns.map((el) => `${el.field_id}`)];
    }
    return ['name'];
  }, [tableColumns]);

  const [
    updateTableView,
    { isLoading: isUpdateTableViewLoading },
  ] = api.endpoints.updateTableView.useMutation();

  const hardcodedColumns = [
    {
      key: 'name',
      title: 'Name',
      minWidth: 'm',
      cellType: 'filesystem',
      mapCellProps: (item: FolderData) => ({
        onClick: () => onNavigate({ type: item.type }),
        folder:
          item.type === 'folder'
            ? {
                id: item.id,
                name: item.name,
                link: { pathname: `/documents/${item.id}` },
                path: '',
              }
            : undefined,
        file:
          item.type === 'document'
            ? {
                name: `${item.name}${item.fileType}`,
                link: { pathname: `/document/${item.id}` },
              }
            : undefined,
      }),
    },
  ];

  const {
    isAllSelected,
    selectedIds,
    onTableUpdate,
    clear,
  } = useTableSelection();

  useImperativeHandle(ref, () => ({
    clearSelectedRows: () => {
      clear();
    },
  }));

  useEffect(() => {
    onSelectAllClick(isAllSelected);
  }, [isAllSelected]);

  useEffect(() => {
    if (data) {
      const newDataMap = Object.fromEntries(data.map((d) => [d.id, d]));
      setDataMap({ ...dataMap, ...newDataMap });
    }
  }, [data]);

  const columns = useMemo(() => {
    if (fieldsResponse) {
      return [
        ...hardcodedColumns,
        ...fieldsResponse.map(prepareTableColumns),
      ].map((column) => ({
        ...column,
        disableSortBy: disableSorting,
      }));
    }
    return [];
  }, [fieldsResponse]);

  function getBulkActions({ selectedRowIds }: any) {
    const isAnyFolderSelected = selectedRowIds.some(
      (id: string) => dataMap[id].type === 'folder',
    );

    const selectedItems = getValidEntity(dataMap, selectedRowIds);
    const isSomeNonMovableItemSelected = selectedItems.some(
      (folderData: FolderData) => !folderData.isMovable,
    );
    const isSomeContainsSyncPairSelected = selectedItems.some(
      (folderData: FolderData) => folderData.contains_sync_pair,
    );
    return [
      {
        key: 'addToGroup',
        label: 'Add to Group',
        onClick: () => handleShowModal(MODAL_DOCUMENT_GROUP, selectedItems),
        disabled: isAnyFolderSelected,
        tooltip: isAnyFolderSelected
          ? "To Add to Group, make sure your selection doesn't include any folders."
          : '',
      },
      {
        key: 'move',
        label: 'Move',
        disabled: isSomeNonMovableItemSelected,
        tooltip: isSomeNonMovableItemSelected
          ? 'You do not have privileges to move one or more of the items you have selected.'
          : '',
        onClick: () =>
          handleShowModal(MODAL_FOLDER_TREE, selectedItems, 'Move'),
      },
      {
        key: 'copy',
        label: 'Copy',
        disabled: isSomeNonMovableItemSelected,
        tooltip: isSomeNonMovableItemSelected
          ? 'You do not have privileges to copy one or more of the items you have selected.'
          : '',
        onClick: () =>
          handleShowModal(MODAL_FOLDER_TREE, selectedItems, 'Copy'),
      },
      {
        key: 'edit',
        label: 'Edit',
        disabled: isSomeNonMovableItemSelected,
        tooltip: isSomeNonMovableItemSelected
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : '',
        onClick: () => handleShowModal(MODAL_MULTI_EDIT, selectedItems, 'edit'),
      },
      {
        key: 'delete',
        label: 'Delete',
        disabled:
          isSomeNonMovableItemSelected || isSomeContainsSyncPairSelected,
        tooltip: isSomeContainsSyncPairSelected
          ? ' The selected item(s) contain synchronizations with external storage providers, please contact your Evisort admin to stop synchronization before deleting the selected item(s).'
          : isSomeNonMovableItemSelected
          ? 'You do not have privileges to delete one or more of the items you have selected.'
          : '',
        onClick: () => handleShowModal(MODAL_DELETE, selectedItems, 'delete'),
      },
    ];
  }

  function getRowActions(item: FolderData) {
    const items = getMenuItems(item, currentUser);
    const isAdmin = testIsAdmin(currentUser);

    const shouldEnableMenuActions =
      isAdmin || item.user_visibility_level === 'OPEN';

    return items.map(({ content, onClick, disabled, tooltip }) => ({
      onClick,
      label: content,
      disabled: !shouldEnableMenuActions || disabled,
      tooltip: tooltip
        ? tooltip
        : !shouldEnableMenuActions
        ? `You do not have privileges to ${content}`
        : '',
    }));
  }

  function onSaveView() {
    handleShowModal(MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE);
  }

  function onLoadView() {
    handleShowModal(MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH);
  }

  function onPageSelect({ pageIndex }: { pageIndex: number }) {
    onUpdateTableState({ ...tableState, page: pageIndex });
  }

  function onPageSizeChange(pageSize: number) {
    onUpdateTableState({ ...tableState, page: 1, pageSize });
  }

  function handleUpdate(state: any, action?: ActionType) {
    onTableUpdate(state, action);
    if (action?.type === 'toggleSortBy') {
      onUpdateTableState({ ...tableState, sortBy: state.sortBy[0] });
    }
  }

  async function onSetColumnOrder(newColumnOrder: string[]) {
    //Since name is a hardcoded column, it will always be included. we need to remove
    //from the viewset request.
    const dynamicColumns = newColumnOrder.filter((col) => col !== 'name');
    if (!isEqual(columnOrder, dynamicColumns)) {
      const newColumnOrderPayload = dynamicColumns.map((col, index) => ({
        field_id: isNaN(parseInt(col)) ? col : parseInt(col),
        column_number: index,
      }));
      await updateTableView({
        fields: newColumnOrderPayload,
        context: undefined,
      }).unwrap();
      refetchData();
    }
  }

  function onDetailsClick(document: FolderData) {
    setActiveDocument(document);
    trackSegment('selectOverlayPanel', {
      name: document.id,
    });
  }

  function onHideDatailsPanel() {
    trackSegment('selectClose', {
      name: activeDocument?.id,
    });
    setActiveDocument(null);
  }

  const isDetailsPanelNextDisabled = useMemo(() => {
    if (activeDocument) {
      /** data has ALL folders defined first and then files. we only
       * need to disable the next button if currentIndex is the last element of the table.
       */
      const currentIndex = data.indexOf(activeDocument);
      return currentIndex === data.length - 1;
    }
    return true;
  }, [data, activeDocument]);

  const isDetailsPanelPreviousDisabled = useMemo(() => {
    if (activeDocument) {
      /** data has ALL folders defined first and then files. we need to check
       * if the previous element is a folder or not. if it is a folder, we need to disable.
       */
      const currentIndex = data.indexOf(activeDocument);
      return data[currentIndex - 1]?.type === 'folder';
    }
    return true;
  }, [data, activeDocument]);

  const onDetailsPanelNextClick = () => {
    if (activeDocument) {
      const currentIndex = data.indexOf(activeDocument);
      setActiveDocument(data[currentIndex + 1]);
    }
  };

  const onDetailsPanelPreviousClick = () => {
    if (activeDocument) {
      const currentIndex = data.indexOf(activeDocument);
      setActiveDocument(data[currentIndex - 1]);
    }
  };

  return (
    <>
      <ContentContainer
        loadingContent={{
          isLoading:
            isColumnsFetching ||
            isUpdateTableViewLoading ||
            !isEqual(derivedcolumnOrder, columnOrder),
        }}
      >
        <PersistedTable
          name="folder-documents-results"
          context={TableContextType.Documents}
          totalCount={totalCount}
          columns={columns}
          isLoading={isLoading}
          data={data}
          activeRowId={activeDocument?.id}
          getBulkActions={getBulkActions}
          getRowActions={getRowActions}
          onPaginate={onPageSelect}
          onPageSizeChange={onPageSizeChange}
          onSetColumnOrder={onSetColumnOrder}
          onUpdate={handleUpdate}
          onSaveView={onSaveView}
          onLoadView={onLoadView}
          rowDetails={
            isDetailsPanelEnabled
              ? {
                  onClick: onDetailsClick,
                  condition: (item: FolderData) => item.type === 'document',
                }
              : undefined
          }
          state={{
            columnOrder: tableSettings.columnOrder,
            pageIndex: page,
            pageSize,
            sortBy: [sortByTableSetting],
            isAllRowsSelected: isAllSelected,
            selectedRowIds: selectedIds,
            columnWidths,
          }}
          options={{
            enableExportXlsx: false,
            enablePageSizeSelect: true,
            enableSelectRows: true,
          }}
          reactTableOptions={{
            autoResetSortBy: false,
            disableSortRemove: true,
            manualSortBy: true,
          }}
          getRowActionsMenuId={(d: any, i: number) =>
            i === 0 ? OnboardingIdType.AccessSettingsTab : undefined
          }
        />
      </ContentContainer>
      {isDetailsPanelEnabled && (
        <DocumentDetailsPanel
          docHandlerId={activeDocument?.id}
          documentName={activeDocument?.name}
          documentFormatType={activeDocument?.fileType}
          onHide={onHideDatailsPanel}
          actionsConfig={{
            next: {
              onClick: onDetailsPanelNextClick,
              disabled: isDetailsPanelNextDisabled,
            },
            previous: {
              onClick: onDetailsPanelPreviousClick,
              disabled: isDetailsPanelPreviousDisabled,
            },
          }}
        />
      )}
    </>
  );
}

function getDisplayValue(item: FolderData, fieldId: PilotId) {
  return item.document_fields
    .find((field) => field.field_id === fieldId)
    ?.display_value.join(' , ');
}

function prepareTableColumns({ fieldId, fieldName }: ViewSetField) {
  const defaultAttributes = {
    key: `${fieldId}`,
    title: fieldName,
  };
  let columnTypeAttributes;
  if (fieldId === 'document_group_id') {
    columnTypeAttributes = {
      disableSortBy: true,
      cellType: 'link',
      minWidth: 'm',
      mapCellProps: (item: FolderData) => ({
        icon: 'groups',
        pathname: `/document-group/${get(item, 'groups[0].groupId')}`,
        text: get(item, 'groups[0].name', ''),
      }),
    };
  } else {
    switch (fieldName) {
      case 'Upload Date':
        columnTypeAttributes = {
          cellType: 'datetime',
          mapCellProps: (item: FolderData) => {
            const displayValue = getDisplayValue(item, fieldId as number);
            return {
              format: 'full',
              datetime: displayValue ? new Date(displayValue) : undefined,
            };
          },
        };
        break;
      default:
        columnTypeAttributes = {
          cellType: 'text',
          mapCellProps: (item: FolderData) => ({
            text: getDisplayValue(item, fieldId as number),
          }),
        };
    }
  }

  return { ...defaultAttributes, ...columnTypeAttributes };
}

export const DocumentsTableV2 = React.forwardRef(_DocumentsTableV2);
