import { debounce } from 'lodash';
import React from 'react';
import { PdfHighlighter as VendorPdfHighlighter } from 'react-pdf-highlighter';
import getBoundingRect from 'react-pdf-highlighter/dist/esm/lib/get-bounding-rect';
import { getPagesFromRange } from 'react-pdf-highlighter/dist/esm/lib/pdfjs-dom';

import { setupFindController } from '~/utils/pdfjs';

import { getClientRects } from '../utils/utils.ts';
import { PdfHighlighterContext } from './PdfHighlighterContext';

/**
 * This component is a short-term solution to overcome two bugs found on react-pdf-highlighter library:
 *  1. https://github.com/agentcooper/react-pdf-highlighter/issues/190 - issue description
 *    - Pull Request to fix the issue: https://github.com/agentcooper/react-pdf-highlighter/pull/191
 *
 *  2. https://github.com/agentcooper/react-pdf-highlighter/issues/172 - issue description
 *   - Pull request to fix the issue: https://github.com/agentcooper/react-pdf-highlighter/issues/172
 *
 *  Since both pull requests are opened for a while, and the library owner is not active for some time, we decided to take
 *  this approach to apply both fixes. The solution is to create a new component PdfHighlighter extending react-pdf-highlighter's PdfHighlighter
 *  and overriding the afterSelection function. The afterSelection function calls two other functions that are the source of the bugs. getClientRects is the source
 *  of the first bug and getPagesFromRange is the source of the second bug.
 *  We redefined those two functions inside ../utils/utils.js applying the respective fix to each bug.
 *
 *  Even though we were able to solve the issues described above, this solution should be temporary. When both pull requests are merged
 *  to the library's repository, we should go back to use the vendor's component.
 */

class PdfHighlighter extends VendorPdfHighlighter {
  componentDidMount() {
    this.init();
    setupFindController(this.viewer, this.linkService, this.eventBus);
    this.context.setViewer(this.viewer);
    this.context.setEventBus(this.eventBus);
    this.context.setLinkService(this.linkService);
    if (this.containerNode) {
      this.containerNode.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.containerNode) {
      this.containerNode.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => this.props.onScroll?.();

  afterSelection = () => {
    const { onSelectionFinished } = this.props;
    const { isCollapsed, range } = this.state;
    if (!range || isCollapsed) {
      return;
    }
    const pages = getPagesFromRange(range);
    if (!pages || pages.length === 0) {
      return;
    }
    const rects = getClientRects(range, pages);
    if (rects.length === 0) {
      return;
    }

    const currentPageNumber = this.viewer.currentPageNumber;
    let rectsFromPage = rects.filter(
      (rect) => rect.pageNumber === currentPageNumber,
    );
    if (!rectsFromPage.length) {
      rectsFromPage = rects;
    }
    const boundingRect = getBoundingRect(rectsFromPage);
    const viewportPosition = {
      boundingRect,
      rects,
      pageNumber: this.viewer.currentPageNumber,
    };
    const content = {
      text: range.toString(),
    };
    const scaledPosition = this.viewportPositionToScaled(viewportPosition);
    // adjusting top position to show the tip below the highlight
    const adjustedViewportPosition = {
      ...viewportPosition,
      boundingRect: {
        ...viewportPosition.boundingRect,
        top:
          viewportPosition.boundingRect.top +
          viewportPosition.boundingRect.height +
          30, // 30 is the height of the context menu,
        width: 0, // shows the tip at the beginning of the highlight
      },
    };

    this.setTip(
      adjustedViewportPosition,
      onSelectionFinished(
        scaledPosition,
        content,
        () => this.hideTipAndSelection(),
        () =>
          this.setState(
            {
              ghostHighlight: { position: scaledPosition },
            },
            () => this.renderHighlights(),
          ),
      ),
    );
  };

  debouncedAfterSelection = debounce(this.afterSelection, 500);
}

PdfHighlighter.contextType = PdfHighlighterContext;

const PdfHighlighterFc = (props) => <PdfHighlighter {...props} />;

export default PdfHighlighterFc;
