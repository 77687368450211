export const integrateSegment = (user) => {
  if (typeof window.analytics != 'undefined' && user) {
    window.analytics.page();
    window.analytics.identify(`${user.id}_${user.client}`, {
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      firstName: user.first_name,
      lastName: user.last_name,
      role: user?.role_uuid?.name ?? '',
      groupId: user.client,
      clientName: user.client_name,
      workflowAdmin: user.is_workflow_admin,
    });
  }
};

export const trackSegment = (name, properties) => {
  if (typeof window.analytics != 'undefined') {
    window.analytics.track(name, properties);
  }
};
