import { History } from 'history';

import { SearchV3 } from '~/features/search/SearchV3';
import { FlagType, useFlag } from '~/flags';

import DeprecatedSearch from './Search_DEPRECATED';

interface Props {
  history: History;
}

function Page(props: Props) {
  const hasSearchV3 = useFlag(FlagType.SearchV3);

  return hasSearchV3 ? (
    <SearchV3 {...props} />
  ) : (
    <DeprecatedSearch {...props} />
  );
}

export default Page;
