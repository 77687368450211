import AuthenticationStore from '~/auth';
import { LoginViewType } from '~/enums';

const AS = AuthenticationStore();

export const useRolodexLoginProps = ({
  setStage,
}: {
  setStage: (stage: LoginViewType) => void;
}) => {
  const onRolodexLoginComplete = () => {
    setTimeout(() => {
      if (AS.isLoggedIn()) {
        setStage(LoginViewType.LoggedIn);
      }
    }, 100);
  };
  return {
    onRolodexLoginComplete,
  };
};
