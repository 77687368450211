import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { Layout, Modal, Text, TextArea, TextInput } from '~/eds';
import { api, coerceRtkqError, selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';
import { PilotId } from '~/types';
import { MODAL_EDIT_DOCUMENTS_GROUP } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

type Props = {
  groupId: PilotId;
  name?: string;
  note?: string;
};

export const EditGroup = ({ groupId, name = '', note = '' }: Props) => {
  const [localName, setName] = useState(name);
  const [localNote, setNote] = useState(note);
  const dispatch = useDispatch();

  const modal = useSelector(selectors.selectDocGroupModal);
  const { modalType } = modal || {};

  const [
    editDocumentGroup,
    result,
  ] = api.endpoints.editDocumentGroup.useMutation();
  const { isSuccess, isError, error, isLoading } = result;

  const onClose = useCallback(() => {
    setName(name);
    setNote(note);
    dispatch(documentGroup.actions.setModal());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, 'Group has been edited successfully.');
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(
        ERROR,
        coerceRtkqError(error)?.response?.data?.message ||
          'An error occurred while updating a new document group.',
      );
    }
  }, [isError, error]);

  const isDirty = localName !== name || localNote !== note;

  return (
    <Modal
      isVisible={modalType === MODAL_EDIT_DOCUMENTS_GROUP}
      title="Edit Group"
      onCancel={onClose}
      onHide={onClose}
      primaryAction={{
        text: 'Save',
        onClick: () => {
          editDocumentGroup({
            groupId,
            data: { name: localName, note: localNote },
          });
        },
        isLoading: isLoading,
        disabled: !localName?.trim() || isLoading || !isDirty,
      }}
    >
      <Layout direction="column">
        <Text mb={2} variant="body-medium">
          GROUP NAME
        </Text>
        <TextInput
          autoFocus
          name="name"
          placeholder="Group Name"
          value={localName}
          onChange={(value) => setName(value || '')}
        />
      </Layout>
      <Layout direction="column" mt={6}>
        <Text mb={2} variant="body-medium">
          NOTE
        </Text>
        <TextArea
          name="note"
          placeholder="Note"
          value={localNote}
          onChange={(value) => setNote(value || '')}
        />
      </Layout>
    </Modal>
  );
};
