import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ActionsMenu,
  Icon,
  IconButton,
  Layout,
  LoadingSpinner,
  Text,
  types,
  useToast,
} from '~/eds';
import { useConversationPipeline } from '~/features/ask-anything/hooks';
import { useCurrentUser } from '~/hooks';
import { api, selectors } from '~/redux';
import { QuestionGroup } from '~/types';
import { testIsAdmin } from '~/utils/user';

interface QuestionGroupItemProps {
  questionGroup: QuestionGroup;
  onQuestionGroupClick: (questionGroup: QuestionGroup) => void;
  disableRun?: boolean;
  status?: 'running' | 'complete' | 'idle';
  onAction?: ({
    action,
    questionGroup,
  }: {
    action: 'edit' | 'delete';
    questionGroup: QuestionGroup;
  }) => void;
}

export const QuestionGroupItem = ({
  questionGroup,
  disableRun,
  status,
  onAction,
  onQuestionGroupClick,
}: QuestionGroupItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { toast } = useToast();
  const isRunning = status === 'running';
  const isComplete = status === 'complete';

  const [
    updateQuestionGroup,
    { isLoading: isUpdating },
  ] = api.endpoints.updateQuestionGroup.useMutation();

  const [interruptAiMessage] = api.endpoints.interruptAiMessage.useMutation();
  const currentUser = useCurrentUser();

  const handlePin = async () => {
    try {
      await updateQuestionGroup({
        id: questionGroup.id,
        pinnedAt: questionGroup.pinnedAt ? null : new Date(),
      }).unwrap();
      toast({
        status: 'success',
        message: `The question group has been successfully ${
          questionGroup.pinnedAt ? 'unpinned' : 'pinned'
        }.`,
      });
    } catch (apiError: any) {
      toast({
        status: 'danger',
        message: `The ${
          questionGroup.pinnedAt ? 'unpin' : 'pin'
        } action failed due to ${apiError.errors[0].title}.`,
      });
    }
  };

  const { queue, clearQueue } = useConversationPipeline();
  const conversationId = useSelector(selectors.selectCurrentConversationId);
  const completedQuestions = queue.filter(
    (message) => message.status === 'complete',
  );

  const interruptAiMessageHandler = async () => {
    if (!conversationId) {
      return;
    }
    try {
      await interruptAiMessage(conversationId).unwrap();
      clearQueue();
    } catch {
      toast({
        status: 'danger',
        message: 'Failed to stop Question Group',
      });
    }
  };

  /**
   * These are used to control the visibility of the "Run question group" button
   * Here are the rules:
   * 1. If it's not running, it should be visible when hovered
   * 2. If a qustion group is running, it should be visible if the running group is this group
   * 3. If a question group is running, it should be hidden if the running group is not this group
   * 4. If the run is complete, and this is the group that was run, it should show "Running complete" and a checkmark icon
   */
  const runText = isRunning
    ? `Running... ${completedQuestions.length}/${
        queue.length > questionGroup.questionIds.length
          ? questionGroup.questionIds.length
          : queue.length
      } complete`
    : 'Run Question Group';
  const shouldHideRun = disableRun && !isRunning;
  const isRunButtonVisible =
    (isHovered && !shouldHideRun) || isRunning || isComplete;

  const getActions = useCallback(() => {
    const actions: types.Action[] = [
      {
        label: questionGroup.pinnedAt ? 'Unpin' : 'Pin',
        value: questionGroup.pinnedAt ? 'unpin' : 'pin',
        onClick: handlePin,
      },
    ];

    if (
      questionGroup.createdBy === currentUser.id ||
      testIsAdmin(currentUser)
    ) {
      actions.push(
        {
          label: 'Edit',
          value: 'edit',
          onClick: () => onAction?.({ action: 'edit', questionGroup }),
        },
        {
          label: 'Delete',
          value: 'delete',
          onClick: () => onAction?.({ action: 'delete', questionGroup }),
        },
      );
    }

    return actions;
  }, [questionGroup, currentUser, onAction]);

  return (
    <Layout
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-eds-styles-disable-interactable
      align="flex-start"
      justify="space-between"
      flex={1}
    >
      <Layout
        flex={1}
        spacing={2}
        direction="column"
        align="flex-start"
        data-eds-styles-disable-interactable
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          // this is necessary to override the default onClick behavior.
          // it should only expand/collapse the accordion when clicked in the chevron
          // clicking the summary would trigger the onQuestionGroupClick
          event.stopPropagation();
          event.preventDefault();
          !disableRun && onQuestionGroupClick(questionGroup);
        }}
      >
        <Text>{questionGroup.name}</Text>
        <Layout justify="space-between" w="100%">
          <Layout
            styles={{
              visibility: isRunButtonVisible ? 'visible' : 'hidden',
            }}
            spacing={2}
          >
            {isComplete ? (
              <Icon icon="check" color="status.success" />
            ) : isRunning ? (
              <LoadingSpinner mode="dashed" />
            ) : null}
            <Text size="s" color="action.link" variant="tiny">
              {isComplete ? 'Running Complete' : runText}
            </Text>
          </Layout>
          {isRunning && (
            <IconButton
              PRIVATE_variant="action"
              size="xs"
              icon="circle-stop"
              tooltip="Stop running Question Group"
              onClick={interruptAiMessageHandler}
            />
          )}
        </Layout>
      </Layout>
      <Layout align="start" justify="flex-end" minW={38} minH={40}>
        {isHovered ? (
          <ActionsMenu
            direction="right"
            name="question item actions"
            enablePortal={true}
            disabled={isUpdating}
            actions={getActions()}
          />
        ) : (
          questionGroup.pinnedAt && <Icon icon="pin" size="s" />
        )}
      </Layout>
    </Layout>
  );
};
