import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { analyzerSetData } from '~/actions';
import SearchQueryEntry from '~/components/AnalyzerPage/SearchQueryEntry';
import { Button, Layout } from '~/eds';
import searchSlice from '~/redux/slices/search';

const EditInAnalyzer = ({ query, analyzerSetData: setAnalyzerFormData }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const resetSearchPage = () => {
    dispatch(searchSlice.actions.reset());
  };

  const applyQueryInAnalyzerForm = () => {
    setAnalyzerFormData({ filters: query });
    history.push('/analyzer');
  };

  return (
    <Layout align="center" spacing={3} wrap={true}>
      <SearchQueryEntry query={query} />
      <Button
        text="Edit in Analyzer"
        variant="action"
        onClick={applyQueryInAnalyzerForm}
      />
      <Button text="Clear Filters" variant="action" onClick={resetSearchPage} />
    </Layout>
  );
};

const mapStateToProps = ({ analyzer }) => ({ analyzer });

export default connect(mapStateToProps, { analyzerSetData })(EditInAnalyzer);
