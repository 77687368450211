import React from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';

import { showToast } from '~/components/Shared/EcToast';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { PAGE_START, UI_TABLE_PAGE_SIZE } from '~/constants/page';
import {
  Layout,
  PageLayout,
  parseDate,
  Text,
  types,
  useDebouncedState,
} from '~/eds';
import { QueryParamType, TableContextType, TicketStageType } from '~/enums';
import { TurnTrackingPanel } from '~/features/turn-tracking';
import { FlagType, useFlag, withFlags } from '~/flags';
import { useCurrentUser, useTableSettings, useUsers } from '~/hooks';
import { checkIsWorkflowOnly } from '~/permissions';
import { actions, api, coerceRtkqError } from '~/redux';
import { TableTicket, Uuid } from '~/types';
import { ERROR } from '~/types/toast.types';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { capitalizeWords } from '~/utils/strings';

import {
  getSortByV2,
  SortBy,
  TabsConfig,
  TicketCompletionType,
  TicketListType,
  useSearchTicketListQuery,
  WizardWorkflowTemplatesList,
} from './common';

const { useState, useEffect } = React;

const SORT_DESC = 'desc';
const SORT_ASC = 'asc';

const stageOrder = ['edit', 'review', 'sign', 'finalize'];

const tabsConfig: Record<TicketCompletionType, TabsConfig> = {
  [TicketCompletionType.NeedsAction]: {
    value: TicketCompletionType.NeedsAction,
    label: 'Needs Action',
    emptyTitle: 'No tickets assigned to you.',
    emptyMessage:
      'Once you create a new ticket or when you are assigned to a ticket, it will be shown here.',
    filter: 'needsAction=true,completed=false',
  },
  [TicketCompletionType.InProgress]: {
    value: TicketCompletionType.InProgress,
    label: 'In Progress',
    emptyTitle: 'No active tickets.',
    emptyMessage: 'It looks like there are no tickets being reviewed.',
    filter: 'needsAction=false,completed=false',
  },
  [TicketCompletionType.Cancelled]: {
    value: TicketCompletionType.Cancelled,
    label: 'Cancelled',
    emptyTitle: 'No cancelled tickets.',
    emptyMessage: 'It looks like there are no cancelled tickets.',
    filter: 'cancelled=true',
    featureFlag: FlagType.TicketListCancelledTab,
  },
  [TicketCompletionType.Done]: {
    value: TicketCompletionType.Done,
    label: 'Done',
    emptyTitle: 'No completed tickets.',
    emptyMessage: 'Once a ticket is finalized, it will be shown here.',
    filter: 'completed=true',
  },
  [TicketCompletionType.All]: {
    value: TicketCompletionType.All,
    label: 'All',
    emptyTitle: 'No tickets match your criteria.',
    filter: '',
  },
};

function getActiveTabConfig(activeTab: number) {
  return Object.values(tabsConfig)[activeTab];
}

interface WorkflowTicketsPageProps {
  flags: Record<FlagType, boolean>;
}
/**
 * TODO: Try updating all 'any' types that are still being used.
 * TODO: Check if we can update activeTab parameter from a number to the tab name.
 * TODO: Update to new tooltip when placement is available.
 * TODO: Start removing ts-ignore as we start switching more of our code to typescript.
 * TODO: Look into typing ticket endpoints.
 */
const TicketListV2 = ({ flags }: WorkflowTicketsPageProps) => {
  const refactorMoment = useFlag(FlagType.DeprecateMoment);
  const { tableSettings } = useTableSettings(TableContextType.WorkflowTickets);
  const currentUser = useCurrentUser();
  const isWorkflowOnlyUser = checkIsWorkflowOnly(currentUser);
  const urlParams = {
    activeTab: getPageSearchQueryByKey(
      [QueryParamType.ActiveTab],
      TicketCompletionType.NeedsAction,
    ),
    page: getPageSearchQueryByKey([QueryParamType.Page], PAGE_START),
    pageSize:
      tableSettings?.pageSize ??
      getPageSearchQueryByKey([QueryParamType.PageSize], UI_TABLE_PAGE_SIZE),
    sortedColumn:
      tableSettings?.sortBy?.id ??
      getPageSearchQueryByKey([QueryParamType.SortedColumn], 'last activity'),
    sortedOrder:
      tableSettings?.sortBy?.desc !== undefined
        ? tableSettings.sortBy.desc
          ? SORT_DESC
          : SORT_ASC
        : getPageSearchQueryByKey([QueryParamType.SortedOrder], SORT_DESC),
  };
  const isTurnTrackingEnabled = useFlag(FlagType.TurnTracking);
  const [activeTab, setActiveTab] = useState(urlParams.activeTab);
  const [pageIndex, setPageIndex] = useState(urlParams.page);
  const [initRender, setInitRender] = useState(true);
  const [sortBy, setSortBy] = useState<SortBy>({
    id: urlParams.sortedColumn,
    desc: urlParams.sortedOrder === SORT_DESC,
  });
  const [pageSize, setPageSize] = useState(
    tableSettings?.pageSize ?? urlParams.pageSize,
  );
  const [showTurnTrackingPanel, setShowTurnTrackingPanel] = useState(false);

  const [throttledSearch, searchText, setSearchText] = useDebouncedState('');
  const [ticketId, setTicketId] = useState('');
  // TODO: change to empty string - phase 1 Turn Tracking
  // any call to getWorkflowParties currently works, update after decision on party implementation
  const [workflowId, setWorkflowId] = useState(uuid.v4());

  const activeTabConfig = getActiveTabConfig(activeTab);

  const {
    data: templates,
    error: templatesError,
    isLoading: isTemplatesLoading,
  } = api.endpoints.getAvailableWorkflows.useQuery({});

  const usersData = useUsers();
  const dispatch = useDispatch();

  // TODO: can the creatorId be the user's extId. Since we already have the usersData have the extId make it easy to get the user.
  const [shouldShowCreateTicketList, setShouldShowCreateTicketList] = useState(
    false,
  );

  // Integration with search API
  const tabsMapping = {
    [TicketCompletionType.NeedsAction]: TicketListType.NeedsAction,
    [TicketCompletionType.InProgress]: TicketListType.InProgress,
    [TicketCompletionType.Cancelled]: TicketListType.Cancelled,
    [TicketCompletionType.Done]: TicketListType.Done,
    [TicketCompletionType.All]: TicketListType.All,
  };

  const {
    count: needsActionCount,
    isLoading: needsActionLoading,
  } = useSearchTicketListQuery({
    ticketListType: TicketListType.NeedsAction,
    params: { pageSize: 0 },
  });
  const {
    count: cancelledCount,
    isLoading: cancelledLoading,
  } = useSearchTicketListQuery({
    ticketListType: TicketListType.Cancelled,
    params: { pageSize: 0 },
  });
  const {
    count: inProgressCount,
    isLoading: inProgressLoading,
  } = useSearchTicketListQuery({
    ticketListType: TicketListType.InProgress,
    params: { pageSize: 0 },
  });

  const {
    data: ticketListData,
    count: ticketListCount,
    isLoading,
    error,
    isFetching,
  } = useSearchTicketListQuery({
    ticketListType: tabsMapping[activeTabConfig.value],
    params: {
      page: pageIndex,
      pageSize: pageSize,
      sortBy: getSortByV2(sortBy),
    },
    searchText: throttledSearch,
  });

  const data = { data: ticketListData, count: ticketListCount };

  const shouldDisplayLoadingState =
    isLoading || inProgressLoading || needsActionLoading || cancelledLoading;

  useEffect(() => {
    updatePageSearchQuery(urlParams);
  }, []);

  useEffect(() => {
    if (data && isWorkflowOnlyUser && initRender) {
      setInitRender(false);
      const { count }: any = data;
      const workflowOnlyRoleDefaultTab = count
        ? TicketCompletionType.NeedsAction
        : TicketCompletionType.InProgress;

      if (workflowOnlyRoleDefaultTab !== activeTab && !count) {
        setActiveTab(workflowOnlyRoleDefaultTab);
        updatePageSearchQuery({ activeTab: workflowOnlyRoleDefaultTab });
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (coerceRtkqError(error)?.response?.data?.statusCode === 404) {
        return;
      }
      showToast(ERROR, 'An error occurred while loading tickets.');
    }
    if (templatesError) {
      showToast(ERROR, 'An error occurred while loading workflow templates.');
    }
  }, [error, templatesError]);

  const handleShowCreateTicketList = (showCreateTicketList: boolean) => {
    setShouldShowCreateTicketList(showCreateTicketList);
  };

  const handleTabClick = (nextActiveTab: string) => {
    setActiveTab(Number(nextActiveTab));
    setPageIndex(PAGE_START);
    updatePageSearchQuery(
      {
        [QueryParamType.ActiveTab]: Number(nextActiveTab),
        [QueryParamType.Page]: PAGE_START,
      },
      true,
    );
  };

  const renderEmptyTabContent = (
    tabName: TicketCompletionType,
  ): JSX.Element => {
    const tabConfig: TabsConfig = tabsConfig[tabName];

    return (
      <Layout
        direction="column"
        align="center"
        justify="center"
        spacing={2}
        py={16}
      >
        <Text variant="body-bold">{tabConfig?.emptyTitle}</Text>
        <Text variant="body">{tabConfig?.emptyMessage}</Text>
      </Layout>
    );
  };

  const renderErrorMessage = (): JSX.Element => (
    <Layout
      direction="column"
      align="center"
      justify="center"
      spacing={2}
      py={4}
    >
      <Text variant="body-bold">An error occurred retrieving tickets.</Text>
      <Text variant="body">
        Refresh the page or reach out to out support team if the issue persists.
      </Text>
    </Layout>
  );

  const render404Message = (): JSX.Element => (
    <Layout
      direction="column"
      align="center"
      justify="center"
      spacing={2}
      py={4}
    >
      <Text variant="body-bold">No tickets match your filter.</Text>
      <Text variant="body">
        You can create a new ticket or try a different filter.
      </Text>
    </Layout>
  );

  const turnTrackingColumns = [
    {
      key: 'current party',
      cellType: 'chips',
      title: 'Current Party',
      width: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => {
        const statusChip = {
          text: ticket.currentParty,
          status: 'info',
        };
        return {
          chips: [statusChip],
        };
      },
    },
    {
      key: 'turns',
      align: 'center',
      cellType: 'number',
      title: 'Turns',
      width: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => ({ number: ticket.turnsCount }),
    },
    {
      key: 'time with current party',
      cellType: 'text',
      title: 'Time with Current Party',
      width: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => {
        const startDate = ticket?.currentTurnStartDate;
        if (!startDate) return {};
        const elapsedDays = Math.round(
          // convert milliseconds to days
          (new Date().valueOf() - startDate.valueOf()) / 8.64e7,
        );
        return { text: `${elapsedDays} days` };
      },
    },
  ];

  const columns = [
    {
      key: 'name',
      cellType: 'link',
      title: 'Name',
      mapCellProps: (ticket: TableTicket) => ({
        text: ticket.name,
        pathname: `/workflow/tickets/${ticket.id}`,
      }),
    },
    {
      key: 'status',
      cellType: 'steps',
      title: 'Status',
      width: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => {
        return {
          isCompleted: ticket.isCompleted,
          isCancelled: ticket.isCancelled,
          currentStepValue: ticket.currentStageName,
          defaultStepLabel: capitalizeWords(ticket.status),
          steps: stageOrder
            .filter((stage: string) => {
              return ticket.enabledStages.includes(stage as TicketStageType);
            })
            .map((stage: string) => {
              return {
                label: capitalizeWords(stage),
                value: stage,
              };
            }),
        };
      },
    },
    {
      key: 'submitted by',
      cellType: 'user',
      title: 'Submitted By',
      minWidth: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => ({
        asyncUser: {
          id: ticket.creator,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'submitted on',
      cellType: 'datetime',
      title: 'Submitted On',
      minWidth: 'm',
      disableResizing: true,
      mapCellProps: (ticket: TableTicket) => {
        return {
          datetime: ticket.createdDate ? ticket.createdDate : undefined,
          format: 'full_datetime',
        };
      },
      width: 'm',
    },
    {
      key: 'last activity',
      cellType: 'datetime',
      title: 'Last Activity',
      width: 'm',
      disableResizing: true,
      mapCellProps: ({ lastActivityDate }: TableTicket) => ({
        datetime: parseDate(lastActivityDate, refactorMoment),
        format: 'relative',
      }),
    },
    {
      key: 'assigned to',
      cellType: 'users',
      title: 'Assigned To',
      width: 'm',
      align: 'left',
      disableResizing: true,
      disableSortBy: true,
      mapCellProps: (ticket: TableTicket) => {
        const users = usersData
          ? ticket.assignees.reduce((acc, id) => {
              if (usersData[id]) {
                acc.push(usersData[id]);
              }
              return acc;
            }, [] as types.User[])
          : [];

        return {
          users,
          limit: 4,
        };
      },
    },
    {
      key: 'participants',
      cellType: 'users',
      title: 'Participants',
      width: 'm',
      align: 'left',
      disableResizing: true,
      disableSortBy: true,
      mapCellProps: (ticket: TableTicket) => {
        const users = usersData
          ? ticket.participants.reduce((acc, id) => {
              if (usersData[id]) {
                acc.push(usersData[id]);
              }
              return acc;
            }, [] as types.User[])
          : [];

        return {
          users,
          limit: 4,
        };
      },
    },
    ...(isTurnTrackingEnabled ? turnTrackingColumns : []),
  ];

  const handleUpdate = (state: any, action: any) => {
    switch (action.type) {
      case 'toggleSortBy':
        if (state.sortBy.length) {
          const { id, desc } = state.sortBy[0];

          if (desc) {
            updatePageSearchQuery(
              { sortedOrder: SORT_DESC, sortedColumn: id },
              true,
            );
          } else {
            updatePageSearchQuery(
              { sortedOrder: SORT_ASC, sortedColumn: id },
              true,
            );
          }

          setSortBy(state.sortBy[0]);
        }
        break;
      default:
        return;
    }
  };

  const handlePagination = ({ pageIndex }: any) => {
    setPageIndex(pageIndex);
    updatePageSearchQuery({ page: pageIndex }, true);
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    setPageIndex(PAGE_START);
  };

  const handlePageSize = (value: number) => {
    updatePageSearchQuery({ pageSize: value }, true);
    setPageSize(value);
  };

  const handleOpenTurnTrackingPanel = async (ticketId: Uuid) => {
    setTicketId(ticketId);
    // TODO: setWorkflowId if necessary in line with parties discussion - phase 1 Turn Tracking
    setWorkflowId(uuid.v4());
    setShowTurnTrackingPanel(true);
  };

  const getTable = (tableData: TableTicket[], count: number) => {
    return (
      <PersistedTable
        name="tickets"
        context={TableContextType.WorkflowTickets}
        columns={columns}
        data={tableData}
        options={{
          enableSearch: true,
          enableExportXlsx: false,
          enableManageColumns: true,
          enableSelectRows: false,
          enablePagination: true,
          enablePageSizeSelect: true,
          enableEmptyState: true,
        }}
        state={{
          columnOrder: tableSettings?.columnOrder,
          globalFilter: searchText,
          pageSize,
          pageIndex,
          sortBy: [sortBy],
        }}
        onPaginate={handlePagination}
        onPageSizeChange={handlePageSize}
        onUpdate={handleUpdate}
        isLoading={isFetching}
        onSearch={handleSearch}
        reactTableOptions={{
          autoResetSortBy: false,
          disableSortRemove: true,
          manualSortBy: true,
        }}
        totalCount={count}
        searchText={searchText}
        {...(isTurnTrackingEnabled && {
          rowActions: [
            {
              label: 'Cycle time tracker',
              onClick: (row: any) => handleOpenTurnTrackingPanel(row.id),
            },
          ],
        })}
      />
    );
  };

  const getCount = (tab: string) => {
    let count;
    switch (tab) {
      case 'Needs Action':
        count = needsActionCount;
        break;
      case 'In Progress':
        count = inProgressCount;
        break;
      case 'Cancelled':
        count = cancelledCount;
        break;
      default:
        count = undefined;
    }
    return count;
  };

  const getPanel = () => {
    const is404Error =
      coerceRtkqError(error)?.response?.data?.statusCode === 404;
    const shouldRenderTable =
      ticketTableData.length > 0 ||
      initWorkFlowOnlyLoad ||
      isFetchingData ||
      searchText.length > 0;
    if (error) return is404Error ? render404Message() : renderErrorMessage();
    return shouldRenderTable
      ? getTable(ticketTableData, count)
      : renderEmptyTabContent(
          (activeTab as TicketCompletionType) ||
            TicketCompletionType.NeedsAction,
        );
  };

  // Deriving conditional page tabs
  const count = data.count;
  const tickets = data.data;
  const isFetchingData = isFetching;
  const ticketTableData: TableTicket[] = tickets;
  const initWorkFlowOnlyLoad = isWorkflowOnlyUser && initRender;
  const tabConfigs = Object.values(tabsConfig);
  const tabs: types.Tab[] = tabConfigs
    .filter((config) => !config.featureFlag || flags[config.featureFlag])
    .map((config) => ({
      label: config.label,
      value: String(config.value),
      count: getCount(config.label),
      panel: getPanel(),
    }));

  if (shouldShowCreateTicketList) {
    return (
      <WizardWorkflowTemplatesList
        templatesData={templates}
        hideModal={() => handleShowCreateTicketList(false)}
      />
    );
  }

  return (
    <>
      <PageLayout
        actions={[
          {
            isLoading: isTemplatesLoading,
            text: 'New Ticket',
            level: 'primary',
            onClick: () => handleShowCreateTicketList(true),
            disabled: !templates || !templates.length,
          },
        ]}
        loadingContent={{
          isLoading: shouldDisplayLoadingState,
          message: 'Loading tickets…',
        }}
        tabs={{
          enableContentPadding: false,
          tabs,
          selectedTab: String(activeTab),
          onSelectTab: handleTabClick,
        }}
        title="Tickets"
      />
      {showTurnTrackingPanel && ticketId && workflowId && (
        <TurnTrackingPanel
          ticketId={ticketId}
          workflowId={workflowId}
          position="right"
          width="m"
          isHidden={!showTurnTrackingPanel}
          onHide={() => {
            setShowTurnTrackingPanel(false);
            setTicketId('');
            setWorkflowId('');
            dispatch(actions.resetTurnTracking());
          }}
        />
      )}
    </>
  );
};

export default withFlags(TicketListV2);
