import { noop } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Text, useToast } from '~/eds';
import { actions, selectors } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

/**
 * A modal to redirect from other domains to creating Field AI models
 * @returns
 */
export const RedirectToXRayModal = () => {
  const { history } = useRouting();

  const dispatch = useDispatch();

  const redirect = useSelector(selectors.selectFieldAiRedirect);

  const { toast } = useToast();

  const clearRedirect = () => dispatch(actions.setFieldAiRedirect(null));

  const primaryAction = {
    text: 'Confirm',
    onClick: () => {
      if (redirect) {
        dispatch(actions.resetFieldAi());
        dispatch(actions.setFieldAiInstructions(redirect.instructions));
        history.push(`${RoutePathType.AutomationHubFields}/new`);
        clearRedirect();
        toast({
          status: 'info',
          title: 'Instructions for the model have been auto-populated',
          message: 'You can edit the instructions.',
          onDismiss: noop,
        });
      }
    },
  };

  return (
    <Modal
      isVisible={redirect !== null}
      primaryAction={primaryAction}
      title="Use This Question for a New Field Model"
      onCancel={clearRedirect}
    >
      <Text>
        <p>
          Would you like to create a New Field Model based on the question
          selected?
        </p>
        <p>
          This action will take you to the place to create a New Field Model
          powered by AI based on the question you selected.
        </p>
      </Text>
    </Modal>
  );
};
