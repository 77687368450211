import { trace } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { snakeCase } from 'lodash';

import { isDev } from '~/dev';

export const contextPropagationUrlMatcher = isDev // <= Note: test is not considered local
  ? /localhost/
  : /([app|auth|clients]\.evisort\.[com|dev])|evisort-service\.com/;

export const ignoreUrlMatchers = [/sentry-public|editor|google/];

export const SERVICE_NAME = 'evisort-ui';

let provider: WebTracerProvider;

interface InitOptions {
  clientId: number;
  userId: number;
}

/**
 * Setup the tracing provider per https://opentelemetry.io/docs/instrumentation/js/instrumentation
 * It also enables several auto instrumentations.
 * For manual instrumentation, we need to expose the provider to acquire a tracer, see:
 * https://opentelemetry.io/docs/instrumentation/js/instrumentation/#acquiring-a-tracer
 * @returns provider WebTracerProvider
 */
export const init = ({ clientId, userId }: InitOptions) => {
  if (provider) {
    return;
  }

  const COLLECTOR_BASE_URL = (window as any)._env_.OTEL_EXPORTER_OTLP_ENDPOINT;
  const COLLECTOR_TRACES_URL = COLLECTOR_BASE_URL + '/v1/traces';

  const collectorOptions = {
    url: COLLECTOR_TRACES_URL,
    // url: 'http://otel-collector:4318', // url is optional and can be omitted - default is http://localhost:4318/v1/traces
    // headers: {}, // an optional object containing custom headers to be sent with each request
    // concurrencyLimit: 10, // an optional limit on pending requests
  };

  provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: SERVICE_NAME,
      client_id: clientId,
      user_id: userId,
    }),
  });

  const exporter = new OTLPTraceExporter(collectorOptions);

  provider.addSpanProcessor(
    new BatchSpanProcessor(exporter, {
      // The maximum queue size. After the size is reached spans are dropped.
      maxQueueSize: 50,
      // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
      maxExportBatchSize: 5,
      // The interval between two consecutive exports
      scheduledDelayMillis: 250,
      // How long the export can run before it is cancelled
      exportTimeoutMillis: 30000,
    }),
  );
  // Note: For local one could consider using the SimpleSpanProcessor here as it sends the spans immediately to the
  // exporter without delay, instead of the BatchSpanProcessor above, which is preferred for production.
  // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter(collectorOptions)));

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  // Set trace global tracer to be able to manual collect spans
  trace.setGlobalTracerProvider(provider);

  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: contextPropagationUrlMatcher,
        ignoreUrls: ignoreUrlMatchers,
        clearTimingResources: true,
      }),
      new UserInteractionInstrumentation(),
      new XMLHttpRequestInstrumentation({
        propagateTraceHeaderCorsUrls: contextPropagationUrlMatcher,
        ignoreUrls: ignoreUrlMatchers,
        clearTimingResources: true,
      }),
    ],
  });
};

interface TraceSpan {
  name: string;
  additionalAttributes?: Record<string, any>;
}

export const createTraceSpan = ({ name, additionalAttributes }: TraceSpan) => {
  const tracer = trace.getTracer(SERVICE_NAME);
  const span = tracer.startSpan(name);
  if (additionalAttributes) {
    const snakedCasedAttributes = Object.entries(additionalAttributes).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [`app.${snakeCase(key)}`]: value,
        };
      },
      {},
    );
    span.setAttributes(snakedCasedAttributes);
  }
  return span;
};
