import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { samlConfigsSet, samlFormReset } from '~/actions';
import { fetchSAMLConfigs } from '~/api';
import { Button, Card, Layout, PageLayout, Table, Text } from '~/eds';
import { OnboardingIdType } from '~/features/onboarding';
import { useAsync } from '~/hooks';
import { generateNav, RoutePathType } from '~/routing';

const options = {
  enableExportXlsx: false,
  enablePageSizeSelect: false,
  enablePagination: false,
  enableManageColumns: false,
  enableSearch: false,
  enableSelectRows: false,
};

function Page({
  saml,
  // actions
  samlConfigsSet,
  samlFormReset,
  currentUser,
}) {
  const history = useHistory();
  const location = useLocation();
  const { clientId } = useParams();

  const { isLoading } = useAsync(
    fetchSAMLConfigs,
    { clientId },
    {
      condition: clientId,
      deps: [clientId],
      successHandler: (response) => {
        samlFormReset();
        samlConfigsSet(response.results);
      },
    },
  );

  const onCreateConfig = () => {
    history.push(`${location.pathname}/form/new`);
  };

  const onEditConfig = (config) => {
    history.push(`${location.pathname}/form/${config.id}`);
  };

  const nav = generateNav({
    current: {
      text: 'Single Sign-On (SAML)',
      pathname: RoutePathType.AdminConsoleClientSamlConfiguration,
    },
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: currentUser.client,
    },
  });

  const rowActions = [
    {
      label: 'Edit configuration',
      onClick: onEditConfig,
    },
  ];

  const data = saml.configs;

  const columns = [
    {
      key: 'name',
      cellType: 'link',
      title: 'Name',
      mapCellProps: (d) => {
        return {
          text: d.idp_name,
          onClick: () => onEditConfig(d),
        };
      },
    },

    {
      key: 'lastUpdated',
      cellType: 'datetime',
      title: 'Last Updated',
      mapCellProps: (config) => {
        return {
          datetime: config.date_updated ? config.date_updated : undefined,
          format: 'full_datetime',
        };
      },
      width: 'auto',
    },
  ];

  const renderConfigTables = () => {
    return (
      <Table
        name="SAML"
        columns={columns}
        data={data}
        options={options}
        rowActions={rowActions}
      />
    );
  };

  const renderConfigCreation = () => {
    return (
      <Card mode="bordered">
        <Layout direction="column" align="center" spacing={6}>
          <Text color="text.primary" variant="subtitle">
            Single Sign-On has not been configured.
          </Text>
          <Text preset="description">
            Tap on the button below to configure and manage SAML-based Single
            Sign-On for your organization.
          </Text>
          <Button
            id={OnboardingIdType.AddSamlConfiguration}
            text="Add Configuration"
            variant="primary"
            onClick={onCreateConfig}
          ></Button>
        </Layout>
      </Card>
    );
  };
  return (
    <PageLayout
      title="Single Sign-On (SAML)"
      nav={nav}
      loadingContent={{ isLoading }}
    >
      {saml.configs.length === 0
        ? renderConfigCreation()
        : renderConfigTables()}
    </PageLayout>
  );
}

const mapStateToProps = ({ currentUser, client, saml }) => ({
  currentUser,
  client,
  saml,
});

export default connect(mapStateToProps, { samlConfigsSet, samlFormReset })(
  Page,
);
