import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ticketDocumentSet,
  ticketDocumentVersionsSet,
  ticketSummarySet,
} from '~/actions';
import { getTicketDocumentVersions } from '~/api';
import binocularsSrc from '~/assets/images/cta/binoculars.svg';
import flagSrc from '~/assets/images/cta/flag.svg';
import { getNextStageButtonText } from '~/components/Workflow/TicketsViewPage/TicketDocumentActions/TicketDocumentActions';
import { StageConfig } from '~/constants/ticket';
import { useToast } from '~/eds';
import { TicketPermissionType, TicketStageType } from '~/enums';
import { withUsers } from '~/hocs';
import { getIsNextStageDisabled } from '~/reducers/ticket';
import { api } from '~/redux';
import { CtaSection, FlexLayout, WizardPageLayout } from '~/ui';
import { getEnabledStages, getNextStageType } from '~/utils/ticket';

import SendTicketActionCta from './SendTicketActionCta';
import ShareAndDownloadCta from './ShareAndDownloadCta';

/* UX wants the title to take half of the space to the left of the Select Workflow header, which should be 325px. */
const TITLE_MAX_WIDTH = 325;

const stageToViewPermissionMap = {
  [TicketStageType.Edit]: TicketPermissionType.EditView,
  [TicketStageType.Review]: TicketPermissionType.ReviewView,
  [TicketStageType.Sign]: TicketPermissionType.SignView,
  [TicketStageType.Finalize]: TicketPermissionType.FinalizeView,
  [TicketStageType.Ticket]: TicketPermissionType.TicketView,
};

const stageToStringMap = {
  [TicketStageType.Review]: 'Review',
  [TicketStageType.Sign]: 'Signatures',
  [TicketStageType.Finalize]: 'Finalization',
};

const WizardActionPage = ({
  ticketId,
  intakeForm,
  // connected
  ticket,
  users,
}) => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const history = useHistory();
  const { isCompleted, permissions, stage } = ticket;
  const shouldShowShare = permissions?.includes(TicketPermissionType.Share);
  const shouldShowDownload = permissions?.includes(
    TicketPermissionType.Download,
  );
  const shouldShowShareAndDownloadCta = shouldShowShare || shouldShowDownload;

  const stages = getEnabledStages(ticket);
  const isNextStageDisabled = getIsNextStageDisabled(ticket, users);
  const nextStageText = getNextStageButtonText(stage, stages);
  const sendActionPermission =
    permissions?.includes(TicketPermissionType.SendForReview) ||
    permissions?.includes(TicketPermissionType.SendForSignatures) ||
    permissions?.includes(TicketPermissionType.SendForFinalization);
  const shouldShowSendTicketActionCta =
    nextStageText && !isNextStageDisabled && sendActionPermission;

  let requiredViewPermission = stageToViewPermissionMap[stage];
  if (stage === TicketStageType.Finalize && isCompleted) {
    requiredViewPermission = TicketPermissionType.CompletedView;
  }
  const shouldShowViewTicketCta = permissions?.includes(requiredViewPermission);

  const ctaCount =
    shouldShowSendTicketActionCta +
    shouldShowShareAndDownloadCta +
    shouldShowViewTicketCta;
  const hasOnlyOneCta = ctaCount === 1;
  const stageString = StageConfig[stage]?.label;
  const nextStageType = getNextStageType(stage, stages);
  const nextStageString = stageToStringMap[nextStageType];
  const ticketStatus =
    stage === TicketStageType.Ticket
      ? 'Your ticket has been created'
      : 'Your ticket has been created in the ' + stageString + ' stage';
  const ctaTitle = hasOnlyOneCta && ticketStatus;
  const pageDescription = ctaCount > 1 && (
    <>
      <p>{ticketStatus}</p>
      <p>How would you like to proceed?</p>
    </>
  );
  let viewTicketDescription = 'View your ticket';
  if (stage !== TicketStageType.Review && nextStageString) {
    viewTicketDescription +=
      ' before submitting it for ' + nextStageString + '.';
  }

  const getDocumentVersions = async (documentId) => {
    const upToDateVersions = await getTicketDocumentVersions(documentId);
    dispatch(ticketDocumentVersionsSet(upToDateVersions));
  };

  // loading ticket information

  const {
    data: ticketDocumentData = {},
    isSuccess: isTicketDocumentSuccess,
    fulfilledTimeStamp: ticketDocumentFulfilledTimeStamp,
  } = api.endpoints.getTicketDocument.useQuery({
    ticketId,
  });

  const {
    data: ticketSummaryData = {},
    isSuccess: isTicketSummarySuccess,
    fulfilledTimeStamp: ticketSummaryFulfilledTimeStamp,
  } = api.endpoints.getTicketSummary.useQuery({
    ticketId,
  });

  const handleTicketError = () => {
    toast({
      message: 'There has been an error when loading this ticket.',
      type: 'error',
    });
  };

  // onSuccess for the ticketDocument fetch
  useEffect(() => {
    if (isTicketDocumentSuccess) {
      try {
        dispatch(ticketDocumentSet(ticketDocumentData));
        getDocumentVersions(ticketDocumentData.id);
      } catch {
        handleTicketError();
      }
    }
  }, [ticketDocumentFulfilledTimeStamp]);

  // onSuccess for the ticketSummary fetch
  useEffect(() => {
    if (isTicketSummarySuccess) {
      try {
        dispatch(ticketSummarySet(ticketSummaryData));
      } catch {
        handleTicketError();
      }
    }
  }, [ticketSummaryFulfilledTimeStamp]);

  const handeViewTicket = () => {
    history.push(`/workflow/tickets/${ticketId}`);
  };

  const handleClose = () => {
    history.push('/workflow/tickets');
  };

  if (ctaCount === 0) {
    return (
      <WizardPageLayout
        currentStep="Create Ticket"
        steps={['Select Workflow', 'Create Ticket']}
        title={{ text: intakeForm.name, maxWidth: TITLE_MAX_WIDTH }}
        onClose={handleClose}
      >
        <CtaSection
          actionButton={{
            text: 'Return to Tickets',
            onClick: handleClose,
          }}
          imageSrc={flagSrc}
          title={
            'Your ticket has been created and will be saved in the ' +
            stageString +
            ' stage.'
          }
        />
      </WizardPageLayout>
    );
  }

  return (
    <WizardPageLayout
      currentStep="Take Action"
      steps={['Select Workflow', 'Create Ticket', 'Take Action']}
      title={{ text: intakeForm.name, maxWidth: TITLE_MAX_WIDTH }}
      onClose={handleClose}
      description={pageDescription}
    >
      <FlexLayout spacing={3} justifyContent="center">
        {shouldShowSendTicketActionCta && (
          <SendTicketActionCta
            ticketId={ticketId}
            nextStageString={nextStageString}
            title={ctaTitle}
          />
        )}
        {shouldShowShareAndDownloadCta && (
          <ShareAndDownloadCta
            ticketId={ticketId}
            shouldShowShare={shouldShowShare}
            shouldShowDownload={shouldShowDownload}
            stage={stage}
            nextStageString={nextStageString}
            title={ctaTitle}
          />
        )}
        {shouldShowViewTicketCta && (
          <CtaSection
            actionButton={{
              text: 'View',
              onClick: handeViewTicket,
            }}
            title={ctaTitle}
            description={viewTicketDescription}
            imageSrc={binocularsSrc}
          />
        )}
      </FlexLayout>
    </WizardPageLayout>
  );
};

const mapStateToProps = ({ ticket }) => ({
  ticket,
});

export default withUsers(connect(mapStateToProps)(WizardActionPage));
