import { sortBy } from 'lodash';

import { zIndices } from '../../tokens';
import { Option } from '../../types';

// coerce a value to an option
export const coerceOption = <V extends unknown>(
  value: V,
  config: {
    isLoading?: boolean;
    shouldAllowInvalidValue: boolean;
  },
) => ({
  label: config.isLoading
    ? 'Loading value…'
    : config.shouldAllowInvalidValue
    ? String(value)
    : 'Removed value',
  value,
});

// typecast-friendly options.find asserting that an option is always found
export const findOption = <V extends unknown>(
  options: Option<V>[],
  shouldAllowInvalidValue: boolean,
  isLoading?: boolean,
) => (value: V): Option<V> => {
  const foundOption = (testIsOptionsGrouped(options)
    ? options.map((option) => option.options ?? []).flat()
    : options
  ).find((option) => option.value === value);
  return (
    foundOption ??
    coerceOption(value, {
      isLoading,
      shouldAllowInvalidValue,
    })
  );
};

export const filterGroupedOptions = <V extends unknown>(
  options: Option<V>[],
  filter: (option: Option<V>) => boolean,
) => {
  return testIsOptionsGrouped(options)
    ? options.map((groupedOption) => ({
        ...groupedOption,
        options: groupedOption.options!.filter(filter),
      }))
    : options.filter(filter);
};

export const getStyles = ({
  isEmbedded,
  isWidthRelative,
}: {
  isEmbedded?: boolean;
  isWidthRelative?: boolean;
}) => ({
  container: (base: any) => ({
    ...base,
    flex: isWidthRelative ? 1 : 'none',
  }),
  menu: isEmbedded
    ? () => ({
        position: 'relative',
      })
    : undefined,
  menuPortal: (base: any) => ({ ...base, zIndex: zIndices.popover }),
});

export const sortOptions = <V extends unknown>(
  options: Option<V>[],
  value: V | V[] | null,
): Option<V>[] => {
  const valueSet = new Set(Array.isArray(value) ? value : [value]);
  return sortBy(options, [
    (option) => (valueSet.has(option.value) ? -1 : 1), // prioritize sorting selected values
    'label',
  ]);
};

export const testIsOptionDisabled = <V extends unknown>(option: Option<V>) =>
  Boolean(option.disabled);

// all options must be grouped to use grouped logic.  This is an efficient predicate test as the size of groups is generally small.
export const testIsOptionsGrouped = <V extends unknown>(
  options: Option<V>[],
): boolean =>
  options.length > 0 && options.every((option) => Boolean(option.options));
