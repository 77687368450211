import React, { useEffect, useState } from 'react';

import { Layout, Modal, PageLayout, Text } from '~/eds';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { RoutePathType, useRouting } from '~/routing';
import {
  deletePageSearchParam,
  getPageSearchQueryByKey,
} from '~/utils/searchQuery';

import { getUserName } from '../../utils/user';

const QueryString = 'welcomeMessage';

const onboardingIds = [
  OnboardingIdType.SplashPageCardOne,
  OnboardingIdType.SplashPageCardTwo,
  OnboardingIdType.SplashPageCardThree,
];

export function Welcome() {
  const enableCanduOnboarding = useFlag(FlagType.Onboarding);

  const { history } = useRouting();

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const hasWelcomeMessage = getPageSearchQueryByKey(QueryString, false);

    if (hasWelcomeMessage === 'true') {
      setShowModal(true);
    }
  }, []);

  const currentUser = useCurrentUser();
  const name = getUserName(currentUser);
  const subdomain = currentUser?.client_config?.sub_domain;

  const children = enableCanduOnboarding ? (
    <PageLayout
      actions={[
        {
          level: 'primary',
          text: 'Set up Evisort',
          onClick: () => {
            setShowModal(false);
            deletePageSearchParam(QueryString);
            history.push(RoutePathType.Onboarding);
          },
        },
      ]}
      description="Finish setting up Evisort using our guided configuration wizard and you’ll be up and running."
      title={`Welcome to Evisort${name ? `, ${name}` : ''}!`}
      subtitle="Get ready to experience intelligent contract management."
      welcome={{
        onboardingIds,
        enableLogo: true,
      }}
    />
  ) : (
    <Layout direction="column" spacing={2}>
      <Text>
        Your Evisort workspace is <b>{subdomain}</b>.{' '}
      </Text>
      <Text>
        In the future, you can log in by visiting evisort.com and logging in to
        your workspace, or by bookmarking the current page and navigating
        directly to{' '}
        <b>
          {window.location.origin}/{subdomain}
        </b>{' '}
        in your browser.
      </Text>
    </Layout>
  );

  return (
    <>
      {
        <Modal
          isFullPage={true}
          title={''}
          isVisible={showModal && enableCanduOnboarding}
          cancelText="Skip Steps"
          children={children}
          onHide={() => {
            setShowModal(false);
            deletePageSearchParam(QueryString);
          }}
        />
      }
      {
        <>
          <Modal
            primaryAction={{
              text: 'Start working with Evisort',
              onClick: () => {
                setShowModal(false);
                deletePageSearchParam(QueryString);
              },
            }}
            isVisible={showModal && !enableCanduOnboarding}
            children={children}
            title={`Welcome to Evisort${name ? `, ${name}` : ''}!`}
            onHide={() => {
              setShowModal(false);
              deletePageSearchParam(QueryString);
            }}
          />
        </>
      }
    </>
  );
}
