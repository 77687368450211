/**
 * TODO EKP-3606: formalize ~/pages module and Page components
 **/
import { FlagType } from '~/flags';
import NoMatch from '~/pages/404';
import ClientsPage from '~/pages/admin';
import AdminCreditsPage from '~/pages/admin/[clientId]/credits';
import AdminRolesPage from '~/pages/admin/[clientId]/roles';
import AdminUsersPage from '~/pages/admin/[clientId]/users';
import AdminNavsPage from '~/pages/admin/console/[clientId]';
import AuditLogsPage from '~/pages/admin/console/[clientId]/audit-logs';
import CopyWorkflowPage from '~/pages/admin/console/[clientId]/copy-workflow';
import DataFieldsPage from '~/pages/admin/console/[clientId]/data-fields';
import DepartmentsPage from '~/pages/admin/console/[clientId]/departments';
import DepartmentPage from '~/pages/admin/console/[clientId]/departments/[departmentId]';
import EmailIntakePage from '~/pages/admin/console/[clientId]/email-intake';
import IntegrationsPage from '~/pages/admin/console/[clientId]/integrations';
import PartyListsPage from '~/pages/admin/console/[clientId]/manage-party-lists';
import MoreSettingsPage from '~/pages/admin/console/[clientId]/more-settings';
import DocumentProcessingPage from '~/pages/admin/console/[clientId]/process-documents';
import DocumentsRestore from '~/pages/admin/console/[clientId]/restoration-hub';
import SSOConfigPage from '~/pages/admin/console/[clientId]/saml-configuration';
import SAMLConfigPage from '~/pages/admin/console/[clientId]/saml-configuration/form/[configId]';
import UsersPage from '~/pages/admin/console/[clientId]/users';
import AlertsPage from '~/pages/alerts';
import AnalyzerPage from '~/pages/analyzer';
import AnalyzerResultsPage from '~/pages/analyzer/results';
import AutomationHubPage from '~/pages/automation';
import AutomationHubDetailPage from '~/pages/automation/detail';
import ClauseLibrary from '~/pages/clause-library';
import DashboardsPage from '~/pages/dashboards/[dashboardId]';
import DocumentTreePage from '~/pages/document-group/[groupId]';
import DocumentGroupsPage from '~/pages/document-groups';
import DocumentsViewPage from '~/pages/document/[documentId]';
import DocumentsPage from '~/pages/documents/[folderId]';
import DownloadEmailAttachmentPage from '~/pages/download-email-attachment';
import DownloadExportReportPage from '~/pages/download-export-report';
import TicketEmailApproveConfirmation from '~/pages/email-action-approve-succeeds';
import TicketEmailRejectConfirmation from '~/pages/email-action-reject-succeeds';
import Fields from '~/pages/fields';
import FieldModel from '~/pages/fields/[modelId]';
import PermissionFromSSOPage from '~/pages/folders-permission-check';
import TicketEmailActionError from '~/pages/invalid-email-action-token';
import LoginPage from '~/pages/login';
import LoginEmbedPage from '~/pages/login/embed';
import LoginEmbedSuccessPage from '~/pages/login/embed/success';
import Onboarding from '~/pages/onboarding';
import RBACPage from '~/pages/rbac';
import ReviewerPage from '~/pages/reviewer';
import ReviewerAdminPage from '~/pages/reviewer/admin';
import ReviewersViewPage from '~/pages/reviewer/data-field-review';
import SavedDashboards from '~/pages/saved-dashboards';
import SavedSearches from '~/pages/saved-searches';
import SearchV2 from '~/pages/search';
import SettingsPage from '~/pages/settings';
import SuperAdminUsersPage from '~/pages/super-admin/users';
import TicketEmailApproveComment from '~/pages/tickets/[ticketId]/judgment-results/[judgementResultId]/[jwt]/approve';
import TicketEmailRejectComment from '~/pages/tickets/[ticketId]/judgment-results/[judgementResultId]/[jwt]/reject';
import Unauthorized from '~/pages/unauthorized';
import UploadPage from '~/pages/upload';
import WordOnlineErrorPage from '~/pages/word-online-invalid-subscription';
import WordOnlinePage from '~/pages/word-online/[file-id]';
import WizardTicketForm from '~/pages/workflow/[workflowId]/intake-form/link';
import WorkflowBuilderDashboardPage from '~/pages/workflow/builder';
import WorkflowBuilderPage from '~/pages/workflow/builder/[workflowId]';
import WorkflowTicketsPage from '~/pages/workflow/tickets';
import TicketsViewPage from '~/pages/workflow/tickets/[ticketId]';
import TicketReviewerPage from '~/pages/workflow/tickets/[ticketId]/reviewer/[versionNumber]';
import * as permissions from '~/permissions';
import {
  createAdminResolvers,
  createDefaultResolvers,
  createPassthroughResolvers,
  createSuperAdminResolvers,
  createWorkflowAdminResolvers,
  createWorkflowResolvers,
  Route,
  RoutePathType,
  Routes,
} from '~/routing';

const privateRoutes: Partial<Routes> = {
  [RoutePathType.Home]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Home,
    title: 'Home',
    resolvers: [
      {
        permission: permissions.checkIsRbac,
        component: RBACPage,
      },
      {
        permission: permissions.checkIsReviewer,
        redirectPath: RoutePathType.Reviewer,
      },
      {
        permission: permissions.checkIsWorkflowOnly,
        redirectPath: RoutePathType.WorkflowTickets,
      },
      {
        permission: permissions.canUseSearchAsHomePage,
        redirectPath: RoutePathType.Search,
      },
      {
        redirectPath: RoutePathType.Documents,
      },
    ],
  },
  [RoutePathType.Admin]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Admin,
    title: 'Admin Console',
    resolvers: createAdminResolvers(ClientsPage),
  },
  [RoutePathType.AdminConsole]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AdminConsole,
    title: 'Admin Console',
    resolvers: createAdminResolvers(AdminNavsPage),
  },
  [RoutePathType.AdminConsoleClient]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AdminConsoleClient,
    title: 'Admin Console',
    resolvers: createAdminResolvers(AdminNavsPage),
  },
  [RoutePathType.AdminConsoleClientAuditLogs]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientAuditLogs,
    title: 'Audit Logs',
    permissions: [
      {
        resourceId: 'audit_logs',
        resourceType: 'view',
      },
    ],
    resolvers: [
      {
        permission: permissions.canUseAuditLogs,
        component: AuditLogsPage,
      },
      {
        redirectPath: RoutePathType.AdminConsoleClient,
      },
    ],
  },
  [RoutePathType.AdminConsoleClientCopyWorkflow]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientCopyWorkflow,
    title: 'Copy Workflow',
    resolvers: createSuperAdminResolvers(CopyWorkflowPage),
  },
  [RoutePathType.AdminConsoleClientDataFields]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientDataFields,
    title: 'Data Fields',
    resolvers: createAdminResolvers(DataFieldsPage),
    permissions: [
      {
        resourceId: 'data_fields',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientDepartments]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientDepartments,
    title: 'Departments',
    resolvers: createAdminResolvers(DepartmentsPage),
    permissions: [
      {
        resourceId: 'departments',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientDepartmentsDepartment]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientDepartmentsDepartment,
    title: 'Department Details',
    resolvers: createAdminResolvers(DepartmentPage),
    permissions: [
      {
        resourceId: 'departments',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientEmailIntake]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientEmailIntake,
    title: 'Email Intake',
    resolvers: [
      {
        permission: permissions.checkIsAdmin,
        component: EmailIntakePage,
      },
      {
        redirectPath: RoutePathType.AdminConsoleClient,
      },
    ],
    permissions: [
      {
        resourceId: 'email_intake',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientIntegrations]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientIntegrations,
    title: 'Integrations',
    resolvers: createAdminResolvers(IntegrationsPage),
    permissions: [
      {
        resourceId: 'integrations',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientManagePartyLists]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientManagePartyLists,
    title: 'Manage Party Lists',
    resolvers: createAdminResolvers(PartyListsPage),
  },
  [RoutePathType.AdminConsoleClientMoreSettings]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientMoreSettings,
    title: 'More Settings',
    resolvers: createAdminResolvers(MoreSettingsPage),
    permissions: [
      {
        resourceId: 'root_folder_access_settings',
        resourceType: 'view',
      },
      {
        resourceId: 'documents_metadata_bulk_upload',
        resourceType: 'view',
      },
      {
        resourceId: 'clause_samples',
        resourceType: 'view',
      },
      {
        resourceId: 'deleted_documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientProcessDocuments]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientProcessDocuments,
    title: 'Process Documents',
    resolvers: createSuperAdminResolvers(DocumentProcessingPage),
  },
  [RoutePathType.AdminConsoleClientRestorationHub]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientRestorationHub,
    title: 'Restoration Hub',
    resolvers: [
      {
        permission: permissions.canUseRestorationHub,
        component: DocumentsRestore,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'restoration_hub',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientSamlConfiguration]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientSamlConfiguration,
    title: 'SAML Configuration',
    resolvers: createAdminResolvers(SSOConfigPage),
    permissions: [
      {
        resourceId: 'sso',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientSamlConfigurationFormConfig]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientSamlConfigurationFormConfig,
    title: 'SAML Configuration',
    resolvers: createAdminResolvers(SAMLConfigPage),
    permissions: [
      {
        resourceId: 'sso',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminConsoleClientUsers]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminConsoleClientUsers,
    title: 'Manage Users',
    resolvers: createAdminResolvers(UsersPage),
    permissions: [
      {
        resourceId: 'users',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminClientCredits]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AdminClientCredits,
    title: 'Credits',
    resolvers: createAdminResolvers(AdminCreditsPage),
  },
  [RoutePathType.AdminRoles]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminRoles,
    title: 'Roles',
    resolvers: createDefaultResolvers(AdminRolesPage),
    permissions: [
      {
        resourceId: 'roles',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AdminUsers]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AdminUsers,
    title: 'Users',
    resolvers: [
      {
        permission: permissions.canUseUsersPage,
        component: AdminUsersPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'users',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Alerts]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.Alerts,
    title: 'Alerts',
    resolvers: [
      {
        permission: permissions.checkIsWorkflowOnly,
        redirectPath: RoutePathType.Home,
      },
      {
        component: AlertsPage,
      },
    ],
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Analyzer]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Analyzer,
    title: 'Analyzer',
    resolvers: createDefaultResolvers(AnalyzerPage),
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AnalyzerResults]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.AnalyzerResults,
    title: 'Analyzer Results',
    resolvers: createDefaultResolvers(AnalyzerResultsPage),
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.ClauseLibrary]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.ClauseLibrary,
    title: 'Clause Library',
    flag: FlagType.ClauseLibrary,
    resolvers: [
      {
        /* TODO: Add isClauseLibraryAdmin permission check */
        //permission: permissions.canUseClauseLibrary,
        component: ClauseLibrary,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'clause_library',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Dashboards]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Dashboards,
    title: 'Dashboards',
    resolvers: createDefaultResolvers(DashboardsPage),
    permissions: [
      {
        resourceId: 'workflows_dashboard',
        resourceType: 'view',
      },
      {
        resourceId: 'documents_dashboard',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AutomationHub]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AutomationHub,
    title: 'Automation',
    resolvers: [
      {
        permission: permissions.canUseAutomation,
        component: AutomationHubPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'clause_samples',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.AutomationHubFields]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AutomationHubFields,
    title: 'Automation',
    resolvers: [
      {
        permission: permissions.canUseAutomation,
        component: Fields,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
  },
  [RoutePathType.AutomationHubFieldModel]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AutomationHubFieldModel,
    title: 'Automation',
    resolvers: [
      {
        permission: permissions.canUseAutomation,
        component: FieldModel,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
  },
  [RoutePathType.AutomationHubDetail]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.AutomationHubDetail,
    title: 'Automation',
    resolvers: [
      {
        permission: permissions.canUseAutomation,
        component: AutomationHubDetailPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'clause_samples',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Onboarding]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Onboarding,
    title: 'Onboarding',
    flag: FlagType.CanduScript,
    resolvers: [
      {
        component: Onboarding,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
  },
  [RoutePathType.Documents]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Documents,
    title: 'Documents',
    resolvers: createDefaultResolvers(DocumentsPage),
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.DocumentsFolder]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.DocumentsFolder,
    title: 'Documents',
    resolvers: [
      {
        permission: permissions.checkIsReviewer,
        redirectPath: RoutePathType.Reviewer,
      },
      {
        permission: permissions.checkIsWorkflowOnly,
        redirectPath: RoutePathType.Home,
      },
      {
        component: DocumentsPage,
      },
    ],
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Document]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.Document,
    title: 'Document Details',
    resolvers: createDefaultResolvers(DocumentsViewPage),
    permissions: [],
  },
  [RoutePathType.DocumentGroups]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.DocumentGroups,
    title: 'Groups',
    resolvers: createDefaultResolvers(DocumentGroupsPage),
    permissions: [
      {
        resourceId: 'document_groups',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.DocumentGroupGroup]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.DocumentGroupGroup,
    title: 'Group details',
    resolvers: createPassthroughResolvers(DocumentTreePage),
    permissions: [
      {
        resourceId: 'document_groups',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.DownloadEmailAttachmentPage]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.DownloadEmailAttachmentPage,
    title: 'Download Email Attachment',
    resolvers: createDefaultResolvers(DownloadEmailAttachmentPage),
  },
  [RoutePathType.DownloadExportReportPage]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.DownloadExportReportPage,
    title: 'Download Excel Export',
    resolvers: createDefaultResolvers(DownloadExportReportPage),
  },
  [RoutePathType.FoldersPermissionCheck]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.FoldersPermissionCheck,
    title: 'Folders Permission Check',
    resolvers: createDefaultResolvers(PermissionFromSSOPage),
  },

  [RoutePathType.MyDeletedDocuments]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.MyDeletedDocuments,
    title: 'My Deleted Items',
    resolvers: createPassthroughResolvers(DocumentsRestore),
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Reviewer]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Reviewer,
    title: 'Reviewer',
    resolvers: [
      {
        permission: permissions.checkIsReviewer,
        component: ReviewerPage,
      },
      {
        permission: permissions.checkIsSuperAdmin,
        component: ReviewerPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
  },
  [RoutePathType.ReviewerAdmin]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.ReviewerAdmin,
    title: 'Reviewer Admin',
    resolvers: createSuperAdminResolvers(ReviewerAdminPage),
  },
  [RoutePathType.ReviewerDataFieldReview]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.ReviewerDataFieldReview,
    title: 'Data Field Review',
    resolvers: [
      {
        permission: permissions.checkIsReviewer,
        component: ReviewersViewPage,
        paramsPredicates: [
          (params) => Boolean(params?.hasOwnProperty('flagged')),
        ],
      },
      {
        permission: permissions.checkIsSuperAdmin,
        component: ReviewersViewPage,
        paramsPredicates: [
          (params) => Boolean(params?.hasOwnProperty('flagged')),
        ],
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
  },
  [RoutePathType.SavedSearches]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.SavedSearches,
    title: 'Saved Searches',
    resolvers: [
      {
        permission: permissions.canUseSearchV2,
        component: SavedSearches,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.SavedDashboards]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.SavedDashboards,
    title: 'Saved Dashboards',
    resolvers: [
      {
        permission: permissions.useDashboardV2,
        component: SavedDashboards,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'workflows_dashboard',
        resourceType: 'view',
      },
      {
        resourceId: 'documents_dashboard',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.SavedSearch]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.SavedSearch,
    title: 'Search',
    flag: FlagType.SearchV3,
    resolvers: [
      {
        permission: permissions.canUseSearchV2,
        component: SearchV2,
      },
      {
        redirectPath: RoutePathType.Search,
      },
    ],
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Search]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.Search,
    title: 'Search',
    resolvers: [
      {
        permission: permissions.canUseSearchV2,
        component: SearchV2,
      },
      {
        redirectPath: RoutePathType.Analyzer,
      },
    ],
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.Settings]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.Settings,
    title: 'Settings',
    resolvers: createPassthroughResolvers(SettingsPage),
  },
  [RoutePathType.SuperAdminUsers]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.SuperAdminUsers,
    title: 'Users',
    resolvers: createSuperAdminResolvers(SuperAdminUsersPage),
  },
  [RoutePathType.Upload]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.Upload,
    title: 'Upload',
    resolvers: [
      {
        permission: permissions.canUseUpload,
        component: UploadPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'documents',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.WorkflowBuilder]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.WorkflowBuilder,
    title: 'Workflow Builder',
    resolvers: createWorkflowAdminResolvers(WorkflowBuilderDashboardPage),
    permissions: [
      {
        resourceId: 'workflow_templates',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.WorkflowBuilderWorkflow]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.WorkflowBuilderWorkflow,
    title: 'Workflow Builder',
    resolvers: createWorkflowAdminResolvers(WorkflowBuilderPage),
    permissions: [
      {
        resourceId: 'workflow_templates',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.WorkflowIntakeFormLink]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.WorkflowIntakeFormLink,
    title: 'Workflow Intake Form',
    resolvers: createWorkflowResolvers(WizardTicketForm),
    permissions: [
      {
        resourceId: 'workflow_tickets',
        resourceType: 'edit',
      },
      {
        resourceId: 'workflow_templates',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.WorkflowTickets]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.WorkflowTickets,
    title: 'Workflow Tickets',
    resolvers: [
      {
        permission: permissions.checkIsWorkflowOnly,
        component: WorkflowTicketsPage,
      },
      {
        permission: permissions.canUseWorkflow,
        component: WorkflowTicketsPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
    permissions: [
      {
        resourceId: 'workflow_tickets',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.WorkflowTicketsTicket]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.WorkflowTicketsTicket,
    title: 'Ticket Details',
    resolvers: [
      {
        permission: permissions.checkIsWorkflowOnly,
        component: TicketsViewPage,
      },
      {
        permission: permissions.canUseWorkflow,
        component: TicketsViewPage,
      },
      {
        redirectPath: RoutePathType.Home,
      },
    ],
  },
  [RoutePathType.WorkflowTicketsTicketReviewerVersionNumber]: {
    isExact: true,
    isPublic: false,
    path: RoutePathType.WorkflowTicketsTicketReviewerVersionNumber,
    title: 'Ticket Version',
    resolvers: createWorkflowResolvers(TicketReviewerPage),
    permissions: [
      {
        resourceId: 'workflow_tickets',
        resourceType: 'view',
      },
    ],
  },
  [RoutePathType.WordOnlineFile]: {
    isExact: false,
    isPublic: false,
    path: RoutePathType.WordOnlineFile,
    title: 'Word for the web',
    resolvers: createWorkflowResolvers(WordOnlinePage),
    permissions: [
      {
        resourceId: 'workflow_tickets',
        resourceType: 'view',
      },
    ],
  },
};

const NoMatchRoute: Route = {
  isExact: true,
  isPublic: true,
  path: RoutePathType.NotFound,
  title: 'Not found',
  resolvers: createPassthroughResolvers(NoMatch),
};

const publicRoutes: Partial<Routes> = {
  [RoutePathType.Login]: {
    isExact: true,
    isPublic: true,
    path: RoutePathType.Login,
    title: 'Log In',
    resolvers: createPassthroughResolvers(LoginPage),
  },
  [RoutePathType.LoginEmbed]: {
    isExact: true,
    isPublic: true,
    path: RoutePathType.LoginEmbed,
    title: 'Log In',
    resolvers: createPassthroughResolvers(LoginEmbedPage),
  },
  [RoutePathType.LoginEmbedSuccess]: {
    isExact: true,
    isPublic: true,
    path: RoutePathType.LoginEmbedSuccess,
    title: 'Log in successful',
    resolvers: createPassthroughResolvers(LoginEmbedSuccessPage),
  },
  [RoutePathType.Unauthorized]: {
    isExact: false,
    isPublic: true,
    path: RoutePathType.Unauthorized,
    title: 'Unauthorized',
    resolvers: createPassthroughResolvers(Unauthorized),
  },
  [RoutePathType.TicketEmailApproveConfirmation]: {
    isExact: false,
    isPublic: true,
    path: RoutePathType.TicketEmailApproveConfirmation,
    title: 'Ticket Approve Via Email',
    resolvers: createPassthroughResolvers(TicketEmailApproveConfirmation),
  },
  [RoutePathType.TicketEmailRejectConfirmation]: {
    isExact: false,
    isPublic: true,
    path: RoutePathType.TicketEmailRejectConfirmation,
    title: 'Ticket Rejct Via Email',
    resolvers: createPassthroughResolvers(TicketEmailRejectConfirmation),
  },
  [RoutePathType.TicketEmailActionError]: {
    isExact: false,
    isPublic: true,
    path: RoutePathType.TicketEmailActionError,
    title: 'Ticket Action Link Invalid',
    resolvers: createPassthroughResolvers(TicketEmailActionError),
  },
  [RoutePathType.TicketEmailApproveComment]: {
    isExact: false,
    isPublic: true,
    path: RoutePathType.TicketEmailApproveComment,
    title: 'Ticket Approve With Comment Via Email',
    resolvers: createPassthroughResolvers(TicketEmailApproveComment),
  },
  [RoutePathType.TicketEmailRejectComment]: {
    isExact: false,
    isPublic: true,
    path: RoutePathType.TicketEmailRejectComment,
    title: 'Ticket Rejct With Comment Via Email',
    resolvers: createPassthroughResolvers(TicketEmailRejectComment),
  },
  [RoutePathType.WordOnlineError]: {
    isExact: true,
    isPublic: true,
    path: RoutePathType.WordOnlineError,
    title: 'Word for the web Error',
    resolvers: createPassthroughResolvers(WordOnlineErrorPage),
  },
};

export const routes = {
  ...publicRoutes,
  ...privateRoutes,
  [RoutePathType.NotFound]: NoMatchRoute, // this should always be specified last
} as Routes;
