import React from 'react';

import { IconButton, Menu } from '~/eds';

interface Props {
  openTagClauseModal: () => void;
  openRemoveProvisionModal: () => void;
  selectedProvision: string | null;
}

export const EditProvision = ({
  openTagClauseModal,
  openRemoveProvisionModal,
  selectedProvision,
}: Props) => {
  const items = [];
  if (!!selectedProvision) {
    items.push(
      {
        label: 'Edit Provision',
        value: 'edit',
        onClick: openTagClauseModal,
        leftIcon: 'edit' as const,
        ariaLabel: 'edit provision',
      },
      {
        label: 'Remove Provision',
        value: 'remove',
        onClick: openRemoveProvisionModal,
        leftIcon: 'trash' as const,
        ariaLabel: 'remove provision',
      },
    );
  } else {
    items.push({
      label: 'Tag a Clause',
      onClick: openTagClauseModal,
      value: 'tag',
      leftIcon: 'tag' as const,
      ariaLabel: 'tag clause',
    });
  }

  return (
    <Menu
      name="edit provisions"
      trigger={<IconButton icon="edit" />}
      options={items}
    />
  );
};
