import { compact, fromPairs, head, isArray, keys, take, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getSampleCsvFile } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import {
  Box,
  EmptyPage,
  Layout,
  SummaryCard,
  Text,
  TextInput,
  useDeletePermanentModal,
  useModal,
} from '~/eds';
import {
  AutomationStageType,
  QueryParamType,
  TrainingStage,
  TrainingStatus,
} from '~/enums';
import { actions, api } from '~/redux';
import { buildQuery } from '~/redux/api/methods';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Alert, Link, LoadingContainer } from '~/ui';
import { removeQueryParam } from '~/utils/browser';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';
import { testIsAdmin } from '~/utils/user';

import AutomationHubSearchingFilters from '../AutomationHubSearchFilters';
import {
  ACCURACY_METRIC_TRAINING_STAGES,
  DEFAULT_PHRASES,
  DEFAULT_RULES,
  LABELING_TRAINING_STAGES,
  POSITIVE_LABELS,
  TRAINING_STAGES,
} from '../constants';
import {
  calculateF1Score,
  getCurrentProgress,
  getCurrentStage,
  verCmp,
} from '../util';
import AccuracyMetrics from './Content/AccuracyMetrics';
import FilterSummary from './Content/FilterSummary';
import PredictiveInfo from './Content/PredictiveInfo';
import ProgressBar from './Content/ProgressBar';
import SampleTable from './Content/SampleTable';

const AutomationHubGuidedAiPage = ({
  model,
  filters,
  fields,
  guidedAiForm,
  currentUser,
  resetAutomationFilters,
  setAutomationModel,
  deleteAutomationModel,
  setAutomationManageStage,
  setAutomationGuidedAiDataForm,
}) => {
  const isAdmin = testIsAdmin(currentUser);

  const isRetraining =
    model.stage === TrainingStage.Predicted ||
    (model?.allVersions?.length || 0) > 1;

  const availableSamples = model?.current?.unlabeledFeedback
    ? model.current.unlabeledFeedback.filter((sample) => !!sample.Text)
    : [];

  const [samples, setSamples] = useState([]);
  const [
    retrainGuidedAIConfirmation,
    setRetrainGuidedAIConfirmation,
  ] = useState('');

  const location = useLocation();
  const history = useHistory();

  const clauseId = getPageSearchQueryByKey(QueryParamType.clauseId, model.id);
  const totalSamples = isArray(model?.current?.userInput?.fullTrainingData)
    ? model?.current?.userInput?.fullTrainingData
    : model?.current?.userInput?.userUploadData;

  const shouldWaitForStatus = model && model.stage === TrainingStage.Waiting;
  const hasStageInitialized =
    model.stage !== TrainingStage.Empty && !shouldWaitForStatus;

  const {
    data: trainingStatus,
    isFetching: isFetchingTrainingStatus,
  } = api.endpoints.getTrainingStatus.useQuery(
    { clauseId },
    { skip: !clauseId, pollingInterval: 3000 },
  );

  const {
    data: filtersData,
    isFetching: isFetchingFiltersData,
  } = api.endpoints.getFiltersData.useQuery({ clauseId }, { skip: !clauseId });

  const { data: idf } = api.endpoints.getIdentifications.useQuery(
    { name: model.name },
    { skip: !model.name },
  );

  const hasPredictionRun = trainingStatus && !!model?.current?.prediction?.id;

  const shouldShowDownloadTrainingDataAction = ![
    TrainingStage.Empty,
    TrainingStage.FirstRoundInProgress,
    TrainingStage.FirstRoundLabeling,
  ].includes(model.stage);

  const shouldShowModelStatusAction = ACCURACY_METRIC_TRAINING_STAGES.includes(
    model.stage,
  );

  const {
    data: predictionStatus,
    isFetching: isFetchingPredictionStatus,
  } = api.endpoints.getPredictionStatus.useQuery(
    { requestId: model?.current?.prediction?.id },
    { skip: !model?.current?.prediction?.id, pollingInterval: 8000 },
  );

  const [
    createRetraining,
    createRetrainingResult,
  ] = api.endpoints.createRetraining.useMutation();
  const {
    isError: isCreateRetrainingError,
    isSuccess: isCreateRetrainingSuccess,
    isLoading: isCreateRetrainingLoading,
  } = createRetrainingResult;

  const [
    publishTrainedModel,
    publishTrainedModelResult,
  ] = api.endpoints.publishTrainedModel.useMutation();
  const {
    isError: isPublishTrainedModelError,
    isSuccess: isPublishTrainedModelSuccess,
    isLoading: isPublishTrainedModelLoading,
  } = publishTrainedModelResult;

  const [
    updatePredictionFilters,
    updatePredictionFiltersResults,
  ] = api.endpoints.updatePredictionFilters.useMutation();
  const {
    isError: isUpdatePredictionFiltersError,
    isSuccess: isUpdatePredictionFiltersSuccess,
    isLoading: isUpdatePredictionFiltersLoading,
  } = updatePredictionFiltersResults;

  const [
    deleteTrainingModel,
    deleteTrainingModelResult,
  ] = api.endpoints.deleteTrainingModel.useMutation();
  const {
    isError: isDeleteTrainingModelError,
    isSuccess: isDeleteTrainingModelSuccess,
    isLoading: isDeleteTrainingModelLoading,
  } = deleteTrainingModelResult;

  const [
    cancelTraining,
    cancelTrainingResult,
  ] = api.endpoints.cancelTraining.useMutation();
  const {
    isError: isCancelTrainingError,
    isSuccess: isCancelTrainingSuccess,
  } = cancelTrainingResult;

  const [predictionModal, predictionModalShow, predictionModalHide] = useModal({
    disableHideOnEscape: true,
    children: (
      <Layout direction="column" spacing={2}>
        <Text>
          You trained your model using All Types of contracts. What contracts
          would you like your model to tag with the {model.name} clause?
        </Text>
        <AutomationHubSearchingFilters prediction />
      </Layout>
    ),
    primaryAction: {
      isLoading: isUpdatePredictionFiltersLoading,
      text: 'Done',
      variant: 'primary',
      onClick: () => {
        const filterQuery = buildQuery({
          filters: filters.filter((f) => !!f.operatorId),
          fields,
          booleanQuery: '',
        });

        return updatePredictionFilters({
          clauseId,
          predictionFilters: filterQuery,
        });
      },
    },
    title: 'Apply Model to Specific Contracts',
    onCancel: () => resetAutomationFilters(),
    onHide: () => resetAutomationFilters(),
  });

  const [retrainModal, retrainModalShow, retrainModalHide] = useModal({
    title: 'Warning',
    primaryAction: {
      text: 'I understand the risk',
      variant: 'primary',
      onClick: () => {
        retrainModalHide();
        setAutomationManageStage(AutomationStageType.GuidedAiForm);
      },
    },
    cancelText: 'Never mind',
    children: (
      <Text>
        You are about to start another round of training. Just a reminder, once
        you start training, your current identifications will be removed and
        replaced with the new changes.
      </Text>
    ),
  });

  const [
    deleteGuidedAiModal,
    deleteGuidedAiModalShow,
    deleteGuidedAiModalHide,
  ] = useDeletePermanentModal({
    disableHideOnEscape: true,
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          This Clause has {totalSamples?.length ?? 0} Samples and{' '}
          {idf?.smartClauseTotalIdentifications ?? 0} identifications. Deleting
          this clause will permanently delete all its Samples and their
          identifications. You won’t be able to revert this action.
        </Text>
      </Layout>
    ),
    isDeleting: isDeleteTrainingModelLoading,
    confirmText: 'DELETE',
    onDelete: () => {
      deleteTrainingModel({ clauseId });
    },
    title: `Delete model for Clause: ${model.name}`,
  });

  const [
    cancelTrainingModal,
    showCancelTrainingModal,
    hideCancelTrainingModal,
  ] = useModal({
    title: 'Cancel Training',
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          By cancelling, the training you are leaving the existing model intact
          including all of the identifications.
        </Text>
      </Layout>
    ),
    primaryAction: {
      text: 'Cancel Training',
      variant: 'primary',
      onClick: () => {
        hideCancelTrainingModal();
        if (isRetraining) {
          cancelTraining({ clauseId });
        } else {
          deleteTrainingModel({ clauseId });
        }
      },
    },
    cancelText: 'Keep Training',
  });

  useEffect(() => {
    if (isCreateRetrainingSuccess) {
      const message = `Retrain ${guidedAiForm.name} successfully.`;
      showToast(SUCCESS, message);

      const prevIterVersions = keys(model.current?.pastIterations);
      switch (prevIterVersions.length) {
        case 1:
          setAutomationModel({
            ...model,
            stage: TrainingStage.SecondRoundInProgress,
          });
          break;
        case 2:
          setAutomationModel({
            ...model,
            stage: TrainingStage.ThirdRoundInProgress,
          });
          break;
        case 3:
          setAutomationModel({
            ...model,
            stage: TrainingStage.FourthRoundInProgress,
          });
          break;
        default:
          break;
      }
    }
    if (isCreateRetrainingError) {
      const message = `Retrain ${guidedAiForm.name} failed. Please try again.`;

      showToast(ERROR, message);
    }
  }, [isCreateRetrainingSuccess, isCreateRetrainingError]);

  useEffect(() => {
    if (isPublishTrainedModelSuccess) {
      const message = `Publish model ${guidedAiForm.name} successfully.`;
      showToast(SUCCESS, message);

      setAutomationModel({ ...model, stage: TrainingStage.Publishing });
    }
    if (isPublishTrainedModelError) {
      const message = `Publish model ${guidedAiForm.name} failed. Please try again.`;

      showToast(ERROR, message);
    }
  }, [isPublishTrainedModelSuccess, isPublishTrainedModelError]);

  useEffect(() => {
    if (isUpdatePredictionFiltersSuccess) {
      const message = 'Update prediction filter successfully.';
      showToast(SUCCESS, message);

      predictionModalHide();
      resetAutomationFilters();
    }
    if (isUpdatePredictionFiltersError) {
      const message = 'Update prediction filter failed. Please try again.';

      showToast(ERROR, message);
    }
  }, [isUpdatePredictionFiltersSuccess, isUpdatePredictionFiltersError]);

  useEffect(() => {
    if (isDeleteTrainingModelSuccess) {
      const message = `${isRetraining ? 'Delete Model' : 'Cancel Training'} ${
        model.name
      } successful.`;
      showToast(SUCCESS, message);

      deleteAutomationModel();
      deleteGuidedAiModalHide();
      removeQueryParam(location, history, QueryParamType.clauseId);
    }
    if (isUpdatePredictionFiltersError) {
      const message = `${isRetraining ? 'Delete Model' : 'Cancel Training'} ${
        model.name
      } failed. Please try again.`;

      showToast(ERROR, message);
    }
  }, [isDeleteTrainingModelSuccess, isDeleteTrainingModelError]);

  useEffect(() => {
    if (isCancelTrainingSuccess) {
      const message = `Cancel Training ${model.name} successful.`;
      showToast(SUCCESS, message);
    }
    if (isCancelTrainingError) {
      const message = `Cancel Training ${model.name} failed. Please try again.`;
      showToast(ERROR, message);
    }
  }, [isCancelTrainingSuccess, isCancelTrainingError]);

  useEffect(() => {
    if (!isFetchingTrainingStatus && trainingStatus) {
      const version = model.version
        ? model.version.split('.').slice(0, 2).join('.')
        : null;

      const allModelVersions = keys(trainingStatus.modelVersions);
      const modelCurVersion = allModelVersions.sort(verCmp)[0];

      const current = {
        version: version || modelCurVersion,
        ...trainingStatus.modelVersions[version || modelCurVersion],
      };

      setSamples(current.userInput?.userUploadData ?? []);

      const pastIterVersionsInOrder = keys(current?.pastIterations).sort(
        verCmp,
      );
      // EKP-13825: this change will take the most 3 recent iterations since
      // there could have more than three iterations during model migration
      const prevIterVersions = take(pastIterVersionsInOrder, 3);
      const iterLastVersion = head(pastIterVersionsInOrder);
      const pastIter = current?.pastIterations?.[iterLastVersion];

      const name = trainingStatus.provisionName;
      const description = trainingStatus.description;

      const isTrainingFailed = [
        TrainingStatus.PUBLISH_ERROR,
        TrainingStatus.TRAINING_ERROR,
      ].includes(current?.status);

      if (isTrainingFailed) {
        setAutomationModel({
          ...model,
          allVersions: allModelVersions,
          current,
          name,
          description,
          id: clauseId,
          stage: TrainingStage.Error,
        });
        return;
      }

      const isTrainingInProgress =
        current?.status === TrainingStatus.IN_PROGRESS;

      const f1ScoreCurrent = calculateF1Score(current?.accuracyMetrics) ?? 0;
      const f1ScorePastIter = calculateF1Score(pastIter?.accuracyMetrics) ?? 0;

      const isScoreLow = f1ScoreCurrent < 0.75;
      const isScoreOverExp = !isScoreLow && f1ScoreCurrent < 0.9;
      const isScoreIdeal = f1ScoreCurrent >= 0.9;
      const isScoreUp = f1ScorePastIter < f1ScoreCurrent;
      const isScoreDown = f1ScorePastIter >= f1ScoreCurrent;

      const isAwaitingUserLabels =
        current?.status === TrainingStatus.AWAITING_LABELS;
      const isAwaitingDecision =
        current?.status === TrainingStatus.AWAITING_DECISION;

      const isReadyToDeploy =
        current?.status === TrainingStatus.READY_TO_DEPLOY;
      const isPublishInProgress =
        current?.status === TrainingStatus.PUBLISH_IN_PROGRESS;

      const isPublishing =
        !!model?.isPublishing || isReadyToDeploy || isPublishInProgress;
      const isPublished = current?.status === TrainingStatus.PUBLISH_COMPLETED;

      const predictionProgress = predictionStatus?.status[clauseId];
      const isPredicting =
        predictionProgress && parseFloat(predictionProgress) < 100;
      const isPredicted = predictionProgress && !isPredicting;

      const stage = getCurrentStage(
        prevIterVersions,
        {
          isTrainingInProgress,
          isAwaitingUserLabels,
          isAwaitingDecision,
        },
        {
          isScoreLow,
          isScoreOverExp,
          isScoreIdeal,
          isScoreUp,
          isScoreDown,
        },
        {
          isPublishing,
          isPublished,
          isPredicting,
          isPredicted,
        },
      );

      const progress = getCurrentProgress({
        stage,
        iters: prevIterVersions?.length ?? 0,
        createdAt: current?.iterationCreatedAt,
        updatedAt: current?.versionUpdatedAt,
        status: current?.status,
        prediction: predictionProgress,
        score: f1ScoreCurrent,
      });

      if (shouldWaitForStatus) {
        setAutomationModel({
          ...model,
          stage: TrainingStage.Empty,
        });
      } else {
        setAutomationModel({
          ...model,
          allVersions: allModelVersions,
          id: clauseId,
          current: { ...model.current, ...current },
          name,
          description,
          progress,
          stage,
        });
      }
    }
  }, [
    trainingStatus,
    isFetchingTrainingStatus,
    predictionStatus,
    isFetchingPredictionStatus,
  ]);

  useEffect(() => {
    if (!isFetchingFiltersData && filtersData) {
      const version = model.version
        ? model.version.split('.').slice(0, 2).join('.')
        : null;

      const allModelVersions = keys(filtersData);
      const modelCurVersion = allModelVersions.sort(verCmp)[0];

      const filterVersionData = filtersData[version || modelCurVersion];

      const predictionFilter = filterVersionData?.predictionFilter || [];
      const trainingFilter = filterVersionData?.trainingFilter || [];

      setAutomationModel({
        ...model,
        current: {
          ...model.current,
          filter: { predictionFilter, trainingFilter },
        },
      });
    }
  }, [isFetchingFiltersData, filtersData]);

  const onPutLabel = () => {
    const feedbacks = model.current?.labeledFeedback;

    const samples = model.current.unlabeledFeedback.map((s) => {
      const feedback = feedbacks.find(
        (f) => String(f?.sampleID) === String(s?.sampleID),
      );

      if (!feedback) return { ...s, Text: s.Text, Label: s.Label };

      const label = POSITIVE_LABELS.includes(toLower(feedback.Label))
        ? 'yes'
        : 'no';
      return { ...s, Text: s.Text, Label: label };
    });

    setAutomationGuidedAiDataForm({
      ...guidedAiForm,
      id: clauseId,
      samples,
      name: trainingStatus.provisionName,
      description: trainingStatus.description,
      metadata: model.current.metadata,
    });

    setAutomationManageStage(AutomationStageType.GuidedAiReview);
  };

  const onRetrain = () => {
    const rules = model.current.userInput?.rules ?? DEFAULT_RULES;
    const phrases = model.current.userInput?.phrases ?? DEFAULT_PHRASES;

    return createRetraining({ clauseId, config: { rules, phrases } });
  };

  const onPublish = async () => {
    setRetrainGuidedAIConfirmation('');
    return publishTrainedModel({ clauseId });
  };

  const onDownloadSamples = () => {
    getSampleCsvFile({ clauseId, version: model.current?.version });
  };

  const handlePublish = () => {
    if (isRetraining) {
      showRetrainGuidedAIWarning();
    } else {
      onPublish();
    }
  };

  const [
    retrainGuidedAIWarning,
    showRetrainGuidedAIWarning,
    hideRetrainGuidedAIWarning,
  ] = useModal({
    title: 'Publish and Overwrite',
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Once published, this improved model will identify new matches to the{' '}
          <Text variant="body-bold">{model?.name} Clause</Text>, and it will
          overwrite the previous results. You won't be able to revert this
          action.
        </Text>
        <Text>If you are sure, please type "OVERWRITE" in the box below</Text>
        <TextInput
          name="confirm-retrain"
          value={retrainGuidedAIConfirmation}
          onChange={setRetrainGuidedAIConfirmation}
        />
      </Layout>
    ),
    primaryAction: {
      text: 'Publish and Overwrite',
      onClick: () => {
        hideRetrainGuidedAIWarning();
        onPublish();
      },
      variant: 'danger',
      disabled: retrainGuidedAIConfirmation !== 'OVERWRITE',
    },
    onCancel: () => {
      setRetrainGuidedAIConfirmation('');
    },
  });

  const actions = {
    ...fromPairs(
      [
        TrainingStage.Empty,
        TrainingStage.FirstRoundInProgress,
        TrainingStage.SecondRoundInProgress,
        TrainingStage.ThirdRoundInProgress,
        TrainingStage.FourthRoundInProgress,
      ].map((key) => [key, []]),
    ),
    ...fromPairs(
      [
        TrainingStage.FirstRoundLabeling,
        TrainingStage.SecondRoundLabeling,
        TrainingStage.ThirdRoundLabeling,
        TrainingStage.FourthRoundLabeling,
      ].map((key) => [
        key,
        [
          {
            text: 'Give Feedback on Samples',
            level: 'primary',
            onClick: onPutLabel,
          },
        ],
      ]),
    ),
    ...fromPairs(
      [TrainingStage.FirstRoundF1Low, TrainingStage.FirstRoundF1Mid].map(
        (key) => [
          key,
          [
            {
              isLoading: isCreateRetrainingLoading,
              text: 'Retrain',
              level: 'primary',
              onClick: onRetrain,
            },
          ],
        ],
      ),
    ),
    ...fromPairs(
      [
        TrainingStage.FirstRoundF1Ideal,
        TrainingStage.SecondRoundF1GoUp,
        TrainingStage.SecondRoundF1GoDown,
        TrainingStage.ThirdRoundF1GoUp,
        TrainingStage.ThirdRoundF1GoDown,
      ].map((key) => [
        key,
        [
          {
            isLoading: isPublishTrainedModelLoading,
            text: 'Publish',
            onClick: handlePublish,
          },
          {
            isLoading: isCreateRetrainingLoading,
            text: 'Retrain',
            level: 'primary',
            onClick: onRetrain,
          },
        ],
      ]),
    ),
    ...fromPairs(
      [TrainingStage.SecondRoundF1Ideal, TrainingStage.ThirdRoundF1Ideal].map(
        (key) => [
          key,
          [
            {
              isLoading: isCreateRetrainingLoading,
              text: 'Retrain',
              onClick: onRetrain,
            },
            {
              isLoading: isPublishTrainedModelLoading,
              text: 'Publish',
              level: 'primary',
              onClick: handlePublish,
            },
          ],
        ],
      ),
    ),
    ...fromPairs(
      [
        TrainingStage.FourthRoundF1GoUp,
        TrainingStage.FourthRoundF1GoDown,
        TrainingStage.FourthRoundF1Ideal,
      ].map((key) => [
        key,
        [
          {
            isLoading: isPublishTrainedModelLoading,
            text: 'Publish',
            level: 'primary',
            onClick: handlePublish,
          },
        ],
      ]),
    ),
  };

  if (model?.stage === TrainingStage.Error) {
    return (
      <Layout direction="column" spacing={4} align="center" mt={4}>
        <Text variant="body-medium" color="status.danger">
          An error occurred during training. Please{' '}
          <Link href="mailto:support@evisort.com">contact support</Link> for
          further assistance.
        </Text>
      </Layout>
    );
  }

  if (!clauseId) {
    return (
      <Layout direction="column" spacing={4} align="center" mt={4}>
        <EmptyPage
          preset="new-clause"
          resolveAction={{
            variant: 'primary',
            icon: 'plus',
            iconPosition: 'left',
            text: 'New Guided AI',
            onClick: () =>
              setAutomationManageStage(AutomationStageType.GuidedAiForm),
          }}
        />
      </Layout>
    );
  }

  return (
    <LoadingContainer
      isLoading={
        !trainingStatus ||
        (hasPredictionRun && !predictionStatus) ||
        !hasStageInitialized
      }
    >
      {model.stage !== TrainingStage.Predicted && (
        <>
          {!isAdmin && LABELING_TRAINING_STAGES.includes(model.stage) && (
            <Alert variant="warning" enableIcon>
              You have access to {availableSamples?.length} of{' '}
              {model?.current?.unlabeledFeedback?.length} samples. Mark the
              samples below and then contact your admin to mark the remaining
              samples.
            </Alert>
          )}
          <SummaryCard
            title={TRAINING_STAGES[model?.stage]?.title}
            actions={actions[model?.stage]}
            moreActions={compact([
              shouldShowModelStatusAction && {
                text: 'View Model Status',
                onClick: () =>
                  setAutomationManageStage(AutomationStageType.ViewStatus),
              },
              shouldShowDownloadTrainingDataAction && {
                text: 'Download Training Data',
                onClick: () => onDownloadSamples(),
              },
              {
                text: 'Cancel Training',
                onClick: () => showCancelTrainingModal(),
              },
              isRetraining && {
                level: 'danger',
                text: 'Delete',
                onClick: () => deleteGuidedAiModalShow(),
              },
            ])}
            description={
              <Text>
                {TRAINING_STAGES[model?.stage]?.description}
                {TRAINING_STAGES[model?.stage]?.canAdjustPredictionFilters ? (
                  <Link onClick={() => predictionModalShow()}>
                    adjust your filters.
                  </Link>
                ) : null}
              </Text>
            }
          >
            <ProgressBar />
          </SummaryCard>
        </>
      )}
      <Layout direction="row" justify="space-between" w="100%" spacing={4}>
        <Box flex="1">
          {model.stage === TrainingStage.Predicted && (
            <PredictiveInfo
              onRetrainGuidedAi={retrainModalShow}
              onDownloadSamples={onDownloadSamples}
              onDeleteGuidedAi={deleteGuidedAiModalShow}
            />
          )}
          <FilterSummary />
        </Box>
        <Box flex="3">
          {model.stage === TrainingStage.Predicted && <AccuracyMetrics />}
          <SampleTable samples={samples} />
        </Box>
      </Layout>
      {predictionModal}
      {deleteGuidedAiModal}
      {retrainModal}
      {retrainGuidedAIWarning}
      {cancelTrainingModal}
    </LoadingContainer>
  );
};

const mapStateToProps = ({ automation, currentUser }) => ({
  model: automation.model,
  filters: automation.filter.filters,
  fields: automation.filter.fields,
  guidedAiForm: automation.guidedAiForm,
  currentUser,
});

export default connect(mapStateToProps, {
  resetAutomationFilters: actions.resetAutomationFilters,
  setAutomationModel: actions.setAutomationModel,
  deleteAutomationModel: actions.deleteAutomationModel,
  setAutomationGuidedAiDataForm: actions.setAutomationGuidedAiDataForm,
  setAutomationManageStage: actions.setAutomationManageStage,
})(AutomationHubGuidedAiPage);
