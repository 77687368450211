import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InfoCard, Tabs } from '~/eds';
import { actions, selectors } from '~/redux';

import { EndpointConfiguration } from './EndpointConfiguration';
import { EndpointModal } from './EndpointModal';
import { Endpoints } from './Endpoints';
import { Endpoint } from './types';

type WebhooksTab = 'endpoints' | 'attempts';

export const Webhooks = () => {
  const dispatch = useDispatch();
  const activeEndpoint = useSelector(selectors.selectActiveEndpoint);
  const [selectedTab, setSelectedTab] = useState<WebhooksTab>('endpoints');

  //TODO replace with actual data from API
  const mockedData: Endpoint[] = [
    {
      endpointUrl: 'https://example.com',
      description: 'This is a test endpoint',
      status: 'Active',
      selectedEvents: ['ticket created', 'ticket updated'],
      dateCreated: new Date(),
      dateModified: new Date(),
    },
  ];

  return (
    <>
      {mockedData.length > 0 ? (
        <Tabs<WebhooksTab>
          tabs={[
            {
              label: 'Endpoints',
              value: 'endpoints',
              panel: <Endpoints endpoints={mockedData} />,
            },
            {
              label: 'Attempts',
              value: 'attempts',
              panel: <div>TBD ATTEMPTS</div>,
            },
          ]}
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
        />
      ) : (
        <InfoCard
          action={{
            level: 'primary',
            onClick: () => dispatch(actions.setEndpointAction('create')),
            text: 'Add Endpoint',
          }}
          description="Create webhook endpoints to listen for Evisort events, allowing your integration to receive notifications when asynchronous actions take place."
          title="Create Endpoints for Evisort Events"
        />
      )}
      <EndpointConfiguration activeEndpoint={activeEndpoint} />
      <EndpointModal />
    </>
  );
};
