import { compact, isEmpty, keys } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  DEFAULT_PHRASES,
  DEFAULT_RULES,
  SAMPLE_MIN_WORD_COUNT_BULK_TAGGING,
} from '~/components/AutomationHub/constants';
import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { Layout, Modal, Text, TextInput, useModal } from '~/eds';
import {
  AutomationStageType,
  QueryParamType,
  SearchQueryFormatTypes,
  TrainingStage,
  TrainingStatus,
} from '~/enums';
import { useCurrentUser } from '~/hooks';
import { actions, api } from '~/redux';
import { buildQuery } from '~/redux/api/methods';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';
import { countWords } from '~/utils/strings';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

import { calculateF1Score } from '../util';
import AutomationTypes from './Content/AutomationTypes';
import GuidedAiForm from './Content/GuidedAiForm';
import GuidedAiReview from './Content/GuidedAiReview';
import GuidedAiStatus from './Content/GuidedAiStatus';
import QuickAiForm from './Content/QuickAiForm';

const AutomationHubManagement = ({
  clause,
  model,
  fields,
  filters,
  quickAiForm,
  guidedAiForm,
  manage,
  resetAutomationFilters,
  resetAutomationQuickAiDataForm,
  resetAutomationGuidedAiDataForm,
  setAutomationModel,
  setAutomationQuickAiDataForm,
  setAutomationGuidedAiDataForm,
  setAutomationManageStage,
  setAutomationClause,
}) => {
  const history = useHistory();
  const [evaluate, setEvaluate] = useState(false);
  const [
    retrainGuidedAIConfirmation,
    setRetrainGuidedAIConfirmation,
  ] = useState('');

  const currentUser = useCurrentUser();

  const [
    createSample,
    createTaggingResult,
  ] = api.endpoints.createSample.useMutation();
  const {
    error: createTaggingError,
    isError: isCreateTaggingError,
    isSuccess: isCreateTaggingSuccess,
    isLoading: isCreateTaggingLoading,
    data: createTaggingData,
  } = createTaggingResult;

  const [
    updateSample,
    updateTaggingResult,
  ] = api.endpoints.updateSample.useMutation();
  const {
    error: updateTaggingError,
    isError: isUpdateTaggingError,
    isSuccess: isUpdateTaggingSuccess,
    isLoading: isUpdateTaggingLoading,
  } = updateTaggingResult;

  const [
    createTraining,
    createTrainingResult,
  ] = api.endpoints.createTraining.useMutation();
  const {
    isError: isCreateTrainingError,
    isSuccess: isCreateTrainingSuccess,
    originalArgs: createTrainingOriginalArgs,
    data: createTrainingData,
    error: createTrainingError,
    isLoading: isCreateTrainingLoading,
  } = createTrainingResult;

  const [
    updateTrainingLabels,
    updateTrainingLabelsResult,
  ] = api.endpoints.updateTrainingLabels.useMutation();
  const {
    isError: isUpdateTrainingLabelsError,
    isSuccess: isUpdateTrainingLabelsSuccess,
    error: updateTrainingLabelsError,
    isLoading: isUpdateTrainingLabelsLoading,
  } = updateTrainingLabelsResult;

  const [
    createRetraining,
    createRetrainingResult,
  ] = api.endpoints.createRetraining.useMutation();
  const {
    isError: isCreateRetrainingError,
    isSuccess: isCreateRetrainingSuccess,
    error: createRetrainingError,
    isLoading: isCreateRetrainingLoading,
  } = createRetrainingResult;

  const [
    publishTrainedModel,
    publishTrainedModelResult,
  ] = api.endpoints.publishTrainedModel.useMutation();
  const {
    isError: isPublishTrainedModelError,
    isSuccess: isPublishTrainedModelSuccess,
    error: publishTrainedModelError,
    isLoading: isPublishTrainedModelLoading,
  } = publishTrainedModelResult;

  const [updateDescription] = api.endpoints.updateDescription.useMutation();

  // quickAiForm validation
  const isMissingQuickAiBasicInfo = !(
    quickAiForm.name &&
    quickAiForm.sampleName &&
    quickAiForm.content
  );
  const isSampleWordsInsufficient =
    countWords(quickAiForm.content) < SAMPLE_MIN_WORD_COUNT_BULK_TAGGING;

  const isTaggingClauseNameOverLimit = (quickAiForm.name?.length ?? 0) > 500;
  const isSampleNameOverLimit = (quickAiForm.sampleName?.length ?? 0) > 100;
  const doesQuickAiFormHaveErrors = (quickAiForm.errors?.length ?? 0) > 0;
  const isBasicTaggingInfoInvalid =
    isTaggingClauseNameOverLimit ||
    isSampleNameOverLimit ||
    doesQuickAiFormHaveErrors;

  // guidedAiForm validation
  const isBasicModelInfoMissing = !guidedAiForm.name || !guidedAiForm.uploadId;

  const isSmartClauseNameOverLimit = guidedAiForm.name.trimLeft().length > 500;
  const isClauseDescriptionOverLimit =
    (guidedAiForm.description?.length ?? 0) > 500;
  const doesGuidedAiFormHaveErrors = (guidedAiForm.errors?.length ?? 0) > 0;
  const isBasicModelInfoInvalid =
    isSmartClauseNameOverLimit ||
    isClauseDescriptionOverLimit ||
    doesGuidedAiFormHaveErrors;

  const isUpdating = !!quickAiForm.id;

  const isOnClausePage = !isEmpty(
    getPageSearchQueryByKey(QueryParamType.Clause, null),
  );

  const isExistingClauseOnClausePage =
    isOnClausePage && quickAiForm.doesClauseExist;

  const allSamples = guidedAiForm.samples || [];
  const availableSampleCount = allSamples.filter((s) => !!s.Text).length;
  const labeledSampleCount = allSamples.filter((s) => !!s.Text && !!s.Label)
    .length;
  const isAllSampleLabeled = labeledSampleCount === availableSampleCount;
  const ctaGuidedAIReviewText =
    allSamples.length > availableSampleCount ? 'Save' : 'Done';

  const isAccuracyOverGoal =
    calculateF1Score(model?.current?.accuracyMetrics) >= 0.9;
  const pastIterTimes = keys(model?.current?.pastIterations)?.length;
  const hasReachedRetrainingLimit = pastIterTimes >= 3;
  const isOnFirstTimeTraining = pastIterTimes <= 0;

  const isFirstTimeTrainingIdeal = isAccuracyOverGoal && isOnFirstTimeTraining;
  const isRetrainingNotIdeal = !isOnFirstTimeTraining && !isAccuracyOverGoal;

  const shouldShowPrimaryRetrain =
    (isOnFirstTimeTraining || isRetrainingNotIdeal) &&
    !hasReachedRetrainingLimit;

  const shouldShowSecondaryRetrain =
    !isOnFirstTimeTraining && isAccuracyOverGoal && !hasReachedRetrainingLimit;
  const shouldShowSecondaryPublish =
    (isFirstTimeTrainingIdeal || isRetrainingNotIdeal) &&
    !hasReachedRetrainingLimit;

  const onModelSubmit = async () => {
    setRetrainGuidedAIConfirmation('');

    const filterQuery = buildQuery({
      filters: filters.filter((f) => !!f.operatorId),
      fields,
      booleanQuery: '',
    });

    if (!!model.id) {
      updateDescription({
        clauseId: model.id,
        description: guidedAiForm.description,
      });
      return createTraining({
        params: {
          provisionName: guidedAiForm.name.trim(),
          description: guidedAiForm.description,
          trainingDataID: guidedAiForm.uploadId,
          filter: filterQuery,
          queryFormat: SearchQueryFormatTypes.V2,
        },
        clauseId: model.id,
      });
    } else {
      return createTraining({
        params: {
          provisionName: guidedAiForm.name.trim(),
          description: guidedAiForm.description,
          trainingDataID: guidedAiForm.uploadId,
          filter: filterQuery,
          queryFormat: SearchQueryFormatTypes.V2,
        },
      });
    }
  };

  const onHideModal = () => {
    resetAutomationQuickAiDataForm();
    resetAutomationGuidedAiDataForm();

    setAutomationModel({ ...model, stage: TrainingStage.Empty });
    if (!isOnClausePage) setAutomationClause({ ...clause, name: '' });
    setAutomationManageStage(AutomationStageType.None);
  };

  const onHideViewModal = () => {
    setAutomationManageStage(AutomationStageType.None);
  };

  const onCloseGuidedAI = () => {
    if (
      manage === AutomationStageType.GuidedAiForm &&
      (guidedAiForm.name || guidedAiForm.description || guidedAiForm.uploadId)
    ) {
      showCancelGuidedAIWarning();
      return;
    }
    setAutomationManageStage(AutomationStageType.None);
  };

  const onCloseGuidedAiReview = async () => {
    setEvaluate(false);

    const samples = guidedAiForm.samples.filter((s) => !!s.Text && !!s.Label);

    if (samples.length <= 0) {
      setAutomationManageStage(AutomationStageType.None);
      return;
    }

    await updateTrainingLabels({
      samples,
      clauseId: guidedAiForm.id,
      metadata: guidedAiForm.metadata,
      evaluate: false,
    });
  };

  const onClose = {
    [AutomationStageType.GuidedAiForm]: onCloseGuidedAI,
    [AutomationStageType.QuickAiForm]: onHideModal,
    [AutomationStageType.AutomationTypes]: onHideModal,
    [AutomationStageType.GuidedAiReview]: onCloseGuidedAiReview,
    [AutomationStageType.GuidedAiStatus]: onHideModal,
    [AutomationStageType.ViewStatus]: onHideViewModal,
  };

  const postTrainingSubmit = () => {
    const { id, name, version } = createTrainingData;
    const { description } = guidedAiForm;

    const isRetraining = !!createTrainingOriginalArgs.clauseId;

    setAutomationModel({
      id,
      version,
      name,
      description,
      showCreating: true,
      stage: isRetraining ? TrainingStage.Empty : model.stage,
    });
    setAutomationManageStage(AutomationStageType.None);
    resetAutomationFilters();

    const clauseNameParam = `${QueryParamType.Clause}=${encodeURIComponent(
      name,
    )}`;
    const clauseParam = `${QueryParamType.clauseId}=${id}`;

    history.push(`/automation/detail?&${clauseNameParam}&${clauseParam}`);
  };

  const postSubmit = {
    [AutomationStageType.GuidedAiForm]: postTrainingSubmit,
    [AutomationStageType.QuickAiForm]: onHideModal,
    [AutomationStageType.AutomationTypes]: onHideModal,
    [AutomationStageType.GuidedAiReview]: onHideModal,
    [AutomationStageType.GuidedAiStatus]: onHideModal,
    [AutomationStageType.ViewStatus]: onHideViewModal,
  };

  const onPublish = async () => {
    setRetrainGuidedAIConfirmation('');
    setAutomationModel({ ...model, stage: TrainingStage.Empty });
    return publishTrainedModel({ clauseId: guidedAiForm.id });
  };

  const [
    cancelGuidedAIWarning,
    showCancelGuidedAIWarning,
    hideCancelGuidedAIWarning,
  ] = useModal({
    title:
      'Cancel Guided AI' +
      (guidedAiForm.name ? ': ' + guidedAiForm.name : '') +
      '?',
    children: 'Are you sure you want to cancel creating this custom clause?',
    primaryAction: {
      text: 'Cancel',
      onClick: async () => {
        hideCancelGuidedAIWarning();
        resetAutomationGuidedAiDataForm();
        setAutomationManageStage(AutomationStageType.None);
      },
    },
    cancelText: 'Close',
  });

  const [
    retrainGuidedAIWarning,
    showRetrainGuidedAIWarning,
    hideRetrainGuidedAIWarning,
  ] = useModal({
    title: 'Publish and Overwrite',
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Once published, this improved model will identify new matches to the{' '}
          <Text variant="body-bold">{model?.name} Clause</Text>, and it will
          overwrite the previous results. You won't be able to revert this
          action.
        </Text>
        <Text>If you are sure, please type "OVERWRITE" in the box below</Text>
        <TextInput
          name="confirm-retrain"
          value={retrainGuidedAIConfirmation}
          onChange={setRetrainGuidedAIConfirmation}
        />
      </Layout>
    ),
    primaryAction: {
      text: 'Publish and Overwrite',
      onClick: () => {
        hideRetrainGuidedAIWarning();
        onPublish();
      },
      variant: 'danger',
      disabled: retrainGuidedAIConfirmation !== 'OVERWRITE',
    },
    onCancel: () => {
      setRetrainGuidedAIConfirmation('');
    },
  });

  const onError = ({ response }) => {
    const error = response?.data?.error ?? [];

    setAutomationQuickAiDataForm({
      ...quickAiForm,
      errors: Array.isArray(error) ? error : [error],
    });
    setAutomationGuidedAiDataForm({
      ...guidedAiForm,
      errors: Array.isArray(error) ? error : [error],
    });
  };

  useEffect(() => {
    if (isCreateTaggingSuccess) {
      const message =
        isOnClausePage && !quickAiForm.doesClauseExist
          ? `New Sample added to ${quickAiForm.name} Clause`
          : `${quickAiForm.name} Clause created successfully.`;

      trackSegment('User creates new Quick AI Clause', {
        groupId: getClientInfo(currentUser),
        userId: getUserClientInfo(currentUser),
        sourceId: createTaggingData.source_id,
      });
      showToast(SUCCESS, message);
      postSubmit[manage]();
    }
    if (isCreateTaggingError) {
      const message =
        isOnClausePage && !quickAiForm.doesClauseExist
          ? `Create Sample in ${quickAiForm.name} Clause failed. Please try again.`
          : `Create ${quickAiForm.name} Clause failed. Please try again.`;

      showToast(ERROR, message);
      onError(createTaggingError);
    }
  }, [isCreateTaggingSuccess, isCreateTaggingError]);

  useEffect(() => {
    if (isUpdateTaggingSuccess) {
      showToast(SUCCESS, 'Sample updated successfully.');
      postSubmit[manage]();
    }
    if (isUpdateTaggingError) {
      showToast(ERROR, 'Update Sample failed. Please try again.');
      onError(updateTaggingError);
    }
  }, [isUpdateTaggingSuccess, isUpdateTaggingError]);

  useEffect(() => {
    if (isCreateTrainingSuccess) {
      const message = `${createTrainingData.name} Clause created successfully.`;
      showToast(SUCCESS, message);
      postSubmit[manage]();
    }
    if (isCreateTrainingError) {
      const message = `Create ${guidedAiForm.name} Model failed. Please try again.`;

      showToast(ERROR, message);
      onError(createTrainingError);
    }
  }, [isCreateTrainingSuccess, isCreateTrainingError]);

  useEffect(() => {
    if (isUpdateTrainingLabelsSuccess) {
      const message = `Labels of ${guidedAiForm.name} updated successfully.`;
      showToast(SUCCESS, message);
      if (evaluate) {
        setAutomationManageStage(AutomationStageType.GuidedAiStatus);
      } else {
        setAutomationModel({ ...model, stage: TrainingStage.Waiting });
        setAutomationManageStage(AutomationStageType.None);
      }
    }
    if (isUpdateTrainingLabelsError) {
      const message = `Labels of ${guidedAiForm.name} failed to update. Please try again.`;

      showToast(ERROR, message);
      onError(updateTrainingLabelsError);
    }
  }, [isUpdateTrainingLabelsSuccess, isUpdateTrainingLabelsError]);

  useEffect(() => {
    if (isCreateRetrainingSuccess) {
      const message = `Retrain ${guidedAiForm.name} successfully.`;
      showToast(SUCCESS, message);
      postSubmit[manage]();
    }
    if (isCreateRetrainingError) {
      const message = `Retrain ${guidedAiForm.name} failed. Please try again.`;

      showToast(ERROR, message);
      onError(createRetrainingError);
    }
  }, [isCreateRetrainingSuccess, isCreateRetrainingError]);

  useEffect(() => {
    if (isPublishTrainedModelSuccess) {
      const message = `Publish model ${guidedAiForm.name} successfully.`;
      showToast(SUCCESS, message);
      postSubmit[manage]();
    }
    if (isPublishTrainedModelError) {
      const message = `Publish model ${guidedAiForm.name} failed. Please try again.`;

      showToast(ERROR, message);
      onError(publishTrainedModelError);
    }
  }, [isPublishTrainedModelSuccess, isPublishTrainedModelError]);

  if (manage === AutomationStageType.None) return null;

  const onTaggingSubmit = async () => {
    if (isUpdating) {
      const { id, sampleName, content } = quickAiForm;
      return updateSample({ id, sampleName, content });
    } else {
      const { name, sampleName, content, applyTo, searchQuery } = quickAiForm;
      return createSample({ name, sampleName, content, applyTo, searchQuery });
    }
  };

  const onUpdateTrainingLabels = async () => {
    if (allSamples.length > availableSampleCount) {
      onCloseGuidedAiReview();
      return;
    }
    setEvaluate(true);

    const samples = guidedAiForm.samples.filter((s) => !!s.Text && !!s.Label);

    return updateTrainingLabels({
      clauseId: guidedAiForm.id,
      samples,
      metadata: guidedAiForm.metadata,
      evaluate: true,
    });
  };

  // iteration, not a proper retrain which is done from createTraining endpoint
  const onRetrain = async () => {
    setAutomationModel({ ...model, stage: TrainingStage.Empty });

    const rules = model.current.userInput?.rules ?? DEFAULT_RULES;
    const phrases = model.current.userInput?.phrases ?? DEFAULT_PHRASES;

    return createRetraining({
      clauseId: guidedAiForm.id,
      config: { rules, phrases },
    });
  };

  const handlePublishRetrain = () => {
    showRetrainGuidedAIWarning();
  };

  const title = {
    [AutomationStageType.AutomationTypes]: 'Get started',
    [AutomationStageType.QuickAiForm]: `Create a New ${
      isOnClausePage ? 'Sample' : 'Clause'
    }`,
    [AutomationStageType.GuidedAiForm]: 'Create a Guided AI',
    [AutomationStageType.GuidedAiReview]: `${model.name} - Give Feedback on Samples`,
    [AutomationStageType.GuidedAiStatus]: 'Model Status',
  };

  const predicted = model.stage === TrainingStage.Predicted;
  const shouldShowRetrainWarning = model.allVersions.length > 1;

  const content = {
    [AutomationStageType.AutomationTypes]: {
      disableFooter: true,
      cancelText: undefined,
      primary: null,
      secondary: [],
      component: AutomationTypes,
    },
    [AutomationStageType.QuickAiForm]: {
      disableFooter: false,
      cancelText: undefined,
      primary: {
        isLoading: isUpdating ? isUpdateTaggingLoading : isCreateTaggingLoading,
        disabled:
          isMissingQuickAiBasicInfo ||
          isSampleWordsInsufficient ||
          isBasicTaggingInfoInvalid ||
          isExistingClauseOnClausePage,
        text: isUpdating ? 'Edit' : 'Create',
        onClick: onTaggingSubmit,
      },
      secondary: [],
      component: QuickAiForm,
    },
    [AutomationStageType.GuidedAiForm]: {
      disableFooter: false,
      cancelText: undefined,
      primary: {
        isLoading: isCreateTrainingLoading,
        disabled:
          isBasicModelInfoMissing ||
          isBasicModelInfoInvalid ||
          guidedAiForm.invalids,
        text: predicted ? 'Start Retraining' : 'Create',
        onClick: onModelSubmit,
      },
      secondary: [],
      component: GuidedAiForm,
    },
    [AutomationStageType.GuidedAiReview]: {
      disableFooter: false,
      cancelText: 'I will finish later',
      primary: {
        isLoading: isUpdateTrainingLabelsLoading && isAllSampleLabeled,
        disabled: !isAllSampleLabeled,
        text: `${ctaGuidedAIReviewText} ${labeledSampleCount}/${availableSampleCount}`,
        onClick: onUpdateTrainingLabels,
      },
      secondary: [],
      component: GuidedAiReview,
    },
    [AutomationStageType.GuidedAiStatus]: {
      disableFooter: isEmpty(model?.current?.accuracyMetrics),
      cancelText: 'Finish Later',
      primary: {
        isLoading: shouldShowPrimaryRetrain
          ? isCreateRetrainingLoading
          : isPublishTrainedModelLoading,
        disabled: model?.current?.status !== TrainingStatus.AWAITING_DECISION,
        text: shouldShowPrimaryRetrain ? 'Retrain' : 'Publish',
        onClick: shouldShowPrimaryRetrain
          ? onRetrain
          : shouldShowRetrainWarning
          ? handlePublishRetrain
          : onPublish,
      },
      secondary: compact([
        shouldShowSecondaryPublish && {
          isLoading: isPublishTrainedModelLoading,
          disabled: model?.current?.status !== TrainingStatus.AWAITING_DECISION,
          text: 'Publish',
          onClick: shouldShowRetrainWarning ? handlePublishRetrain : onPublish,
        },
        shouldShowSecondaryRetrain && {
          isLoading: isCreateRetrainingLoading,
          disabled: model?.current?.status !== TrainingStatus.AWAITING_DECISION,
          text: 'Retrain',
          onClick: onRetrain,
        },
      ]),
      component: GuidedAiStatus,
    },
    [AutomationStageType.ViewStatus]: {
      disableFooter: true,
      cancelText: undefined,
      primary: null,
      secondary: [],
      component: GuidedAiStatus,
    },
  };

  const {
    component: Content,
    cancelText,
    disableFooter,
    primary,
    secondary,
  } = content[manage];

  return (
    <>
      <Modal
        title={title[manage]}
        isFullPage={true}
        isVisible={!!content[manage]}
        primaryAction={primary}
        secondaryActions={secondary}
        onCancel={onClose[manage]}
        cancelText={cancelText}
        disableFooter={disableFooter}
      >
        <Layout maxW="1000px" p={4} alignSelf="center" justify="center">
          <Content />
        </Layout>
      </Modal>
      {cancelGuidedAIWarning}
      {retrainGuidedAIWarning}
    </>
  );
};

const mapStateToProps = ({ automation }) => ({
  clause: automation.clause,
  model: automation.model,
  fields: automation.filter.fields,
  filters: automation.filter.filters,
  quickAiForm: automation.quickAiForm,
  guidedAiForm: automation.guidedAiForm,
  manage: automation.manage,
  view: automation.view,
});

export default connect(mapStateToProps, {
  resetAutomationFilters: actions.resetAutomationFilters,
  setAutomationModel: actions.setAutomationModel,
  setAutomationQuickAiDataForm: actions.setAutomationQuickAiDataForm,
  setAutomationGuidedAiDataForm: actions.setAutomationGuidedAiDataForm,
  resetAutomationQuickAiDataForm: actions.resetAutomationQuickAiDataForm,
  resetAutomationGuidedAiDataForm: actions.resetAutomationGuidedAiDataForm,
  setAutomationManageStage: actions.setAutomationManageStage,
  setAutomationClause: actions.setAutomationClause,
})(AutomationHubManagement);
