import React from 'react';

import { formatNumber, Info, Layout } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';

import { ModelPreviewSearchFilters } from './fields/shared/ModelPreviewSearchFilters';
import { FieldModel } from './fields/types';
import { getModelHeader } from './fields/utils';
import { usePollModelProgress } from './hooks';
import { testIsPublishingStatus } from './utils';

interface Props {
  enableHeader?: boolean;
  modelVersion: FieldModel;
  sectionTitle?: string;
}

// TODO: Deprecate in favor of `ModelVersionInfo`
export const DEPRECATED_ModelInfo = ({
  modelVersion,
  sectionTitle = '',
  enableHeader,
}: Props) => {
  const { config, modifiedBy, modifiedDate } = modelVersion;

  const currentUser = useCurrentUser();
  const clientId = currentUser.client;

  const {
    data: modifiedByUser,
    isFetching: isLoadingUser,
  } = api.endpoints.resolveUser.useQuery({
    id: modifiedBy,
    params: { clientId },
  });

  const { progress } = usePollModelProgress({
    model: modelVersion,
  });

  const { docsInScope, docsProcessed, publishStatus } = progress;

  const header = enableHeader
    ? getModelHeader({
        ...modelVersion,
        publishStatus,
      })
    : undefined;

  return (
    <Layout preset="sections">
      <Info
        header={header}
        sections={[
          {
            title: sectionTitle,
            infos: [
              {
                title: 'Percentage Completed',
                type: 'progress' as const,
                props: {
                  isLoading: testIsPublishingStatus(publishStatus),
                  max: docsInScope,
                  name: 'publish progress',
                  status: 'active',
                  statsLabel: {
                    formatLabel: (value: number, max: number) =>
                      `${formatNumber(value / max, {
                        decimal: 1,
                        style: 'percent',
                      })} (${formatNumber(value)} of ${formatNumber(
                        max,
                      )} Documents)`,
                    position: 'bottom',
                  },
                  value: docsProcessed,
                },
              },
              {
                title: 'Scope',
                type: 'content' as const,
                props: {
                  children: <ModelPreviewSearchFilters model={modelVersion} />,
                },
              },
              {
                title: 'Documents in Scope',
                type: 'number' as const,
                props: {
                  number: docsInScope,
                },
              },
              {
                title: 'User activity',
                type: 'user-activity' as const,
                props: {
                  activity: 'Modified',
                  date: modifiedDate,
                  user: modifiedByUser!,
                },
                isLoading: isLoadingUser,
              },
              {
                title: 'Instructions for the Model',
                type: 'textarea' as const,
                props: {
                  readOnly: true,
                  name: 'instructions',
                  value: config.instructions,
                },
              },
            ],
          },
        ]}
      />
    </Layout>
  );
};
