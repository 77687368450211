import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import EcModal from '~/components/Shared/EcModal';
import QuickSearchModal from '~/components/Shared/QuickSearchModal';
import { J } from '~/constants/keyCodes';
import { useDisableBodyScroll } from '~/eds';
import { FeatureFlagType, QueryEntityType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import search from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { MODAL_AUTOCOMPLETE, MODAL_QUICK_SEARCH } from '~/types/modal.types';

import * as actions from './../actions';
import { useHasFeatureFlag } from './useHasFeatureFlag';

export const useSearchModal = (): [React.ReactNode, () => void] => {
  const hasSearchV2Feature = useHasFeatureFlag(FeatureFlagType.SearchV2);
  const hasSearchV3 = useFlag(FlagType.SearchV3);
  const { pathname } = useLocation();
  const history = useHistory();
  const [modal, setModal] = useState<string | null>(null);
  useDisableBodyScroll(modal === MODAL_QUICK_SEARCH);

  const handleShowModal = () => {
    setModal(hasSearchV2Feature ? MODAL_QUICK_SEARCH : MODAL_AUTOCOMPLETE);
  };

  const handleHideModal = () => {
    setModal(null);
  };

  const handleOnSearchShortcutPress = (event: KeyboardEvent) => {
    if (event.keyCode === J && event.metaKey) {
      handleShowModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleOnSearchShortcutPress, false);

    return () => {
      document.removeEventListener(
        'keydown',
        handleOnSearchShortcutPress,
        false,
      );
    };
  }, []);

  const dispatch = useDispatch();

  const navigateTo = (path: string) => {
    if (pathname !== path) history.push(path);
  };

  const searchOnSearchV3 = (query: string) => {
    dispatch(searchV3.actions.reset());
    dispatch(searchV3.actions.setSearchText(query));
  };

  const goToAnalyzerPage = (query: string) => {
    dispatch(
      actions.analyzerSetData({
        filters: [{ entity: QueryEntityType.BoolTextSearch, query }],
      }),
    );

    navigateTo('/analyzer/results');
  };

  const goToSearchPage = (query: string) => {
    if (hasSearchV3) {
      searchOnSearchV3(query);
    } else {
      dispatch(search.actions.clearUnsupportedQuery());
      dispatch(search.actions.setBooleanQuery(query));
      dispatch(
        search.actions.setQuery({
          booleanQuery: query,
          filters: [],
          fields: {},
        }),
      );
      dispatch(search.actions.clearFilterValues());
    }
    navigateTo('/search');
  };

  const handleOnShowMoreClick = (query: string) => {
    if (hasSearchV2Feature) {
      goToSearchPage(query);
    } else {
      goToAnalyzerPage(query);
    }

    handleHideModal();
  };

  const handleOnLinkClick = (documentId: number) => {
    const href = `/document/${documentId}`;
    href.startsWith('http') ? window.open(href, '_blank') : history.push(href);
  };

  const renderSearchOverlay = () => {
    return (
      <QuickSearchModal
        isVisible={modal === MODAL_QUICK_SEARCH}
        hideModal={handleHideModal}
        onSearchSubmit={goToSearchPage}
      />
    );
  };

  const renderSearchModal = () => {
    return (
      <EcModal
        modalType={MODAL_AUTOCOMPLETE}
        title="Search"
        width="720px"
        toViewDocument={true}
        hideModal={handleHideModal}
        handleOnSearchItemClick={handleOnLinkClick}
        handleOnShowMoreClick={handleOnShowMoreClick}
      />
    );
  };

  const searchModal = (
    <>
      {modal === MODAL_QUICK_SEARCH && renderSearchOverlay()}
      {modal === MODAL_AUTOCOMPLETE && renderSearchModal()}
    </>
  );

  return [searchModal, handleShowModal];
};
