import { useEffect, useState } from 'react';

import {
  Button,
  ContentContainer,
  Layout,
  StatusMessage,
  useDebouncedState,
  useToast,
} from '~/eds';
import { useConversationPipeline } from '~/features/ask-anything/hooks';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { Nullable, Question, QuestionGroup, Uuid } from '~/types';

import { QuestionGroupList } from './QuestionGroupList';
import { SearchAndFilters } from './SearchAndFilters';
import { useQuestionGroupFormModal } from './useQuestionGroupFormModal';

const failedToLoadQuestionGroupsMessage = {
  status: 'warning' as const,
  title: 'Error: Unable to Load Saved Question Groups',
  message: 'Failed to load saved question groups.',
};

export const QuestionGroups = () => {
  const [debouncedSearch, search, setSearch] = useDebouncedState('');
  const [visibility, setVisibility] = useState<Nullable<'PRIVATE' | 'SHARED'>>(
    null,
  );

  const isShareQuestionGroupEnabled = useFlag(FlagType.ShareQuestionGroup);

  const { toast } = useToast();
  const {
    data: questionGroups,
    isFetching,
    isError,
  } = api.endpoints.getQuestionGroups.useQuery({
    search: debouncedSearch,
    visibility,
  });

  const [
    questionGroupFormModal,
    openQuestionGroupFormModal,
  ] = useQuestionGroupFormModal({
    questionGroup: null,
  });

  // Clear the queue when the component unmounts
  useEffect(() => {
    return clearQueue;
  }, []);

  useEffect(() => {
    if (isError) {
      toast(failedToLoadQuestionGroupsMessage);
    }
  }, [isError]);

  const renderStatusMessage = () => {
    if (isError) {
      return <StatusMessage {...failedToLoadQuestionGroupsMessage} />;
    }

    if (!isFetching && debouncedSearch && questionGroups?.length === 0) {
      return (
        <StatusMessage
          status="info"
          title="No saved question groups match the search"
          message="Try searching with a different text."
        />
      );
    }

    if (!isFetching && !debouncedSearch && questionGroups?.length === 0) {
      return (
        <StatusMessage
          status="info"
          title="No question groups yet"
          message="Create a question group by clicking on the Create a Question Group button below."
        />
      );
    }

    return null;
  };

  const onFinishQueue = () => {
    setTimeout(() => {
      clearQueue();
    }, 3000);
  };

  const {
    postMessage,
    clearQueue,
    parentId: runningQuestionGroupId,
  } = useConversationPipeline({
    onFinishQueue,
  });

  const runQuestion = (question: Question, questionGroupId: Uuid) => {
    postMessage({
      id: question.id,
      message: question.text,
      parentId: questionGroupId,
    });
  };

  const runQuestionGroup = (
    questionGroup: QuestionGroup,
    questions: Question[],
  ) => {
    const messages = questions.map((question) => ({
      id: question.id,
      message: question.text,
      parentId: questionGroup.id,
    }));
    postMessage(messages);
  };

  return (
    <Layout direction="column" styles={componentStyles.mainContainer}>
      <Layout
        spacing={4}
        direction="column"
        styles={componentStyles.questionGroupsContainer}
      >
        <SearchAndFilters
          search={search}
          visibility={isShareQuestionGroupEnabled ? visibility : null}
          onFilter={isShareQuestionGroupEnabled ? setVisibility : undefined}
          onSearch={setSearch}
        />
        {renderStatusMessage() ?? (
          <ContentContainer
            loadingContent={{
              isLoading: isFetching,
              message: 'Loading question groups...',
            }}
          >
            <QuestionGroupList
              runningQuestionGroupId={runningQuestionGroupId}
              questionGroups={questionGroups || []}
              onQuestionClick={runQuestion}
              onQuestionGroupClick={runQuestionGroup}
            />
          </ContentContainer>
        )}
      </Layout>

      <Layout
        justify="center"
        align="center"
        styles={componentStyles.createQuestionGroupButton}
      >
        <Button
          icon="plus"
          iconPosition="left"
          variant="action"
          text="Create a Question Group"
          onClick={openQuestionGroupFormModal}
        />
      </Layout>

      {questionGroupFormModal}
    </Layout>
  );
};

const componentStyles = {
  mainContainer: {
    overflow: 'hidden',
    height: '100%',
    justifyContent: 'space-between',
    width: 'sideContent.width',
    position: 'relative',
    flex: 1,
  },
  questionGroupsContainer: {
    overflow: 'hidden',
    flex: 1,
    marginBottom: 8,
  },
  createQuestionGroupButton: {
    bottom: 0,
    width: '100%',
    position: 'absolute',
  },
};
