import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { types, useToast } from '~/eds';
import { api } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';

import { MessageAction, MessageType } from '../chatbot/types';
import { SideContentTabs } from './SideContent';

const DEFAULT_TIMEOUT = 3000;

interface UseGetUserMessageActionsProps {
  scope: 'document_handler' | 'document_version' | 'all_documents';
  options?: {
    timeout?: number;
  };
}

export const useGetUserMessageActions = ({
  scope,
  options = {},
}: UseGetUserMessageActionsProps): ({
  resolveIcon?: (message: MessageType) => types.IconType;
} & MessageAction)[] => {
  const [savedQuestionIds, setSavedQuestionIds] = useState<string[]>([]);
  const [
    createQuestion,
    { isLoading },
  ] = api.endpoints.createQuestion.useMutation();
  const { toast } = useToast();

  // redux
  const dispatch = useDispatch();

  const openSideContent = (tab?: SideContentTabs) => {
    dispatch(chatbotSlice.actions.openSideContent(tab));
  };

  // clear saved question ids after 3000ms so the icon will change
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (savedQuestionIds.length) {
      timeout = setTimeout(() => {
        setSavedQuestionIds?.([]);
      }, options?.timeout ?? DEFAULT_TIMEOUT);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [savedQuestionIds]);

  const handleOnClick = useCallback(
    async (message: MessageType) => {
      if (!scope) {
        toast({
          message: `A scope must be selected in order to save a question.`,
          status: 'danger',
        });
      }
      try {
        await createQuestion({ text: message.text, scope }).unwrap();
        setSavedQuestionIds((prev) => [...prev, message.id]);
        toast({
          message: 'Question was successfully saved.',
          status: 'success',
          action: {
            text: 'View',
            level: 'action',
            onClick: () => {
              openSideContent('questions');
            },
          },
        });
      } catch (apiError: any) {
        // TODO: find a better way to show all errors
        const error = apiError.errors[0];
        toast({
          message: `Question was not able to be saved due to ${error.title}.`,
          status: 'danger',
        });
      }
    },
    [scope],
  );

  const resolveIcon = useCallback(
    (message: MessageType): types.IconType => {
      return savedQuestionIds.includes(message.id)
        ? 'bookmark-filled'
        : 'bookmark';
    },
    [savedQuestionIds],
  );

  const userAction = useMemo(
    () => [
      {
        tooltip: isLoading ? 'Saving Question' : 'Save question',
        icon: 'bookmark' as const,
        isLoading: isLoading,
        resolveIcon,
        onClick: handleOnClick,
      },
    ],
    [isLoading, resolveIcon, handleOnClick],
  );

  return userAction;
};
