import { wordCount } from '~/eds';
import { Nullable } from '~/types';

import { PROMPT_WORD_LIMIT } from '../constants';
import {
  ModelOptimize,
  OptimizeState,
  PromptModelPublishStatus,
} from '../types';

export const getModelStatusChip = ({
  optimize,
  publishStatus,
  version,
}: {
  optimize: Nullable<ModelOptimize>;
  publishStatus: Nullable<PromptModelPublishStatus>;
  version: Nullable<number>;
}) => {
  return optimize && (version ? optimize.version === version : true)
    ? getModelOptimizeStateChip(optimize.state)
    : getModelPublishStatusChip(publishStatus);
};

export const getModelOptimizeStateChip = (optimizeState: OptimizeState) => {
  switch (optimizeState) {
    case 'error':
      return {
        icon: 'ai' as const,
        status: 'danger' as const,
        text: 'Boost Error',
      };
    case 'done':
      return {
        icon: 'ai' as const,
        status: 'ai' as const,
        text: 'Boost Ready',
      };
    case 'initializing':
    case 'running':
    default:
      return {
        icon: 'ai' as const,
        status: 'warning' as const,
        text: 'Boost in Progress',
      };
  }
};

export const getModelPublishStatusChip = (
  publishStatus: Nullable<PromptModelPublishStatus>,
) => {
  switch (publishStatus) {
    case 'in_progress':
    case 'resumed':
      return {
        status: 'info' as const,
        text: 'In Progress',
      };
    case 'completed':
      return {
        status: 'success' as const,
        text: 'Published',
      };
    case 'failed':
      return {
        status: 'danger' as const,
        text: 'Failed',
      };
    case 'suspended':
      return {
        status: 'danger' as const,
        text: 'Suspended',
      };
    default:
      return {
        status: 'warning' as const,
        text: 'Draft',
      };
  }
};

export const testIsPromptOverLimit = (prompt: string) =>
  wordCount(prompt) > PROMPT_WORD_LIMIT;
