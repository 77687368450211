import React from 'react';
import { useHistory } from 'react-router-dom';

import { checkEsignatureIntegration } from '~/api/integrations';
import { withCurrentUser } from '~/hocs';
import { useAsync } from '~/hooks';
import { Alert } from '~/ui';
import { getUserClientId, testIsAdmin } from '~/utils/user';

function ESignatureSetupAlert({
  // injected,
  currentUser,
}) {
  const history = useHistory();
  const clientId = getUserClientId(currentUser);

  const { response: hasEsignatureIntegration, isLoading } = useAsync(
    checkEsignatureIntegration,
    {},
    { condition: true },
  );

  if (isLoading || hasEsignatureIntegration) {
    return null;
  }

  const actions = [];
  if (testIsAdmin(currentUser)) {
    actions.push({
      text: 'Set up e-signature providers',
      onClick: () => {
        history.push(`/admin/console/${clientId}/integrations`);
      },
    });
  }

  return (
    <Alert actions={actions} variant="info">
      This account has not set up an e-signature provider yet.
    </Alert>
  );
}

export default withCurrentUser(ESignatureSetupAlert);
