import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getEnvVariable } from '~/dev';
import { PageHeader as EdsPageHeader } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { AskAnythingButton } from '~/features/ask-anything';
import { useCandu } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import {
  useCurrentUser,
  useDashboardsSubnavs,
  useHasFeatureFlag,
  usePermission,
  useSearchModal,
} from '~/hooks';
import { selectors } from '~/redux';
import dashboardV2 from '~/redux/slices/dashboardV2';
import search from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { RoutePathType, useRouting } from '~/routing';
import { createNavlinks } from '~/utils';
import { logout } from '~/utils/logout';

import { trackSegment } from '../SegmentAnalytics';

export const PageHeader = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const user = useCurrentUser();
  const { location } = useRouting();
  const [searchModal, setModal] = useSearchModal();

  // initialize candu once the page is loaded (consider moving it further upstream, but after the user has logged in)
  useCandu(user);

  const banner = useSelector(selectors.selectPageBanner(pathname));

  const { dashboards } = useDashboardsSubnavs();
  const hasAlertsInSearchSubNav = useFlag(FlagType.AlertsInSearchSubNav);
  const hasClauseLibrary = useFlag(FlagType.ClauseLibrary);
  const hasDashboardV2 = useFlag(FlagType.DashboardV2);
  const enableFieldAi = useFlag(FlagType.FieldAi);
  const hasAskAnything = useFlag(FlagType.AskAnything);

  const enableCandu = useFlag(FlagType.CanduScript);

  const dashboard = useSelector(selectors.selectActiveDashboard);
  const activeSearch = useSelector(selectors.selectSearchV3SavedSearch);
  const dashboardAndPathname = { ...dashboard, pathname };

  const onNavLinkClick = (pathname: string) => {
    // TODO: the relevant domain pages should own the state cleanup logic
    dispatch(search.actions.reset());
    dispatch(dashboardV2.actions.reset());
    dispatch(searchV3.actions.reset());
    history.push(pathname);
  };

  const hasSettings = useHasFeatureFlag(FeatureFlagType.Settings);

  const hasDocumentsAccess = usePermission({
    permission: { resourceId: 'documents', resourceType: 'view' },
  });

  const hasDocumentGroupsAccess = usePermission({
    permission: { resourceId: 'document_groups', resourceType: 'view' },
  });

  const hasWorkflowTicketsAccess = usePermission({
    permission: { resourceId: 'workflow_tickets', resourceType: 'view' },
  });
  const hasWorkflowBuilderAccess = usePermission({
    permission: { resourceId: 'workflow_templates', resourceType: 'view' },
  });

  const hasClauseLibraryAccess = usePermission({
    permission: { resourceId: 'clause_library', resourceType: 'view' },
  });

  const hasClauseSamples = usePermission({
    permission: { resourceId: 'clause_samples', resourceType: 'view' },
  });

  const hasDocumentXRay = usePermission({
    permission: { resourceId: 'document_x_ray', resourceType: 'view' },
  });
  const hasDocumentsDashboard = usePermission({
    permission: { resourceId: 'documents_dashboard', resourceType: 'view' },
  });
  const hasWorkflowsDashboard = usePermission({
    permission: { resourceId: 'workflows_dashboard', resourceType: 'view' },
  });

  const hasAskAnythingPermission = usePermission({
    permission: {
      resourceId: 'conversational_ai',
      resourceType: 'edit',
    },
  });

  const links = useMemo(
    () =>
      createNavlinks(
        user,
        onNavLinkClick,
        dashboards,
        activeSearch,
        hasAlertsInSearchSubNav,
        hasClauseLibrary,
        hasDashboardV2,
        enableFieldAi,
        dashboardAndPathname,
        {
          documents: !!hasDocumentsAccess.hasPermission,
          documentGroups: !!hasDocumentGroupsAccess.hasPermission,
          workflowTickets: !!hasWorkflowTicketsAccess.hasPermission,
          workflowBuilder: !!hasWorkflowBuilderAccess.hasPermission,
          clauseLibrary: !!hasClauseLibraryAccess.hasPermission,
          automation: !!hasClauseSamples.hasPermission,
          documentXRay: enableFieldAi && !!hasDocumentXRay.hasPermission, // IMPORTANT to gate behind feature flag as we continue to refactor formally for RBAC permissions
          documentsDashboard: !!hasDocumentsDashboard.hasPermission,
          workflowsDashboard: !!hasWorkflowsDashboard.hasPermission,
        },
      ),
    [
      user,
      dashboards,
      activeSearch,
      hasDocumentsAccess,
      hasDocumentGroupsAccess,
      hasWorkflowTicketsAccess,
      hasWorkflowBuilderAccess,
      hasClauseLibraryAccess,
      hasClauseSamples,
      hasDocumentsDashboard,
      hasWorkflowsDashboard,
    ],
  );

  const help = {
    actions: [
      {
        label: 'Knowledge Center',
        href:
          getEnvVariable('ZENDESK_AUTH_ENTRY_URL') ||
          'https://support.evisort.com/hc/en-us',
        value: 'knowledge-center',
      },
      {
        label: 'Evisort Academy',
        href: 'https://academy.evisort.com/',
        value: 'evisort-academy',
      },
    ],
  };

  const profile = useMemo(() => {
    const settings = [];
    if (hasSettings) {
      settings.push({
        label: 'Settings',
        pathname: '/settings',
        value: 'profileSettings',
      });
    }

    if (hasDocumentsAccess.hasPermission) {
      settings.push({
        label: 'My Deleted Items',
        pathname: '/my-deleted-items',
        value: 'myDeletedItems',
      });
    }

    settings.push({
      label: 'Log out',
      onClick: () => logout(user),
      value: 'logOut',
    });

    return { settings, user };
  }, [user, hasDocumentsAccess]);

  const onSearchClick = () => {
    trackSegment('selectNavigation', {
      name: 'Quick Search',
    });
    setModal();
  };

  // TODO: this is not ideal and should be driven via `routes.ts` configuration to include/hide the header.  But this logic needs significant incremental refactoring to decouple this module.
  if (
    [
      RoutePathType.Login,
      RoutePathType.LoginEmbed,
      RoutePathType.LoginEmbedSuccess,
    ].includes(location.pathname as RoutePathType)
  ) {
    return null;
  }

  return (
    <>
      <EdsPageHeader
        ctaButton={
          hasAskAnything && hasAskAnythingPermission.hasPermission ? (
            <AskAnythingButton />
          ) : undefined
        }
        isLoading={
          hasDocumentsAccess.isLoading ||
          hasDocumentGroupsAccess.isLoading ||
          hasWorkflowTicketsAccess.isLoading ||
          hasWorkflowBuilderAccess.isLoading ||
          hasClauseLibraryAccess.isLoading ||
          hasClauseSamples.isLoading ||
          hasAskAnythingPermission.isLoading
        }
        activePathname={pathname}
        banner={banner}
        help={enableCandu ? { actions: [] } : help}
        links={links}
        profile={profile}
        onSearchClick={onSearchClick}
      />
      {searchModal}
    </>
  );
};
