import { CheckboxCell } from './CheckboxCell';
import { ChipsCell } from './ChipsCell';
import { DatetimeCell } from './DatetimeCell';
import { FileSystemCell } from './FileSystemCell';
import { LinkCell } from './LinkCell';
import { LoadingCell } from './LoadingCell';
import { NumberCell } from './NumberCell';
import { RadioCell } from './RadioCell';
import { StatusTextCell } from './StatusTextCell';
import { StepsCell } from './StepsCell';
import { TextCell } from './TextCell';
import { UserCell } from './UserCell';
import { UsersCell } from './UsersCell';

export const renderers = {
  checkbox: CheckboxCell,
  chips: ChipsCell,
  datetime: DatetimeCell,
  link: LinkCell,
  loading: LoadingCell,
  number: NumberCell,
  radio: RadioCell,
  statusText: StatusTextCell,
  steps: StepsCell,
  text: TextCell,
  user: UserCell,
  users: UsersCell,
  filesystem: FileSystemCell,
};
