import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Layout, SearchInput, Table } from '~/eds';
import { actions } from '~/redux';

import { Endpoint } from './types';

interface Props {
  endpoints: Endpoint[];
}

export const Endpoints = ({ endpoints }: Props) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'endpointUrl',
      title: 'Endpoint URL',
      cellType: 'link',
      mapCellProps: (endpoint: Endpoint) => ({
        pathname: endpoint.endpointUrl,
        text: endpoint.endpointUrl,
        onClick: () => {
          dispatch(actions.setActiveEndpoint(endpoint));
        },
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (endpoint: Endpoint) => ({
        chips: [
          {
            text: endpoint.status,
            status: 'success',
          },
        ],
      }),
    },
    {
      key: 'description',
      title: 'Description',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: endpoint.description,
      }),
    },
    {
      key: 'selectedEvents',
      title: 'Selected Events',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: `${endpoint.selectedEvents.length}`,
      }),
      width: 'm',
    },
    {
      key: 'dateCreated',
      title: 'Created',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateCreated,
        format: 'full',
      }),
      width: 'm',
    },
    {
      key: 'dateModified',
      title: 'Modified',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateModified,
        format: 'full',
      }),
      width: 'm',
    },
  ];

  const handleViewDetails = (endpoint: Endpoint) => {
    dispatch(actions.setActiveEndpoint(endpoint));
  };

  return (
    <Layout preset="sections">
      <Layout
        direction="rows"
        justify="space-between"
        spacing={12}
        role="toolbar"
      >
        <SearchInput
          name="Endpoints Search"
          onChange={() => {
            /*TBD*/
          }}
          value={null}
          width="input.l.width"
        />
        <Button
          text="Add Endpoint"
          variant="primary"
          onClick={() => {
            dispatch(actions.setEndpointAction('create'));
          }}
        />
      </Layout>
      <Table
        data={endpoints}
        columns={columns}
        options={{
          enableManageColumns: false,
          enableSelectRows: false,
        }}
        rowActions={[
          {
            label: 'View Details',
            onClick: handleViewDetails,
          },
          {
            label: 'View Attempts',
            onClick: () => {
              /*TBD*/
            },
          },
          {
            label: 'Edit',
            onClick: () => {
              dispatch(actions.setEndpointAction('edit'));
            },
          },
          {
            label: 'Suspend',
            onClick: () => {
              dispatch(actions.setEndpointAction('suspend'));
            },
          },
          {
            label: 'Delete',
            onClick: () => {
              dispatch(actions.setEndpointAction('delete'));
            },
          },
        ]}
      />
    </Layout>
  );
};
