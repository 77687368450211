import { PromptModelTestCase } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelTestCase as PromptModelTestCaseResponse } from '../../types/sylvanus/model';
import { mapTestCase } from './transformers';

interface Request {
  modelId: Uuid;
  version: number;
  testCaseNumber: number;
}

export const executePromptModelTestCase = async ({
  modelId,
  version,
  testCaseNumber,
}: Request): Promise<PromptModelTestCase> => {
  const response: PromptModelTestCaseResponse = await sylvanus.post(
    `/model/${modelId}/version/${version}/testcase/${testCaseNumber}/execute`,
  );

  return mapTestCase(response);
};
