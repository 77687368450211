import { sortBy, union } from 'lodash';
import React, { useMemo } from 'react';

import { Option as BaseOption, Nullable, RecordKey } from '../../types';
import { typedMemo } from '../../utils';
import { CheckboxGroup } from '../CheckboxGroup';
import { Layout } from '../Layout';

type GroupName = string; // alias

type GroupValues<V> = Nullable<V[]>;

type Option<V> = BaseOption<V, { group: string }>;

type Value<V> = Nullable<Record<GroupName, V[]>>;

interface Group<V> {
  /** CheckboxGroup name */
  name: string;
  /** CheckboxGroup options */
  options: Option<V>[];
  /** CheckboxGroup label */
  label: string;
  /** Group info tooltip */
  info?: string;
  /** CheckboxGroup pinnable */
  isPinnable?: boolean;
  /** CheckboxGroup disabled */
  disabled?: boolean;
}

interface Props<V> {
  /** Checkbox groups */
  groups: Group<V>[];
  /** Value tracking selected values keyed on group names */
  value: Value<V>;
  /** Value change handler */
  onChange: (updatedValue: Value<V>) => void;
  /** Group columns */
  columns?: number;
  /** Pinned values */
  pins?: V[];
  /** When option.label matches search, render a text match */
  search?: string;
  /** Enables pin features when callback is provided */
  onUpdatePins?: (
    updatedPins: V[],
    action: { value: V; type: 'pin' | 'unpin' },
  ) => void;
  /** CheckboxGroup mode */
  mode?: 'descriptive';
}

export const CheckboxGroups = typedMemo(
  <V extends RecordKey>({
    columns = 1,
    groups,
    mode,
    pins = [],
    search,
    value,
    onChange,
    onUpdatePins,
  }: Props<V>) => {
    const handleChange = (groupName: string) => (
      updatedGroupValues: GroupValues<V>,
    ) => {
      const updatedValue = {
        ...(value || {}),
        [groupName]: updatedGroupValues || [],
      };
      onChange(updatedValue);
    };

    // normalized value lookup
    const valueLookup = useMemo(
      () => new Set(Object.values(value || {}).flat()),
      [value],
    );

    // normalized options lookup
    const optionsLookup = useMemo(() => {
      return groups.reduce((acc, group) => {
        group.options.forEach((option) => {
          acc[option.value] = {
            ...option,
            data: {
              group: group.name,
            },
          };
        });
        return acc;
      }, {} as Record<RecordKey, Option<V>>);
    }, [groups]);

    const enablePins = Boolean(onUpdatePins);

    const pinnedValues = useMemo(
      () => pins.filter((pin) => valueLookup.has(pin)),
      [pins, valueLookup],
    );

    const pinnedOptions = useMemo(() => {
      const pinOptions = pins
        .map((pin) => optionsLookup[pin])
        .filter((pin) => pin);
      return sortBy(pinOptions, 'label');
    }, [optionsLookup, pins]);

    const handleChangePinValue = (updatedPinValues: Nullable<V[]>) => {
      const updatedValue = { ...value }; // clone
      pinnedOptions.forEach((pinnedOption) => {
        const { data, value: pinnedValue } = pinnedOption;
        if (updatedValue && data) {
          const { group } = data;
          const groupValues = updatedValue[group] || [];
          updatedValue[group] = updatedPinValues?.includes(pinnedValue)
            ? union([...groupValues, pinnedValue])
            : groupValues.filter((groupValue) => groupValue !== pinnedValue);
        }
      });
      onChange(updatedValue);
    };

    const pinnedGroup = enablePins && (
      <CheckboxGroup
        columns={columns}
        label="Pinned"
        mode={mode}
        name="pinned"
        options={pinnedOptions}
        pins={pins}
        search={search}
        value={pinnedValues}
        onChange={handleChangePinValue}
        onUpdatePins={onUpdatePins}
      />
    );

    return (
      <Layout direction="column" spacing={6}>
        {pinnedGroup}
        {groups.map(
          ({ info, label, name, options, isPinnable = true, disabled }) => (
            <CheckboxGroup
              key={name}
              columns={columns}
              info={info}
              label={label}
              mode={mode}
              name={name}
              options={options}
              pins={pins}
              search={search}
              value={value ? value[name] : []}
              onChange={handleChange(name)}
              onUpdatePins={isPinnable ? onUpdatePins : undefined}
              disabled={disabled}
            />
          ),
        )}
      </Layout>
    );
  },
);
