import React from 'react';

import { useCurrentUser } from '~/hooks';
import { checkIsAdmin } from '~/permissions';
import { useRouting } from '~/routing';

import { ClauseLibraryPage } from './ClauseLibraryPage/index';
import { TabValue } from './types';
import { formatUrl } from './utils';
import { WelcomePage } from './WelcomePage/index';

export const ClauseLibrary = () => {
  const { history, getSearchParam } = useRouting();
  const tab = getSearchParam<TabValue>('tab');

  const currentUser = useCurrentUser();
  const isAdmin = checkIsAdmin(currentUser);

  const clauseSummariesData = []; // placeholder

  const isWelcomeEnabled = !tab && isAdmin && clauseSummariesData.length === 0;

  const handleContinue = () => {
    history.push(formatUrl('ai-suggested'));
  };

  const handleSkip = () => {
    history.push(formatUrl('published'));
  };

  if (isWelcomeEnabled) {
    return <WelcomePage onContinue={handleContinue} onSkip={handleSkip} />;
  }

  return <ClauseLibraryPage />;
};
