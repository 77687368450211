/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as apiServices from '~/services';
import { bytesToBlob } from '~/utils/files';

const { pilot } = apiServices;

export async function uploadAttachment(file) {
  const formData = new FormData();
  formData.append('file', file);
  return await pilot.post('/attachment/', formData);
}

// TODO: Switch these and uploadAtttachment(s) in api/tickets.js to not accept callbacks here
export const uploadAttachments = (files, callback, errorHandler) => {
  const promises = files.map((file) => uploadAttachment(file));
  const promisesResolved = promises.map((promise) =>
    promise.catch(() => {
      errorHandler(promise);
    }),
  );
  return pilot
    .concurrentRequests(promisesResolved)
    .then((data) => callback(data));
};

export async function downloadAttachment(attachmentId) {
  const config = { responseType: 'blob' };

  let original;
  try {
    original = await pilot.get(`/attachment/${attachmentId}`, config);
  } catch {
    original = null;
  }

  return bytesToBlob(original, original.type);
}

export function uploadFile({ service, method = 'post', url, payload, config }) {
  return apiServices[service][method](url, payload, config);
}
