import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { CommentDraftWithMentions } from '~/components/Shared/Comments';
import { ContextMenuValues } from '~/components/Shared/ContextMenu';
import Toolbar from '~/components/Shared/EcPdfViewerHighlighter/components/Toolbar';
import { showToast } from '~/components/Shared/EcToast';
import { Box, ContentContainer } from '~/eds';
import {
  AlgorithmStatusType,
  DataFieldType,
  EntityType,
  FileExtensionType,
  HighlightType,
} from '~/enums';
import { TagClauseModal } from '~/features/quick-ai';
import { FlagType, withFlags } from '~/flags';
import {
  canUsePdfPreviewHighlighting,
  canUsePostSigActivityFeed,
} from '~/permissions';
import { getDocumentOriginal } from '~/redux/api/methods';
import { MODAL_DELETE, MODAL_TAG_CLAUSE } from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';
import {
  createPositionObject,
  renderHighlight,
  scrollToHighlight,
  validateHighlightCoordinatesFormat,
} from '~/utils/highlights';
import { getPdfDocumentFromBlob } from '~/utils/pdfjs';

import EcCard from '../../Shared/EcCard';
import EcDocumentViewer from '../../Shared/EcDocumentViewer';
import EcModal from '../../Shared/EcModal';
import PdfViewerHighlighter, {
  PdfHighlighterContext,
} from '../../Shared/EcPdfViewerHighlighter';
import ChevronLeftIcon from '../../Shared/Icons/ChevronLeftIcon';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import CloseIcon from '../../Shared/Icons/CloseIcon';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import ContractSummary from '../ContractSummary';
import { getDocumentPDF } from '../Document.data';
import KeyInformation from '../KeyInformation';
import styles from './DocumentContentsPanel.styles';
import { EditProvision } from './EditProvision';
import EditProvisionMenu from './EditProvisionMenu';

const defaultContainerStyle = { padding: '16px', overflowY: 'scroll' };
let docSideInfoStyle;

const SAMPLE_MIN_WORD_COUNT_BULK_TAGGING = 5;
const SAMPLE_MIN_WORD_COUNT_SINGLE_TAGGING = 1;

const componentStyles = {
  documentParentPanel: {
    position: 'relative',
  },
  documentContentPanel: ({ isVisible }) => ({
    visibility: isVisible ? 'visible' : 'hidden',
  }),
  documentPanelSpinner: {
    position: 'absolute',
    inset: 0, // TODO remove the zIndex and background color
    //  but need for now to hide the comment dialog
    //  which has a zIndex of 9999
    zIndex: 10000,
    backgroundColor: 'white',
  },
};

class DocumentContentsPanel extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.fabWrapper = React.createRef();
    this.pdfWrapperRef = React.createRef();
    this.cardRef = React.createRef();
    this.highlightsCache = {};
    this.hideContextMenuAndSelection = undefined;

    this.state = {
      shouldShowSelectionControls: false,
      shouldLoadingDocumentContent: false,
      fabVerticalPosition: 0,
      fieldTokenIndex: 0,
      selectedTokens: [],
      selectionContent: '',
      selectedPosition: {},
      isTagClauseModalOpen: false,
      isRemoveProvisionModalOpen: false,
      selectedProvision: null,
      showOCR: true,
      htmlData: null,
      pdfFile: null,
      pdfHtmlData: null,
      pdfScaleValue: undefined,
      numPages: null,
      errorLoadDocument: false,
      highlights: [],
      sources: [],
      sourceHighlights: [],
    };

    this.openTagClauseModal = this.openTagClauseModal.bind(this);
    this.closeTagClauseModal = this.closeTagClauseModal.bind(this);
    this.openRemoveProvisionModal = this.openRemoveProvisionModal.bind(this);
    this.closeRemoveProvisionModal = this.closeRemoveProvisionModal.bind(this);
    this.createProvisionText = this.createProvisionText.bind(this);
    this.updateProvision = this.updateProvision.bind(this);
    this.removeProvision = this.removeProvision.bind(this);
    this.onContractSummaryProvisionSelect = this.onContractSummaryProvisionSelect.bind(
      this,
    );
    this.onContractSummaryProvisionSelectPdfDocument = this.onContractSummaryProvisionSelectPdfDocument.bind(
      this,
    );
    this.onContractSummaryProvisionSelectTextDocument = this.onContractSummaryProvisionSelectTextDocument.bind(
      this,
    );
    this.onKeyInformationFieldTokenSelect = this.onKeyInformationFieldTokenSelect.bind(
      this,
    );
    this.handleOCRClick = this.handleOCRClick.bind(this);
    this.handlePDFClick = this.handlePDFClick.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.onDocumentLoadError = this.onDocumentLoadError.bind(this);
    this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    this.resizeContentContainer = this.resizeContentContainer.bind(this);
    this.highlightFieldToken = this.highlightFieldToken.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.resizeContentContainer();
    this.setState({ htmlData: this.props.htmlData });
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.outsideOfDocumentClickHandle);
    window.addEventListener('resize', this.resizeContentContainer);
    if (this.isPdfPreviewHighlighting()) {
      this.loadDocument();
    }
    if (this.props.documentVersionHighlights) {
      this.addCommentHighlightsToDocument();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.outsideOfDocumentClickHandle);
    window.removeEventListener('resize', this.resizeContentContainer);
  }

  componentDidUpdate(prevProps) {
    const { activeSourceIndex, flags, OCRStatus, sources } = this.props;
    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];
    if (
      enableUnprocessedPDFPreview &&
      this.isPdfPreviewHighlighting() &&
      OCRStatus === AlgorithmStatusType.Success &&
      prevProps.OCRStatus === AlgorithmStatusType.InProgress
    ) {
      this.loadDocument();
    }
    if (this.props.documentProvisions !== prevProps.documentProvisions) {
      this.unhighlightHtmlTokens();
      this.setState({ shouldShowSelectionControls: false, selectedTokens: [] });
    } else if (
      this.props.documentVersionHighlights !==
      prevProps.documentVersionHighlights
    ) {
      this.addCommentHighlightsToDocument();
    }
    if (sources.length > 0 && sources !== prevProps.sources) {
      this.setState({ fieldTokenIndex: activeSourceIndex, sources }, () => {
        this.highlightSource();
      });
    }
  }

  addCommentHighlightsToDocument = () => {
    this.setState({ highlights: [...this.props.documentVersionHighlights] });
  };

  highlightSource = () => {
    const { pdfFile } = this.state;
    this.unhighlightHtmlTokens();
    if (this.isPdfPreviewHighlighting()) {
      if (pdfFile) {
        this.highlightPdfDocumentSource();
      }
    } else {
      this.highlightTextDocumentSource();
    }
  };

  highlightPdfDocumentSource = () => {
    const { fieldTokenIndex, sources } = this.state;
    const sourceHighlights = sources.map((source) => {
      const { id, coordinates } = source;
      if (
        !coordinates?.length ||
        !validateHighlightCoordinatesFormat(coordinates)
      )
        return null;
      return this.createOrGetHighlightFromCache({
        highlightKey: this.getDocumentInformationHighlightCacheKey(
          id,
          fieldTokenIndex,
        ),
        type: HighlightType.DocumentInfo,
        position: createPositionObject(coordinates),
        highlightedContent: '',
      });
    });
    const activeHighlight = sourceHighlights[fieldTokenIndex];
    if (!activeHighlight) return null;
    this.setState(
      (prevState) => ({
        highlights: [
          ...prevState.highlights.filter(
            (h) => h.type === HighlightType.Comment,
          ),
          activeHighlight,
        ],
        sourceHighlights,
        selectedProvision: null,
        shouldShowFieldHighlightControls: sources.length > 1,
        selectedFieldToHighlight: { ...sources[fieldTokenIndex] },
      }),
      () => scrollToHighlight(this.context.viewer, activeHighlight.position),
    );
    this.props.setActiveHighlight(activeHighlight.highlightKey);
  };

  highlightTextDocumentSource = () => {
    const { fieldTokenIndex, sources } = this.state;
    const activeSource = sources[fieldTokenIndex];
    const { html_tokens } = activeSource;

    const activeTokens = html_tokens[fieldTokenIndex];
    this.highlightHtmlTokensArray(activeTokens);

    if (!html_tokens || !activeTokens) {
      return null;
    }

    if (activeTokens.length > 0) {
      const firstToken = document.getElementById(activeTokens[0]);

      if (firstToken)
        firstToken.scrollIntoView({ behavior: 'auto', block: 'center' });

      this.setState({
        selectedTokens: activeTokens,
        shouldShowFieldHighlightControls: html_tokens.length > 1,
        selectedFieldToHighlight: activeSource,
      });
    }
  };

  resizeContentContainer() {
    docSideInfoStyle = {
      ...defaultContainerStyle,
      minHeight: '400px',
      maxHeight: window.innerHeight - 420 + 'px',
      height: window.innerHeight - 420 + 'px',
    };
    this.setState({ docSideInfoStyle });
  }

  // needed to handle clicks outside of document, since handles for mouse down/up are on doc wrapper only
  outsideOfDocumentClickHandle = () => {
    if (!this.state.isTagClauseModalOpen && window.getSelection().isCollapsed) {
      this.unhighlightHtmlTokens();

      if (
        this.state.shouldShowSelectionControls ||
        !!this.state.selectedTokens.length
      ) {
        return this.setState({
          shouldShowSelectionControls: false,
          selectedTokens: [],
        });
      }
    }
  };

  handleOCRClick() {
    this.setState({ showOCR: true });
  }

  handlePDFClick() {
    this.setState({ showOCR: false });
  }

  onDocumentLoadSuccess(document) {
    this.setState({
      numPages: document.numPages,
      errorLoadDocument: false,
    });
  }

  onDocumentLoadError() {
    this.setState({ errorLoadDocument: true });
    this.setState({ numPages: document.numPages });
  }

  loadDocument = () => {
    const { flags, OCRStatus, queued } = this.props;
    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];
    const unprocessedPDFPreview =
      enableUnprocessedPDFPreview &&
      (OCRStatus === AlgorithmStatusType.InProgress || queued);
    const getOriginalInProgress = unprocessedPDFPreview
      ? getDocumentOriginal
      : getDocumentPDF;
    const getPdfFunction = this.isPdfPreviewHighlighting()
      ? getOriginalInProgress
      : getDocumentOriginal;

    getPdfFunction(this.props.documentHandlerId)
      .then(async (data) => {
        if (data.type === 'application/pdf') {
          // not sure why application/docx
          if (canUsePdfPreviewHighlighting(this.props.user)) {
            const pdfDocument = await getPdfDocumentFromBlob(
              data,
              !flags[FlagType.PDFJSDisableRunningJSCode],
            );
            this.setState({ pdfFile: pdfDocument });
          }
        } else {
          data.text().then((text) => {
            this.setState({
              pdfHtmlData: text
                .replace(
                  '#page-container{position:absolute;',
                  '#page-container{position:inherit;',
                )
                .replace(/\\"/g, '"')
                .replace(/<br\/>/gi, ''),
            });
          });
        }
      })
      .catch(() => this.onDocumentLoadError());
  };

  handleOnMouseDownPdfViewer = () => {
    this.unhighlightSystemCreatedHighlights();
    this.setState({
      shouldShowFieldHighlightControls: false,
      fieldTokenIndex: 0,
    });
  };

  handleOnMouseDown = () => {
    // needed for issue fix when clicked inside of selection (otherwise it wouldn't show correct isCollapsed value)
    window.getSelection().empty();

    this.unhighlightHtmlTokens();
    // needed for issue fix when selecting is started outside of document and ends inside
    this.setState({ selectedProvision: null, fieldTokenIndex: 0 });
  };

  handleOnMouseUp() {
    const selection = window.getSelection();
    const selectionContent = selection?.toString();

    if (selection.isCollapsed || selection.rangeCount === 0) {
      return this.setState({
        shouldShowSelectionControls: false,
        selectedTokens: [],
      });
    }

    const isSingleTokenSelected =
      selection.getRangeAt(0).startContainer ===
      selection.getRangeAt(0).endContainer;

    const tokenElements = isSingleTokenSelected
      ? [selection.focusNode.parentNode]
      : selection.getRangeAt(0).cloneContents().querySelectorAll('.token');
    const selectedTokens = [...tokenElements].map((token) => token.id);

    const firstNode = document.getElementById(selectedTokens[0]);
    const fabOffSetTop =
      this.calculateOffsetFromDocumentWrapper(firstNode) < 50
        ? 100
        : this.calculateOffsetFromDocumentWrapper(firstNode);

    this.highlightHtmlTokens(selectedTokens);
    this.setState({
      shouldShowSelectionControls:
        selectedTokens.length >= SAMPLE_MIN_WORD_COUNT_SINGLE_TAGGING,
      selectedTokens,
      selectionContent,
      fabVerticalPosition: fabOffSetTop,
    });
  }

  highlightHtmlTokens(tokenIds) {
    tokenIds.forEach((tokenId) => {
      const tokenElement = document.getElementById(tokenId);
      if (tokenElement) tokenElement.classList.add('token-highlighted');
    });
  }

  highlightHtmlTokensArray(tokenIds) {
    if (!tokenIds) return null;

    tokenIds.forEach((tokenId) => {
      const tokenElement = document.getElementById(tokenId);
      if (tokenElement) tokenElement.classList.add('token-highlighted');
    });
  }

  unhighlightHtmlTokens() {
    this.setState({ shouldShowFieldHighlightControls: false });
    Array.from(document.getElementsByClassName('token-highlighted')).forEach(
      (tokenElement) => {
        tokenElement.classList.remove('token-highlighted');
      },
    );
  }

  unhighlightSystemCreatedHighlights = () => {
    this.setState((prevState) => ({
      selectedProvision: null,
      shouldShowSelectionControls: false,
      highlights: prevState.highlights.filter(
        (highlight) => highlight.type === HighlightType.Comment,
      ),
    }));
  };

  calculateOffsetFromDocumentWrapper = (htmlElement) =>
    !!htmlElement
      ? htmlElement.getBoundingClientRect().y -
        this.wrapperRef.current.getBoundingClientRect().y
      : 0;

  getDocumentInformationHighlightCacheKey(documentInfoId, coordinateIndex) {
    return `${HighlightType.DocumentInfo}-${documentInfoId}-${coordinateIndex}`;
  }

  onKeyInformationFieldTokenSelect(fieldToHighlight) {
    const { pdfFile } = this.state;
    if (this.isPdfPreviewHighlighting()) {
      if (pdfFile) {
        this.onKeyInformationFieldTokenSelectPdfDocument(fieldToHighlight);
      }
    } else {
      this.onKeyInformationFieldTokenSelectTextDocument(fieldToHighlight);
    }
  }

  onKeyInformationFieldTokenSelectPdfDocument = (fieldToHighlight) => {
    this.unhighlightHtmlTokens();
    const { fieldTokenIndex, selectedFieldToHighlight } = this.state;

    this.setState(
      {
        fieldTokenIndex:
          selectedFieldToHighlight &&
          selectedFieldToHighlight.id !== fieldToHighlight.id
            ? 0
            : fieldTokenIndex,
      },
      () => {
        const { fieldTokenIndex } = this.state;

        const areCoordinatesUndefinedOrEmpty =
          !fieldToHighlight.coordinates ||
          fieldToHighlight.coordinates.length === 0;

        const areFieldCoordinatesUndefinedOrEmpty =
          areCoordinatesUndefinedOrEmpty ||
          !fieldToHighlight.coordinates[fieldTokenIndex] ||
          fieldToHighlight.coordinates[fieldTokenIndex].length === 0;

        if (areFieldCoordinatesUndefinedOrEmpty) return null;

        const coordinates = fieldToHighlight.coordinates[fieldTokenIndex];
        if (!validateHighlightCoordinatesFormat(coordinates)) {
          showToast(
            ERROR,
            'An error occurred when trying to highlight the selected key information',
          );
          return null;
        }

        const highlightKey = this.getDocumentInformationHighlightCacheKey(
          fieldToHighlight.id,
          fieldTokenIndex,
        );
        let highlightedContent = '';

        if (fieldToHighlight.value) {
          highlightedContent =
            fieldToHighlight.type === DataFieldType.ARRAY_MULTIPLE &&
            fieldToHighlight.value[fieldTokenIndex]
              ? (highlightedContent =
                  fieldToHighlight.value[fieldTokenIndex].value)
              : (highlightedContent = fieldToHighlight.value.value);
        }

        const highlight = this.createOrGetHighlightFromCache({
          highlightKey,
          type: HighlightType.DocumentInfo,
          position: createPositionObject(coordinates),
          highlightedContent,
        });

        this.setState(
          (prevState) => ({
            highlights: [
              ...prevState.highlights.filter(
                (h) => h.type === HighlightType.Comment,
              ),
              highlight,
            ],
            shouldShowFieldHighlightControls:
              fieldToHighlight.coordinates.length > 1,
            selectedFieldToHighlight: { ...fieldToHighlight },
          }),
          () => scrollToHighlight(this.context.viewer, highlight.position),
        );
        this.props.setActiveHighlight(highlightKey);
      },
    );
  };

  onKeyInformationFieldTokenSelectTextDocument(fieldToHighlight) {
    this.unhighlightHtmlTokens();
    const { fieldTokenIndex, selectedFieldToHighlight } = this.state;

    this.setState(
      {
        fieldTokenIndex:
          selectedFieldToHighlight &&
          selectedFieldToHighlight.id !== fieldToHighlight.id
            ? 0
            : fieldTokenIndex,
      },
      () => {
        const { fieldTokenIndex } = this.state;
        this.highlightHtmlTokensArray(
          fieldToHighlight.html_tokens[fieldTokenIndex],
        );

        if (
          !fieldToHighlight.html_tokens ||
          !fieldToHighlight.html_tokens[fieldTokenIndex]
        )
          return null;

        if (fieldToHighlight.html_tokens[fieldTokenIndex].length > 0) {
          const firstToken = document.getElementById(
            fieldToHighlight.html_tokens[fieldTokenIndex][0],
          );

          if (firstToken)
            firstToken.scrollIntoView({ behavior: 'auto', block: 'center' });

          this.setState({
            selectedTokens: fieldToHighlight.html_tokens,
            shouldShowFieldHighlightControls:
              fieldToHighlight.html_tokens.length > 1 ? true : false,
            selectedFieldToHighlight: { ...fieldToHighlight },
          });
        }
      },
    );
  }

  createOrGetHighlightFromCache = ({
    highlightKey,
    type,
    position,
    highlightedContent,
  }) => {
    let highlight = this.highlightsCache[highlightKey];
    if (!highlight) {
      highlight = {
        type,
        id: highlightKey,
        position,
        content: { text: highlightedContent },
        comment: { text: '', emoji: '' },
      };
      this.highlightsCache[highlightKey] = highlight;
    }

    return highlight;
  };

  isPdfPreviewHighlighting() {
    const { fileType, user } = this.props;
    const filetypesWithPdfPreviewHighlighting = [
      FileExtensionType.Pdf,
      FileExtensionType.Jpg,
      FileExtensionType.Jpeg,
      FileExtensionType.Png,
      FileExtensionType.Tif,
      FileExtensionType.Tiff,
    ];
    return (
      canUsePdfPreviewHighlighting(user) &&
      filetypesWithPdfPreviewHighlighting.includes(fileType)
    );
  }

  onContractSummaryProvisionSelect(provision, provisionName) {
    const { pdfFile } = this.state;
    if (this.isPdfPreviewHighlighting()) {
      if (pdfFile) {
        this.onContractSummaryProvisionSelectPdfDocument(
          provision,
          provisionName,
        );
      }
    } else {
      this.onContractSummaryProvisionSelectTextDocument(
        provision,
        provisionName,
      );
    }
  }

  getProvisionHighlightCacheKey(type, id) {
    return `${type}-${id}`;
  }

  onContractSummaryProvisionSelectPdfDocument(provision, provisionName) {
    if (provision.coordinates && provision.coordinates.length > 0) {
      if (!validateHighlightCoordinatesFormat(provision.coordinates)) {
        showToast(
          ERROR,
          'An error occurred when trying to highlight the selected provision',
        );
        return null;
      }

      const highlightKey = this.getProvisionHighlightCacheKey(
        HighlightType.Provision,
        provision.id,
      );
      const highlight = this.createOrGetHighlightFromCache({
        highlightKey,
        type: HighlightType.Provision,
        position: createPositionObject(provision.coordinates),
        highlightedContent: provision.content,
      });

      const HIGHLIGHT_DISTANCE_TO_TOP_OF_VIEWER = 100;
      const fabStateAttributes = this.getFABPositionPdfHighlighter(
        HIGHLIGHT_DISTANCE_TO_TOP_OF_VIEWER,
      );

      this.setState(
        (prevState) => ({
          highlights: [
            ...prevState.highlights.filter(
              (h) => h.type === HighlightType.Comment,
            ),
            highlight,
          ],
          ...fabStateAttributes,
          shouldShowSelectionControls: true,
          selectedProvision: { ...provision, provisionName },
        }),
        () =>
          scrollToHighlight(
            this.context.viewer,
            highlight.position,
            HIGHLIGHT_DISTANCE_TO_TOP_OF_VIEWER,
          ),
      );
      this.props.setActiveHighlight(highlightKey);
    }
  }

  onContractSummaryProvisionSelectTextDocument(htmlTokens, provisionName) {
    this.highlightHtmlTokens(htmlTokens.html_tokens);
    setTimeout(
      () => document.getElementById('editProvisionMenuIcon')?.focus(),
      1300,
    );

    if (htmlTokens.html_tokens.length > 0) {
      const firstToken = document.getElementById(htmlTokens.html_tokens[0]);

      firstToken.scrollIntoView({ behavior: 'auto', block: 'center' });

      this.setState({
        fabVerticalPosition:
          this.wrapperRef.current.getBoundingClientRect().height / 2 + 20,
        selectedTokens: htmlTokens.html_tokens,
        shouldShowSelectionControls: true,
        selectedProvision: { ...htmlTokens, provisionName },
      });
    }
  }

  getFABPositionPdfHighlighter = (highlightDistanceToTop) => {
    const FLOATING_ACTION_BUTTON_DIMENSION = 50;
    const textLayer = document.querySelector('.textLayer');
    let left = 0;
    const top = highlightDistanceToTop + FLOATING_ACTION_BUTTON_DIMENSION;

    if (textLayer) {
      left =
        document.querySelector('.textLayer').getBoundingClientRect().x -
        FLOATING_ACTION_BUTTON_DIMENSION;
    }

    return {
      fabVerticalPosition: top,
      fabHorizontalPosition: left,
    };
  };

  closeFieldTokenHightlights = () => {
    if (this.isPdfPreviewHighlighting()) {
      this.unhighlightSystemCreatedHighlights();
    } else {
      this.unhighlightHtmlTokens();
    }
    this.setState({
      shouldShowFieldHighlightControls: false,
      fieldTokenIndex: 0,
    });
  };

  createProvisionText(selectedProvisionName, selectedApplyTo, searchQuery) {
    const { onCreateProvision } = this.props;
    const { selectedTokens, selectionContent } = this.state;

    return onCreateProvision(
      selectedProvisionName,
      selectedTokens,
      selectionContent,
      selectedApplyTo,
      searchQuery,
    );
  }

  createProvisionPdf = (
    selectedProvisionName,
    selectedApplyTo,
    searchQuery,
  ) => {
    const { onCreateProvision } = this.props;
    const { selectedPosition, selectionContent } = this.state;

    return onCreateProvision(
      selectedProvisionName,
      selectedPosition,
      selectionContent,
      selectedApplyTo,
      searchQuery,
    );
  };

  updateProvision(selectedProvisionName, selectedApplyTo) {
    const { onUpdateProvision } = this.props;
    const {
      selectedProvision: { id: provisionId, clause_id: clauseId },
      selectedTokens,
      selectionContent,
    } = this.state;

    return onUpdateProvision(
      provisionId,
      clauseId,
      selectedProvisionName,
      selectedTokens,
      selectionContent,
      selectedApplyTo,
    );
  }

  removeProvision() {
    const { onRemoveProvision } = this.props;
    const { selectedProvision } = this.state;

    return onRemoveProvision(
      selectedProvision.id,
      selectedProvision.provisionName,
    );
  }

  openTagClauseModal() {
    this.setState({ isTagClauseModalOpen: true });
  }

  openTagClauseModalPdfHighlighter = (
    selectionContent,
    selectedPosition,
    hideContextMenuAndSelection,
  ) => {
    this.setState({
      isTagClauseModalOpen: true,
      selectionContent,
      selectedPosition,
    });
    this.hideContextMenuAndSelection = hideContextMenuAndSelection;
  };

  closeTagClauseModal() {
    this.setState({ isTagClauseModalOpen: false });
  }

  openRemoveProvisionModal() {
    this.setState({ isRemoveProvisionModalOpen: true });
  }

  closeRemoveProvisionModal() {
    this.setState({ isRemoveProvisionModalOpen: false });
  }

  stopPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  highlightFieldToken(position) {
    const { selectedFieldToHighlight } = this.state;
    this.setState(
      { fieldTokenIndex: this.state.fieldTokenIndex + position },
      () =>
        selectedFieldToHighlight.type === HighlightType.Source
          ? this.highlightSource()
          : this.onKeyInformationFieldTokenSelect(selectedFieldToHighlight),
    );
  }

  renderFieldTokensControls() {
    const { classes } = this.props;
    const {
      fieldTokenIndex,
      selectedFieldToHighlight,
      showOCR,
      sourceHighlights,
    } = this.state;
    const coordinates =
      sourceHighlights.length > 1 &&
      selectedFieldToHighlight.type === HighlightType.Source
        ? sourceHighlights.map((highlight) => highlight.coordinates)
        : selectedFieldToHighlight.coordinates;
    const highlightField = this.isPdfPreviewHighlighting()
      ? coordinates
      : selectedFieldToHighlight.html_tokens;

    if (showOCR) {
      return (
        <div className={classes.fieldTokenWrapper}>
          <div className={classes.fieldTokenName}>
            {selectedFieldToHighlight.name}
          </div>
          <div className={classes.fieldTokenControlButtons}>
            <button
              title="previous hightlight token"
              className={
                fieldTokenIndex === 0
                  ? classes.fieldTokenControlDisabled
                  : classes.fieldTokenControlEnabled
              }
              onClick={() => this.highlightFieldToken(-1)}
            >
              <ChevronLeftIcon />
            </button>
            <button
              title="next hightlight token"
              className={
                fieldTokenIndex === highlightField.length - 1
                  ? classes.fieldTokenControlDisabled
                  : classes.fieldTokenControlEnabled
              }
              onClick={() => this.highlightFieldToken(1)}
            >
              <ChevronRightIcon />
            </button>
            <button
              title="close highlight navigator"
              className={classes.closeFieldTokenHightlightButtons}
              onClick={this.closeFieldTokenHightlights}
            >
              <CloseIcon color="#fff" size="20" />
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderSelectionControls() {
    const { classes, userVisibilityLevel, flags } = this.props;
    const {
      fabVerticalPosition,
      fabHorizontalPosition,
      selectedProvision,
      showOCR,
    } = this.state;
    const canEdit = userVisibilityLevel === 'OPEN';
    const enableQuickAiFilters = flags[FlagType.QuickAiFilters];

    if (!canEdit || !showOCR) return null;

    const left = fabHorizontalPosition ? fabHorizontalPosition : 0;
    return (
      <div
        style={{ top: fabVerticalPosition, left }}
        className={classes.fabWrapper}
      >
        {enableQuickAiFilters ? (
          <EditProvision
            openTagClauseModal={this.openTagClauseModal}
            openRemoveProvisionModal={this.openRemoveProvisionModal}
            selectedProvision={selectedProvision}
          />
        ) : (
          <EditProvisionMenu
            id="editProvisionMenuIcon"
            openTagClauseModal={this.openTagClauseModal}
            openRemoveProvisionModal={this.openRemoveProvisionModal}
            selectedProvision={selectedProvision}
          />
        )}
      </div>
    );
  }

  renderTagClauseModal() {
    const createProvisionFunction = this.isPdfPreviewHighlighting()
      ? this.createProvisionPdf
      : this.createProvisionText;

    const onCreateProvision = async (
      selectedProvisionName,
      selectedApplyTo,
      searchQuery,
    ) => {
      this.setState({ ...this.state, shouldLoadingDocumentContent: true });

      await createProvisionFunction(
        selectedProvisionName,
        selectedApplyTo,
        searchQuery,
      );
      this.hideContextMenuAndSelection?.();
      this.setState({ ...this.state, shouldLoadingDocumentContent: false });
    };

    const { selectionContent } = this.state;
    const { flags } = this.props;
    const bulkTaggingInsufficientWords =
      selectionContent.trim().split(' ').length <
      SAMPLE_MIN_WORD_COUNT_BULK_TAGGING;

    const enableQuickAiFilters = flags[FlagType.QuickAiFilters];

    return (
      <>
        {enableQuickAiFilters ? (
          <TagClauseModal
            hideModal={this.closeTagClauseModal}
            bulkTaggingInsufficientWords={bulkTaggingInsufficientWords}
            createProvision={onCreateProvision}
          />
        ) : (
          <EcModal
            isMulti
            modalType={MODAL_TAG_CLAUSE}
            width="720px"
            hideModal={this.closeTagClauseModal}
            bulkTaggingInsufficientWords={bulkTaggingInsufficientWords}
            createProvision={onCreateProvision}
          />
        )}
      </>
    );
  }

  renderEditProvisionModal() {
    const { selectedProvision } = this.state;

    const onUpdateProvision = async (
      selectedProvisionName,
      selectedApplyTo,
    ) => {
      this.setState({ ...this.state, shouldLoadingDocumentContent: true });

      await this.updateProvision(selectedProvisionName, selectedApplyTo);

      if (this.isPdfPreviewHighlighting()) {
        this.unhighlightSystemCreatedHighlights();
      }
      this.setState({ ...this.state, shouldLoadingDocumentContent: false });
    };

    const bulkTaggingInsufficientWords =
      (selectedProvision?.content ?? '').trim().split(' ').length <
      SAMPLE_MIN_WORD_COUNT_BULK_TAGGING;

    return (
      <EcModal
        modalType={MODAL_TAG_CLAUSE}
        width="720px"
        hideModal={this.closeTagClauseModal}
        preselectedProvisionType={selectedProvision.provisionName}
        bulkTaggingInsufficientWords={bulkTaggingInsufficientWords}
        createProvision={onUpdateProvision}
      />
    );
  }

  renderRemoveProvisionModal() {
    const onRemoveProvision = async () => {
      this.setState({ ...this.state, shouldLoadingDocumentContent: true });

      await this.removeProvision();

      if (this.isPdfPreviewHighlighting()) {
        this.unhighlightSystemCreatedHighlights();
      }
      this.setState({ ...this.state, shouldLoadingDocumentContent: false });
    };

    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title="Remove Provision?"
        text={<div>Are you sure you want to remove this provision tag?</div>}
        infoIcon={<WarningIcon size="20" red />}
        confirmButtonText="Remove"
        deleteItem={onRemoveProvision}
        hideModal={this.closeRemoveProvisionModal}
      />
    );
  }

  renderDocumentViewer() {
    const {
      activeHighlightId,
      fileType,
      flags,
      OCRStatus,
      onCreateComment,
      documentId,
      setActiveHighlight,
      setIsDocumentLoading,
      user,
    } = this.props;
    const {
      numPages,
      pdfFile,
      pdfScaleValue,
      errorLoadDocument,
      docSideInfoStyle,
      htmlData,
      pdfHtmlData,
      highlights,
    } = this.state;
    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];

    const isOcrProcessed = [
      AlgorithmStatusType.Success,
      AlgorithmStatusType.Fallback,
    ].includes(OCRStatus);
    if (this.isPdfPreviewHighlighting()) {
      return (
        <EcCard
          title={
            enableUnprocessedPDFPreview && !isOcrProcessed ? null : (
              <Toolbar
                searchListenOn={this.cardRef}
                zoom={{
                  scale: pdfScaleValue,
                  onChange: (updatedPdfScaleValue) =>
                    this.setState({ pdfScaleValue: updatedPdfScaleValue }),
                }}
              />
            )
          }
          contentStyles={{
            ...docSideInfoStyle,
            overflowY: 'none',
            padding: 0,
          }}
          ref={this.cardRef}
        >
          <PdfViewerHighlighter
            key={pdfScaleValue}
            activeHighlightId={activeHighlightId}
            errorLoadDocument={errorLoadDocument}
            highlights={highlights}
            OCRStatus={OCRStatus}
            pdfFile={pdfFile}
            pdfScaleValue={pdfScaleValue}
            renderHighlight={(highlight) =>
              renderHighlight(highlight, activeHighlightId === highlight.id, {
                onClick: setActiveHighlight,
              })
            }
            setIsDocumentLoading={setIsDocumentLoading}
            contextMenu={(position, text, hideMenu) => [
              {
                icon: 'edit',
                text: 'Tag a clause',
                value: ContextMenuValues.GenAi,
                onClick: () =>
                  this.openTagClauseModalPdfHighlighter(
                    text,
                    position,
                    hideMenu,
                  ),
                /*
                 Hide the menu if no text is selected or
                 if the selected text is less than 10 words when the small sample support is disabled
                 or have another check of enabling bulk tagging when the small sample support is enabled
                 */
                hidden: isEmpty(text.trim()),
              },
              {
                icon: 'message',
                tooltip: 'Add a comment',
                hidden: !canUsePostSigActivityFeed(user),
                value: ContextMenuValues.Comments,
                component: (
                  <CommentDraftWithMentions
                    entity={{
                      id: documentId,
                      type: EntityType.Document,
                    }}
                    isFocused
                    onSubmit={(comment) => {
                      onCreateComment({
                        highlight: {
                          position: position,
                          highlightedText: text,
                        },
                        comment,
                      });
                      hideMenu();
                    }}
                  />
                ),
              },
            ]}
            onMouseDownHandler={this.handleOnMouseDownPdfViewer}
          />
        </EcCard>
      );
    } else {
      return (
        <EcDocumentViewer
          htmlData={htmlData}
          pdfHtmlData={pdfHtmlData}
          errorLoadDocument={errorLoadDocument}
          fileType={fileType}
          numPages={numPages}
          pdfFile={pdfFile}
          onMouseDownHandler={this.handleOnMouseDown}
          onMouseUpHandler={this.handleOnMouseUp}
          loadDocument={this.loadDocument}
          handleOCRClick={this.handleOCRClick}
          handlePDFClick={this.handlePDFClick}
          onDocumentLoadSuccessHandler={this.onDocumentLoadSuccess}
          onDocumentLoadErrorHandler={this.onDocumentLoadError}
          containerStyle={docSideInfoStyle}
          OCRStatus={OCRStatus}
        />
      );
    }
  }

  render() {
    const {
      sections,
      classes,
      documentProvisions,
      handleNavigateToClauseTab,
      OCRStatus,
      userVisibilityLevel,
      displaySideInfoIn2Cols = false,
      queued,
    } = this.props;
    const {
      shouldShowSelectionControls,
      shouldShowFieldHighlightControls,
      isTagClauseModalOpen,
      isRemoveProvisionModalOpen,
      selectedProvision,
      docSideInfoStyle,
      showOCR,
    } = this.state;

    const keyInfoStyle = {
      minHeight: '400px',
      maxHeight: parseInt(docSideInfoStyle.maxHeight) + 70 + 'px',
      height: parseInt(docSideInfoStyle.maxHeight) + 70 + 'px',
      overflow: docSideInfoStyle.overflow,
    };

    // TODO: EKP-2219 create an alternative layout that
    // puts KeyInformation and ContractSummary side by side instead of stacked
    const ecCardContentStyles = showOCR
      ? { maxHeight: '560px', overflowY: 'scroll' }
      : undefined;
    const sideInfo =
      showOCR && displaySideInfoIn2Cols ? (
        <>
          <div
            className={classes.documentContentSideInfoIn2Columns}
            style={keyInfoStyle}
          >
            {showOCR && (
              <ContractSummary
                onProvisionClick={this.onContractSummaryProvisionSelect}
                documentProvisions={documentProvisions}
                isPdfHighlighter={this.isPdfPreviewHighlighting()}
                handleNavigateToClauseTab={handleNavigateToClauseTab}
              />
            )}
          </div>
          <div
            className={classes.documentContentSideInfoIn2Columns}
            style={keyInfoStyle}
          >
            <KeyInformation
              OCRStatus={OCRStatus}
              showOCR={showOCR}
              sections={sections}
              userVisibilityLevel={userVisibilityLevel}
              onFieldTokenClick={this.onKeyInformationFieldTokenSelect}
              isPdfHighlighter={this.isPdfPreviewHighlighting()}
              queued={queued}
            />
          </div>
        </>
      ) : (
        <div className={classes.documentContentSideInfo} style={keyInfoStyle}>
          <KeyInformation
            OCRStatus={OCRStatus}
            showOCR={showOCR}
            sections={sections}
            userVisibilityLevel={userVisibilityLevel}
            onFieldTokenClick={this.onKeyInformationFieldTokenSelect}
            ecCardContentStyles={ecCardContentStyles}
            isPdfHighlighter={this.isPdfPreviewHighlighting()}
            queued={queued}
          />
          {showOCR && (
            <ContractSummary
              onProvisionClick={this.onContractSummaryProvisionSelect}
              documentProvisions={documentProvisions}
              isPdfHighlighter={this.isPdfPreviewHighlighting()}
              handleNavigateToClauseTab={handleNavigateToClauseTab}
            />
          )}
        </div>
      );

    return (
      <div className={classes.documentContentsWrapper} ref={this.wrapperRef}>
        <div
          className={classes.documentViewerWrapper}
          role="presentation"
          ref={this.pdfWrapperRef}
          onMouseUp={this.stopPropagation}
        >
          <Box styles={componentStyles.documentParentPanel}>
            <Box
              styles={componentStyles.documentContentPanel}
              styleProps={{
                isVisible: !this.state.shouldLoadingDocumentContent,
              }}
            >
              {this.renderDocumentViewer()}
              {shouldShowSelectionControls && this.renderSelectionControls()}
            </Box>
            {this.state.shouldLoadingDocumentContent && (
              <Box styles={componentStyles.documentPanelSpinner}>
                <ContentContainer
                  loadingContent={{
                    isLoading: true,
                    message:
                      'Please wait while your Quick AI Clause is being processed.',
                  }}
                />
              </Box>
            )}
          </Box>
          {shouldShowFieldHighlightControls && this.renderFieldTokensControls()}
        </div>
        {sideInfo}
        {isTagClauseModalOpen &&
          !selectedProvision &&
          this.renderTagClauseModal()}
        {isRemoveProvisionModalOpen && this.renderRemoveProvisionModal()}
        {isTagClauseModalOpen &&
          selectedProvision &&
          this.renderEditProvisionModal()}
      </div>
    );
  }
}

DocumentContentsPanel.propTypes = {
  htmlData: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  fileType: PropTypes.string.isRequired,
  onCreateProvision: PropTypes.func.isRequired,
  onUpdateProvision: PropTypes.func.isRequired,
  onRemoveProvision: PropTypes.func.isRequired,
  documentProvisions: PropTypes.array.isRequired,
  OCRStatus: PropTypes.number.isRequired,
};

DocumentContentsPanel.contextType = PdfHighlighterContext;

export default injectSheet(styles)(withFlags(DocumentContentsPanel));
