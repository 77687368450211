import { createSelector } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { RootState, WebhooksState as State } from '../types';

const selectState = (rootState: Partial<RootState>): State =>
  rootState[SliceType.Webhooks];

export const selectActiveEndpoint = createSelector(
  selectState,
  (state: State) => state.activeEndpoint,
);

export const selectEndpointAction = createSelector(
  selectState,
  (state: State) => state.endpointAction,
);
