import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { downloadAttachment, uploadAttachments } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import FieldValuesMultiSelect from '~/components/Shared/FieldValuesMultiSelect';
import FieldValuesSingleSelect from '~/components/Shared/FieldValuesSingleSelect';
import { EM_DASH } from '~/constants/unicode';
import { Button, formatDate, LoadingSpinner, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';
import { FlagType, withFlags } from '~/flags';
import * as toastTypes from '~/types/toast.types';
import { Box, FileInput, FileLabel, FlexLayout } from '~/ui';
import { getUniqueFieldValues } from '~/utils/document.utils';
import { downloadFile } from '~/utils/files';

import {
  black2,
  black5,
  evisortBlue,
} from '../../../../assets/shared-styles/general';
import EcDatepicker from '../../../Shared/EcDatepicker';
import EcInput from '../../../Shared/EcInput';
import EcMultipleSelectValue from '../../../Shared/EcMultipleSelectValue';
import EcSelect from '../../../Shared/EcSelect';
import EcTooltip from '../../../Shared/EcTooltip';
import EditIcon from '../../../Shared/Icons/EditIcon';
import InfoIcon from '../../../Shared/Icons/InfoIcon';
import SearchIcon from '../../../Shared/Icons/SearchIcon';
import SubArrowIcon from '../../../Shared/Icons/SubArrowIcon';
import { editDocument } from '../../Document.data';
import { booleanOptions } from '../../DocumentOverviewPanel/DocumentOverviewPanel.utils';
import styles from './InformationField.styles';
import { preprocessFieldValue } from './InformationField.utils';

class InformationField extends Component {
  constructor(props) {
    super(props);

    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnCancelClick = this.handleOnCancelClick.bind(this);
    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);
    this.state = { editMode: false, fieldValue: null, loading: false };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState() {
    const { field } = this.props;
    if (field.type === DataFieldType.ARRAY_MULTIPLE) {
      this.setState({ fieldValue: getUniqueFieldValues(field.value) });
    } else {
      this.setState({ fieldValue: field.value });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.editMode && nextProps.field.value !== prevState.fieldValue) {
      if (nextProps.field.type === DataFieldType.ARRAY_MULTIPLE) {
        return { fieldValue: getUniqueFieldValues(nextProps.field.value) };
      } else {
        return { fieldValue: nextProps.field.value };
      }
    } else {
      return null;
    }
  }

  downloadFileAttachment(fileObject) {
    downloadAttachment(fileObject.value)
      .then((file) => {
        showToast(
          toastTypes.DOWNLOAD,
          `${fileObject.display_value} successfully downloaded.`,
        );
        downloadFile(file, fileObject.display_value);
      })
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Download attachment failed. Please try again.',
        ),
      );
  }

  handleFileAttachError() {
    showToast(toastTypes.ERROR, 'Attach file failed. Please try again.');
  }

  handleOnEditClick() {
    this.setState({ editMode: !this.state.editMode });
  }

  handleOnCancelClick() {
    this.setInitialState();
    this.setState({ editMode: false });
  }

  async handleOnSaveClick() {
    const { fieldValue } = this.state;
    const { documentId, field, refreshField } = this.props;
    const processedFieldValue = preprocessFieldValue(fieldValue);
    const editedFieldData = [
      { id: field.id, label: field.name, value: processedFieldValue },
    ];

    this.setState({ loading: true, editMode: false });

    try {
      const res = await editDocument(documentId, editedFieldData);
      if (res[0].value === null) {
        res[0]['value'] = { value: null, display_value: '' };
      }
      await refreshField();
      this.setState({
        editMode: false,
        loading: false,
        fieldValue: res[0].value.value,
      });
      showToast(
        toastTypes.SUCCESS,
        'Document information field has been updated.',
      );
    } catch (e) {
      this.setInitialState();
      this.setState({ editMode: false, loading: false });
      if (e.response && e.response.status === 400) {
        showToast(
          toastTypes.ERROR,
          'Only 30 field values can be added or removed at a time. Please update and try again.',
        );
      } else {
        showToast(
          toastTypes.ERROR,
          'An error occurred while updating information field.',
        );
      }
    }
  }

  renderBooleanField(field) {
    const { classes } = this.props;
    const { fieldValue } = this.state;

    if (this.state.editMode) {
      const presentValue = fieldValue
        ? { label: fieldValue.display_value, value: fieldValue.value }
        : null;

      return (
        <div className={classes.editItemContainer}>
          <EcSelect
            aria-label={field.name}
            defaultValue={presentValue}
            options={booleanOptions}
            onChange={(newValue) =>
              this.setState({
                fieldValue: {
                  display_value: newValue.label,
                  value: newValue.value,
                },
              })
            }
            isDisabled={!field.is_editable}
            isSearchable={false}
          />
        </div>
      );
    }

    return fieldValue ? fieldValue.display_value : EM_DASH;
  }

  renderNumberField(field) {
    const { classes } = this.props;
    const { fieldValue } = this.state;

    if (this.state.editMode) {
      const presentValue = fieldValue ? fieldValue.value : '';

      return (
        <div className={classes.editItemContainer}>
          <EcInput
            ariaLabel={field.name}
            input={{
              value: presentValue,
              onChange: (event) => {
                const newState =
                  event.target.value === '' ? '' : Number(event.target.value);
                this.setState({
                  fieldValue: { display_value: newState, value: newState },
                });
              },
            }}
            placeholder="enter amount"
            type="number"
            width="100%"
            isDisabled={!field.is_editable}
          />
        </div>
      );
    }

    return fieldValue ? fieldValue.display_value : EM_DASH;
  }

  renderDateField(field) {
    const { classes, flags } = this.props;
    const { fieldValue } = this.state;
    const refactorMoment = flags[FlagType.DeprecateMoment];

    if (this.state.editMode) {
      const { focused } = this.state;
      const presentValue = fieldValue ? fieldValue.value : '';

      return (
        <div className={classes.editItemContainer}>
          <EcDatepicker
            title={field.name}
            date={presentValue ? parseDate(presentValue, refactorMoment) : null}
            focused={focused}
            onDateChange={(date) => {
              const newDate = date ? formatDate(date) : null;
              this.setState({
                fieldValue: { display_value: newDate, value: newDate },
              });
            }}
            isClearable={field.is_nullable}
            disabled={!field.is_editable}
          />
        </div>
      );
    }
    return !fieldValue || fieldValue.display_value === ''
      ? EM_DASH
      : fieldValue.display_value;
  }

  renderStringField(field) {
    const { classes } = this.props;
    const { fieldValue } = this.state;

    if (this.state.editMode) {
      const presentValue = fieldValue ? fieldValue.value : '';

      return (
        <div className={classes.editItemContainer}>
          <EcInput
            ariaLabel={field.name}
            input={{
              value: presentValue,
              onChange: (event) =>
                this.setState({
                  fieldValue: {
                    display_value: event.target.value,
                    value: event.target.value,
                  },
                }),
            }}
            placeholder={`Enter ${field.name}`}
            width="100%"
            isDisabled={!field.is_editable}
          />
        </div>
      );
    }

    return !fieldValue || fieldValue.display_value === ''
      ? EM_DASH
      : fieldValue.display_value;
  }

  renderSingleArrayField(field) {
    const { classes } = this.props;
    const { fieldValue } = this.state;

    if (this.state.editMode) {
      const presentValue = fieldValue
        ? { label: fieldValue.display_value, value: fieldValue.value }
        : null;

      return (
        <div className={classes.editItemContainer}>
          <FieldValuesSingleSelect
            aria-label={field.name}
            defaultValue={presentValue}
            onChange={(newValue) => {
              const newState = newValue ? newValue.value : newValue;
              this.setState({
                fieldValue: { display_value: newState, value: newState },
              });
            }}
            isDisabled={!field.is_editable}
            isClearable={field.is_nullable}
            isCreatable={field.allow_new_options}
            fieldId={field.id}
            fieldValues={field.values}
          />
        </div>
      );
    }

    return fieldValue ? fieldValue.display_value : EM_DASH;
  }

  renderMultipleValues({ is_editable }) {
    const { classes } = this.props;
    const { fieldValue } = this.state;

    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {fieldValue.length ? (
            fieldValue.map((value, index) => (
              <EcMultipleSelectValue
                label={value.display_value}
                key={index}
                marginBottom="8px"
                closeHandler={() => {
                  const newState = this.state.fieldValue.filter(
                    (item) => item.value !== value.value,
                  );
                  this.setState({ fieldValue: newState });
                }}
                isDisabled={!is_editable}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  renderMultipleArrayField(field) {
    const { classes, flags } = this.props;
    const { fieldValue } = this.state;

    if (this.state.editMode) {
      const presentValue =
        fieldValue && fieldValue.length
          ? fieldValue.map((val) => ({
              label: val.display_value,
              value: val.value,
            }))
          : [];
      const disableSelectAll =
        flags[FlagType.FieldValueMigrationDeferredFeaturesBypass];
      return (
        <div className={classes.editItemContainerMultiple}>
          <FieldValuesMultiSelect
            aria-label={field.name}
            value={presentValue}
            onChange={(newItems) => {
              const newState = newItems.map((item) => ({
                display_value: item.label,
                value: item.value,
              }));
              this.setState({ fieldValue: newState });
            }}
            width="100%"
            placeholder={`Search for ${field.name}...`}
            noOptionsMessage={`No ${field.name} found`}
            isDisabled={!field.is_editable}
            isCreatable={field.allow_new_options}
            useMinInputLength={['Counterparties', 'Internal Parties'].includes(
              field.class_name,
            )}
            allowSelectAll
            disableSelectAll={disableSelectAll}
            fieldId={field.id}
            fieldValues={field.values}
          />
          {this.renderMultipleValues(field)}
        </div>
      );
    }

    return fieldValue && fieldValue.length
      ? fieldValue.map((val) => val.display_value).join(' • ')
      : EM_DASH;
  }

  renderTextAreaField(field) {
    const { classes } = this.props;
    const { fieldValue } = this.state;

    return (
      <div className={classes.editItemContainer}>
        <textarea
          aria-label={field.name}
          value={fieldValue ? fieldValue.value : ''}
          rows="7"
          disabled={!this.state.editMode}
          className={classes.textArea}
          onChange={(event) => {
            this.setState({
              fieldValue: {
                display_value: event.target.value,
                value: event.target.value,
              },
            });
          }}
        />
      </div>
    );
  }

  renderAttachmentField(field) {
    const handleFileDataChangeHelper = (newFiles) => {
      if (!newFiles || !newFiles.length) {
        showToast(toastTypes.ERROR, 'Could not attach file.');
        return;
      }
      const newItems = newFiles.map((fileData) => ({
        display_value: fileData.fileName + fileData.fileType,
        value: fileData.id,
      }));
      this.setState({ fieldValue: [...fieldValue, ...newItems] });
      showToast(toastTypes.SUCCESS, 'Attach file succeeded.');
    };

    const { classes } = this.props;
    const { fieldValue } = this.state;

    if (this.state.editMode) {
      return (
        <div className={classes.editItemContainerMultiple}>
          <FileInput
            acceptedFiles="*"
            enableUpload
            enableDropzone
            enableMultiple
            layout="condensed"
            onChange={(files) =>
              uploadAttachments(
                files,
                handleFileDataChangeHelper,
                this.handleFileAttachError,
              )
            }
          />
          {this.renderMultipleValues(field)}
        </div>
      );
    }

    if (fieldValue?.length) {
      // TODO: Refactor to avoid duplicating this code with DocumentOverviewPanel.js
      return (
        <FlexLayout flexDirection="column" space={5}>
          {fieldValue.map((fileObject) => {
            const file = new File([], fileObject.display_value);
            return (
              <Box onClick={() => this.downloadFileAttachment(fileObject)}>
                <FileLabel shouldTruncate file={file} />
              </Box>
            );
          })}
        </FlexLayout>
      );
    } else {
      return EM_DASH;
    }
  }

  renderFieldValue(field) {
    let returnValue;

    switch (field.type) {
      case DataFieldType.BOOLEAN:
        returnValue = this.renderBooleanField(field);
        break;
      case DataFieldType.DATE:
        returnValue = this.renderDateField(field);
        break;
      case DataFieldType.NUMBER:
        returnValue = this.renderNumberField(field);
        break;
      case DataFieldType.STRING:
        returnValue = this.renderStringField(field);
        break;
      case DataFieldType.ARRAY_SINGLE:
        returnValue = this.renderSingleArrayField(field);
        break;
      case DataFieldType.ARRAY_MULTIPLE:
        returnValue = this.renderMultipleArrayField(field);
        break;
      case DataFieldType.TEXT_AREA:
        returnValue = this.renderTextAreaField(field);
        break;
      case DataFieldType.ATTACHMENT:
        returnValue = this.renderAttachmentField(field);
        break;
      default:
        returnValue = EM_DASH;
        break;
    }

    return returnValue;
  }

  renderFieldHover() {
    const {
      classes,
      isFocused,
      isHovered,
      documentId,
      field,
      userVisibilityLevel,
    } = this.props;
    const { editMode } = this.state;
    const canEdit = userVisibilityLevel === 'OPEN';

    const shouldShowEdit =
      (isFocused || isHovered) && !editMode && !field.is_flagged && canEdit;

    if (editMode) return null;
    return (
      <div
        tabIndex="-1"
        id={`document_contentsPanel_editKeyInformation?document=${documentId}&keyInformation=${field.id}`}
        className={classes.fieldActionIcons}
      >
        <span className={classes.horizontalSeparator} />
        <button
          className={
            shouldShowEdit ? classes.showEditButton : classes.hideEditButton
          }
          onClick={this.handleOnEditClick}
          title={`Edit ${field.name} field`}
        >
          <EditIcon />
        </button>
      </div>
    );
  }

  renderFieldFooter() {
    const { classes, field } = this.props;
    const { editMode } = this.state;

    if (field.is_flagged && !editMode) {
      return (
        <div className={classes.fieldFooter}>
          <Button
            icon="check"
            iconPosition="left"
            text="Accept"
            onClick={this.handleOnSaveClick}
          />
          <Button
            icon="pencil"
            iconPosition="left"
            text="Edit"
            onClick={this.handleOnEditClick}
          />
        </div>
      );
    } else if (editMode) {
      return (
        <div className={classes.fieldFooter}>
          <Button
            icon="check"
            iconPosition="left"
            text="Save"
            onClick={this.handleOnSaveClick}
          />
          <Button text="Cancel" onClick={this.handleOnCancelClick} />
        </div>
      );
    }

    return null;
  }

  renderLoadingSpinner() {
    const { classes, field } = this.props;

    return (
      <div className={classes.loadingContainer}>
        <LoadingSpinner label={`documents spin button ${field.name}`} />
      </div>
    );
  }

  sectionFieldClass() {
    const { classes } = this.props;
    const { loading, editMode } = this.state;

    if (loading && editMode) {
      return classes.sectionFieldEditModeLoading;
    } else if (loading) {
      return classes.sectionFieldLoading;
    }

    return editMode ? classes.sectionFieldEditMode : classes.sectionField;
  }

  shouldHaveFindButton = () => {
    const { field, isPdfHighlighter, showOCR } = this.props;
    if (!showOCR) return false;

    return isPdfHighlighter
      ? field.coordinates.length > 0
      : field.html_tokens.length > 0;
  };

  render() {
    const { classes, field, onFieldTokenClick } = this.props;
    const { loading } = this.state;

    const sectionFieldClass = this.sectionFieldClass();

    return (
      <div className={classes.sectionFieldWrapper} role="menuitem" tabIndex="0">
        <div className={sectionFieldClass}>
          <div className={classes.fieldTitle}>
            {field.name}
            {field.is_beta ? (
              <Fragment>
                <span
                  className={classes.betaTooltip}
                  data-tip
                  data-for="betaTag"
                >
                  BETA
                </span>
                <div className={classes.tooltipContent}>
                  <EcTooltip id="betaTag" width="260px" place="left">
                    This is a newly released intelligent field which is
                    currently in beta testing. <br />
                    If you have feedback about the outputs you're seeing, please
                    send us a note at beta@evisort.com
                  </EcTooltip>
                </div>
              </Fragment>
            ) : null}
            {field.help_text ? (
              <Fragment>
                <span
                  className={classes.helpTextTooltip}
                  data-tip
                  data-for={`helpTextTag-${field.id}`}
                >
                  <InfoIcon color={black2} size="15px" />
                </span>
                <div className={classes.tooltipContent}>
                  <EcTooltip
                    id={`helpTextTag-${field.id}`}
                    width="260px"
                    place="left"
                  >
                    {field.help_text}
                  </EcTooltip>
                </div>
              </Fragment>
            ) : null}
            {this.shouldHaveFindButton() ? (
              <button
                title={`Find ${field.name} information in the document`}
                onClick={() => onFieldTokenClick(field)}
                className={classes.fieldTokenIndicator}
              >
                <SearchIcon color={evisortBlue} size="18" opacity="1" />
              </button>
            ) : null}
          </div>
          <div className={classes.fieldValue}>
            {this.renderFieldValue(field)}
          </div>
          {this.renderFieldHover()}
          {this.renderFieldFooter()}
        </div>
        {loading ? this.renderLoadingSpinner() : null}
      </div>
    );
  }
}

export default injectSheet(styles)(withFlags(InformationField));
