import {
  Actions,
  formatDate,
  getUserName,
  Layout,
  Markdown,
  Tooltip,
  types,
  User,
} from '~/eds';

import { Message as MessageType } from './types';

interface Props<Source extends unknown> {
  message: MessageType<Source>;
  actions?: types.UserAction[];
  user: types.User;
}

export const UserMessage = <Source extends unknown>({
  actions,
  message,
  user,
}: Props<Source>) => {
  const { text, timestamp } = message;
  const tooltip = `${getUserName(user)}\n${formatDate(timestamp, 'full')}`;
  return (
    <Layout justify="space-between" align="center">
      <Layout align="center" spacing={4}>
        <Layout alignSelf="flex-start">
          <User mode="avatar" user={user} />
        </Layout>
        <Tooltip tooltip={tooltip} placement="left">
          <Markdown variant="body-medium" text={text} />
        </Tooltip>
      </Layout>

      <Layout alignSelf="flex-start">
        {actions && <Actions actions={actions} />}
      </Layout>
    </Layout>
  );
};
