import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, ErrorPage } from '~/eds';

const Page = () => {
  const history = useHistory();

  const resolveAction = {
    text: 'Take me home',
    onClick: () => history.push('/'),
  };

  return (
    <Box py={16}>
      <ErrorPage preset="page-not-found" resolveAction={resolveAction} />
    </Box>
  );
};

export default Page;
