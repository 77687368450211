import { auth, rolodex } from '~/services';

export async function domainCheck(data) {
  const rolodexConfigs = await rolodex.get(
    `public/auth-configs/${data.client}`,
  );

  const useRolodex = rolodexConfigs.signin_enabled;
  if (!useRolodex) {
    const aegisConfigs = await auth.post('auth/login-info', data);

    return {
      useRolodex,
      password: {
        enabled: aegisConfigs.password.enabled,
      },
      sso: aegisConfigs.sso,
      oauth: {
        enabled: false, // Aegis has no oauth option
      },
    };
  } else {
    return {
      useRolodex,
      password: {
        enabled: rolodexConfigs.password_signin_enabled,
      },
      sso: {
        enabled: rolodexConfigs.sso_signin_enabled,
        // TODO: Add the following fields to the response when rolodex supports SSO
        provider_name: '',
        signon_url: '',
        error: '',
      },
      oauth: {
        enabled: rolodexConfigs.oauth_signin_enabled,
      },
    };
  }
}

export async function loginWithClientUsernamePassword(data) {
  return await auth.post('auth/login', data);
}

export async function loginWithToken(token) {
  return await auth.post('auth/login-token', token);
}

export async function logout() {
  try {
    return await auth.post(
      '/auth/logout',
      {},
      {
        withCredentials: true,
      },
    );
  } catch (error) {
    if (error.response?.status === 401) {
      console.log('session expired');
    } else {
      throw error;
    }
  }
}

export function getOneTimeUseToken() {
  return auth.post('/auth/user-one-use-token', {}, { withCredentials: true });
}

export function getUserEditorConfigToken(data) {
  return auth.post('/auth/user-editor-config', data, { withCredentials: true });
}

export function getForceSaveToken(data) {
  return auth.post('/auth/force-save-token', data, { withCredentials: true });
}
