import { capitalize } from 'lodash';
import React, { useState } from 'react';

import { PageLayout } from '~/eds';
import {
  getModelStatusChip,
  ModelResult,
  ModelResults,
  ModelVersionInfo,
  usePollModelVersionProgress,
  useResolveTargetEntityDetails,
} from '~/features/x-ray';
import {
  CreditBalance,
  useCreditStatusMessage,
} from '~/features/x-ray/billing';
import { FlagType, useFlag } from '~/flags';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable } from '~/types';

import DEPRECATED_Page from './DEPRECATED_index';

const Page = () => {
  const enableDocumentXRayDevListPageRefactor = useFlag(
    FlagType.DocumentXRayDevListPageRefactor,
  );

  const { history, location } = useRouting();

  const [activeModelResult, setActiveModelResult] = useState<
    Nullable<ModelResult>
  >(null);

  const { modelVersion } = usePollModelVersionProgress(
    activeModelResult
      ? {
          modelId: activeModelResult.id,
          version: activeModelResult.latestVersion,
        }
      : null,
  );

  const [activeTargetEntityDetails] = useResolveTargetEntityDetails(
    activeModelResult?.targetEntity ?? null,
  );

  const creditStatusMessage = useCreditStatusMessage();

  if (!enableDocumentXRayDevListPageRefactor) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <DEPRECATED_Page />;
  }

  const { entityType, title } = resolvePageProps(location.pathname);

  const createAction = {
    level: 'primary' as const,
    text: `New ${capitalize(entityType)} Model`,
    onClick: () => history.push(`${RoutePathType.AutomationHubFields}/new`),
  };

  const handleShowResult = (modelResult: ModelResult) =>
    setActiveModelResult(modelResult);

  const panel = activeModelResult
    ? {
        children: (
          <ModelVersionInfo
            enableTitle
            modelId={activeModelResult.id}
            version={activeModelResult.latestVersion}
          />
        ),
        chips: modelVersion ? [getModelStatusChip(modelVersion)] : undefined,
        hidden: {
          isHidden: false,
          onHide: () => setActiveModelResult(null),
        },
        title: activeTargetEntityDetails.label,
        width: 'm' as const,
      }
    : undefined;

  return (
    <PageLayout
      actions={[createAction]}
      headerCalloutContent={<CreditBalance />}
      panel={panel}
      statusMessage={creditStatusMessage}
      title={title}
    >
      <ModelResults onShowResult={handleShowResult} />
    </PageLayout>
  );
};

export default Page;

const resolvePageProps = (
  pathname: string,
): {
  entityType: 'field';
  title: string;
} => {
  switch (pathname) {
    case RoutePathType.AutomationHubFields:
    default:
      return {
        entityType: 'field',
        title: 'Automation Hub',
      };
  }
};
