import React, { memo } from 'react';

import { DateFormat, User as UserType } from '../../types';
import { DateText } from '../DateText';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { User } from '../User';

interface Props {
  /** Activity performed by user */
  activity: string;
  /** JS Date */
  date: Date;
  /** A user object */
  user: UserType;
  /** Date format */
  format?: DateFormat;
}

export const UserActivity = memo(
  ({ activity, date, format = 'iso', user }: Props) => {
    return (
      <Layout spacing={1}>
        <Text>{activity} at </Text>
        <DateText date={date} format={format} />
        <Text> by </Text>
        <User user={user} mode="name" />
      </Layout>
    );
  },
);
