import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TicketForm } from '~/components/Workflow/WorkflowTicketsPage/TicketCreatePage/WizardTicketForm';
import { IconButton } from '~/eds';
import { FlexLayout, WizardPageLayout } from '~/ui';
import { copyToClipboard } from '~/utils/helper.utils';

const steps = ['Select Workflow', 'Create Ticket', 'Take Action'];

/* UX wants the title to take half of the space to the left of the Select Workflow header, which should be 325px. */
const TITLE_MAX_WIDTH = 325;

const WizardTicketForm = (props) => {
  const [formState, setFormState] = useState({
    isSubmitDisabled: true,
    disabledSubmitTooltip: '',
  });

  const ticketFormRef = useRef();

  const handleCreateTicket = () => {
    ticketFormRef.current.onFormSubmit();
  };

  const history = useHistory();
  const handleClose = () => {
    history.push('/workflow/tickets');
  };

  const [copiedTooltip, setCopiedTooltip] = useState(false);
  const handleOnCopyAction = () => {
    setCopiedTooltip(true);
    copyToClipboard(window.location.href, null, null, handleCopiedTooltip);
  };

  const handleCopiedTooltip = () => {
    setTimeout(() => {
      setCopiedTooltip(false);
    }, 2000);
  };

  return (
    <WizardPageLayout
      currentStep="Create Ticket"
      steps={steps}
      title={{ text: props.intakeForm.name, maxWidth: TITLE_MAX_WIDTH }}
      actionButton={{
        promise: handleCreateTicket,
        iconRight: 'arrowRight',
        text: 'Create Ticket',
        disabled:
          // TODO: refactor this, we need to remove this indirection (using ref to access child state is terrible. Really terribe.)
          !ticketFormRef.current?.state ||
          ticketFormRef.current?.state.isSubmittingForm ||
          ticketFormRef.current?.state.isLoadingForm,
        tooltip: formState.disabledSubmitTooltip,
      }}
      isContentCentered={false}
      onClose={handleClose}
      rightContent={
        <FlexLayout alignItems="center" space={4}>
          <IconButton
            icon="link"
            text="Copy Link"
            tooltip={
              copiedTooltip
                ? 'Intake Form Link copied'
                : 'Copy Intake Form Link'
            }
            onClick={handleOnCopyAction}
            id="copy"
          />
        </FlexLayout>
      }
    >
      <TicketForm
        ref={ticketFormRef}
        {...props}
        onUpdateFormState={setFormState}
      />
    </WizardPageLayout>
  );
};

const mapStateToProps = ({ intakeForm }) => ({ intakeForm });

export default connect(mapStateToProps)(WizardTicketForm);
