import { SaveSearchPermissionType } from '~/enums';

export const getSaveSearchPermissionOptions = (clientName) => {
  return [
    { label: 'Only me (private)', value: SaveSearchPermissionType.Private },
    {
      label: 'Specific departments (groups) / users',
      value: SaveSearchPermissionType.Shared,
    },
    { label: `Anyone at ${clientName}`, value: SaveSearchPermissionType.Open },
  ];
};

/* Below are the types of search considered complex for new Search V2 UI. Saved Search that contains one
   of these types will be redirected to Analyzer page form to edit filters. */
export const checkSearchQueryHasUnsupportedEntity = (searchQuery) => {
  const hasBooleanTextSearches =
    searchQuery.filter((q) => q.entity === 'bool_text_search')?.length > 1;
  const hasOrOperator = !!searchQuery.find((q) => q.operator === 'or');
  const hasSection = !!searchQuery.find((q) => q.entity === 'section');

  return hasBooleanTextSearches || hasOrOperator || hasSection;
};

export const SaveSearchesTypes = [
  {
    label: 'My Saved Searches',
    value: 'mySearches',
  },
  {
    label: 'Shared with me',
    value: 'sharedSearches',
  },
];

export const getSearchableFields = (columnOrder) =>
  columnOrder
    ?.filter(
      (columnId) =>
        !isNaN(columnId) ||
        columnId === 'document_group_id' ||
        columnId === 'folder_id',
    )
    ?.map((columnId, index) => {
      return {
        column_number: index,
        field_id: columnId,
      };
    });
