import PT from 'prop-types';
import React from 'react';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { formatDate, Icon, parseDate } from '~/eds';
import { DateFormatType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { FlexLayout, Text, useHover } from '~/ui';
import { withInputError } from '~/ui/hocs';

import '../../../styles/react-datepicker_overrides.css';

const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fullWidth: '100%',
};

function toFnsFormat(format) {
  switch (format) {
    case DateFormatType.EuShort:
      return 'dd/LL/yyyy';
    case DateFormatType.Iso:
      return 'yyyy-LL-dd';
    case DateFormatType.Long:
      return 'LLLL d, yyyy';
    case DateFormatType.Prose:
      return "do 'day of' LLLL, yyyy";
    case DateFormatType.UsShort:
    case DateFormatType.Default:
    default:
      return 'LL/dd/yyyy';
  }
}

function DateInput({
  disabled = false,
  error,
  format = DateFormatType.Default,
  isClearable = true,
  placeholder = 'Select a date',
  placement,
  value,
  width = 'm',
  onChange,
  name,
}) {
  const [inputRef, isHovered] = useHover();
  const refactorMoment = useFlag(FlagType.DeprecateMoment);

  const CustomDateInput = ({ value, onClick }) => (
    <FlexLayout
      alignItems="center"
      disabled={disabled}
      justifyContent="space-between"
      px={4}
      ref={inputRef}
      aria-label={name}
      role="combobox"
      sx={{
        border: 'border-dark',
        borderColor: error ? 'red-400' : 'black-alpha-20',
        borderRadius: 'm',
        height: 'input-height',
        position: 'relative',
        width: widths[width],
      }}
      onClick={onClick}
    >
      {value ? (
        <Text variant="s-dense">{value}</Text>
      ) : (
        <Text color="gray-500" variant="s-dense">
          {placeholder}
        </Text>
      )}
      {isClearable && isHovered && value && (
        <Icon
          icon="x"
          tooltip="remove"
          onClick={(event) => {
            event.stopPropagation();
            onChange(null);
          }}
        />
      )}
      <Icon icon="chevron-down" />
    </FlexLayout>
  );

  const date = value ? parseDate(value, refactorMoment) : undefined;

  return (
    <Datepicker
      customInput={<CustomDateInput />}
      dateFormat={toFnsFormat(format)}
      disabled={disabled}
      dropdownMode="select"
      selected={date}
      showMonthDropdown={true}
      showMonthYearPicker={false}
      showYearDropdown={true}
      popperPlacement={placement}
      onChange={(updatedDate) => {
        // TODO: address this by enforcing `date` value to be of type `Date` or `ISO`, and use `format` only for display purposes.
        onChange(formatDate(new Date(updatedDate)));
      }}
    />
  );
}

DateInput.propTypes = {
  /** Disables the date input */
  disabled: PT.bool,
  /** Error that gets passed to component */
  error: PT.string,
  /** One of the following moment-supported format */
  format: PT.oneOf(Object.values(DateFormatType)),
  /** Placeholder */
  placeholder: PT.string,
  /** popup placement */
  placement: PT.oneOf([undefined, 'bottom-end']),
  /** Date string value (null supported) */
  value: PT.string,
  /** Input width */
  width: PT.oneOf(['s', 'm', 'l', 'fullWidth']),
  /** Captures updated date as a string formatted by the provided format */
  onChange: PT.func,
};

export default withInputError(DateInput);
