import {
  BlockWidget,
  BodyWidget,
  CommentElementBox,
  DocumentEditor,
  ElementBox,
  FieldElementBox,
  ImageElementBox,
  IWidget,
  LineWidget,
  Page,
  ParagraphWidget,
  Point,
  Revision,
  TableCellWidget,
  TableRowWidget,
  TableWidget,
  TextElementBox,
  TextPosition,
  WCharacterFormat,
  Widget,
} from '@syncfusion/ej2-react-documenteditor';
import diff from 'diff-sequences';

// import { SequenceMatcher } from 'difflib';
import { EntityType } from '~/enums';
import { Comment, Nullable } from '~/types';

import { IMAGE_PLACEHOLDER_TEXT, TEMP_BOOKMARK } from './constants';
import { Bookmarks, bookmarkTypes, RevisionType } from './types';

// it matches any word with spaces at the end e.g "hello " or "hello \r"
const MATCH_WITH_SPACES = /[ \t\r]*[^ \t\r]+[ \t\r]*/g;

export const convertSfdtToFile = (json: object) => {
  if (json) {
    const file = new File([JSON.stringify(json)], 'document.json', {
      type: 'application/json',
    });
    return file;
  } else {
    return null;
  }
};

const isInEditMode = (documentEditor: DocumentEditor) => {
  if (!documentEditor) return false;
  return !documentEditor.isReadOnly;
};

/**
 * Test if the document editor is in edit mode
 *
 * @param documentEditor a document editor instance
 * @returns true if enabled, otherwise, false.
 */
export const validateEditMode = (documentEditor: DocumentEditor) => {
  validateDocumentEditor(documentEditor);
  if (!isInEditMode(documentEditor)) {
    throw new Error('Document editor must be in edit mode');
  }
};

/**
 * Test if the temp bookmark id is valid (present in the document).
 *
 * @param documentEditor a document editor instance
 * @param id the bookmark id
 */
export const validateTempBookmark = (documentEditor?: DocumentEditor) => {
  validateDocumentEditor(documentEditor);
  const bookmark = documentEditor?.documentHelper.bookmarks.get(TEMP_BOOKMARK);
  if (!bookmark) {
    throw new Error(`There is no bookmark with id: ${TEMP_BOOKMARK}`);
  }
};

/**
 * Test if the given bookmark id exists in generated bookmarks.
 *
 * @param documentEditor a document editor instance
 * @param id the bookmark id
 */
export const validateBookmark = (
  id: string,
  bookmarks: Bookmarks,
  documentEditor?: DocumentEditor,
) => {
  if (id === TEMP_BOOKMARK) {
    return validateTempBookmark(documentEditor);
  }
  const type = getBookmarkType(id);

  if (!type || !bookmarks[type]) {
    throw new Error(`There are no ${type} bookmarks`);
  } else if (type && !bookmarks[type].has(id)) {
    throw new Error(`There is no ${type} bookmark with id: ${id}`);
  }
};

/**
 * Test if the given document editor instance is valid
 *
 * @param documentEditor a document editor instance
 */
export const validateDocumentEditor = (documentEditor?: DocumentEditor) => {
  if (!documentEditor) {
    throw new Error('The document editor is not valid.');
  }
};

export const getComment = (id: string, documentEditor: DocumentEditor) => {
  validateDocumentEditor(documentEditor);
  let foundComment;
  for (const comment of documentEditor.documentHelper.comments) {
    if (comment.commentId === id) {
      foundComment = comment;
      break;
    }
    for (const reply of comment.replyComments) {
      if (reply.commentId === id) {
        foundComment = reply;
        break;
      }
    }
  }
  return foundComment;
};

/**
 * Extract the offset of a given text based on the words array position provided
 *
 * @param wordsPosition array of words position
 * @param text the text to extract the offset
 * @param includeLastSelection if enabled, it will also include the selected word into offset
 * @returns the offset
 */
export const getOffset = (
  position: number,
  text: string,
  includeSelection = false,
): number => {
  const wordAndSpacesList = text.match(MATCH_WITH_SPACES) ?? [];
  const offset = wordAndSpacesList
    .slice(0, includeSelection ? position + 1 : position)
    .join('').length;
  return offset;
};

type BlockToSectionMapping = Record<number, [number, number]>;

const extractTextFromParagraph = (paragraph: ParagraphWidget) => {
  return paragraph.childWidgets
    .map((child) => {
      if (child instanceof LineWidget) {
        return (
          child.children
            .map((textEl) => {
              if (textEl instanceof TextElementBox) {
                // remove hyperlink double addition because of how EJ2 renders them in their internal tree.
                if (
                  textEl.previousElement instanceof FieldElementBox &&
                  textEl.previousElement.fieldType === 0
                ) {
                  return '';
                }
                return textEl.text;
              }
              return '';
            })
            .join('')
            // adds a \r when jumping from one line to another inside a table, this is necessary because inside tables
            // EJ2 adds it silently and without it the next word will be glued to the previous one.
            .concat(paragraph.isInsideTable ? '\r' : '')
        );
      }
      return '';
    })
    .join('');
};

/**
 * Extract the text recursively until a ParagraphWidget is found
 */
const extractTextFromBlock = (widget: IWidget): string => {
  const type = widget.constructor;
  switch (type) {
    case ParagraphWidget: {
      return extractTextFromParagraph(widget as ParagraphWidget);
    }
    case TableCellWidget:
    case TableRowWidget:
    case TableWidget: {
      return (widget as TableWidget).childWidgets
        .map(extractTextFromBlock)
        .join('');
    }
    default: {
      return '';
    }
  }
};

interface ExtractTextFromEditor {
  text: string[];
  mapping: BlockToSectionMapping;
}

interface WordMappingToPosition {
  section: number;
  block: number;
  word: string;
  startOffset: number;
  endOffset: number;
}

export const extractTextFromEditor = (
  documentEditor: DocumentEditor,
): ExtractTextFromEditor => {
  const blockMapping: BlockToSectionMapping = {};
  let blockCount = 0;
  const extractedText = documentEditor.documentHelper.pages
    .map((page) => {
      return page.bodyWidgets
        .map((body) => {
          return body.childWidgets
            .map((block) => {
              const extractedBlock = extractTextFromBlock(block);
              blockMapping[blockCount] = [
                body.sectionIndex,
                (block as Widget).index,
              ];
              blockCount = blockCount + 1;
              return extractedBlock;
            })
            .flat();
        })
        .flat();
    })
    .flat();
  return {
    text: extractedText,
    mapping: blockMapping,
  };
};

export const mapTextToWordPosition = (
  documentEditor: DocumentEditor,
): Record<number, WordMappingToPosition> => {
  let wordCount = 0;
  let blockOffset = 0;
  let actualBlockIndex = 0;
  const blockMapping: Record<number, WordMappingToPosition> = {};
  for (const page of documentEditor.documentHelper.pages) {
    for (const body of page.bodyWidgets) {
      for (const block of body.childWidgets) {
        // being in a different body doesn't mean to change the block, so we need to check if we want to change it
        if ((block as Widget).index !== actualBlockIndex) {
          actualBlockIndex = (block as Widget).index;
          blockOffset = 0;
        }
        const extractedBlockArray = extractTextFromBlock(block).match(
          MATCH_WITH_SPACES,
        );
        if (!extractedBlockArray) continue;
        for (const word of extractedBlockArray) {
          if (word !== '') {
            blockMapping[wordCount] = {
              section: body.sectionIndex,
              block: actualBlockIndex,
              word,
              startOffset: blockOffset,
              endOffset: blockOffset + word.length,
            };
            blockOffset = blockOffset + word.length;
            wordCount = wordCount + 1;
          }
        }
      }
    }
  }

  return blockMapping;
};

export const mapWordsToEj2Words = (
  wordsMapping: { word: string; id: Nullable<number> }[],
  ej2Mapping: Record<number, WordMappingToPosition>,
) => {
  const remap: Record<number, WordMappingToPosition> = {};
  const ej2Words = Object.values(ej2Mapping).map((entry) => entry.word.trim());
  const htmlWords = Object.values(wordsMapping).map((entry) =>
    entry.word.trim(),
  );

  const isEqual = (a: number, b: number) => htmlWords[a] === ej2Words[b];
  const evaluateMatchSequence = (size: number, a: number, b: number) => {
    for (let i = 0; i < size; i++) {
      const id = wordsMapping[a + i]?.id;
      // 0 is a valid id, so we need to check for undefined and null
      if (id !== undefined && id !== null) {
        remap[id] = ej2Mapping[b + i];
      }
    }
  };

  diff(htmlWords.length, ej2Words.length, isEqual, evaluateMatchSequence);
  return remap;
};

// helpers for creating highlights
const getXPositionFromElement = (
  element: ElementBox,
  documentEditor: DocumentEditor,
) => {
  const currentPage = documentEditor.selection.getPage(element.paragraph);
  let firstLineIndent = documentEditor.documentHelper.getLeftValue(
    element.line,
  );

  if (
    element.paragraph.paragraphFormat.textAlignment === 'Center' ||
    element.paragraph.paragraphFormat.textAlignment === 'Right'
  ) {
    const leftIdentation =
      element.paragraph.width -
      element.line.children.reduce(
        (width, child) => (width += child?.width || 0),
        0,
      );
    // this tells us if we should base our calc in center or right alignment
    const modifier =
      element.paragraph.paragraphFormat.textAlignment === 'Right' ? 1 : 2;
    firstLineIndent += leftIdentation / modifier;
  }

  return (
    firstLineIndent * documentEditor.documentHelper.zoomFactor +
    currentPage.boundingRectangle.x
  );
};

const getYPositionFromElement = (
  element: ElementBox,
  documentEditor: DocumentEditor,
) => {
  const accumulatedHeight = element.paragraph.childWidgets
    .slice(0, element.line.indexInOwner)
    .reduce<number>((height, line) => height + (line as Widget).height, 0);
  const currentPage = documentEditor.selection.getPage(element.paragraph);
  const pageGapAdjustment =
    documentEditor.viewer.pageGap * (currentPage.index + 1);
  const top =
    accumulatedHeight +
    element.line.paragraph.y +
    currentPage.boundingRectangle.y;
  return (
    (top - pageGapAdjustment) * documentEditor.documentHelper.zoomFactor +
    pageGapAdjustment
  );
};

const getNextValidElement = (widget: IWidget): ElementBox | undefined => {
  if (!widget) return undefined;
  if (!(widget instanceof LineWidget)) {
    return getNextValidElement((widget as Widget).firstChild);
  }

  if ((widget as LineWidget).children.length === 0) {
    return getNextValidElement((widget as LineWidget).paragraph.nextWidget);
  }
  return (widget as LineWidget).children[0];
};

const getNextValidWidget = (widget: Widget): Widget | undefined => {
  if (!widget.nextWidget && widget.containerWidget) {
    return getNextValidWidget(widget.containerWidget);
  }
  return widget.nextWidget;
};

const getFirstValidLineInPage = (
  page: Page,
  documentEditor: DocumentEditor,
): LineWidget | undefined => {
  for (const body of page.bodyWidgets) {
    const line = getFirstValidLineInBody(body, documentEditor);
    return line;
  }
};

const getFirstValidLineInBody = (
  body: BodyWidget,
  documentEditor: DocumentEditor,
): LineWidget | undefined => {
  for (const block of body.childWidgets) {
    const firstParagraph = documentEditor.documentHelper.getFirstParagraphBlock(
      block as BlockWidget,
    );
    for (const line of firstParagraph.childWidgets) {
      if (line instanceof LineWidget && line.children.length > 0) {
        return line;
      }
    }
  }
};

const getNextLine = (
  line: LineWidget,
  documentEditor: DocumentEditor,
): LineWidget | undefined => {
  if (line.nextLine) {
    return line.nextLine;
    // if we are inside a table, we need to search for the next valid widget
  } else if (!line.paragraph.nextWidget && line.paragraph.isInsideTable) {
    const nextValidWidget = getNextValidWidget(line.paragraph);
    if (nextValidWidget) return getNextValidElement(nextValidWidget)?.line;
    // going to next widget, that can be a table or a paragraph
  } else if (line.paragraph.nextWidget) {
    return getNextValidElement(line.paragraph.nextWidget)?.line;
    // if we don't have a next widget for paragraph, we need to go to the next body
  } else if (
    line.paragraph.bodyWidget.nextWidget &&
    line.paragraph.bodyWidget.nextWidget instanceof BodyWidget
  ) {
    return getFirstValidLineInBody(
      line.paragraph.bodyWidget.nextWidget,
      documentEditor,
    );
    // lastly, if we don't have a next body, we need to go to the next page
  } else {
    const nextPage = documentEditor.selection.getPage(line.paragraph).nextPage;
    if (!nextPage) return undefined;
    const nextValidLine = getFirstValidLineInPage(nextPage, documentEditor);
    return nextValidLine;
  }
};

const getNextElement = (
  element: ElementBox,
  documentEditor: DocumentEditor,
): ElementBox | undefined => {
  if (element.nextElement) {
    return element.nextElement;
  }
  const nextLine = getNextLine(element.line, documentEditor);
  return nextLine?.children?.[0];
};

const getInitialWidth = (bookmark: ElementBox) => {
  const line = bookmark.line;
  return line.children
    .slice(0, bookmark.indexInOwner)
    .reduce<number>((width, elementBox) => {
      return width + elementBox.width;
    }, 0);
};

export const getHighlightRects = (
  id: string,
  documentEditor: DocumentEditor,
  type: 'comment' | 'bookmark' | 'revision' | 'selection',
) => {
  let firstElement: ElementBox | null | undefined = null;
  let lastElement: ElementBox | null | undefined = null;

  switch (type) {
    case 'comment':
      const comment = getComment(id, documentEditor);
      // the first and last element of a comment element is a CommentCharacterBox, so we need to get the next/previous one
      firstElement = comment?.commentStart?.nextElement;
      lastElement = comment?.commentEnd?.previousElement;
      break;
    case 'bookmark':
      const bookmark = getBookmark(id, documentEditor);
      // the first and last element of a bookmark element is a BookmarkElementBox, so we need to get the next/previous one
      firstElement = bookmark?.nextElement;
      lastElement = bookmark?.reference?.previousElement;
      break;
    case 'revision':
      const revision = getRevision(id, documentEditor);
      firstElement = revision?.range?.find(
        (element) => element instanceof ElementBox,
      ) as ElementBox;
      // last element is a bit tricky because our TS version doest support findLast, so we copy, reverse and find the first again.
      lastElement = [...(revision?.range || [])]
        ?.reverse()
        ?.find((element) => element instanceof ElementBox) as ElementBox;
      break;
    case 'selection':
      const start = documentEditor.selection.start;
      const end = documentEditor.selection.end;
      const startOffset: number = start.offset;
      const endOffset: number = end.offset;
      // get the current inline in the start and end line based on the offset
      const startElementInfo = (start.currentWidget as LineWidget).getInline(
        startOffset,
        0,
      );
      const endElementInfo = (end.currentWidget as LineWidget).getInline(
        endOffset,
        0,
      );

      firstElement = startElementInfo.element;
      lastElement = endElementInfo.element;
      break;
    default:
      return [];
  }
  if (!firstElement || !lastElement) {
    return [];
  }

  let currentElement: ElementBox | undefined = firstElement;
  const rects = [];

  let rect = {
    height:
      (currentElement?.line?.height || 0) *
      documentEditor.documentHelper.zoomFactor,
    width:
      (currentElement?.width || 0) * documentEditor.documentHelper.zoomFactor,
    x:
      getXPositionFromElement(currentElement, documentEditor) +
      getInitialWidth(firstElement) * documentEditor.documentHelper.zoomFactor,
    y: getYPositionFromElement(currentElement, documentEditor),
  };

  // means that we only have one element
  if (currentElement === lastElement) {
    rects.push(rect);
  }

  while (currentElement !== lastElement) {
    const previousElement = currentElement;
    currentElement = getNextElement(currentElement, documentEditor);

    if (!currentElement) {
      break;
    }

    if (currentElement.line !== previousElement.line) {
      rects.push(rect);
      rect = {
        height:
          currentElement.line.height * documentEditor.documentHelper.zoomFactor,
        width: currentElement.width * documentEditor.documentHelper.zoomFactor,
        x: getXPositionFromElement(currentElement, documentEditor),
        y: getYPositionFromElement(currentElement, documentEditor),
      };
    } else {
      // adds the width of the currentElement to the rect
      rect.width =
        rect.width +
        currentElement.width * documentEditor.documentHelper.zoomFactor;
    }

    if (currentElement === lastElement) {
      rects.push(rect);
      break;
    }
  }

  return rects;
};

// TODO: we should refactor based in this code if possible.
// https://github.com/syncfusion/ej2-javascript-ui-controls/blob/1ab03cb9d3aa39e217e9bb40fd5c657e6f2d9675/controls/documenteditor/src/document-editor/implementation/track-changes/track-changes-pane.ts#L937
const isReferenceText = (element: TextElementBox) => {
  return (
    element.text &&
    (element.text.includes('REF') ||
      element.text.includes('MERGEFORMAT') ||
      element.text.includes('HYPERLINK') ||
      element.text.includes('PAGE'))
  );
};

export const getRevisionText = (revision: Revision) => {
  return revision.range.reduce((acc, current) => {
    let text = '';
    if (current instanceof WCharacterFormat) {
      text = '¶\n';
    } else if (current instanceof TextElementBox) {
      if (!isReferenceText(current)) {
        text = current.text;
      }
    } else if (current instanceof ImageElementBox) {
      text = IMAGE_PLACEHOLDER_TEXT;
    }

    return acc + text;
  }, '');
};

export const getCommentText = (
  commentId: string,
  documentEditor: DocumentEditor,
) => {
  const comment = documentEditor.documentHelper.comments.find(
    (comment) => comment.commentId === commentId,
  );
  let text = '';
  if (comment) {
    const firstElement: ElementBox = comment.commentStart;
    const lastElement: ElementBox = comment.commentEnd;
    let currentElement: ElementBox | undefined = firstElement;

    while (currentElement && currentElement !== lastElement) {
      if (currentElement instanceof TextElementBox) {
        if (!isReferenceText(currentElement)) {
          text = text.concat(currentElement.text);
        }
      }

      if (currentElement instanceof ImageElementBox) {
        text = text.concat(IMAGE_PLACEHOLDER_TEXT);
      }

      currentElement = getNextElement(currentElement, documentEditor);

      if (currentElement === lastElement) {
        break;
      }
    }
  }

  return text;
};

/**
 *
 * @param bookmarkId the bookmarkId
 * @param documentEditor the document editor instance
 * @returns true or false
 */
export const testBookmarkInsideSelection = (
  bookmarkId: string,
  documentEditor: DocumentEditor,
) => {
  const bookmarkIds: string[] = (documentEditor.selection as any).getSelBookmarks(
    true,
  );
  return bookmarkIds.some((id) => id === bookmarkId);
};

/**
 *
 * @param offset the offset to be moved
 * @param documentEditor the document editor instance
 */
export const moveCursorInText = (
  offset: number,
  documentEditor: DocumentEditor,
  shouldSkipExtraMove = false,
) => {
  for (let index = 0; index < offset; index++) {
    // if it's the end of the line, we need to move one position forward
    if (
      documentEditor.selection.end.offset ===
        documentEditor.selection.end.currentWidget.getEndOffset() &&
      !documentEditor.selection.end.isAtParagraphEnd &&
      !shouldSkipExtraMove
    ) {
      documentEditor.selection.moveNextPosition();
    }
    documentEditor.selection.moveNextPosition();
  }
};

type ExtendSelectionType = 'end' | 'start';

/**
 *
 * @param type the type of selection, that can be "end" or "start"
 * @param offset the offset to be moved
 * @param documentEditor the document editor instance
 */
export const extendSelection = (
  type: ExtendSelectionType,
  offset: number,
  documentEditor: DocumentEditor,
) => {
  for (let index = 0; index < offset; index++) {
    // if it's the end of the line, we need to move one position forward
    if (
      documentEditor.selection[type].offset ===
        documentEditor.selection[type].currentWidget.getEndOffset() &&
      !documentEditor.selection[type].isAtParagraphEnd
    ) {
      documentEditor.selection[type].moveNextPosition();
    }
    documentEditor.selection[type].moveNextPosition();
  }
};

/**
 * Finds the clicked ElementBox
 *
 * @param point the point where the click happened
 * @param documentEditor the document editor instance
 * @returns the selected element or null if not found.
 */
export const getElementAtPoint = (
  point: Point,
  documentEditor: DocumentEditor,
): Nullable<ElementBox> => {
  const touchPoint = documentEditor.viewer.findFocusedPage(point, true, true);
  const touchedLine = documentEditor.documentHelper.getLineWidget(touchPoint);
  let selectedElement = null;
  if (touchedLine && touchPoint) {
    let analyzedX = touchedLine.paragraph.x;
    selectedElement =
      touchedLine.children.find((element) => {
        if (
          analyzedX <= touchPoint?.x &&
          element.width + analyzedX >= touchPoint?.x
        ) {
          return true;
        } else {
          analyzedX = analyzedX + element.width;
          return false;
        }
      }) || null;
  }

  return selectedElement;
};

/**
 * Generates an object containing all bookmarks in the document.
 *
 * @param documentEditor the editor containing bookmark information.
 * @returns The object containing Maps of bookmarks for every existing bookmark type. (e.g clause, risk, etc)
 */

export const generateBookmarks = (
  documentEditor: DocumentEditor,
  bookmarks: Bookmarks,
) => {
  const docBookmarks = documentEditor.documentHelper.bookmarks;
  return docBookmarks.keys.reduce<Bookmarks>(
    (acc: Bookmarks, current: string) => {
      const bookmark = docBookmarks.get(current);
      if (!bookmark?.name) {
        return acc;
      }
      const bookmarkType = getBookmarkType(current);

      if (bookmarkType) {
        acc[bookmarkType].set(current, bookmark);
      }

      return acc;
    },
    bookmarks,
  );
};

/**
 * Retrieves the type of bookmark.
 *
 * @param key The key that is used to retrieve the bookmark.
 * @returns If there is a match of a bookmark type in the key then it returns that bookmark type else it returns undefined.
 */
export const getBookmarkType = (key: string) => {
  for (const type of bookmarkTypes) {
    const reg = new RegExp(type);
    const match = key.match(reg);
    if (match) {
      return type;
    }
  }
};

/**
 * Retrieves a bookmark based on the bookmarkId.
 *
 * @param bookmarkId The id that is used to retrieve the bookmark. (e.g '_clause_123', '_risk_123')
 * @returns If there is an existing bookmark with the bookmarkId then it wll return the bookmark else it return undefined.
 */
export const retrieveBookmark = (bookmarkId: string, bookmarks: Bookmarks) => {
  const type = getBookmarkType(bookmarkId);
  return type ? bookmarks[type].get(bookmarkId) : undefined;
};

/**
 * Retrieves the bookmark based on the bookmark name.
 * @param bookmarkId The id that is used to retrieve the bookmark. (e.g '_clause_123', '_risk_123')
 * @param documentEditor The document editor instance.
 * @returns If there is an existing bookmark with the bookmarkId then it wll return the bookmark else it return undefined.
 */
export const getBookmark = (
  bookmarkId: string,
  documentEditor: DocumentEditor,
) => {
  validateDocumentEditor(documentEditor);
  return documentEditor.documentHelper.bookmarks.get(bookmarkId);
};

/**
 * Retrieves the revision based on the revisionID
 * @param revisionId The id of the revision
 * @param documentEditor The document editor instance.
 * @returns If there is an existing revision with the revisionId then it wll return the revision else it return undefined.
 */
export const getRevision = (
  revisionId: string,
  documentEditor: DocumentEditor,
) => {
  validateDocumentEditor(documentEditor);
  return documentEditor.revisions.changes.find(
    (revision) => revision.revisionID === revisionId,
  );
};

const createComment = (
  comment: CommentElementBox,
  ticketId: string,
  versionId: string,
  threadId: Nullable<string> = null,
): Comment => {
  return {
    // TODO: maybe add the mentioned users since we have the email (if they exists in our database)
    content: [{ text: comment.text }],
    context: [
      { type: EntityType.EditorHighlight, id: comment.commentId },
      { type: EntityType.TicketDocumentVersion, id: versionId },
    ],
    mentions: [],
    entity: { type: EntityType.Ticket, id: ticketId },
    threadId: threadId,
    id: comment.commentId,
    modifiedDate: new Date(comment.date),
    isResolved: comment.isResolved,
    createdDate: new Date(comment.date),
    createdBy: null,
    creatorName: comment.author,
    replies: comment.replyComments.length,
  };
};

/**
 * Extract the comments from the editor and parse it to be used within the sidepanel
 *
 * @param ticketId the id of the ticket
 * @param versionId the version id of the document
 * @param documentEditor document editor instance
 * @returns
 */
export const extractComments = (
  ticketId: string,
  versionId: string,
  documentEditor: DocumentEditor,
): Comment[] => {
  const allComments = documentEditor.documentHelper?.comments ?? [];
  const parsedComments: Comment[] = [];
  for (const comment of allComments) {
    parsedComments.push(createComment(comment, ticketId, versionId));
    for (const reply of comment.replyComments) {
      parsedComments.push(
        createComment(reply, ticketId, versionId, comment.commentId),
      );
    }
  }
  return parsedComments;
};

/**
 * Checks if the given element has revisions of the given type
 */
const hasRevisionsOfType = (
  element: ElementBox,
  type: Omit<RevisionType, 'All'>,
) => {
  // if type is None, it means we don't want revisions
  if (type === 'None') return !(element.revisions.length > 0);
  return element.revisions.some((revision) => revision.revisionType === type);
};

const testShouldBeIncluded = (
  element: ElementBox,
  type: Omit<RevisionType, 'All'>,
) => {
  // return true if there are no revisions
  if (element.revisions.length === 0) return true;

  return hasRevisionsOfType(element, type);
};

const testIsLastElement = (element: ElementBox) => {
  return (
    element.indexInOwner === element.line.children.length - 1 &&
    element.line.indexInOwner === element.paragraph.childWidgets.length - 1
  );
};

/**
 * @param documentEditor
 * @returns the selected text without revisions
 */
export const getSelectedTextRevisionType = (
  documentEditor: DocumentEditor,
  type: Omit<RevisionType, 'All'>,
) => {
  let start = documentEditor.selection.start;
  let end = documentEditor.selection.end;
  let text = '';

  // case it was selected backwards
  if (start.isExistAfter(end)) {
    const temp: TextPosition = end;
    end = start;
    start = temp;
  }

  // if they are the same position, then return it empty
  if (start.isAtSamePosition(end)) {
    return text;
  }

  const startOffset: number = start.offset;
  const endOffset: number = end.offset;
  // get the current inline in the start and end line based on the offset
  const startElementInfo = (start.currentWidget as LineWidget).getInline(
    startOffset,
    0,
  );
  const endElementInfo = (end.currentWidget as LineWidget).getInline(
    endOffset,
    0,
  );

  const startInline = startElementInfo.element;
  const endInline = endElementInfo.element;

  if (!startInline || !endInline) {
    return text;
  }

  if (startInline && startInline === endInline) {
    if (startInline instanceof TextElementBox) {
      return startInline.text.substring(startOffset, endOffset);
    }
    return text;
  }

  if (startInline instanceof TextElementBox) {
    text = startInline.text.substring(startElementInfo.index);
  }

  let currentInline: ElementBox | undefined = startInline;

  // it will loop until it finds the endInline or until it reaches an undefined element
  do {
    currentInline = getNextElement(currentInline, documentEditor);
    if (currentInline instanceof TextElementBox) {
      if (testShouldBeIncluded(currentInline, type)) {
        if (!isReferenceText(currentInline)) {
          if (currentInline === endInline) {
            text = text + currentInline.text.substring(0, endElementInfo.index);
            // If the element is at the end of a paragraph we should add a \r.
            if (testIsLastElement(currentInline)) {
              text = text + '\r';
            }
            break;
          }
          text = text + currentInline.text;
          // If the element is at the end of a paragraph we should add a \r.
          if (testIsLastElement(currentInline)) {
            text = text + '\r';
          }
        }
      }
    }
  } while (currentInline && currentInline !== endInline);
  return text;
};

export const approveRevisionsInSelection = (documentEditor: DocumentEditor) => {
  let start = documentEditor.selection.start;
  let end = documentEditor.selection.end;

  const revisions: Revision[] = [];

  // case it was selected backwards
  if (start.isExistAfter(end)) {
    const temp: TextPosition = end;
    end = start;
    start = temp;
  }

  // if they are the same position, then return it empty
  if (start.isAtSamePosition(end)) {
    return;
  }

  const startOffset: number = start.offset;
  const endOffset: number = end.offset;
  // get the current inline in the start and end line based on the offset
  const startElementInfo = (start.currentWidget as LineWidget).getInline(
    startOffset,
    0,
  );
  const endElementInfo = (end.currentWidget as LineWidget).getInline(
    endOffset,
    0,
  );

  const startInline = startElementInfo.element;
  const endInline = endElementInfo.element;

  if (!startInline || !endInline) {
    return;
  }

  let currentInline: ElementBox | undefined = startInline;
  currentInline.revisions.forEach((revision) => {
    if (!revisions.includes(revision)) {
      revisions.push(revision);
    }
  });
  // it will loop until it finds the endInline or until it reaches an undefined element
  do {
    currentInline = getNextElement(currentInline, documentEditor);
    currentInline &&
      currentInline.revisions.forEach((revision) => {
        if (!revisions.includes(revision)) {
          revisions.push(revision);
        }
      });
  } while (currentInline && currentInline !== endInline);

  revisions.forEach((revision) => {
    revision.accept();
  });
};

export const getRevisions = (documentEditor: DocumentEditor) => {
  return {
    changes: ((documentEditor?.trackChangesPane as any)?.sortedRevisions ||
      []) as Revision[],
    acceptAll: (revisions: Revision[]) =>
      documentEditor?.revisions?.handleRevisionCollection(true, revisions),
    rejectAll: (revisions: Revision[]) =>
      documentEditor?.revisions?.handleRevisionCollection(false, revisions),
  };
};

export const updateRevisions = (documentEditor: DocumentEditor) => {
  documentEditor?.trackChangesPane?.updateTrackChanges?.();
};

export const selectTokens = (
  tokens: number[],
  wordIndexToPosition: Record<number, WordMappingToPosition>,
) => {
  const firstWordIndex = tokens[0];
  const lastWordIndex = tokens[tokens.length - 1];
  let firstWordLocation,
    lastWordLocation,
    firstWordLocationShift = 0,
    lastWordLocationShift = 0;

  // if we don't find the match in the first try, we try to find the next/last word position for both first and last word index
  do {
    firstWordLocation =
      wordIndexToPosition[firstWordIndex + firstWordLocationShift];
    lastWordLocation =
      wordIndexToPosition[lastWordIndex - lastWordLocationShift];
    if (!firstWordLocation) {
      firstWordLocationShift = firstWordLocationShift + 1;
    }
    if (!lastWordLocation) {
      lastWordLocationShift = lastWordLocationShift + 1;
    }
    if (
      firstWordIndex + firstWordLocationShift >=
      lastWordIndex - lastWordLocationShift
    ) {
      break;
    }
  } while (!firstWordLocation || !lastWordLocation);

  return {
    firstWordLocation,
    lastWordLocation,
  };
};

/**
 * @param documentEditor The document editor instance
 * @param documentContent the document content in format of { words: { word: string; id: Nullable<number> }[] }
 * @returns the mapping of the words to the ej2 position with format { htmlId: { section: number, block: number, startOffset: number, endOffset: number } }
 */
export const extractPositionFromTokens = (
  documentEditor: DocumentEditor,
  documentContent: {
    words: { word: string; id: Nullable<number> }[];
  },
) => {
  if (!documentEditor) {
    return {};
  }
  const wordsMapping = documentContent.words;
  const ej2Mapping = mapTextToWordPosition(documentEditor);
  return mapWordsToEj2Words(wordsMapping, ej2Mapping);
};

/**
 * @param startSelection EJ2 position in terms of <section>;<block>;<offset>
 * @param endSelection EJ2 position in terms of <section>;<block>;<offset>
 * @param mapping the mapping of the words to the ej2 position with format { htmlId: { section: number, block: number, startOffset: number, endOffset: number } }
 * @returns the selected token ids array
 */
export const getTokensFromSelection = (
  startSelection: string,
  endSelection: string,
  mapping: Record<number, WordMappingToPosition>,
) => {
  const [startSection, startBlock, startOffset] = startSelection.split(';');
  const [endSection, endBlock, endOffset] = endSelection.split(';');
  const selectedTokens: number[] = [];
  Object.entries(mapping).forEach(([htmlId, tokenMap]) => {
    if (
      tokenMap.section >= Number(startSection) &&
      tokenMap.section <= Number(endSection)
    ) {
      if (
        tokenMap.block >= Number(startBlock) &&
        tokenMap.block <= Number(endBlock)
      ) {
        if (
          tokenMap.startOffset >= Number(startOffset) &&
          tokenMap.endOffset <= Number(endOffset)
        ) {
          selectedTokens.push(Number(htmlId));
        }
      }
    }
  });
  return selectedTokens;
};

export const testIsTokenMatch = (
  selectedTokens: number[],
  clauseContentTokens: number[],
) => {
  // console.log(selectedTokens, clauseContentTokens);
  return clauseContentTokens.some((token) => selectedTokens.includes(token));
};
