import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import {
  aribaPasswordAdapterChoices,
  createAribaSyncPair,
  getSyncPairsAriba,
  updateAribaSyncPair,
} from '~/api/evisync';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Chips, DateText, Divider, Filename, Grid, Layout, Text } from '~/eds';
import { AribaIntegrationType, ManageSyncPairStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { useRouting } from '~/routing';
import { Folder } from '~/ui';
import { fileTypesToChips } from '~/utils';

import { CONTRACT_WORKSPACE_EVISORT_VIEW, SYNC_SYMBOL } from '../../constants';
import { resetSyncPairAuthData } from '../../util';
import AribaFooter from '../AribaFooter';
import SummaryRowAriba from './SummaryRowAriba';

function SummaryAriba({
  // connected,
  activeSyncPair,
  integrationsSetSyncPairs,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const oneTimeImportStage = useFlag(FlagType.FieldMappingOneTimeImport);
  const defaultState = !oneTimeImportStage;

  const { provider, syncType, syncPair, allowedSyncFileTypes } = activeSyncPair;
  const { locationName, realm } = activeSyncPair[ManageSyncPairStageType.Realm];
  const { evisortFolder } = activeSyncPair[ManageSyncPairStageType.FolderAriba];
  const fieldsMappingStage = defaultState
    ? ManageSyncPairStageType.FolderAriba
    : ManageSyncPairStageType.FieldMapping;
  const docIdsFile =
    activeSyncPair[ManageSyncPairStageType.FolderAriba].docIdsFile ?? [];
  const cwCount =
    activeSyncPair[ManageSyncPairStageType.FolderAriba].cwCount ?? 'Unknown';
  const sinceDate =
    activeSyncPair[ManageSyncPairStageType.FolderAriba].sinceDate;
  const fieldsMapping =
    activeSyncPair[fieldsMappingStage].fieldsMapping.filter(
      (field) => field.evisort && field.ariba && field.sync,
    ) ?? [];
  const fieldMappingFilePath =
    activeSyncPair[fieldsMappingStage].fieldMappingFilePath ?? null;
  const taskDiscovery = activeSyncPair.taskDiscovery ?? { enabled: false };

  const { history } = useRouting();
  const { clientId } = useParams();

  const formatSyncPair = () => {
    const updatedSyncPair = {
      id: syncPair,
      provider,
      providerFolderPath: CONTRACT_WORKSPACE_EVISORT_VIEW,
      folderId: evisortFolder.folderId,
      allowedSyncFileTypes: allowedSyncFileTypes,
      mapping: fieldsMapping,
      extraContext: { taskDiscovery },
      syncType,
    };
    if (fieldMappingFilePath) {
      updatedSyncPair.fieldMappingFilePath = fieldMappingFilePath;
    }
    if (syncType === AribaIntegrationType.CONTINUOUS) {
      updatedSyncPair.sinceDate = sinceDate?.getTime();
    } else {
      updatedSyncPair.providerOptions = { file: docIdsFile[0] };
    }
    return updatedSyncPair;
  };

  const successHandlerSyncPair = () => {
    getSyncPairsAriba({ clientId }).then((pairs) =>
      integrationsSetSyncPairs(pairs),
    );
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
    history.push(`/admin/console/${clientId}/integrations?tab=procurement`);
  };

  const { isLoading: isCreating, executor: executeCreate } = useAsync(
    createAribaSyncPair,
    formatSyncPair(),
    {
      errorToastMessage: 'Unable to create sync pair, please try again.',
      successHandler: () => {
        successHandlerSyncPair();
      },
    },
  );

  const { isLoading: isUpdating, executor: executeUpdate } = useAsync(
    updateAribaSyncPair,
    formatSyncPair(),
    {
      errorToastMessage: 'Unable to create sync pair, please try again.',
      successHandler: () => {
        successHandlerSyncPair();
      },
    },
  );

  const handleCreateSyncPair = () => {
    if (syncType === AribaIntegrationType.ONE_TIME) {
      executeCreate();
    } else {
      executeUpdate();
    }
  };

  const handleGoToStage = (stage) => () => {
    integrationsSetManageSyncPairStage(stage);
    integrationsSetActiveSyncPair(resetSyncPairAuthData(activeSyncPair));
  };

  const handleBack = () => {
    if (defaultState) {
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.FolderAriba);
    } else if (oneTimeImportStage) {
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.FieldMapping);
    }
  };

  const getEvisortFieldLabel = (metaData) => {
    return metaData.evisortField ? metaData.evisortField.label : null;
  };

  const getAribaFieldLabel = (metaData) => {
    return metaData.aribaField ? metaData.aribaField.fieldName : null;
  };
  const fieldMappingToText = (fieldData) => {
    return [
      getEvisortFieldLabel(fieldData.meta),
      SYNC_SYMBOL[fieldData.sync],
      getAribaFieldLabel(fieldData.meta),
    ].join(' ');
  };

  const fieldsData = fieldsMapping.map((field, index) => {
    return {
      evisortField: `${index + 1}. ${getEvisortFieldLabel(field.meta)}`,
      sync: SYNC_SYMBOL[field.sync],
      aribaField: getAribaFieldLabel(field.meta),
    };
  });

  const fieldsColumns = [
    {
      label: 'Evisort Field',
      key: 'evisortField',
    },
    {
      label: 'Sync',
      key: 'sync',
      props: { align: 'center' },
    },
    {
      label: 'Ariba Field',
      key: 'aribaField',
    },
  ];

  const FieldList = memo(({ columns, data }) => {
    const fields = data.map((datum) => {
      const inputs = columns.map(({ key, props }) => {
        const value = datum[key];
        return (
          <Text key={key} style={{ flex: 1 }} {...props}>
            {value}
          </Text>
        );
      });

      return inputs;
    });

    return (
      <Grid
        columns={columns.length}
        columnSpacing={2}
        rowSpacing={2}
        columnWidths={['8fr', '1fr', '8fr']}
      >
        {fields}
      </Grid>
    );
  });

  return (
    <Layout align="center" direction="column" spacing={8} w="100%">
      <Layout align="center">
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Layout direction="column" spacing={8} w="90%">
        <SummaryRowAriba title="Realm ID">
          <Text>{realm}</Text>
        </SummaryRowAriba>
        <SummaryRowAriba title="Data Center Location">
          <Text>{locationName}</Text>
        </SummaryRowAriba>
        <SummaryRowAriba
          title="Evisort Folder"
          onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
        >
          <Folder
            folder={evisortFolder}
            type="internal"
            shouldTruncate={true}
          />
        </SummaryRowAriba>
        <SummaryRowAriba
          title="File Types"
          onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
        >
          <Chips chips={fileTypesToChips(allowedSyncFileTypes)} limit={100} />
        </SummaryRowAriba>
        {syncType === AribaIntegrationType.ONE_TIME ? (
          <SummaryRowAriba
            title="Ariba Document IDs"
            onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
          >
            <Filename name={docIdsFile[0].name} />
          </SummaryRowAriba>
        ) : null}
        {syncType === AribaIntegrationType.CONTINUOUS ? (
          <SummaryRowAriba
            title="Date filter"
            onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
          >
            <DateText format="short" date={sinceDate} />
          </SummaryRowAriba>
        ) : null}

        {syncType === AribaIntegrationType.CONTINUOUS ? (
          <SummaryRowAriba
            title="Use Ariba Task for Faster Sync"
            onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
          >
            <Text>{taskDiscovery.enabled ? 'Yes' : 'No'}</Text>
          </SummaryRowAriba>
        ) : null}
        {syncType === AribaIntegrationType.CONTINUOUS ? (
          <SummaryRowAriba
            title="Ariba Task User"
            onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
          >
            <Text>{taskDiscovery.approverId || '--'}</Text>
          </SummaryRowAriba>
        ) : null}
        {syncType === AribaIntegrationType.CONTINUOUS ? (
          <SummaryRowAriba
            title="Ariba Task User Authentication Method"
            onChange={handleGoToStage(ManageSyncPairStageType.FolderAriba)}
          >
            <Text>
              {aribaPasswordAdapterChoices
                .filter((each) => each.value === taskDiscovery.passwordAdapter)
                .map((each) => each.label)
                .shift() || '--'}
            </Text>
          </SummaryRowAriba>
        ) : null}
        {syncType === AribaIntegrationType.CONTINUOUS ? (
          <SummaryRowAriba title="Contract Workspaces">
            <Text>{cwCount}</Text>
          </SummaryRowAriba>
        ) : null}
        {syncType === AribaIntegrationType.CONTINUOUS || oneTimeImportStage ? (
          <SummaryRowAriba
            title="List of Field Mappings"
            onChange={handleGoToStage(
              oneTimeImportStage
                ? ManageSyncPairStageType.FieldMapping
                : ManageSyncPairStageType.FolderAriba,
            )}
          >
            <Layout>
              {oneTimeImportStage && (
                <FieldList columns={fieldsColumns} data={fieldsData} />
              )}
              {defaultState && (
                <Layout direction="column">
                  {fieldsMapping.map((field) => (
                    <Text>{fieldMappingToText(field)}</Text>
                  ))}
                </Layout>
              )}
            </Layout>
          </SummaryRowAriba>
        ) : null}
      </Layout>
      <AribaFooter
        secondaryAction={{
          text: 'Back',
          onClick: handleBack,
        }}
        mainAction={{
          text: 'Confirm Sync Pair',
          onClick: handleCreateSyncPair,
          isLoading: isCreating || isUpdating,
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetSyncPairs,
  integrationsSetManageSyncPairStage,
})(SummaryAriba);
