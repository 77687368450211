import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { MAX_DASHBOARD_NAME_LENGTH } from '~/constants/max_lengths';
import { Box, Button, Layout, TextInput, useModal } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { DashboardEntityData } from '~/redux/api/methods/dashboardV2';
import { Nullable } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';

type Props = {
  dashboard: DashboardEntityData;
};

const SaveDashboard = ({ dashboard }: Props) => {
  const history = useHistory();
  const [
    saveAsDashboard,
    saveDashboardResults,
  ] = api.endpoints.saveAsDashboard.useMutation();
  const {
    data,
    error: savingError,
    isError: isSavingError,
    isLoading: isSaving,
    isSuccess: isSavingSuccess,
  } = saveDashboardResults;

  const placeholderName = `${dashboard.attributes.name} (1)`;
  const [name, setName] = useState<Nullable<string>>(placeholderName);
  const user = useCurrentUser();

  const handleSubmit = () => {
    dashboard.attributes = { ...dashboard.attributes, name: name as string };
    const { id, ...dashboardToSaveAs } = dashboard;
    trackSegment('saveAsDashboard', {
      dashboard_id: id,
      dashboard_name: name,
      user_id: user.id,
      client_id: user.client,
    });
    saveAsDashboard(dashboardToSaveAs);
  };

  const handleHide = () => {
    setName(placeholderName);
  };

  useEffect(() => {
    if (isSavingError) {
      const resp = coerceRtkqError(savingError)?.response || {};
      const msg = resp.data?.errors?.[0];
      showToast(
        ERROR,
        msg?.title || 'An error occurred while saving the dashboard.',
      );
    }
  }, [isSavingError]);

  useEffect(() => {
    if (isSavingSuccess) {
      showToast(SUCCESS, 'Dashboard has been saved successfully.');

      history.push(`/dashboards/${data?.data.id}`);
    }
  }, [isSavingSuccess]);

  const getTooltip = () => {
    if (!name?.trim()) {
      return 'Please enter a name.';
    } else if (name?.length > MAX_DASHBOARD_NAME_LENGTH) {
      return `A dashboard name can not exceed ${MAX_DASHBOARD_NAME_LENGTH} characters.`;
    } else {
      return '';
    }
  };

  const [modal, showModal] = useModal({
    children: (
      <Layout direction="column" h="100%" spacing={6}>
        <Box flex="auto" overflowY="auto">
          <TextInput
            autoFocus={true}
            disabled={isSaving}
            name="save as dashboard"
            placeholder="Enter a dashboard name"
            value={name}
            onChange={setName}
          />
        </Box>
      </Layout>
    ),
    primaryAction: {
      disabled:
        !name?.trim() || name?.length > MAX_DASHBOARD_NAME_LENGTH || isSaving,
      isLoading: isSaving,
      text: 'Save Dashboard',
      tooltip: getTooltip(),
      onClick: handleSubmit,
    },
    title: 'Save Dashboard',
    width: 'm',
    onHide: handleHide,
  });

  return (
    <>
      <Button text="Save As" onClick={showModal} />
      {modal}
    </>
  );
};

export default SaveDashboard;
