import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect, { ActionMeta, Styles } from 'react-select';
import Creatable, { Props as CreatableProps } from 'react-select/creatable';

import { InputValue, Nullable, Option, ValuesMap } from '../../types';
import {
  fromValuesMap,
  testHasTextMatch,
  toValuesMap,
  typedMemo,
} from '../../utils';
import * as components from './components';
import { SELECT_ALL_OPTION, toValue } from './components/Option';
import { arrayMove, getSortProps } from './sortable-utils';
import { SelectProps } from './types';
import {
  filterGroupedOptions,
  findOption,
  getStyles,
  sortOptions,
  testIsOptionDisabled,
  testIsOptionsGrouped,
} from './utils';

export const getSelectAllLabel = (countString?: string, search?: string) => {
  const count = Number(countString);
  const exceeding = countString?.endsWith('+');
  return !countString || (isNaN(count) && !exceeding)
    ? SELECT_ALL_OPTION.label
    : `${SELECT_ALL_OPTION.label} ${countString} ${
        search ? 'Matching ' : ''
      }${pluralize('Value', count)}`;
};

/**
 * `Select` provides a common controlled component for managing user selections.
 *
 * It implements the `Input` and `Option` interfaces with generic value signature set by the domain.
 *
 * It supports multi-selection when `isMulti` is enabled.
 *
 * It supports an embedded rendering mode when `isEmbedded` is enabled.  This causes the select to be embedded in its parents container with some behavioral changes.
 *
 * Constrained configurations (e.g. `enableSearchIcon`, `enableValueContainer`, `isSearchable`) ensure `Select` is used in consistent ways that do not deviate from design specs.
 *
 * `Select` also supports the ability to create new options.  Specify the `onAddOption` callback and control the `options` prop to leverage this feature.  Note that the `onAddOption` will add the newly created option to the selected values and run the provided value `onChange` handler.
 *
 * `Select` is implemented by lightly wrapping the react-select library (see https://react-select.com/).  We implement customoizations by overriding vendor components in the ./components folder and avoid customizations using the vendor `styles` API.
 */
export const Select = typedMemo(
  <V extends unknown, M extends boolean>({
    autoFocus,
    disabled,
    enableControl = true,
    enablePortal = false,
    enableSearchIcon,
    enableValueContainer = true,
    enableErrorMessage,
    error,
    filterOption = () => true, // no filtering
    footerAction,
    id,
    isClearable = true,
    isEmbedded,
    isLoading,
    isMenuOpen,
    isMulti,
    isSearchable = true,
    menuHeader,
    menuPlacement,
    name,
    noOptionsMessage = 'No options',
    readOnly,
    options: initialOptions,
    placeholder,
    value: initialValue,
    width,
    onAddOption,
    onBlur,
    onChange,
    onFocus,
    onFilterOption,
    onMount,
    onSearch,
    enableOptionIndicator = isMulti,
    enableSelectAll = true,
    enableSorting = isEmbedded,
    asyncValue,
    shouldAllowInvalidValue = true,
    selectAllCount,
  }: SelectProps<V, M>) => {
    const [search, setSearch] = useState('');

    const isCreatable = !!onAddOption;

    const value = useMemo(() => {
      // TODO EKP-8607: implement this formally, placeholder implementation for supporting new interface (props.asyncValue)
      return isMulti && asyncValue
        ? fromValuesMap<V>(asyncValue.selectedValuesMap)
        : initialValue ?? null;
    }, [isMulti, asyncValue, initialValue]);

    const options = useMemo(() => {
      const sortedOptions =
        enableSorting && !testIsOptionsGrouped(initialOptions)
          ? sortOptions(initialOptions, value)
          : initialOptions;
      return onFilterOption
        ? filterGroupedOptions(sortedOptions, onFilterOption)
        : sortedOptions;
    }, [enableSorting, initialOptions, value]);

    // TODO: deprecate `shouldNotControlFiltering` so filtering can be merged with `props.onFilterOption = defaultFilterOption`
    const shouldNotControlFiltering = !search || onSearch;
    const filteredOptions = useMemo(() => {
      if (shouldNotControlFiltering) {
        return options;
      }
      const defaultFilterOption = (option: Option<V>) =>
        testHasTextMatch(option.label, search) ||
        testHasTextMatch(option.description || '', search);
      return filterGroupedOptions(options, defaultFilterOption);
    }, [search, options]);

    const optionsWithSelectAll = useMemo(() => {
      return isMulti &&
        enableSelectAll &&
        !testIsOptionsGrouped(filteredOptions) && // select all is not supported for grouped options yet
        filteredOptions?.length
        ? [
            {
              ...SELECT_ALL_OPTION,
              label: getSelectAllLabel(selectAllCount, search),
            },
            ...filteredOptions,
          ]
        : filteredOptions;
    }, [isMulti, enableSelectAll, filteredOptions, selectAllCount]);

    // option-based value used by react-select
    const optionValue: Option<V> | Option<V>[] | null = useMemo(() => {
      if (value === null) {
        return null;
      } else if (isMulti && Array.isArray(value)) {
        return value.map(
          findOption(options, shouldAllowInvalidValue, isLoading),
        );
      } else {
        return findOption(
          options,
          shouldAllowInvalidValue,
          isLoading,
        )(value as V);
      }
    }, [isLoading, options, value]);

    const isAllSelected = useMemo(() => {
      if (isMulti) {
        const valueSet = new Set(value as V[]);
        return filteredOptions.every((o) => valueSet.has(o.value));
      }
      return false;
    }, [isMulti, value, filteredOptions]);

    // Used in onChange handler to determine if the user is clicking on the special Select All option.
    // Note: Since this option will never be selected (ie. become part of the value, which causes a series of issues we then have to fix), we cannot rely on react-select to inform us if the user is checking or unchecking it.
    const isSelectAllClicked = (updatedValue: any[]) => {
      return updatedValue[updatedValue.length - 1] === SELECT_ALL_OPTION.value;
    };

    // This state is used to "snapshot" the above search state whenever user checks the Select All option in async mode.
    // The main reason this is a string not a boolean is that user can search a subset of options and select the entire subset. Using a boolean won't give us enough information as soon as user changes the search.
    // As a result, it is the center piece of the async select all handling and used in these different ways:
    //   1. determine the checkbox status of select all - checked, unchecked, indeterminate
    //   2. determine the onChange updatedAsyncValue payload when an option is checked/unchecked
    // Note: the initial state - null, is purposely designed to differentiate from an empty string ''.
    // That is because user can Select All without a search, and we need the '' to capture that state, which is different than the state that, for example, user unchecks it subsequently.
    const [asyncSearch, setAsyncSearch] = useState<Nullable<string>>(null);

    useEffect(() => {
      const initAsyncSearch = asyncValue?.search;
      if (initAsyncSearch) {
        setSearch(initAsyncSearch);
      }
      if (asyncValue?.isAllSelected) {
        setAsyncSearch(initAsyncSearch ?? '');
      }
      if (onMount) {
        onMount(initAsyncSearch || '');
      }
    }, []);

    // prettier-ignore
    const isIndeterminate =
      null !== asyncSearch && // short circuit
      (
        search !== asyncSearch || // case 1: user selects all and changes search
        options.length !== (value as any[]).length // case 2: user selects all and unselects an option
      );

    // Since many of the considerations between async and non-async are quite different, it makes sense (to me at least) to fork the code paths (for maintainability's sake).
    // We may change how we derive this flag but this should suffice for a while.
    const isAsync = !!onSearch;

    const sortProps = getSortProps(({ oldIndex, newIndex }) => {
      const updatedValues = arrayMove(
        value as V[],
        oldIndex,
        newIndex,
      ) as InputValue<V, M>;
      onChange(updatedValues, asyncValue, { action: 'sort-option' });
    });

    const handleSearch = (updatedSearch: string) => {
      onSearch?.(updatedSearch);
      setSearch(updatedSearch);
    };

    const ReactSelectComponent = (isCreatable
      ? Creatable
      : ReactSelect) as React.ComponentType<CreatableProps<Option<V>, boolean>>;

    /**
     *
     * This logic is fairly complex but the goals are to correctly update both `value` and `search` state given the configuration of `Select` (e.g. `isMulti`, `isAsync`) and the action meta that has fired.
     */
    const handleUpdatedValues = (
      incomingValues: V | V[] | null,
      meta: ActionMeta<Option<V, unknown>>,
    ) => {
      let updatedAsyncValue;
      let updatedValue;
      // always reset search on this action
      if (meta.action === 'clear') {
        handleSearch('');
      }
      // empty value
      if (incomingValues === null) {
        updatedValue = null;
      }
      // multi-value
      else if (isMulti && Array.isArray(incomingValues)) {
        if (!isEmbedded) {
          // only clear search in non-embedded mode
          handleSearch('');
        }
        if (!isAsync) {
          if (isSelectAllClicked(incomingValues)) {
            if (!isAllSelected) {
              if (search) {
                updatedValue = filteredOptions.map(toValue);
              } else {
                updatedValue = options.map(toValue);
              }
            } else {
              // uncheck a subset of selected values via select all
              const filteredOptionSet = new Set(
                filteredOptions.map((o) => o.value),
              );
              updatedValue = (value as V[]).filter(
                (v) => !filteredOptionSet.has(v),
              );
            }
          } else {
            updatedValue = incomingValues;
          }
          updatedAsyncValue = null;
        } else {
          // async mode with select all and search - where the fun begins
          if (isSelectAllClicked(incomingValues)) {
            // user is clicking on the Select All option - either checking or unchecking is unknown atm
            if (null === asyncSearch || isIndeterminate) {
              // the Select All option was unchecked/indeterminate and user is checking it
              updatedValue = options.map(toValue);
              setAsyncSearch(search);
              updatedAsyncValue = {
                isAllSelected: true,
                length: updatedValue.length,
                search,
                selectedValuesMap: toValuesMap<V>(updatedValue),
              };
            } else {
              // the Select All option was checked and user is unchecking it
              updatedValue = [] as V[];
              setAsyncSearch(null);
              updatedAsyncValue = {
                isAllSelected: false,
                length: updatedValue.length,
                search,
                selectedValuesMap: toValuesMap<V>(updatedValue),
              };
            }
          } else if (meta.action === 'clear') {
            // user clicks the clear icon in the search bar to remove all chips
            updatedValue = incomingValues;
            setAsyncSearch(null);
            updatedAsyncValue = {
              isAllSelected: false,
              length: updatedValue.length,
              search: asyncSearch,
              selectedValuesMap: toValuesMap<V>(updatedValue),
            };
          } else {
            // user is clicking on an option
            updatedValue = incomingValues;
            if (search === asyncSearch) {
              const selectedValuesMap = {
                ...(asyncValue?.selectedValuesMap || ({} as ValuesMap<V>)),
              };

              // This is important for upstream (ie. backend) to derive the blacklist.
              // For example, user selects all 1000 options except one.
              if (meta.action === 'deselect-option') {
                selectedValuesMap[meta.option!.value] = false;
              } else if (meta.action === 'remove-value') {
                // user clicks on the remove icon on a value chip
                selectedValuesMap[meta.removedValue.value] = false;
              } else if (meta.action === 'pop-value') {
                // user use keyboard to backspace on the search input
                selectedValuesMap[meta.removedValue.value] = false;
              } else if (meta.action === 'select-option') {
                selectedValuesMap[meta.option!.value] = true;
              }

              // We always know user wants to select all at this moment because search === asyncSearch
              updatedAsyncValue = {
                isAllSelected: true,
                length: updatedValue.length,
                search: asyncSearch,
                selectedValuesMap,
              };
            } else {
              // This is when user updated search, and checks or unchecks an option.
              // There are potentially some fine tunings we can do here, but here is what we agreed on we'll do for now:
              // Regardless of if user previously selected all (with or without search), we will clear it. See test case A1.
              setAsyncSearch(null);
              updatedAsyncValue = {
                isAllSelected: false,
                length: updatedValue.length,
                search,
                selectedValuesMap: toValuesMap<V>(updatedValue),
              };
            }
          }
        }
      }
      // single-value
      else {
        updatedValue = incomingValues;
        handleSearch('');
      }

      const actionMeta = {
        action: meta.action,
      };

      onChange(updatedValue as InputValue<V, M>, updatedAsyncValue, actionMeta);
    };

    // react-select vendor lacks formal support of handling `readOnly` (e.g. disabling updating value)
    // we handle readOnly and disabled UI similarly in various UI features
    const isDisabledOrReadOnly = disabled || readOnly;
    const isWidthRelative = width && ['100%', '50%'].includes(width);

    return (
      <ReactSelectComponent
        autoFocus={autoFocus}
        components={components as any} // figure out components type
        closeMenuOnSelect={!isMulti}
        footerAction={footerAction}
        hideSelectedOptions={false}
        id={id}
        inputValue={search}
        isAllSelected={isAsync ? asyncSearch !== null : isAllSelected}
        isClearable={isClearable}
        isDisabled={isDisabledOrReadOnly}
        isIndeterminate={isIndeterminate}
        isLoading={isLoading}
        isOptionDisabled={testIsOptionDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuIsOpen={isEmbedded || isMenuOpen}
        menuPosition={isEmbedded || enablePortal ? undefined : 'fixed'}
        name={name}
        noOptionsMessage={() => noOptionsMessage}
        options={optionsWithSelectAll}
        filterOption={(option) => filterOption(option as Option<V>)}
        placeholder={placeholder}
        readOnly={readOnly}
        styles={
          getStyles({ isEmbedded, isWidthRelative }) as Styles<
            Option<V>,
            boolean
          >
        }
        value={optionValue}
        onChange={(updatedOption, meta) => {
          const updatedValue = isMulti
            ? (updatedOption as Option<V>[]).map(toValue)
            : (updatedOption as Option<V>)?.value ?? null;
          handleUpdatedValues(updatedValue, meta);
        }}
        onCreateOption={
          onAddOption
            ? (newValue) => {
                const isNewValue = !options.some(
                  (option) => option.value === newValue,
                );
                if (isNewValue) {
                  onAddOption({ value: newValue as V, label: newValue });
                  const updatedValue = isMulti
                    ? ([...((value as V[]) || []), newValue] as V[])
                    : (newValue as V);
                  handleUpdatedValues(updatedValue, {
                    action: 'create-option',
                  });
                }
              }
            : undefined
        }
        onBlur={onBlur}
        onFocus={onFocus}
        onInputChange={(updatedSearch: string, meta) => {
          if (meta.action === 'input-change') {
            handleSearch(updatedSearch);
          }
        }}
        // non-react-select props
        enableErrorMessage={enableErrorMessage}
        enableControl={enableControl}
        enableOptionIndicator={enableOptionIndicator}
        enableSearchIcon={enableSearchIcon}
        enableValueContainer={isEmbedded ? false : enableValueContainer}
        error={error}
        inputWidth={width} // do not assign to "width" prop (would affect the vendor's behavior)
        isEmbedded={isEmbedded}
        menuHeader={menuHeader}
        menuPlacement={menuPlacement}
        sortProps={sortProps}
        role={isSearchable ? 'combobox' : 'listbox'}
      />
    );
  },
);
