import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Reusable state hook that sets URL params.
 * @param {string} paramKey
 * @returns {Array} [updated query param value, updater function that takes param's new value]
 */
export function useSearchParams(paramKey) {
  if (!paramKey) throw new Error("expected parameter 'paramKey'");

  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const queryParamValue = query.get(paramKey);
  const [value, setValue] = useState(queryParamValue);

  useEffect(() => setValue(queryParamValue), [queryParamValue]);

  function updateValue(updatedValue) {
    setValue(updatedValue);

    query.set(paramKey, updatedValue);
    history.replace({ path: history.location.pathname, search: `?${query}` });
  }

  return [value, updateValue];
}
