import { add, format, sub } from 'date-fns';

import { getIntervalValues } from '~/components/DashboardV2/DashboardV2.utils';
import { formatDate as edsFormatDate, parseDate, pluralize } from '~/eds';
import { NumeralFormatType } from '~/enums';
import { Bucket } from '~/types';
import { formatNumeral } from '~/utils';
import { capitalizeWords } from '~/utils/strings';

const intervalMapping: Record<string, string> = {
  M: 'MONTH',
  d: 'DAY',
  y: 'YEAR',
};
const defaultDateFnsFormat = 'yyyy-MM-dd';
const dateFnsFormatMapping: Record<string, string> = {
  M: 'yyyy-MM',
  d: 'yyyy-MM-dd',
  y: 'yyyy',
};
const durationMapping: Record<string, string> = {
  M: 'months',
  d: 'days',
  y: 'years',
};

export const formatTitleCase = ({
  label,
  value,
  bucketValue,
}: Bucket): Bucket => {
  return { label: capitalizeWords(label), value, bucketValue: bucketValue };
};

export const formatDate = (
  { label, value }: Bucket,
  interval: string,
  multiple: number,
  deprecateMoment: boolean,
  selectedInterval?: string,
) => {
  if (multiple > 1) {
    const labelDate = parseDate(label, deprecateMoment);

    if (labelDate) {
      const lastDate = sub(
        add(labelDate, { [durationMapping[interval]]: multiple }),
        { days: 1 },
      );
      const lastDateFormatted = format(
        lastDate,
        dateFnsFormatMapping[interval],
      );

      const dateFnsObject = {
        label: `${format(
          labelDate,
          dateFnsFormatMapping[interval] || defaultDateFnsFormat,
        )} to ${lastDateFormatted}`,
        bucketValue: `${edsFormatDate(
          labelDate,
          'iso_date',
        )} to ${edsFormatDate(lastDate)}`,
        value,
      };

      return dateFnsObject;
    } else {
      return { label: 'null', bucketValue: 'null', value };
    }
  } else {
    const intervalForValues = selectedInterval
      ? selectedInterval
      : intervalMapping[interval];
    const parsedDate = parseDate(label, deprecateMoment);
    //* parsedDate should not be null but in case it is for now we will return empty array.
    const intervalValues = parsedDate
      ? getIntervalValues(parsedDate, intervalForValues)
      : [];
    const formattedDate = parsedDate
      ? format(
          parsedDate,
          dateFnsFormatMapping[interval] || defaultDateFnsFormat,
        )
      : null;
    return {
      label: `${formattedDate}`,
      bucketValue: intervalValues
        .map((intervalValue) => edsFormatDate(intervalValue))
        .join(' to '),
      value,
    };
  }
};

export const formatBoolean = ({ label, value }: Bucket) => {
  return { label: String(label) === '1' ? 'True' : 'False', value };
};

export const formatNumber = ({ label, value }: Bucket, interval: number) => {
  const truncatedLabel = Math.trunc(Number(label));
  const firstNumber = formatNumeral(NumeralFormatType.Number)(truncatedLabel);
  if (interval > 1) {
    const lastNumber = formatNumeral(NumeralFormatType.Number)(
      truncatedLabel + interval - 1,
    );
    return {
      label: `${firstNumber} - ${lastNumber}`,
      bucketValue: `${label} - ${Number(label) + interval - 1}`,
      value,
    };
  } else {
    return { label: firstNumber, bucketValue: Number(label), value };
  }
};

export const formatAge = (bucket: Bucket, interval = 0) => {
  const numericLabel = Number(bucket.label);
  const label =
    interval === 1
      ? `${pluralize(numericLabel, 'day')}`
      : `${bucket.label} - ${interval + numericLabel - 1} days`;
  return {
    ...bucket,
    label,
    bucketValue: `${bucket.label} - ${interval + numericLabel - 1}`,
  };
};
